import React from "react";
import { Context } from "./Report";
import { useTranslation } from "react-i18next";
import { TickLocalModal } from "../../modals/TickLocalModal";
import styles from "./report.module.scss";
import { Form, Button, Checkbox } from "antd";
import i18n from "../../i18n"

const ReportExportModal = () => {
  const context = React.useContext(Context);
  const [checkedList, setCheckedList] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [language, setLanguage] = React.useState(i18n.language);
  
  const { t } = useTranslation();
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (!context?.adminFormConfigs?.order) return;
    const ops = context?.adminFormConfigs?.order.split(",");
    ops.forEach((options, index) => {
      ops[index] = {
        label: options,
        value: options
      }
    })
    setOptions(ops);
  }, [context?.adminFormConfigs?.order])

  const onChange = (list) => {
    setCheckedList(list);
  };

  const onCloseModal = () => {
    form.resetFields();
    context.setIsExportModalVisible(false);
  };

  const onAllSelect = () => {
    const ops = context?.adminFormConfigs?.order.split(",");
    setCheckedList(ops)
  };

  const onHandleSubmit = () => {
    context.onExportClick(checkedList.join(","));
  };

  return (
    <TickLocalModal
      onSubmit={onHandleSubmit}
      showLocalModal={context.isExportModalVisible}
      onCancel={onCloseModal}
      title={t("t_exportSettings")}
      width={"500px"}
      okText={t("t_export")}
      form={form}
    >
      <div className={styles.drawerContainer}>
        <div className={styles.border} />
        <Form
          layout={'vertical'}
          form={form}
        >
          <div style={{ marginBottom: '20px' }}>
            <Button style={{ marginRight: '10px' }} onClick={onAllSelect}>{t("t_checkAll")}</Button>
            <Button onClick={() => setCheckedList([])}>{t("t_uncheckAll")}</Button>
          </div>
          <div className={styles.checkboxContainer}>
            <Checkbox.Group options={options} value={checkedList} onChange={onChange} />
          </div>
          {context.pagination?.total > 500 &&
            <b style={{ display: 'flex', justifyContent: 'space-around', color: 'red' }}>
              {language === 'en' ?
                `***${context.pagination?.total} items will be export (may take a while)***` :
                `***${context.pagination?.total}個項目將會匯出，此動作需時***`
              }
            </b>
          }
        </Form >
      </div>
    </TickLocalModal>
  )
}

export default ReportExportModal