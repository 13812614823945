import { useEffect, useState } from "react";
import { Button, Divider, Input, Modal, Select, Space } from "antd";
import { lang } from "../../config/systemConfig";
import { useTranslation } from "react-i18next";
import ColorApi from "../../service/colorApi/colorApi";
import BrandApi from "../../service/brandApi/brandApi";
import RatingApi from "../../service/ratingApi/ratingApi";
import AccessoryApi from "../../service/accessoryApi/accessoryApi";
import OriginApi from "../../service/originApi/originApi";
import MaterialApi from "../../service/materialApi/materialApi";
import CategoryApi from "../../service/categoryApi/categoryApi";
import TransactionTypeApi from "../../service/transactionTypeApi/transactionTypeApi";
import BankApi from "../../service/bankApi/bankApi";
import ConsignorApi from "../../service/consignorApi/consignorApi";
import AddConsigneeModal from "../addConsigneeModal/AddConsigneeModal";
import i18n from '../../i18n';
import CatApi from "../../service/catApi/catApi";
import CortexApi from "../../service/cortexApi/cortexApi";
import SystemRoleApi from "../../service/systemApi/systemRoleApi";
import PaymentCatOptsApi from "../../service/paymentApi/paymentCatOptsApi";

const CommonSelection = (props) => {
    const {
        allowClear = true,
        showSearch = true,
        value = undefined,
        optionKey = "",
        size = {
            width: "100%",
            margin: "0.5rem 0"
        },
        disabled = false,
        showChi = false,
        mode = "",
        style = {},
        defaultValue = undefined,
        instantAdd = false,
    } = props;
    const { t } = useTranslation();

    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [searchString, setSearchString] = useState("");
    const [language, setLanguage] = useState(i18n.language);

    const [openAddConsigneeModal, setOpenAddConsigneeModal] = useState(false);

    useEffect(() => {
        listSelectOptions();
    }, []);

    const listSelectOptions = (input) => {
        setLoading(true);
        switch (optionKey) {
            case `accessoriesIds`:
                AccessoryApi.listSelectOptions()
                    .then((resp) => {
                        console.log(resp, "resp")
                        let o = [];
                        resp?.data?.forEach((item) => {
                            o.push({
                                label: language === 'tc_hk' ? `${item.nameTc}${item.code !== null ? " | " + item.code : ""} ` : item.nameEn ? `${item.nameEn}${item.code !== null ? " | " + item.code : ""} ` : `${item.nameTc}${item.code !== null ? " | " + item.code : ""} `,
                                value: item.id,
                                remarks: `${item.nameTc ? item.nameTc:item.nameEn} | ${item.nameEn ? item.nameEn:item.nameTc}`
                            });
                        });
                        // console.log(o)
                        setOptions(o);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                break;
            case `brand`:
                BrandApi.listSelectOptions()
                    .then((resp) => {
                        let o = [];
                        resp?.data?.forEach((item) => {
                            o.push({
                                label: item[`name${lang}`],
                                value: item.itemDeptKey,
                                remarks: `${item.nameTc ? item.nameTc:item.nameEn} | ${item.nameEn ? item.nameEn:item.nameTc}`
                            });
                        });
                        // console.log(o)
                        setOptions(o);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                break;
            case `category`:
                CategoryApi.listSelectOptions()
                    .then((resp) => {
                        let o1 = [];
                        let o2 = [];
                        resp?.data?.bags.forEach((item) => {
                            o1.push({
                                label: language === 'tc_hk' ? item.nameTc : item.nameEn ? item.nameEn : item.nameTc,
                                value: item.itemSubCatKey,
                                remarks: `${item.nameTc ? item.nameTc:item.nameEn} | ${item.nameEn ? item.nameEn:item.nameTc}`
                                // disabled: item.isDeleted,
                            });
                        });
                        resp?.data?.accessories.forEach((item) => {
                            o2.push({
                                label: language === 'tc_hk' ? item.nameTc : item.nameEn ? item.nameEn : item.nameTc,
                                value: item.itemSubCatKey,
                                remarks: `${item.nameTc ? item.nameTc:item.nameEn} | ${item.nameEn ? item.nameEn:item.nameTc}`
                                // disabled: item.isDeleted,
                            });
                        });
                        setOptions([...o1, ...o2]);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                break;
            case `colorIds`:
                ColorApi.listSelectOptionsCats().then((colorCats) => {
                    ColorApi.listSelectOptions()
                        .then((resp) => {
                            let o = [];
                            resp?.data?.forEach((item) => {
                                o.push({
                                    label: language === 'tc_hk' ? `${item.nameTc}${item.nameEn && " | " + item.nameEn} ` : item.nameEn ? `${item.nameEn}${item.nameTc && " | " + item.nameTc} ` : `${item.nameTc}${item.nameEn && " | " + item.nameEn} `,
                                    value: item.id,
                                    // disabled: item.isDeleted,
                                    remarks: `${item.nameTc} ${item.nameEn} ${colorCats.data?.find(i => i.id === item.colorCategoryId)?.nameTc} ${colorCats.data?.find(i => i.id === item.colorCategoryId)?.nameEn}`
                                });
                            });
                            setOptions(o);
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                })

                break;
            case `materialIds`:
                MaterialApi.listSelectOptions()
                    .then((resp) => {
                        let o = [];
                        resp?.data?.forEach((item) => {
                            o.push({
                                label: language === 'tc_hk' ? item.nameTc : item.nameEn ? item.nameEn : item.nameTc,
                                value: item.id,
                                remarks: `${item.nameTc ? item.nameTc:item.nameEn} | ${item.nameEn ? item.nameEn:item.nameTc}`
                                // disabled: item.isDeleted,
                            });
                        });
                        setOptions(o);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                break;
            case `originIds`:
                OriginApi.listSelectOptions()
                    .then((resp) => {
                        let o = [];
                        resp?.data?.forEach((item) => {
                            o.push({
                                label: language === 'tc_hk' ? item.nameTc : item.nameEn ? item.nameEn : item.nameTc,
                                value: item.id,
                                remarks: `${item.nameTc ? item.nameTc:item.nameEn} | ${item.nameEn ? item.nameEn:item.nameTc}`
                                // disabled: item.isDeleted,
                            });
                        });
                        setOptions(o);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                break;
            case `ratingIds`:
                RatingApi.listSelectOptions()
                    .then((resp) => {
                        let o = [];
                        resp?.data?.forEach((item) => {
                            o.push({
                                label: language === 'tc_hk' ? item.nameTc : item.nameEn ? item.nameEn : item.nameTc,
                                value: item.id,
                                remarks: `${item.nameTc ? item.nameTc:item.nameEn} | ${item.nameEn ? item.nameEn:item.nameTc}`
                            });
                        });
                        // console.log(o)
                        setOptions(o);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                break;
            case `transactionTypeId`:
                TransactionTypeApi.listSelectOptions()
                    .then((resp) => {
                        let o = [];
                        resp?.data?.forEach((item) => {
                            o.push({
                                label: item[`name${language === 'tc_hk' ? 'Tc' : 'En'}`],
                                value: item.id,
                                remarks: `${item.nameTc ? item.nameTc:item.nameEn} | ${item.nameEn ? item.nameEn:item.nameTc}`
                            });
                        });
                        // console.log(o)
                        setOptions(o);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                break;
            case `bankCode`:
                BankApi.listSelectOptions()
                    .then((resp) => {
                        let o = [];
                        resp?.data?.forEach((item) => {
                            o.push({
                                label: item.code,
                                value: item.code,
                                
                            });
                        });
                        setOptions(o);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                break;
            case `consignor`:
                let payload = undefined;
                if (input) payload = input;
                else if (value) payload = value;
                ConsignorApi.listSelectionOptions(payload)
                    .then((resp) => {
                        let o = [];
                        resp?.data?.forEach((item) => {
                            o.push({
                                label: `${item.fullName}|${item.phoneNum}`,
                                value: item?.id,
                            });
                        });
                        setOptions(o);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                break;
            case `colorCats`:
                ColorApi.listSelectOptionsCats()
                    .then((resp) => {
                        let o = [];
                        resp?.data?.forEach((item) => {
                            o.push({
                                label: `${item.nameTc} (${item.nameEn})`,
                                value: item.id,
                                remarks: `${item.nameTc ? item.nameTc:item.nameEn} | ${item.nameEn ? item.nameEn:item.nameTc}`
                            });
                        });
                        setOptions(o);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                break;
            case `itemCat`:
                CatApi.listSelectOptions()
                    .then((resp) => {
                        let o = [];
                        resp?.data?.forEach((item) => {
                            o.push({
                                label: language === 'tc_hk' ? item.nameTc : item.nameEn,
                                value: item.id,
                                remarks: `${item.nameTc ? item.nameTc:item.nameEn} | ${item.nameEn ? item.nameEn:item.nameTc}`
                            });
                        });
                        setOptions(o);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                break;
            case `cortexIds`:
                CortexApi.listSelectOptions()
                    .then((resp) => {
                        let o = [];
                        resp?.data?.forEach((item) => {
                            o.push({
                                label: item.nameEn,
                                value: item.id,
                            });
                        });
                        setOptions(o);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                break;
            case `roles`:
                SystemRoleApi.listSelectOptions()
                    .then((resp) => {
                        let o = [];
                        resp?.data?.forEach((item) => {
                            o.push({
                                label: item.name,
                                value: item.id,
                            });
                        });
                        setOptions(o);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                break;
            case `paymentCat`:
                PaymentCatOptsApi.listSelectOptions()
                    .then((resp) => {
                        let o = [];
                        resp?.data?.forEach((item) => {
                            o.push({
                                label: item[`title${language === 'tc_hk' ? 'Tc' : 'En'}`],
                                value: item.id,
                                remarks: `${item.nameTc ? item.nameTc:item.nameEn} | ${item.nameEn ? item.nameEn:item.nameTc}`
                            });
                        });
                        setOptions(o);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                break;
        }
    };

    const triggerChange = (changedValue) => {
        // console.log("trigger change")
        // console.log(changedValue)
        // console.log("props!!!!!", props)
        props.onChange?.(changedValue);
    };

    const onChange = (value) => {
        const selectedOption = options.find((option) => option.value === value);
        // console.log("selectedOption?.label", selectedOption?.label);
        setSearchString(selectedOption?.label);
        triggerChange(value);
    };

    const onSearch = (value) => {
        // console.log("on search", value)
        setSearchValue(value)
        listSelectOptions(value)
    };

    const RenderConsignorOptions = () => (
        options.map((item, index) => {
            const { label } = item;
            const newLabel = label.split('|');
            return (
                <Select.Option value={item.value} label={label} key={`each_option_${index}`}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span style={{ flex: 2 }}>{newLabel[0]}</span>
                        <span style={{ flex: 1 }}>{newLabel[1]}</span>
                    </div>
                </Select.Option>
            )
        })
    )

    const DropdownRender = (menu) => (
        <>
            <Button onClick={() => setOpenAddConsigneeModal(true)} style={{ width: "100%" }}>
                {t("t_addConsignor")}
            </Button>
            <Divider style={{ margin: '8px 0' }} />
            {menu}
        </>
    )

    return (
        <>
            <Select
                allowClear={allowClear}
                showSearch={showSearch}
                size={size}
                disabled={disabled}
                value={value}
                options={optionKey !== 'consignor' ? options : undefined}
                loading={loading}
                onChange={onChange}
                placeholder={t("t_pleaseSelect")}
                filterOption={(input, option) => 
                    // (option?.label).toLowerCase().includes(input.toLowerCase())
                    option.remarks ? (option?.remarks).toLowerCase().includes(input.toLowerCase()) : (option?.label).toLowerCase().includes(input.toLowerCase())
                }
                onSearch={onSearch}
                mode={mode}
                style={style}
                defaultValue={defaultValue}
                dropdownRender={optionKey == 'consignor' && instantAdd ? DropdownRender : undefined}
            >
                {optionKey == 'consignor' && RenderConsignorOptions()}
            </Select>
            <AddConsigneeModal
                open={openAddConsigneeModal}
                onCancel={() => setOpenAddConsigneeModal(false)}
            />
        </>

    );
};
export default CommonSelection;