import { showLog } from "../config/systemConfig";

export const tickLog = (prefix, content) => {
  if (showLog && content) {
    console.log(`=====${prefix}=====`);
    console.log(content);
    console.log(`=====end of ${prefix}=====`);
  } else {
    console.log(`=====${prefix}=====`);
  }
};
