import { MAG } from "../api";

export default class SystemUserApi {
  static suffix = "/api/system/user";

  static listByPage(params = {}) {
    const {
      pageNum = 1,
      pageSize = 15,
      sortField = "id",
      sortOrder = "ASC",
      input = ""
    } = params;
    let url = `${this.suffix}/listByPage/${pageNum}/${pageSize}?`;

    if (input != "") {
      url = url.concat(`&input=${input}`);
    }
    return MAG.get(url);
  }

  static save(item) {
    return MAG.post(`${this.suffix}/save`, item);
  }

  static update(id, item) {
    return MAG.put(`${this.suffix}/update/${id}`, item);
  }

  static delete(id) {
    return MAG.put(`${this.suffix}/delete/${id}`);
  }

  static activeOnChange(id) {
    return MAG.put(`${this.suffix}/activeOnChange/${id}`);
  }
}