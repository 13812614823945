import React, { useState, useRef, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { LockTwoTone, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useSelector, useDispatch } from "react-redux";
import BackgroundImg from "../../assets/img/background/lhLoginBackground.svg";
import LoginImg from "../../assets/img/logo/lhLoginImg2.svg";
import LoginLogo from "../../assets/img/logo/lhLoginLogo.svg";
import variables from "../../theme/variable.scss";
import styles from "./loginForm.module.scss";
import { tickLog } from "../../utils/logger";
import { closeModal, showModal } from "../../redux/tickModalSilce";
import SystemApi from "../../service/systemApi/systemApi";
// import { FormattedMessage, useIntl } from "react-intl";
// import { lhBasePath } from "../../config";
// import {
//   getAuthorization,
//   getPermission,
// } from "../../reducers/authSlice/authSlice";

const LoginForm = (props) => {
  const {
    // hasBackgroundImg = false,
    backgroundImg = null,
    backgroundColor = null,
    hasTitleBar = false,
    // loginImg,
    loginLogo,
    // loginError,
    children, // normally will be footer text
  } = props;
  // const [loginFormOnSubmitRef, setLoginFormOnSubmitRef] = useState({
  //   username: null,
  //   password: null,
  // });
  const [form] = Form.useForm();
  // const intl = useIntl();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onLoginClick = async (e) => {
    e.preventDefault();
    form
      .validateFields()
      ?.then(async (formValue) => {
        if (formValue.username == undefined) {
          //TODO prompt error
          dispatch(
            showModal({
              key: "warning",
              header: "This is a warning message",
              msg: "username cannot be empty",
              // showCloseBtn: true,
              callback: () => {
                dispatch(closeModal());
              },
            }))
          return tickLog("username cannot be empty");
        }
        if (formValue.password == undefined) {
          //TODO prompt error
          dispatch(
            showModal({
              key: "warning",
              header: "This is a warning message",
              msg: "password cannot be empty",
              // showCloseBtn: true,
              callback: () => {
                dispatch(closeModal());
              },
            }))
          return tickLog(" password cannot be empty");
        }
        formValue.rememberMe = true
        SystemApi.login(formValue).then((res) => {
          if (res.success) {
            localStorage.setItem(
              "USERINFO",
              JSON.stringify(res.data.user)
            );
            localStorage.setItem(
              "MENU",
              JSON.stringify(res.data.menus)
            );
            localStorage.setItem(
              "ROLE",
              JSON.stringify(res.data.role)
            );
            localStorage.setItem(
              "PERMISSION",
              JSON.stringify(res.data.permissions)
            );
            localStorage.setItem(
              "TempItems",
              JSON.stringify([])
            );
            if (res.data.menus.length > 0) {
              navigate(`${res.data.menus[0].path}`, { replace: true });
            } else {
              tickLog('No permission to access any pages');
            }
          } else {
            dispatch(
              showModal({
                key: "warning",
                header: "This is a warning message",
                msg: res.msg,
                // showCloseBtn: true,
                callback: () => {
                  dispatch(closeModal());
                },
              }))
            tickLog(res.msg)
          }
        })
      })
      .catch((e) => {
        tickLog("onLoginClick catch", e);
      });
  };

  const handleKeyDown = (e) => {
    if (e.key == "Enter") {
      onLoginClick(e);
    }
  };

  return (
    <div
      style={{
        backgroundImage: backgroundImg ? `url(${BackgroundImg})` : "",
        backgroundColor: backgroundColor ? backgroundColor : "",
      }}
      className={`
      ${styles.background}
      ${hasTitleBar ? styles.heightWithTitleBar : styles.heightWithoutTitleBar}
        ${backgroundImg ? styles.backgroundImg : ""}`}
    >
      <div className={styles.loginFormContainer}>
        <img
          className={styles.loginFormImgContainer}
          src={LoginImg}
          alt="login image"
        />
        <div className={styles.loginFormInputContainer}>
          <img src={LoginLogo} alt="logo" className={styles.loginFormLogo} />
          <Form layout={"vertical"} form={form} onKeyDown={handleKeyDown}>
            <Form.Item
              name="username"
              rules={[
                {
                  // required: true,
                  // message:
                  // intl.formatMessage({ id: "loginUsernameError" }),
                  // ...errUserNameMsg && {
                  //   help: errUserNameMsg,
                  //   validateStatus: 'error',
                  // }
                },
              ]}
            >
              <Input
                autoFocus={hasTitleBar ? false : true}
                name="username"
                size="large"
                placeholder="User ID"
                prefix={<UserOutlined style={{ color: variables.themeBlue }} />}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  // required: true,
                },
              ]}
            >
              <Input.Password
                name="password"
                size="large"
                placeholder="Password"
                prefix={<LockTwoTone />}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                block
                // loading={isLoading}
                className="loginButton"
                onClick={onLoginClick}
              >
                {t("t_signIn")}
              </Button>
            </Form.Item>
          </Form>
          {children}
        </div>
      </div>
    </div>
  );
};

export default memo(LoginForm);
