import { MAG } from "../api";

export default class ColorApi {
  static suffix = "/api/color";

  static listSelectOptions() {
    return MAG.get(`${this.suffix}/listSelectOptions`);
  }

  static listSelectOptionsCats() {
    return MAG.get(`${this.suffix}/listSelectOptionsCats`);
  }
}
