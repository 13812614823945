import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import styles from "./tickDrawer.module.scss";
import closeIcon from "../../assets/img/icon/close_icon.svg";
import { clearTempItemData } from "../../redux/tickTableSlice";

const TickDrawer = (props, ref) => {
  const { children, title, open = false, setOpen = () => { } } = props;
  const __tickDrawer = useRef(null);
  const dispatch = useDispatch();


  useEffect(() => {
    if (open) {
      __tickDrawer.current.setAttribute("data-expanded", "true");
    } else {
      __tickDrawer.current.setAttribute("data-expanded", "false");
    }
  }, [open]);

  function onCloseDrawer() {
    setOpen(false)
    dispatch(clearTempItemData())
  }

  return (
    <div ref={__tickDrawer} className={styles.container} data-expanded="false">
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <img
          src={closeIcon}
          alt="cross_close_icon"
          className={styles.crossClose}
          onClick={() => onCloseDrawer()}
        />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default TickDrawer;
