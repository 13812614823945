import React from 'react'
import { useSelector } from 'react-redux';

const TickRemark = () => {
  const itemsDrawerProductDetails = useSelector((state) => state.tickTable.itemsDrawerProductDetails);
  return (
    <div>{itemsDrawerProductDetails?.remark || "-"}</div>
  )
}

export default TickRemark