import React, { useEffect, useState } from 'react'
import { Table } from 'antd';
import ReactDragListView from 'react-drag-listview';
import { Resizable } from 'react-resizable';
import variable from '../../theme/variable.scss';
import styles from './tickTable.module.scss';

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }
  return (
    <Resizable
      width={width}
      height={500}
      handle={
        <span
          className={[styles.reactResizableHandle]}
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} className={`${restProps.className} ${styles.grab}`} />
    </Resizable>
  )
}


const TickTable = (props) => {
  const { 
    dataSource,
    columns,
    pagination = false,
    rowKey,
    loading,
    scroll = {
      x: variable.tableMinWidth, 
      y: variable.tableMaxHeight
    },
    onChange,
    rowClassName,
    showSorterTooltip = false,
    onRow,
  } = props;

  const [cols, setCols] = useState([]);

  useEffect(() => {
    if (!columns || columns.length === 0) return;
    handleColumns();
  }, [columns])

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const column = [...cols];
      if (column[fromIndex]?.fixed || column[toIndex]?.fixed) return;
      const item = column.splice(fromIndex, 1)[0];
      column.splice(toIndex, 0, item);
      setCols(column);
    },
    nodeSelector: "th",
    handleSelector: ".dragHandler",
    ignoreSelector: "reactResizableHandle"
  };

  const handleResize = (dataIndex) => 
  (e, { size }) => {
    setCols((columns) => {
      const index = columns.findIndex(col => col.dataIndex === dataIndex)
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width >= 60 ? size.width : 60 ,
      };
      return nextColumns;
    });
  }

  const handleColumns = () => {
    const newColumns = columns.map( col => ({
        ...col,
        onHeaderCell: (column) => ({
          width: column.width,
          onResize: handleResize(column.dataIndex)
        }),
        title: <span className="dragHandler">{col.title}</span>,
        width: col?.width || 100
      })
    )
    setCols(newColumns)
    // return newColumns;
  }

  return (
    <ReactDragListView.DragColumn {...dragProps}>
      <Table
        dataSource={dataSource}
        columns={cols}
        pagination={pagination}
        rowKey={rowKey}
        loading={loading}
        scroll={scroll}
        components={{
          header: {
            cell: ResizableTitle
          }
        }}
        onChange={onChange}
        rowClassName={rowClassName}
        showSorterTooltip={showSorterTooltip}
        onRow={onRow}
      />
    </ReactDragListView.DragColumn>
  )
}

export default TickTable