import React, { memo, useEffect, useState } from "react";
import TitleBar from "../../components/titleBar/TitleBar";
import variables from "../../theme/variable.scss";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import styles from './master.module.scss';
import { tickLog } from "../../utils/logger";
import { useDispatch, useSelector } from "react-redux"
import { closeModal, showModal } from "../../redux/tickModalSilce";
import { setMasterTableData, setMasterParams } from "../../redux/tickTableSlice";
import TickTable from "../../components/tickTable/TickTable";
import TitleBarMasterPaginationContent from "../../components/titleBarMasterPaginationContent/TitleBarMasterPaginationContent";
import MasterModal from "../../modals/MasterModal";
import MasterApi from "../../service/masterApi/masterApi";
import CatApi from "../../service/catApi/catApi";
import i18n from '../../i18n';
import { Tooltip } from 'antd';

const Master = (props) => {
  const {  } = props
  const [dataSource, setDataSource] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [itemCats, setItemCats] = useState([]);
  const [masterTableLoading, setMasterTableLoading] = useState(false);
  const [showMasterModal, setShowMasterModal] = useState(false);
  const [buttonType, setButtonType] = useState('add');
  const [language, setLanguage] = useState(i18n.language);
  const permissions = JSON.parse(localStorage.getItem('PERMISSION'))

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const masterTableParams = useSelector(state => state.tickTable.masterParams);

  useEffect(() => {
    listByPage()
    listItemCats()
  }, [masterTableParams])

  const listByPage = async () => {
    setMasterTableLoading(true);
    try {
      const resp = await MasterApi.listByPage(masterTableParams);
      tickLog("all product data", resp)
      if (resp.success) {
        const { data, msg } = resp
        setDataSource(data.array)
        dispatch(
          setMasterTableData({ totalCount: data.totalCount })
        )
      }
    } catch {
    } finally {
      setMasterTableLoading(false);
    }
  }

  const listItemCats = async () => {
    try {
      const resp = await CatApi.listSelectOptions();
      if (resp.success) {
        const { data } = resp
        setItemCats(data)
      }
    } catch {
    } finally {
      setMasterTableLoading(false);
    }
  }
  
  function onEdit(record) {
    setModalData(record)
    setShowMasterModal(true)
    setButtonType("update")
  }

  function onDelete(record) {
    dispatch(
      showModal({
        key: "warning",
        header: t("t_delete"),
        msg: t("t_confirmDelete"),
        showCloseBtn: true,
        callback: async () => {

          try {
            let resp = await MasterApi.delete(record.id, masterTableParams.type)
            const { msg } = resp
            if (resp.success) {
              dispatch(
                showModal({
                  key: "success",
                  header: "Deleted",
                  msg: msg,
                  callback: () => {
                    dispatch(closeModal());
                  },
                })
              );
              listByPage()
            } else {
              dispatch(
                showModal({
                  key: "error",
                  header: "This is an error message",
                  msg: msg,
                  callback: () => {
                    dispatch(closeModal());
                  },
                })
              );
            }
          } catch (e) {
            console.log(e);
          }
        },
      })
    );
  }

  const onReload = () => {
    dispatch(setMasterParams({...masterTableParams, type: masterTableParams.type, sortOptions: {}, input: '', pageNum: 1 }));
  }
  

  const handleSortKey = (sortOrder) => {
    let sortKey;
    if (sortOrder === 'ascend') {
      sortKey = 'ASC'
    }

    if (sortOrder === 'descend') {
      sortKey = 'DESC'
    }
    return sortKey;
  }
  
  const onChange = (pagination, filter, sorter) => {
    let sortOptions = {};
    if (Array.isArray(sorter)) {
      sorter.forEach(sortEle => {
        sortOptions[sortEle.columnKey] = handleSortKey(sortEle.order);
      })
    } else {
      sortOptions[sorter.columnKey] = handleSortKey(sorter.order);
    }
    
    if (!sorter.order) {
      delete sortOptions[sorter.columnKey]
    }

    dispatch(setMasterParams({
      ...masterTableParams,
      sortOptions,
      isCreate: false
    }))
  }

  const sortOrder  = (key) =>  {
    if (masterTableParams.sortOptions[key] === 'ASC') {
      return 'ascend';
    }
    if (masterTableParams.sortOptions[key] === 'DESC') {
      return 'descend';
    }
    return undefined;
  }

  const columns = {
    colorOptions: [
      {
        title: t("t_ccNameTc"),
        dataIndex: "ccNameTc",
        key: "colorCategoryId",
        sorter: {
          multiple: 4
        },
        sortOrder: sortOrder('colorCategoryId'),
        render: (_) => (
          <div>{_ || "-"}</div>
        )
      },
      {
        title: t("t_ccNameEn"),
        dataIndex: "ccNameEn",
        key: "colorCategoryId",
        sorter: {
          multiple: 3
        },
        sortOrder: sortOrder('colorCategoryId'),
        render: (_) => (
          <div>{_ || "-"}</div>
        )
      },
      {
        title: t("t_coNameTc"),
        dataIndex: "nameTc",
        key: "nameTc",
        sorter: {
          multiple: 2
        },
        sortOrder: sortOrder('nameTc'),
        render: (_) => (
          <div>{_ || "-"}</div>
        )
      },
      {
        title: t("t_coNameEn"),
        dataIndex: "nameEn",
        key: "nameEn",
        sorter: {
          multiple: 1
        },
        sortOrder: sortOrder('nameEn'),
        render: (_) => (
          <div>{_ || "-"}</div>
        )
      },
      {
        title: t("t_function"),
        dataIndex: "operate",
        key: "operate",
        fixed: 'right',
        width: 60,
        render: (_,
          record
        ) =>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            {
              permissions.findIndex((item) => item === "master:edit") > -1 ? 
              // <EditOutlined style={{ color: variables.themeBlue, padding: "5px" }} onClick={() => onEdit(record)} /> :
              <EditOutlined style={{ color: variables.themeBlue, fontSize: "24px" }} onClick={() => onEdit(record)} /> :
              <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
                {/* <EditOutlined style={{ color: "grey", padding: "5px" }}/> */}
                <EditOutlined style={{ color: "grey", fontSize: "24px" }}/>
              </Tooltip>
            }
            {
              permissions.findIndex((item) => item === "master:delete") > -1 ? 
              // <DeleteOutlined style={{ color: variables.themeRed, padding: "5px" }} onClick={() => onDelete(record)} /> :
              <DeleteOutlined style={{ color: variables.themeRed, fontSize: "24px" }} onClick={() => onDelete(record)} /> :
              <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
                {/* <DeleteOutlined style={{ color: "grey", padding: "5px" }} />  */}
                <DeleteOutlined style={{ color: "grey", fontSize: "24px" }} /> 
              </Tooltip>
            }          
          </div>
      }
    ],
    category: [
      {
        title: t("t_itemCatKey"),
        dataIndex: "itemCatKey",
        key: "itemCatKey",
        sorter: {
          multiple: 3
        },
        sortOrder: sortOrder('itemCatKey'),
        render: (_,
          record
        ) => {
          // const item = itemCats.find((item) => item.itemCatKey === record.itemCatKey)
          return <div>{language === 'tc_hk' ? record?.catNameTc : record?.catNameEn}</div>
        }
      },
      {
        title: t("t_nameTc"),
        dataIndex: "nameTc",
        key: "nameTc",
        sorter: {
          multiple: 2
        },
        sortOrder: sortOrder('nameTc'),
        render: (_) => (
          <div>{_ || "-"}</div>
        )
      },
      {
        title: t("t_nameEn"),
        dataIndex: "nameEn",
        key: "nameEn",
        sorter: {
          multiple: 1
        },
        sortOrder: sortOrder('nameEn'),
        render: (_) => (
          <div>{_ || "-"}</div>
        )
      },
      {
        title: t("t_function"),
        dataIndex: "operate",
        key: "operate",
        fixed: 'right',
        width: 60,
        render: (_,
          record
        ) =>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            {
              permissions.findIndex((item) => item === "master:edit") > -1 ? 
              // <EditOutlined style={{ color: variables.themeBlue, padding: "5px" }} onClick={() => onEdit(record)} /> :
              <EditOutlined style={{ color: variables.themeBlue, fontSize: "24px" }} onClick={() => onEdit(record)} /> :
              <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
                {/* <EditOutlined style={{ color: "grey", padding: "5px" }}/> */}
                <EditOutlined style={{ color: "grey", fontSize: "24px" }}/>
              </Tooltip>
            }
            {
              permissions.findIndex((item) => item === "master:delete") > -1 ? 
              // <DeleteOutlined style={{ color: variables.themeRed, padding: "5px" }} onClick={() => onDelete(record)} /> :
              <DeleteOutlined style={{ color: variables.themeRed, fontSize: "24px" }} onClick={() => onDelete(record)} /> :
              <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
                {/* <DeleteOutlined style={{ color: "grey", padding: "5px" }} />  */}
                <DeleteOutlined style={{ color: "grey", fontSize: "24px" }} /> 
              </Tooltip>
            }             
          </div>
      }
    ],
    accessory: [
      {
        title: t("t_nameTc"),
        dataIndex: "nameTc",
        key: "nameTc",
        sorter: {
          multiple: 2
        },
        sortOrder: sortOrder('nameTc'),
        render: (_) => (
          <div>{_ || "-"}</div>
        )
      },
      {
        title: t("t_nameEn"),
        dataIndex: "nameEn",
        key: "nameEn",
        sorter: {
          multiple: 1
        },
        sortOrder: sortOrder('nameEn'),
        render: (_) => (
          <div>{_ || "-"}</div>
        )
      },
      {
        title: t("t_code"),
        dataIndex: "code",
        key: "code",
        sorter: {
          multiple: 3
        },
        sortOrder: sortOrder('code'),
        render: (_) => (
          <div>{_ || "-"}</div>
        )
      },
      {
        title: t("t_function"),
        dataIndex: "operate",
        key: "operate",
        fixed: 'right',
        width: 60,
        render: (_,
          record
        ) =>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            {
              permissions.findIndex((item) => item === "master:edit") > -1 ? 
              // <EditOutlined style={{ color: variables.themeBlue, padding: "5px" }} onClick={() => onEdit(record)} /> :
              <EditOutlined style={{ color: variables.themeBlue, fontSize: "24px" }} onClick={() => onEdit(record)} /> :
              <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
                {/* <EditOutlined style={{ color: "grey", padding: "5px" }}/> */}
                <EditOutlined style={{ color: "grey", fontSize: "24px" }}/>
              </Tooltip>
            }
            {
              permissions.findIndex((item) => item === "master:delete") > -1 ? 
              // <DeleteOutlined style={{ color: variables.themeRed, padding: "5px" }} onClick={() => onDelete(record)} /> :
              <DeleteOutlined style={{ color: variables.themeRed, fontSize: "24px" }} onClick={() => onDelete(record)} /> :
              <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
                {/* <DeleteOutlined style={{ color: "grey", padding: "5px" }} />  */}
                <DeleteOutlined style={{ color: "grey", fontSize: "24px" }} /> 
              </Tooltip>
            }             
          </div>
      }
    ],
    commonColumns: [
      {
        title: t("t_nameTc"),
        dataIndex: "nameTc",
        key: "nameTc",
        sorter: {
          multiple: 2
        },
        sortOrder: sortOrder('nameTc'),
        render: (_) => (
          <div>{_ || "-"}</div>
        )
      },
      {
        title: t("t_nameEn"),
        dataIndex: "nameEn",
        key: "nameEn",
        sorter: {
          multiple: 1
        },
        sortOrder: sortOrder('nameEn'),
        render: (_) => (
          <div>{_ || "-"}</div>
        )
      },
      {
        title: t("t_function"),
        dataIndex: "operate",
        key: "operate",
        fixed: 'right',
        width: 60,
        render: (_,
          record
        ) =>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {
            permissions.findIndex((item) => item === "master:edit") > -1 ? 
            // <EditOutlined style={{ color: variables.themeBlue, padding: "5px" }} onClick={() => onEdit(record)} /> :
            <EditOutlined style={{ color: variables.themeBlue, fontSize: "24px" }} onClick={() => onEdit(record)} /> :
            <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
              {/* <EditOutlined style={{ color: "grey", padding: "5px" }}/> */}
              <EditOutlined style={{ color: "grey", fontSize: "24px" }}/>
            </Tooltip>
          }
          {
            permissions.findIndex((item) => item === "master:delete") > -1 ? 
            // <DeleteOutlined style={{ color: variables.themeRed, padding: "5px" }} onClick={() => onDelete(record)} /> :
            <DeleteOutlined style={{ color: variables.themeRed, fontSize: "24px" }} onClick={() => onDelete(record)} /> :
            <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
              {/* <DeleteOutlined style={{ color: "grey", padding: "5px" }} />  */}
              <DeleteOutlined style={{ color: "grey", fontSize: "24px" }} /> 
            </Tooltip>
          }             
      </div>
      }
    ],
  }

  return (
    <>
      <TitleBar hasSideBar hasShadow color={variables.themeWhite}>
        <TitleBarMasterPaginationContent
          setShowMasterModal={setShowMasterModal}
          setButtonType={setButtonType}
          reload={onReload}
        />
      </TitleBar>
      <div className={styles.container}>
        <div className={styles.tableWrapper}>
          <TickTable
            dataSource={dataSource}
            // columns={columns[`${masterTableParams.type}`] }
            columns={
              masterTableParams.type === "colorOptions" ? columns.colorOptions :
              masterTableParams.type === "category" ? columns.category :
              masterTableParams.type === "accessory" ? columns.accessory :
              columns.commonColumns
            }
            rowKey={(record) => record.id}
            loading={masterTableLoading}
            onChange={onChange}
          />         
        </div>
      </div>
      {showMasterModal &&
        <MasterModal
          showMasterModal={showMasterModal}
          setShowMasterModal={setShowMasterModal}
          width="500px"
          modalData={modalData}
          buttonType={buttonType}
          listByPage={listByPage}
        />}
    </>
  );
};

export default memo(Master);
