import { useTranslation } from "react-i18next";
import { TickLocalModal } from "./TickLocalModal";
import styles from "./consignmentModalV3.module.scss";
import { Form, Input } from "antd";
import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonSelection from "../components/commonSelection/CommonSelection";
import MasterApi from "../service/masterApi/masterApi";
import { closeModal, showModal } from "../redux/tickModalSilce";


const MasterModal = (props) => {
  const { showMasterModal, onSubmit, onCancel, setShowMasterModal, subCatOptions, buttonType, width, modalData, listByPage } = props;
  const { TextArea } = Input;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const tickTableState = useSelector((state) => state.tickTable)
  const masterParams = useSelector((state) => state.tickTable.masterParams)
  const dispatch = useDispatch()


  useEffect(() => {
    if (tickTableState?.consignment?.rowData?.consignor != {}) {
      form.setFieldsValue(tickTableState.consignment.rowData.consignor)
    }
  }, [tickTableState.consignment.rowData])

  useEffect(() => {
    if (buttonType === 'add') {
      console.log("THIS IS ADD");
    } else {
      console.log("THIS IS UPDATE");
      form.setFieldsValue({
        nameTc: modalData.nameTc,
        nameEn: modalData.nameEn,
        colorCategoryId: modalData.colorCategoryId,
        itemCat: modalData.itemCatId,
        code: modalData.code,
      })
    }
  }, [modalData])

  const onCloseModal = () => {
    form.resetFields();
    setShowMasterModal(false);
  };

  const onHandleSubmit = async (e) => {
    if (buttonType === 'add') {
      // console.log("THIS IS SUBMIT WITH ADD");
      const result = form.getFieldsValue()
      await MasterApi.save(masterParams.type, result).then((res) => {
        setShowMasterModal(false);
        listByPage();
        if (res.success) {
          dispatch(
            showModal({
              key: "success",
              header: "Add successfully",
              callback: () => {
                dispatch(closeModal());
              },
            })
          );
        } else {
          dispatch(
            showModal({
              key: "error",
              header: "This is an error message",
              msg: res.msg,
              callback: () => {
                dispatch(closeModal());
              },
            })
          );
        }
      })
    } else {
      // console.log("THIS IS SUBMIT WITH UPDATE");
      const result = form.getFieldsValue()
      await MasterApi.update(modalData.id, masterParams.type, result).then((res) => {
        console.log(res);
        setShowMasterModal(false);
        listByPage();
        if (res.success) {
          dispatch(
            showModal({
              key: "success",
              header: "Update successfully",
              callback: () => {
                dispatch(closeModal());
              },
            })
          );
        } else {
          dispatch(
            showModal({
              key: "error",
              header: "This is an error message",
              msg: res.msg,
              callback: () => {
                dispatch(closeModal());
              },
            })
          );
        }
      })
    }
  };

  const FormItem = {
    ColorOption: () => {
      return (
        <>
          <Form.Item
            label={t("t_colorCategory")}
            name="colorCategoryId"
            rules={[{ required: true }]}
          >
            <CommonSelection optionKey="colorCats" />
          </Form.Item>
          <Form.Item
            label={t("t_nameTc")}
            name="nameTc"
            rules={[{ required: true }]}
          >
            <Input
              name="nameTc"
              placeholder={t("t_pleaseEnter")}
            />
          </Form.Item>
          <Form.Item
            label={t("t_nameEn")}
            name="nameEn"
            // rules={[{ required: true }]}
          >
            <Input
              name="nameEn"
              placeholder={t("t_pleaseEnter")}
            />
          </Form.Item>
        </>
      )
    },
    Category: () => {
      return (
        <>
          <Form.Item
            label={t("t_itemCatKey")}
            name="itemCat"
            rules={[{ required: true }]}
          >
            <CommonSelection optionKey="itemCat" />
          </Form.Item>
          <Form.Item
            label={t("t_nameTc")}
            name="nameTc"
            rules={[{ required: true }]}
          >
            <Input
              name="nameTc"
              placeholder={t("t_pleaseEnter")}
            />
          </Form.Item>
          <Form.Item
            label={t("t_nameEn")}
            name="nameEn"
            rules={[{ required: true }]}
          >
            <Input
              name="nameEn"
              placeholder={t("t_pleaseEnter")}
            />
          </Form.Item>
        </>
      )
    },
    Brand: () => {
      return (
        <>
          <Form.Item
            label={t("t_nameTc")}
            name="nameTc"
            rules={[{ required: true }]}
          >
            <Input
              name="nameTc"
              placeholder={t("t_pleaseEnter")}
            />
          </Form.Item>
          <Form.Item
            label={t("t_nameEn")}
            name="nameEn"
            rules={[{ required: true }]}
          >
            <Input
              name="nameEn"
              placeholder={t("t_pleaseEnter")}
            />
          </Form.Item>
        </>
      )
    },
    Accessory: () => {
      return (
        <>
          <Form.Item
            label={t("t_nameTc")}
            name="nameTc"
            rules={[{ required: true }]}
          >
            <Input
              name="nameTc"
              placeholder={t("t_pleaseEnter")}
            />
          </Form.Item>
          <Form.Item
            label={t("t_nameEn")}
            name="nameEn"
            rules={[{ required: true }]}
          >
            <Input
              name="nameEn"
              placeholder={t("t_pleaseEnter")}
            />
          </Form.Item>
          <Form.Item
            label={t("t_code")}
            name="code"
          >
            <Input
              name="code"
              placeholder={t("t_pleaseEnter")}
            />
          </Form.Item>
        </>
      )
    },
    CommonFormItemsTcRequired: () => {
      return (
        <>
          <Form.Item
            label={t("t_nameTc")}
            name="nameTc"
            rules={[{ required: true }]}
          >
            <Input
              name="nameTc"
              placeholder={t("t_pleaseEnter")}
            />
          </Form.Item>
          <Form.Item
            label={t("t_nameEn")}
            name="nameEn"
            // rules={[{ required: true }]}
          >
            <Input
              name="nameEn"
              placeholder={t("t_pleaseEnter")}
            />
          </Form.Item>
        </>
      )
    },
    CommonFormItemsEnRequired: () => {
      return (
        <>
          <Form.Item
            label={t("t_nameTc")}
            name="nameTc"
            // rules={[{ required: true }]}
          >
            <Input
              name="nameTc"
              placeholder={t("t_pleaseEnter")}
            />
          </Form.Item>
          <Form.Item
            label={t("t_nameEn")}
            name="nameEn"
            rules={[{ required: true }]}
          >
            <Input
              name="nameEn"
              placeholder={t("t_pleaseEnter")}
            />
          </Form.Item>
        </>
      )
    },
  }

  return (
    <TickLocalModal
      onSubmit={onHandleSubmit}
      showLocalModal={showMasterModal}
      onCancel={onCloseModal}
      title={buttonType === 'add' ? `${t("t_add")} (${t(`t_${masterParams.type}`)})` : `${t("t_edit")} (${t(`t_${masterParams.type}`)})`}
      form={form}
      width={width}
      setVisible={setShowMasterModal}
    >
      <div className={styles.drawerContainer}>
        <div className={styles.border} />
        <Form
          layout={'vertical'}
          form={form}
        >
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div style={{ width: "100%", margin: "10px" }}>
              {masterParams.type === 'colorCategory' && <FormItem.CommonFormItemsTcRequired />}
              {masterParams.type === 'colorOption' && <FormItem.ColorOption />}
              {masterParams.type === 'category' && <FormItem.Category />}
              {masterParams.type === 'brand' && <FormItem.Brand />}
              {masterParams.type === 'rating' && <FormItem.CommonFormItemsTcRequired />}
              {masterParams.type === 'origin' && <FormItem.CommonFormItemsTcRequired />}
              {masterParams.type === 'material' && <FormItem.CommonFormItemsTcRequired />}
              {masterParams.type === 'accessory' && <FormItem.Accessory />}
              {masterParams.type === 'cortex' && <FormItem.CommonFormItemsEnRequired />}
            </div>
          </div>
        </Form >
      </div>
      {/* </div> */}
    </TickLocalModal>
  );
};

export default memo(MasterModal)