import React, { memo, useEffect, useState, useRef } from "react";
import TitleBar from "../../components/titleBar/TitleBar";
import variables from "../../theme/variable.scss";
import i18n from '../../i18n';
import { useTranslation } from "react-i18next";
import styles from './coupon.module.scss';
import TickTable from "../../components/tickTable/TickTable";
import { useDispatch, useSelector } from "react-redux"
import CouponApi from "../../service/couponApi/couponApi";
import { COUPON_STATUS } from "../../utils/couponStatusEnum";
import { setCouponParams, setCouponTableData } from "../../redux/tickTableSlice";
import TitleBarCouponPaginationContent from "../../components/titleBarCouponPaginationContent/TitleBarCouponPaginationContent";
import dayjs from "dayjs";
import CouponEditModal from "../../modals/CouponEditModal";
import { EditOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const Coupon = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false)
  const [modalData, setModalData] = useState([])
  const permissions = JSON.parse(localStorage.getItem('PERMISSION'))
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const couponParams = useSelector(state => state.tickTable.couponParams);
  useEffect(() => {
    listByPage()
  }, [couponParams])

  const listByPage = async () => {
    setTableLoading(true);
    try {
      let resp = await CouponApi.listByPage(couponParams)
      if (resp.success) {
        const { data } = resp
        setDataSource(data.array)
        dispatch(
          setCouponTableData({ totalCount: data.totalCount })
        )
      }
    } catch {
    } finally {
      setTableLoading(false);
    }
  }

  const onReload = () => {
    dispatch(setCouponParams({ ...couponParams, sortOptions: {}, input: '', pageNum: 1 }));
  }


  const handleSortKey = (sortOrder) => {
    let sortKey;
    if (sortOrder === 'ascend') {
      sortKey = 'ASC'
    }

    if (sortOrder === 'descend') {
      sortKey = 'DESC'
    }
    return sortKey;
  }

  const onChange = (pagination, filter, sorter) => {
    let sortOptions = {};
    if (Array.isArray(sorter)) {
      sorter.forEach(sortEle => {
        sortOptions[sortEle.columnKey] = handleSortKey(sortEle.order);
      })
    } else {
      sortOptions[sorter.columnKey] = handleSortKey(sorter.order);
    }

    if (!sorter.order) {
      delete sortOptions[sorter.columnKey]
    }

    dispatch(setCouponParams({
      ...couponParams,
      sortOptions,
    }))
  }

  const sortOrder = (key) => {
    if (couponParams.sortOptions[key] === 'ASC') {
      return 'ascend';
    }
    if (couponParams.sortOptions[key] === 'DESC') {
      return 'descend';
    }
    return undefined;
  }

  // function handleDetails(record) {
  //   console.log(record);
  //   setOpen(true);
  //   setItemDetails(record);
  //   setSelectedItemList(record.items)
  // }

  const onEdit = (record) => {    
    setShowEditModal(true);
    setModalData(record);
  }

  const columns = [
    {
      title: t("t_couponNum"),
      dataIndex: "couponNum",
      key: "couponNum",
      sorter: {
        multiple: 5
      },
      sortOrder: sortOrder('couponNum'),
      render: (_, record) => (
        <div
          // style={{ cursor: "pointer" }}
        // onClick={() => handleDetails(record)}
        >
          {_ || "-"}
        </div>
      )
    },
    {
      title: t("t_couponAmount"),
      dataIndex: "amt",
      key: "amt",
      sorter: {
        multiple: 5
      },
      sortOrder: sortOrder('amt'),
      render: (_, record) => (
        <div
          // style={{ cursor: "pointer" }}
        // onClick={() => handleDetails(record)}
        >
          {Math.abs(_) || "-"}
        </div>
      )
    },
    {
      title: t("t_customerName"),
      dataIndex: "customerName",
      key: "customerName",
      sorter: {
        multiple: 5
      },
      sortOrder: sortOrder('customerName'),
      render: (_, record) => (
        <div
          // style={{ cursor: "pointer" }}
        // onClick={() => handleDetails(record)}
        >
          {_ || "-"}
        </div>
      )
    },
    {
      title: t("t_customerMobile"),
      dataIndex: "customerMobile",
      key: "customerMobile",
      sorter: {
        multiple: 5
      },
      sortOrder: sortOrder('customerMobile'),
      render: (_, record) => (
        <div
          // style={{ cursor: "pointer" }}
        // onClick={() => handleDetails(record)}
        >
          {_ || "-"}
        </div>
      )
    },
    {
      title: t("t_createTranNum"),
      dataIndex: "createTranNum",
      key: "createTranNum",
      sorter: {
        multiple: 5
      },
      sortOrder: sortOrder('createTranNum'),
      render: (_, record) => (
        <div
          // style={{ cursor: "pointer" }}
        // onClick={() => handleDetails(record)}
        >
          {_ || "-"}
        </div>
      )
    },
    {
      title: t("t_redeemTranNum"),
      dataIndex: "redeemTranNum",
      key: "redeemTranNum",
      sorter: {
        multiple: 5
      },
      sortOrder: sortOrder('redeemTranNum'),
      render: (_, record) => (
        <div
          // style={{ cursor: "pointer" }}
        // onClick={() => handleDetails(record)}
        >
          {_ || "-"}
        </div>
      )
    },
    {
      title: t("t_voidTranNum"),
      dataIndex: "voidTranNum",
      key: "voidTranNum",
      sorter: {
        multiple: 5
      },
      sortOrder: sortOrder('voidTranNum'),
      render: (_, record) => (
        <div
          // style={{ cursor: "pointer" }}
        // onClick={() => handleDetails(record)}
        >
          {_ || "-"}
        </div>
      )
    },
    {
      title: t("t_expDate"),
      dataIndex: "expDate",
      key: "expDate",
      sorter: {
        multiple: 5
      },
      sortOrder: sortOrder('expDate'),
      render: (_, record) => (
        <div>
          {dayjs(record.expDate).format('YYYY-MM-DD') || "-"}
          {/* { || "-"} */}
        </div>
      )
    },
    {
      title: t("t_status"),
      dataIndex: "status",
      key: "status",
      sorter: {
        multiple: 5
      },
      sortOrder: sortOrder('status'),
      render: (_, record) => (
        <div
          // style={{ cursor: "pointer" }}
          // onClick={() => handleDetails(record)}
          className={styles[`${COUPON_STATUS[`${_}`]?.class}`] || styles.default}
        >
          {t(COUPON_STATUS?.[`${_}`]?.label || "-")}
        </div>
      )
    },
    {
      title: t("t_function"),
      dataIndex: "operate",
      key: "operate",
      fixed: 'right',
      width: 40,
      render: (_,
        record
      ) =>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {
            permissions.findIndex((item) => item === "coupon:edit") > -1 ? 
            <EditOutlined style={{ color: variables.themeBlue, fontSize: 24 }} onClick={() => onEdit(record)} />:
            <Tooltip title={"沒有權限/已鎖定"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
              <EditOutlined style={{ color: "grey", fontSize: 24 }}/>
            </Tooltip>
          }
        </div>
    }
  ]

  return (
    <>
      <TitleBar hasSideBar hasShadow color={variables.themeWhite}>
        <TitleBarCouponPaginationContent
          reload={onReload}
        />
      </TitleBar>
      <div className={styles.container}>
        <div className={styles.tableWrapper}>
          <TickTable
            dataSource={dataSource}
            columns={columns}
            rowKey={(record) => record.id}
            loading={tableLoading}
            onChange={onChange}
          />
        </div>
      </div>
      {showEditModal &&
        <CouponEditModal 
          modalData={modalData}
          showMasterModal={showEditModal}
          setShowMasterModal={setShowEditModal}
          width="500px"
          listByPage={listByPage}
        />
      }
    </>
  );
};

export default memo(Coupon);
