import { useTranslation } from "react-i18next";
import { TickLocalModal } from "./TickLocalModal";
import styles from "./consignmentModalV3.module.scss";
import { Form, Input, DatePicker, Select } from "antd";
import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, showModal } from "../redux/tickModalSilce";
import { COUPON_STATUS } from "../utils/couponStatusEnum";
import CouponApi from "../service/couponApi/couponApi";

const CouponEditModal = (props) => {
  const { showMasterModal, setShowMasterModal, width, modalData, listByPage } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch()

  useEffect(() => {
    form.setFieldsValue({
      status: modalData.status,
    })
  }, [modalData])

  const onCloseModal = () => {
    form.resetFields();
    setShowMasterModal(false);
  };

  const onHandleSubmit = async (e) => {
    const result = form.getFieldsValue()
    await CouponApi.update(modalData.id, result).then((res) => {
      setShowMasterModal(false);
      listByPage();
      if (res.success) {
        dispatch(
          showModal({
            key: "success",
            header: "Update successfully",
            callback: () => {
              dispatch(closeModal());
            },
          })
        );
      } else {
        dispatch(
          showModal({
            key: "error",
            header: "This is an error message",
            msg: res.msg,
            callback: () => {
              dispatch(closeModal());
            },
          })
        );
      }
    })
  };

  return (
    <TickLocalModal
      onSubmit={onHandleSubmit}
      showLocalModal={showMasterModal}
      onCancel={onCloseModal}
      title={t("t_edit")}
      form={form}
      width={width}
      setVisible={setShowMasterModal}
    >
      <div className={styles.drawerContainer}>
        <div className={styles.border} />
        <Form
          layout={'vertical'}
          form={form}
        >
          <Form.Item
            label={t("t_status")}
            name="status"
          >
            <Select
              placeholder={t("t_pleaseSelect")}
              options={Object.keys(COUPON_STATUS).map(status => {
                return {
                  label: t(COUPON_STATUS[status].label),
                  value: status
                }
              })}
            />
          </Form.Item>
        </Form >
      </div>
    </TickLocalModal>
  );
};

export default memo(CouponEditModal)