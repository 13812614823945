import React, { memo } from "react";
import { Button } from "antd";
import variables from "../../theme/variable.scss";
import styles from "./iconWithTextButton.module.scss";

const IconWithTextButton = (props) => {
  const { color, children, onBtnClick = () => { }, type, disabled, danger, permission, styleClass } = props;

  const permissions = JSON.parse(localStorage.getItem('PERMISSION'))

  let hasPermission = false
  if (permission === undefined) {
    hasPermission = true
  } else {
    hasPermission = permissions.findIndex((item) => item === permission) > -1
  }
  return (
    disabled || hasPermission === false ?
      <Button
        className={`${styles.button} ${styleClass}`}
        type={type == null ? "default" : type}
        disabled
        danger={danger}
      >
        {children}
      </Button>
      :
      <Button
        className={`${styles.button} ${styleClass}`}
        type={type == null ? "default" : type}
        onClick={onBtnClick}
        danger={danger}
      >
        {children}
      </Button>
  );
};

export default memo(IconWithTextButton);
