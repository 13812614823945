import React, { memo } from "react";
import styles from "./titleBarMainContent.module.scss";
import NavigationBar from "../navigationBar/NavigationBar";
import ScanBar from "../scanBar/ScanBar";
import QuickSearch from "../quickSearch/QuickSearch";
import AvatarDropDown from "../avatarDropDown/AvatarDropDown";
import { useLocation } from "react-router";

const TitleBarMainContent = () => {
  const location = useLocation().pathname.split('/')[useLocation().pathname.split('/').length - 1];

  return (
    <>
      <div className={styles.left}>
        <NavigationBar />
        {/* <ScanBar /> */}
      </div>
      <div className={styles.middle}>
        {
          location !== "report" &&
          <QuickSearch />
        }
      </div>
      <div className={styles.right}>
        <AvatarDropDown />
      </div>
    </>
  );
};

export default memo(TitleBarMainContent);
