import { MAG } from "../api";

export default class CategoryApi {
  static suffix = "/api/item/subCat";

  //   public static listByPage(
  //     page: number | undefined,
  //     pageSize: number | undefined,
  //     params?: any
  //   ): Promise<PageVO<CompanyVO>> {
  //     return MAG.get(`${this.suffix}/listByPage/${page}/${pageSize}`, params);
  //   }

  static listSelectOptions() {
    return MAG.get(`${this.suffix}/listSelectOptions`);
  }

  //   public static save(adminDTO: CompanyDTO) {
  //     return MAG.post(`${this.suffix}/save/`, adminDTO);
  //   }

  //   public static update(id: number, adminDTO: CompanyDTO) {
  //     return MAG.put(`${this.suffix}/update/${id}`, adminDTO);
  //   }

  //   public static updateStatus(id: number, status: number) {
  //     return MAG.put(`${this.suffix}/updateStatus/${id}`, { status: status });
  //   }

  //   public static delete(id: number) {
  //     return MAG.del(`${this.suffix}/delete/${id}`);
  //   }
}
