import React, { useState, useEffect, memo } from "react";
import { fontSizeConfig } from "../../config/systemConfig";
import variables from "../../theme/variable.scss";
import styles from "./fontSize.module.scss";

const FontSize = () => {
  const [fontSizeIndex, setFontSizeIndex] = useState(null);

  useEffect(() => {
    let currentFontSize =
      document.documentElement.getAttribute("data-font-size");
    if (currentFontSize === "m") {
      setFontSizeIndex(1);
    } else if (currentFontSize === "l") {
      setFontSizeIndex(2);
    } else {
      setFontSizeIndex(0);
    }
  }, []);

  const changeFontSize = (value, index) => {
    setFontSizeIndex(index);
    document.documentElement.setAttribute("data-font-size", value);
  };

  return (
    <div style={{ display: "flex" }}>
      {fontSizeConfig.map((item, index) => {
        return (
          <div
            key={index}
            className={`${styles.button} ${styles[item.class]} ${
              index == fontSizeIndex ? styles.active : ""
            }`}
            onClick={() => {
              changeFontSize(item.value, index);
            }}
          >
            {item.name}
          </div>
        );
      })}
    </div>
  );
};
export default memo(FontSize);
