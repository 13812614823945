import React from 'react'
import { TickGlobalModal } from './TickGlobalModal'
import styles from './modal.module.scss'
import logoutIcon from "../assets/img/icon/modalWarningIcon.svg";
import { useSelector } from 'react-redux';

export const TickLogoutModal = () => {
  const tickGlobalModalState = useSelector((state) => state.tickModal);
  return (
    <TickGlobalModal
      header={
        <>
          <img src={logoutIcon} alt="logout-icon" className={styles.icon} />
          {tickGlobalModalState?.params?.header}
        </>
      }
      okText='Yes'
      cancelText='No'
      >
      <div className={styles.content}>{tickGlobalModalState?.params?.msg}</div>
    </TickGlobalModal>
  )
}