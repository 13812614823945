import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import { setReceiptParams } from '../../redux/tickTableSlice';


const TickReceipt = () => {
  const receiptParams = useSelector(state => state?.tickTable?.receiptParams);
  const receiptNumDetails = useSelector(state => state?.tickTable?.itemsDrawerProductDetails?.receiptNumDetails);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const receiptTypeEnum = {
    directIn: "t_directIn",
    sold: "t_normalSold"
  }

  return (
    <Col span={24}>
      <Row>
        <Col span={6}>{t("t_receiptNum")}:</Col>
        <Col span={18}>
          <NavLink to={"/main/receipt"} onClick={() => dispatch(setReceiptParams({...receiptParams, input: receiptNumDetails.receiptNum}))}>
            {receiptNumDetails.receiptNum}
          </NavLink>
        </Col>
      </Row>
      <Row>
        <Col span={6}>{t("t_consignorNum")}:</Col>
        <Col span={18}>{receiptNumDetails.consignorNum}</Col>
      </Row>
      <Row>
        <Col span={6}>{t("t_receiptType")}:</Col>
        <Col span={18}>
            {t(receiptTypeEnum[receiptNumDetails.receiptType])}
        </Col>
      </Row>
    </Col>
  )
}

export default TickReceipt