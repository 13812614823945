import React from 'react'
import { lang } from '../config/systemConfig';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

const useHandleDefaultValue = () => {
  const optionsOfAccessory = useSelector(state => state.tickTable.optionsOfAccessory);
  const optionsOfBrand = useSelector(state => state.tickTable.optionsOfBrand);
  const optionsOfCategory = useSelector(state => state.tickTable.optionsOfCategory);
  const optionsOfColor = useSelector(state => state.tickTable.optionsOfColor);
  const optionsOfTransactionType = useSelector(state => state.tickTable.optionsOfTransactionType);
  const optionsOfMaterial = useSelector(state => state.tickTable.optionsOfMaterial);
  const optionsOfConsignor = useSelector(state => state.tickTable.optionsOfConsignor);
  const optionsOfRating = useSelector(state => state.tickTable.optionsOfRating);
  const optionsOfOrigin = useSelector(state => state.tickTable.optionsOfOrigin);
  const optionsOfCortex = useSelector(state => state.tickTable.optionsOfCortex);

  const handleDefaultProductInfo = (modalData) => {        // base on formatBodyToFormItem ^

    const handleSelect = (val, sets) => {
      if (!val) return;
      if (!sets) return;
      const filteredResult = sets.filter(item => item.value === val)
      const [newSets] = filteredResult;
      return newSets;
    };

    const handleTransactionTypeSelect = (val, sets) => {
      if (!val) return;
      if (!sets) return;
      const filteredResult = sets.filter(item => item?.id === val)
      const [newSets] = filteredResult;
      return newSets?.id;
    };

    const handleDate = (val) => {
      if (!val) return;                                   // for antd date picker which only accept undefined when value is null or empty string
      const newDate = dayjs(val);
      return newDate;
    };

    const handleMultiSelect = (val, sets) => {
      if (!val) return;
      if (!sets) return;
      const newVal = val.split(",");
      const valSets = [];
      newVal.forEach(key => {
        sets.forEach((item) => {
          if (item.id == key) {
            valSets.push({label: item[`name${lang}`], value: item.id})
          }
        })
      })
      return valSets;
    };

    const handleMultiSelectEng = (val, sets) => {
      if (!val) return;
      if (!sets) return;
      const newVal = val.split(",");
      const valSets = [];
      sets.forEach(item => {
        newVal.forEach(key => {
          if (item.id == key) {
            valSets.push({label: item[`nameEn`], value: item.id})
          }
        })
      })
      return valSets;
    };

    const handleSingleSelect = (val, sets) => {
      if (!val) return;
      if (!sets) return;
      const filteredResult = sets.filter(item => item.id == val);
      if (filteredResult.length === 0) return;
      const [ result ] = filteredResult;

      return {
        label: result[`name${lang}`],
        value: result.id
      };
    }

    const handleCategorySelect = () => {
      if (!modalData?.subCatKey) return;
      if (!optionsOfCategory) return;
      const filteredResult = optionsOfCategory.all.filter(item => item.itemSubCatKey == modalData?.subCatKey);
      if (filteredResult.length === 0) return;
      const [ result ] = filteredResult;
      return {
        label: result[`name${lang}`],
        value: result.id
      };
    }
    let body = {
      transactionTypeId: handleTransactionTypeSelect(modalData?.transactionTypeId, optionsOfTransactionType),
      consignorId: modalData?.consignorId,
      itemNum: modalData?.itemNum || null,
      handlingFee: modalData?.handlingFee || 0,
      handlingFeePercentage: modalData?.handlingFeePercentage || undefined,
      transactionDate: handleDate(modalData?.transactionDate),
      [`itemName${lang}`]: modalData?.[`itemName${lang}`] || null,
      onSalesPrice: modalData?.onSalesPrice || null,
      directInCost: modalData?.directInCost,
      directInDate: handleDate(modalData?.directInDate),
      deptKey: handleSelect(modalData?.deptKey, optionsOfBrand),
      amt: modalData?.amt === null || modalData?.amt === undefined ? null : modalData?.amt,
      qty: modalData?.qty || null,
      // category: handleSelect(modalData?, optionsOfCategory),
      // year: handleDate(modalData?.year),
      year: modalData?.year,
      colorIds: handleMultiSelect(modalData?.colorIds, optionsOfColor), // multi
      ratingIds: handleMultiSelect(modalData?.ratingIds, optionsOfRating),
      width: modalData?.width !== "0.00" ? modalData?.width : null,
      height: modalData?.height !== "0.00" ? modalData?.height : null,
      length: modalData?.length !== "0.00" ? modalData?.length : null,
      materialIds: handleMultiSelect(modalData?.materialIds, optionsOfMaterial), //multi
      model: modalData?.model || null,
      originIds: handleSingleSelect(modalData?.originIds, optionsOfOrigin),
      accessoriesIds: handleMultiSelect(modalData.accessoriesIds, optionsOfAccessory), // multi
      cortexIds: handleMultiSelectEng(modalData.cortexIds, optionsOfCortex), // multi
      photo: undefined,
      itemNameInternal: modalData?.itemNameInternal || null,
      colorIdsInternal: handleMultiSelect(modalData?.colorIdsInternal, optionsOfColor), // multi
      remark: modalData?.remark || null,
      subCatKey: handleCategorySelect(),
      status: modalData?.status,
      branchNum: modalData?.branchNum,
      stockLocation: modalData?.stockLocation,
      itemImage: modalData?.itemImage,
      mark: modalData?.mark,
      isReprintTag: modalData?.isReprintTag
    }
    return body
  }
  return { handleDefaultProductInfo }
}

export default useHandleDefaultValue