import React, { useRef, useEffect, memo, forwardRef, useState } from "react";
import styles from "./tickConsignmentReceipt.module.scss"
import companyName from "../../assets/img/invLetterhead.png"
import * as dayjs from "dayjs";
import { amtFormatted } from "../../utils/amtFormatted";

const TickInvoice = forwardRef(({ receiptData }, ref) => {
  const renderPages = () => {
    const itemsPerPage = 8
    const pdfPageCount = Math.ceil(receiptData.items.length / itemsPerPage)
    const pages = [];

    for (let i = 0; i < pdfPageCount; i++) {
      pages.push(
        <div className={styles.container}>
          <InvHeader receiptNum={receiptData.receiptNum} consignor={receiptData.consignor}/>
          <InvBody items={receiptData.items}/>
          <InvSummary items={receiptData.items} payment={receiptData.paymentMethod}/>
          <InvFooter />
        </div>
      );
    }
    return pages;
  };

  return <div className={styles.root} ref={ref}>{renderPages()}</div>;

})

export default memo(TickInvoice);

const InvHeader = (props) => {

  return (
    <div className={styles.receiptHeader}>
      <div className={styles.companyNameImg}>
        <img
          className={styles.img}
          src={companyName}
          alt="company name"
        />
      </div>
      <div className={styles.receiptInfo}>
        <div className={styles.line}>
          <span>Invoice Number: </span>
          <span className={styles.value}>{props?.receiptNum}</span>
        </div>
        <div className={styles.line}>
          <span>Date:</span>
          <span className={styles.value}>{dayjs().format('YYYY-MM-DD')}</span>
        </div>
        <div className={styles.line}>
          <span>Customer Name:</span>
          <span className={styles.value}>{props?.consignor?.fullName}</span>
        </div>
      </div>
    </div>
  );
};

const InvBody = (props) => {
  return (
    <div className={styles.receiptBody}>
      <div className={styles.receiptTitle}>
        CONSIGNMENT PAYMENT RECEIPT
      </div>
      <table>
        <thead className={styles.receiptTableHeader}>
          <tr>
            <th className={styles.tableItemCode}>ITEM CODE</th>
            <th className={styles.tableItemName}>ITEM NAME</th>
            <th className={styles.tableItemQty}>QTY</th>
            <th className={styles.tableItemTotal}>TOTAL HKD</th>
          </tr>
        </thead>
        <tbody className={styles.tableBody} >
          {
            props.items?.map((item) => {
              return (
                <tr className={styles.tableColumn}>
                  <td className={styles.tableItemCode}>{item.itemNum}</td>
                  <td className={styles.tableItemName}>{item.itemNameEn}</td>
                  <td className={styles.tableItemQty}>1</td>
                  <td className={styles.tableItemTotal}>{amtFormatted(item.amt)}</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  );
};

const InvSummary = (props) => {
  const totalAmount = props?.items?.reduce((sum, item) => sum + Number(item.amt), 0)
  return (
    <>
      <div className={styles.receiptSummary}>
        <div className={styles.summaryLeft}>
          <div>Payment:</div>
          <div className={styles.normalFontWeight}>{props?.payment?.titleEn}</div>
        </div>
        <div className={styles.summaryRight}>
          <div>
            <div>TOTAL AMOUNT (HKD):</div>
          </div>
          <div className={styles.normalFontWeight}>
            <div>{amtFormatted(totalAmount)}</div>
          </div>
        </div>
      </div>
    </>
  )
};

const InvFooter = () => {
  return (
    <>
      <footer className={styles.receiptFooter}>
        <div className={styles.signWarp}>
          <div className={styles.sign}>Signature</div>
        </div>
        Room 906-907andRoom 1202,KimberleyPlaza,45-47KimberleyRoad,Tsim ShaTsui
        <br />
        Tel: (852)9844 1216
      </footer>
    </>
  )
};