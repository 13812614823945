import React from "react";
import { Row, Col, Divider, Upload, Image } from "antd";
import styles from "./tickProductInfo.module.scss";
import useTabsContentFields from "../../hooks/useTabsContentFields";
import { lang } from "../../config/systemConfig";

const TickProductInfo = () => {
  const {getProductInfoField} = useTabsContentFields();
  const defaultContentFields = getProductInfoField();

  return (
    <div className={styles.container}>
      <Row>
        {defaultContentFields.top.map((item, index) => (
          <Col {...item.grid} key={`top_item_${index}`}>
            <div className={`${styles[item?.titleStyles ? item.titleStyles : "title"]}`}>
              {item.title}
            </div>
            <div className={`${styles[item?.valueStyles ? item.valueStyles : "value"]}`}>
              {`${item.value}`}
            </div>
          </Col>
        ))}
      </Row>
      <Divider />
      <Row gutter={[16]}>
        {defaultContentFields.middle.map((item, index) => {
          if (item.key === "img") {
            return (
              <Col
                {...item.grid}
                style={{ width: "100px", display: "flex", alignItems: "center"}}
                key={`middle_item_img_${index}`}
              >
                <Image src={item.img} alt={item.alt} style={{ width: "100%" }} />
              </Col>
            );
          }
          if (item.descriptions) {
            return (
              <Col xs={12} key={`middle_item_${index}`}>
                {item.descriptions.map((nestedItem, key) => {
                  if (nestedItem.other) {
                    return (
                      <Row key={`nested_item_${key}`}>
                        {nestedItem.other.map((othersItem, othersKey) => {
                          let content =
                            othersItem.unit === "%"
                              ? `${othersItem.value} ${othersItem.unit}`
                              : `${othersItem.unit} ${othersItem.value}`;
                          return (
                            <Col
                              {...othersItem.grid}
                              key={`other_items_${othersKey}`}
                            >
                              <div
                                className={`${styles[othersItem?.titleStyles ? othersItem.titleStyles : "title"]}`}
                              >
                                {othersItem.title}
                              </div>
                              <div
                                className={`${styles[othersItem.valueStyles ? othersItem.valueStyles : "value"]}`}
                              >
                                {content}
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    );
                  }
                  return (
                    <Row style={{ marginBottom: 4 }} key={`nested_item_${key}`}>
                      <span
                        className={`${styles[nestedItem.titleStyles ? nestedItem.titleStyles : 'value']}`}
                      >
                        {nestedItem.unit}
                      </span>
                      <span 
                        className={`${styles[nestedItem.valueStyles ? nestedItem.valueStyles : 'value']}`}
                      >
                        {`${nestedItem.value}`}
                      </span>
                    </Row>
                  );
                })}
              </Col>
            );
          }
        })}
      </Row>
      <Divider />
      <Row>
        {defaultContentFields.end.map((item, index) => {
          let isLastIndex = index === defaultContentFields.end.length - 1;

          if (item.type == "multi") {
            return (
              <Col
                {...item.grid}
                style={{ marginBottom: !isLastIndex ? 16 : 0 }}
                key={`end_item_${index}`}
              >
                <div className={`${styles[item?.titleStyles ? item.titleStyles : "title"]}`}>
                  {item.title}
                </div>
                <div className={`${styles[item?.valueStyles ? item.valueStyles : "value"]}`}>
                  {
                    item?.value?.map((itemVal, itemValIndex) => {
                      if (itemValIndex > 0 && itemVal === "-") return;
                      if (itemValIndex === 0 && itemVal === "-") return itemVal;
                      return <span className={styles.greyBox} key={`each_items_${itemValIndex}`}>{itemVal}</span>
                    })
                  }
                  {/* {(item?.value) != [] && item?.value?.map((i, index) => {
                    let name = i?.[`name${lang}`]
                    if (index !== item.value.length - 1) {
                      return (<>{name}, </>)
                    } else {
                      return (<>{name}</>)
                    }
                  })} */}
                </div>
              </Col>
            );
          } else {
            let anotherValue = item?.value?.[`name${lang}`] != undefined ? item?.value?.[`name${lang}`] : item?.value;
            // console.log({ anotherValue })
            return (
              <Col
                {...item.grid}
                style={{ marginBottom: !isLastIndex ? 16 : 0 }}
                key={`end_item_${index}`}
              >
                <div className={`${styles[item?.titleStyles ? item.titleStyles : "title"]}`}>
                  {item.title}
                </div>
                <div className={`${styles[item?.valueStyles ? item.valueStyles : "value"]}`}>
                  {`${anotherValue}`}
                </div>
              </Col>
            );
          }
        }
        )}
      </Row>
      <Divider />
    </div>
  );
};

export default TickProductInfo;
