import { useEffect, useState } from "react";
import { Select } from "antd";
import { lang } from "../../config/systemConfig";
import { useTranslation } from "react-i18next";
import BrandApi from "../../service/brandApi/brandApi";
import { useSelector } from "react-redux";
import ColorApi from "../../service/colorApi/colorApi";
import i18n from "../../i18n";

const TickFilterColorSelection = (props) => {
  const {
    allowClear = true,
    showSearch = true,
    size = {
      width: "100%",
      margin: "0.5rem 0"
    },
    disabled = false,
    mode = "",
    style = {},
    defaultValue,
    onSearch = () => {}
  } = props;
  const { t } = useTranslation();

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState(i18n.language);

  const colorIds = useSelector(state => state.tickTable.itemParams.colorIds)

  useEffect(() => {
    listSelectOptions();
  }, []);


  const listSelectOptions = () => {
    setLoading(true);
    ColorApi.listSelectOptions()
      .then((resp) => {
        console.log(resp);
        let o = [];
        resp?.data?.forEach((item) => {
          o.push({
            label: language === 'tc_hk' ? `${item.nameTc}${item.nameEn && " | " + item.nameEn} ` : item.nameEn ? `${item.nameEn}${item.nameTc && " | " + item.nameTc} ` : `${item.nameTc}${item.nameEn && " | " + item.nameEn} `,
            value: item.id,
            remarks: `${item.nameTc ? item.nameTc:item.nameEn} | ${item.nameEn ? item.nameEn:item.nameTc}`
          });
        });
        setOptions(o);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChange = (value) => {
    props.onChange?.(value)
  };

  return (
    <Select
      value={colorIds} // for reload to clear the value of display
      allowClear={allowClear}
      showSearch={showSearch}
      size={size}
      disabled={disabled}
      options={options}
      loading={loading}
      onChange={onChange}
      placeholder={t("t_pleaseSelect")}
      filterOption={(input, option) =>
        option.remarks ? (option?.remarks).toLowerCase().includes(input.toLowerCase()) : (option?.label).toLowerCase().includes(input.toLowerCase())
      }
      onSearch={onSearch}
      mode={mode}
      style={style}
      defaultValue={defaultValue}
    />
  );
};
export default TickFilterColorSelection;