import React from "react";
import { Context } from './Report';
import { useTranslation } from "react-i18next";
import styles from './report.module.scss';
import { Button } from "antd";


const ReportUtilsBar = () => {
  const context = React.useContext(Context);
  const { t } = useTranslation();

  return (
    <div className={styles.reportEditBarContainer}>
      <span>{context.selectedTab}</span>
      <div className={styles.functionWrapper}>
        <Button type="primary" onClick={() => context.setIsExportModalVisible(true)}>{t("t_export")}</Button>
      </div>
    </div>
  )
}

export default ReportUtilsBar