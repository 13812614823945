export const COUPON_STATUS = {
    OPEN: {
        label: 't_couponStatusOpen',
        class: 'green'
    },
    REDEEMED: {
        label: "t_couponStatusRedeemed",
        class: 'yellow'
    },
    CANCELLED: {
        label: "t_couponStatusCancelled",
        class: 'grey'
    },
    VOID: {
        label: "t_couponStatusVoid",
        class: 'red'
    },
}