import React from "react";
import { Select, Checkbox, Radio } from "antd";
import ReportCommonListOptionApi from "./reportServices";
import i18n from '../../i18n';


const ReportCommonGroupField = (props) => {
  const { value, type } = props;
  const { options, service, extraProps } = props?.fieldDetails || {};

  const [fetchedOptions, setFetchedOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const lang = i18n.language === 'en' ? "En" : "Tc";

  React.useEffect(() => {
    if (!service) return;
    fetchListSelectOptions();
  }, [value]);

  const fetchListSelectOptions = async () => {
    setLoading(true);
    try {
      const resp = await ReportCommonListOptionApi.listSelectOptions(service);
      if (!resp?.success) return;
      const tempArr = [];
      resp?.data?.forEach(item => tempArr.push(
        {
          label: item[`name${lang}`] || item[`name${lang === 'En' ? "Tc" : "En"}`],
          value: item[`name${lang}`] || item[`name${lang === 'En' ? "Tc" : "En"}`],
        }
      ));
      setFetchedOptions(tempArr);
    } catch (e) {
      const newType = type.charAt(0).toUpperCase() + type.slice(1);
      console.error(`This error occurred in 'ReportCommon${newType}'::::`, e)
    } finally {
      setLoading(false);
    }
  };

  const onChange = (value) => {
    props.onChange?.(value);
  };

  const renderGroupField = () => {
    let fieldComponent = <></>;
    switch (type) {
      case "select":
        fieldComponent = <Select
          value={value}
          onChange={onChange}
          options={options ? options : fetchedOptions}
          loading={loading}
          {...extraProps}
        />
        break;
      case "checkBox":
        fieldComponent = <Checkbox.Group
          value={value}
          options={options ? options : fetchedOptions}
          onChange={onChange}
          {...extraProps}
        />
        break;
      case "radio":
        fieldComponent = <Radio.Group
          value={value}
          options={options ? options : fetchedOptions}
          onChange={onChange}
          {...extraProps}
        />
        break;

      default:
        fieldComponent = <>Miss Field Type</>
    };

    return fieldComponent
  };

  return (
    <>
      {renderGroupField()}
    </>
  )
}

export default ReportCommonGroupField