import React, { memo, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { PlusOutlined, EditOutlined, DeleteOutlined, ShoppingOutlined, DollarOutlined } from "@ant-design/icons";
import styles from "./card.module.scss";
import { Tooltip } from 'antd';

const selectableAction = ["consignor"]

const Card = (props) => {
    const { 
      children, 
      title, 
      setShowAddModal, 
      setBtn, 
      isSelected, 
      setSelectedItemList, 
      action,
      receipted
    } = props
    const { t } = useTranslation();

    const selectable = selectableAction.includes(action) && !receipted;

    return (
        children != undefined ?
            <div 
                className={styles.cardWithContent} 
                style={{ 
                    display: "flex", 
                    flexDirection: "column", 
                    border: isSelected && selectable ? "2px solid #3683ca" : "",
                    boxShadow: isSelected && selectable ? "0px 0px 4px 0px #3683ca" : "",
                    cursor: selectable ? "pointer" : "auto",
                    pointerEvents: !receipted ? "auto" : "none",
                 }} 
            >
                <div className={styles.header}>
                    <div className={styles.title}>
                      <div>
                        <ShoppingOutlined style={{ padding: "0 5px" }} />
                        {title}
                      </div>
                      {
                        receipted &&
                        <Tooltip title={`${t("t_receiptNum")}: ${receipted}`}>
                          <DollarOutlined style={{ padding: "0 5px", color: "red" }}/> 
                        </Tooltip>
                      }
                    </div>
                    <div className={styles.border} />
                </div>
                {children}
            </div>
            :
            <div className={styles.card} onClick={() => { setShowAddModal(true); setBtn("add"); setSelectedItemList([])}}>
                <div style={{ cursor: "pointer" }}>
                    <PlusOutlined />
                    <>{t("t_addConsignment")}</>
                </div>
            </div >
    )
}

export default memo(Card)
