import React from 'react'
import { TickGlobalModal } from './TickGlobalModal'
import styles from './modal.module.scss'
import deleteIcon from "../assets/img/icon/modalDeleteIcon.svg";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const TickDeleteModal = () => {
    const tickGlobalModalState = useSelector((state) => state.tickModal);
    
    const { t } = useTranslation();
    console.log(t("t_confirm"))
    return (
        <TickGlobalModal 
            header={
                <>
                    <img src={deleteIcon} alt="delete-icon" className={styles.icon} />
                    {tickGlobalModalState?.params?.header}
                </>
            }
            okText={t("t_confirm")}
            cancelText={t("t_cancel")}
            >
            <div className={styles.content}>{tickGlobalModalState?.params?.msg}</div>
        </TickGlobalModal>
    )
}