import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { ReloadOutlined } from "@ant-design/icons";
import PaginationBar from "../paginationBar/PaginationBar";
import styles from "./titleBarCouponPaginationContent.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { setCouponParams } from "../../redux/tickTableSlice";

const TitleBarCouponPaginationContent = (props) => {
  const { reload } = props;
  const couponParams = useSelector(state => state.tickTable.couponParams);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.left}>
        <span className={styles.title}>{t("t_coupon")}</span>
      </div>
      <div className={styles.middle}>
        <PaginationBar dispatchParams={setCouponParams} params={couponParams} />
      </div>
      <div className={styles.right}>
        <ReloadOutlined className={styles.reloadIcon} onClick={reload} />
      </div>
    </>
  );
};

export default memo(TitleBarCouponPaginationContent);
