import { MAG } from "../api";
import { printer } from "../../config/envConfig";

export default class printerApi {
  static suffix = "/api/system/printer";

  static getPrinters() {
    return MAG.get(`${this.suffix}`)
  }

  static fetchMachineIp(machineId) {
    return MAG.get(`${this.suffix}/${machineId}`)
  }

  static printLabel(id) {
    console.log(printer, "printer")
    return MAG.customMethod(`http://${printer.printerIp}:${printer.printerPort}`, `/printTag/${id}`)
  }
}