import React from "react";
import styles from "./modal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "antd";
import { closeModal } from "../redux/tickModalSilce";
import IconWithTextButton from "../components/iconWithTextButton/IconWithTextButton";

export const TickGlobalModal = ({
  children,
  width = 416,
  header = "",
  cancelText = "Cancel",
  okText = "Done",
  color,
}) => {
  const dispatch = useDispatch();
  const tickGlobalModalState = useSelector((state) => state.tickModal);
  function onCloseClick() {
    if (tickGlobalModalState?.params?.cancelCallback) tickGlobalModalState?.params?.cancelCallback();
    dispatch(closeModal());
  }
  return (
    <>
      <Modal
        title={header === "" ? tickGlobalModalState?.params?.header : header}
        open={tickGlobalModalState.show}
        centered
        closable={false}
        width={width}
        transitionName=""
        maskClosable={false}
        keyboard={false}
        footer={
          <>
            {tickGlobalModalState?.params?.showCloseBtn ? (
              <Button className={styles.modalBtn} onClick={onCloseClick}>
                {cancelText}
              </Button>
            ) : null}
            <IconWithTextButton
              color={color}
              onBtnClick={tickGlobalModalState?.params?.callback}
            >
              {okText}
            </IconWithTextButton>
          </>
        }
      >
        {children}
      </Modal>
    </>
  );
};
