import { MAG } from "../api";
import { pageSize } from "../../redux/tickTableSlice";

export default class ConsignorApi {
  static suffix = "/api/consignor";

  static listByPage(params = {}) {
    const {
      pageNum = 1,
      pageSize = pageSize,
      sortField = "consignorNum",
      sortOrder = "ASC",
      input = "",
    } = params;
    // console.log(params);
    // let url = `/api/consignor/listByPage/${pageNum}/${pageSize}?sortField=${sortField}&&sortOrder=${sortOrder}`;
    let url = `/api/consignor/listByPage/${pageNum}/${pageSize}?`;
    if (params?.sortOptions) {
      const sortOptions = Object.entries(params?.sortOptions);
      if (sortOptions.length > 0) {
        sortOptions.forEach(option => {
          url = url.concat(`&&sortField=${encodeURIComponent(option[0])}&&sortOrder=${encodeURIComponent(option[1])}`)
        })
      }
    }
    if (input != "") {
      url = url.concat(`&&input=${encodeURIComponent(input)}`);
    }
    console.log(url);
    return MAG.get(url);
  }

  static save(body) {
    return MAG.post(`${this.suffix}/save`, body);
  }

  static update(id, body) {
    return MAG.put(`${this.suffix}/update/${id}`, body);
  }

  static delete(id) {
    return MAG.put(`${this.suffix}/delete/${id}`);
  }

  static listSelectionOptions(input) {
    let query = "";
    if (input) query = `?input=${input}`
    return MAG.get(`${this.suffix}/listSelectionOptions${query}`);
  }

  static getById(id) {
    return MAG.get(`${this.suffix}/${id}`);
  }
}
