import React from 'react';
import styles from './titleBarMemberPaginationContent.module.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PaginationBar from '../paginationBar/PaginationBar';
import { ReloadOutlined, PlusOutlined } from "@ant-design/icons";
import { setMemberParams } from '../../redux/tickTableSlice';
import IconWithTextButton from '../iconWithTextButton/IconWithTextButton';
import MemberModal from '../../modals/memberModal/MemberModal';

const TitleBarMemberPaginationContent = (props) => {
  const { reload, fetchDataSource } = props;
  const [isAddModalVisible, setIsAddModalVisible] = React.useState(false);
  const memberTableParams = useSelector(state => state.tickTable.memberParams);
  const { t } = useTranslation();

  const onAdd = () => {
    setIsAddModalVisible(true);
  };

  return (
    <>
      <div className={styles.left}>
        <span className={styles.title}>{t("t_memberList")}</span>
      </div>
      <div className={styles.middle}>
        <PaginationBar dispatchParams={setMemberParams} params={memberTableParams} />
      </div>
      <div className={styles.right}>
        <IconWithTextButton type="primary" permission="member:add" onBtnClick={onAdd}>
          <span>
            <PlusOutlined style={{ pointerEvents: "cursor", marginRight: 4 }} />
            {t("t_addMember")}
          </span>
        </IconWithTextButton>
        <ReloadOutlined className={styles.reloadIcon} onClick={reload} />
      </div>
      {
        isAddModalVisible ?
          <MemberModal isVisible={isAddModalVisible} setIsVisible={setIsAddModalVisible} fetchDataSource={fetchDataSource} /> : <></>
      }
    </>
  )
}

export default TitleBarMemberPaginationContent