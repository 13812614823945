import { useTranslation } from "react-i18next";
import { TickLocalModal } from "./TickLocalModal";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Upload, Modal, Select, Image } from "antd";
import { formatDatetime } from "../utils/formatDatetime";
import styles from "./ProductModal.module.scss";
import { Form, Input, InputNumber, DatePicker, Row, Col, Switch } from "antd";
import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, showModal } from "../redux/tickModalSilce";
import { defaultBranchNum, defaultStockLocation, disabledUploadImg, getItemImgSrc, hideStockLocationItemStatus, lang, sizeInputMaxLength } from "../config/systemConfig";
import CommonSelection from "../components/commonSelection/CommonSelection";
import ItemApi from "../service/itemApi/itemApi";
// import { formatBodyToFormItem } from "../utils/formatBodyToFormItem";
import { formatFormItemToBody } from "../utils/formatFormItemToBody";
import useHandleItemDefaultValue from "../hooks/useHandleItemDefaultValue";
import useHandleItemPostBody from "../hooks/useHandleItemPostBody";
import { setItemParams, setItemsStateToInitialState, setShowItemDrawer } from "../redux/tickTableSlice";
import dayjs from "dayjs";
import { handleHandlingFee } from "../utils/handleHandlingFee";
import { tickLog } from "../utils/logger";
import { PrefixEnum } from "../utils/prefixEnum";

const transactionTypeEnum = ['consignment', 'direct', 'buyIn'];

const ProductModal = (props) => {
  const { showProductModal, setShowProductModal, btn = "add", modalData = null, setModalData, setIsReload } = props;
  const { TextArea } = Input;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { handleItemPostBody, handleItemPostUpdateBody } = useHandleItemPostBody();          // handle the update or create body
  const { handleDefaultProductInfo } = useHandleItemDefaultValue();                         // display value from table

  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [showTransactionType, setShowTransactionType] = useState("direct");
  const [updateField, setUpdateField] = useState({});
  const watchPhoto = Form.useWatch("photo", form);

  const tickTableState = useSelector((state) => state.tickTable)
  const itemTableParams = useSelector((state) => state.tickTable.itemParams)
  const dispatch = useDispatch()

  // Gary
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState();

  const width = window.innerWidth;

  const isShowStockLocation = () => {
    return !hideStockLocationItemStatus.includes(form.getFieldValue("status"))
  }

  //* TEST UPLOAD START - FUNC *//

  useEffect(() => {
    if (watchPhoto) {
      setFileList([
        {
          uid: "-1",
          name: "photo.jpg",
          status: "done",
          url: watchPhoto,
          preview: watchPhoto,
        },
      ]);
    } else {
      setFileList([]);
    }
  }, [watchPhoto]);

  const getBase64Async = async (file) => {
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      tickLog('ImageUpload', 'You can only upload JPG/PNG file!');
      dispatch(
        showModal({
          key: "error",
          header: "This is an error message",
          msg: "You can only upload JPG/PNG file!",
          callback: () => {
            dispatch(closeModal());
          },
        })
      );
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      tickLog('ImageUpload', 'Image must smaller than 2MB!');
            dispatch(
        showModal({
          key: "error",
          header: "This is an error message",
          msg: "Image must smaller than 2MB!",
          callback: () => {
            dispatch(closeModal());
          },
        })
      );
    }
    return isJpgOrPng && isLt2M;
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    // if (!file.url && !file.preview) {
    //   file.preview = await getBase64Async(file.originFileObj);
    // }
    // setPreviewImage(file.url || (file.preview));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url?.substring(file.url?.lastIndexOf('/') + 1));
  };

  const handleChange = async ({ fileList: newFileList }) => { 
    setFileList(newFileList) 

    const file = newFileList.length >= 1 ? newFileList[0] : null;
    if (!file) {
      form.setFieldsValue({ itemImage: null })
      setUpdateField({ ...updateField, itemImage: null })
      setPreviewImage(undefined);
      setLoading(false);
      return;
    }
    if (file.status === "uploading") {
      setLoading(true);
      return;      
    } else if (file.status === "done") {
      getBase64(file.originFileObj, (url) => {
        // update form state
        form.setFieldsValue({ itemImage: url })
        setUpdateField({ ...updateField, itemImage: url })
        setPreviewImage(url);
        setLoading(false);
      });
    } else if (file.status === "error") { // error
      dispatch(
      showModal({
          key: "error",
          header: "This is an error message",
          msg: "Upload error",
          callback: () => {
            dispatch(closeModal());
          },
        })
      );
    }
    setLoading(false);
  };

  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t("t_upload")}
      </div>
    </div>
  );

  // useEffect(() => {
  //   console.log(props)
  // }, [])

  // useEffect(() => {
  //   console.log(previewImage)
  // }, [previewOpen, previewImage])

  // useEffect(() => {
  //   setShowTransactionType(transactionType(modalData?.transactionTypeId))
  // }, [form.getFieldsValue()])

  useEffect(() => {
    if (Object.entries(modalData).length <= 0) {
      return
    }
    // let body = formatBodyToFormItem(modalData, tickTableState.optionsOfBrand, tickTableState.optionsOfCategory, tickTableState.optionsOfTransactionType)
    let body = handleDefaultProductInfo(modalData);
    form.setFieldsValue({ ...body })
    setShowTransactionType(transactionType(modalData?.transactionTypeId))
    // if (body.itemImage) {
    //   const imgFormat = body.itemImage.split(';')[0].split('/')[1];
    //   setFileList([{
    //     uid: '1',
    //     name: `item.${imgFormat}`,
    //     status: 'done',
    //     url: body.itemImage
    //   }])
    //   setPreviewImage(body.itemImage);
    // }
  }, [modalData]);

  const onCloseModal = () => {
    form.resetFields();
    setModalData({})
    setShowProductModal(false);
    setIsReload(true)
  };

  const onHandleSubmit = async (e) => {
    let data = form.getFieldsValue()

    // let body = formatFormItemToBody(data, tickTableState?.optionsOfBrand, tickTableState?.optionsOfCategory)

    let body = handleItemPostBody(data);

    if (btn === "update") {
      body = handleItemPostUpdateBody(updateField);
    }

    let resp
    if (btn == "add") {
      resp = await ItemApi.save(body)
    } else if (btn == "update") {
      console.log(body);
      resp = await ItemApi.update(modalData?.id, body)
    }
    try {
      if (resp.success) {
        dispatch(
          showModal({
            key: "success",
            header: btn == "add" ? "Add Item success" : "Update Item success",
            msg: resp.msg,
            callback: () => {
              dispatch(closeModal());
            },
          })
        );
        // dispatch(setItemsStateToInitialState());  // fetch one more time table data when update success
        dispatch(setItemsStateToInitialState({ isCreate: btn === 'add' }));
        dispatch(setShowItemDrawer(false))
        onCloseModal();
        if (btn == "update") {
          dispatch(setItemParams({
            ...itemTableParams
          }));
        }
      } else {
        dispatch(
          showModal({
            key: "error",
            header: "This is an error message",
            msg: resp.msg,
            callback: () => {
              dispatch(closeModal());
            },
          })
        );
      }
    } catch (e) {
      // console.log(e)
    } finally {
      // onCloseModal()
    }
  }

  const onFieldsChange = (changedValue, originValue) => {
    setUpdateField({ ...updateField, ...changedValue });
    if (changedValue.amt || changedValue.handlingFeePercentage === null) {
      const amt = form.getFieldValue('amt')
      form.setFieldsValue({ handlingFee: handleHandlingFee(amt) })
      if (amt && amt >= 50000 ) {
        form.setFieldsValue({ handlingFeePercentage: 10 })
        setUpdateField({ ...updateField, ...changedValue, handlingFee: handleHandlingFee(amt), handlingFeePercentage: 10 });
      } else if (amt && amt >= 2001 && amt <= 49999 ) {
        form.setFieldsValue({ handlingFeePercentage: 15 })
        setUpdateField({ ...updateField, ...changedValue, handlingFee: handleHandlingFee(amt), handlingFeePercentage: 15 });
      } else {
        form.setFieldsValue({ handlingFeePercentage: undefined })
        setUpdateField({ ...updateField, ...changedValue, handlingFee: handleHandlingFee(amt), handlingFeePercentage: 0 });
      }
    }
    if (changedValue.handlingFeePercentage) {
      let handlingFeeCal = Math.round( form.getFieldValue('amt') * ( form.getFieldValue('handlingFeePercentage') / 100 ) )
      form.setFieldsValue({ handlingFee: handlingFeeCal })
      setUpdateField({ ...updateField, ...changedValue, handlingFee: handlingFeeCal });
    }
    if (changedValue.transactionTypeId) {
      const itemNum = form.getFieldValue('itemNum');
      if (changedValue.transactionTypeId === 2) {
        form.setFieldsValue({ itemNum: `${PrefixEnum.DIRECT_IN_ITEM}${itemNum}` })
        setUpdateField({ ...updateField, itemNum: `${PrefixEnum.DIRECT_IN_ITEM}${itemNum}`, transactionTypeId: changedValue.transactionTypeId})
      } else {
        form.setFieldsValue({ itemNum: itemNum.replace(PrefixEnum.DIRECT_IN_ITEM, "") })
        setUpdateField({ ...updateField, itemNum: itemNum.replace(PrefixEnum.DIRECT_IN_ITEM, ""), transactionTypeId: changedValue.transactionTypeId })
      }
    }
  }

  const transactionType = (type) => {
    let transactionType = null;
    switch (type) {
      case 1:
        transactionType = transactionTypeEnum[0]
        break;
      case 2:
        transactionType = transactionTypeEnum[1]
        break;
      case 3:
        transactionType = transactionTypeEnum[2]
        break;
      default:
        transactionType = transactionTypeEnum[1]
        break
    }
    return transactionType;
  }


  return (
    <TickLocalModal
      onSubmit={onHandleSubmit}
      showLocalModal={showProductModal}
      onCancel={onCloseModal}
      title={btn === 'add' ? t("t_addNewItem") : t("t_editItem")}
      form={form}
      setVisible={setShowProductModal}
      okText={btn === 'add' ? "Submit" : "Save"}
    >
      <Form
        layout={"vertical"}
        form={form}
        // onFieldsChange={onFieldsChange}
        onValuesChange={onFieldsChange}
      >
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div style={{ display: "flex", flexDirection: "column", width: "75%" }}>
            <div className={styles.header}>{t("t_generalInformation")}</div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <div className={styles.formCol} style={{ width: "33.3%" }}>
                <Form.Item
                  label={t("t_transactionType")}
                  // rules={[{ required: true }]} 
                  name="transactionTypeId"
                >
                  <CommonSelection
                    optionKey={`transactionTypeId`}
                    defaultValue={2}
                    onChange={e => setShowTransactionType(transactionType(e))}
                  />
                </Form.Item>
                {(showTransactionType === transactionTypeEnum[0]) &&
                  <Form.Item
                    label={t("t_consignor")}
                    rules={[{ required: true }]}
                    name="consignorId"
                  >
                    <CommonSelection
                      optionKey={`consignor`}
                      instantAdd
                    />
                  </Form.Item>}
                <Form.Item
                  label={t("t_productNum")}
                  name="itemNum"
                  rules={[{ required: true }]}
                  initialValue={""}
                >
                  <Input
                    name="itemNum"
                    placeholder={t("t_pleaseEnter")}
                  />
                </Form.Item>
                {showTransactionType === transactionTypeEnum[1] &&
                  <Col span={24}>
                    <Form.Item
                      label={t("t_directInCost")}
                      name="directInCost"
                      initialValue={0}
                    >
                      <InputNumber
                        name="directInCost"
                        min={0}
                        type="number"
                        prefix={"$"}
                        // max={100}
                        // formatter={(value) => `${value}%`}
                        // parser={(value) => value.replace("%", "")}
                        controls={false}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                }
                {showTransactionType === transactionTypeEnum[1] &&
                  <Col span={24}>
                    <Form.Item
                      label={t("t_directInDate")}
                      name="directInDate"
                    >
                      <DatePicker
                        name="directInDate"
                        placeholder={"YYYY-MM-DD"}
                        allowClear={true}
                        style={{ width: "100%" }}
                        onKeyDown={(e) => {
                          if (e.code !== "Backspace" && e.target.value?.length == 4 || e.code !== "Backspace" && e.target.value?.length == 7) {
                            e.target.value += "-"
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                }
                {(showTransactionType === transactionTypeEnum[0] || showTransactionType === transactionTypeEnum[2]) &&
                  <Row justify="space-between">
                    <Col span={showTransactionType === transactionTypeEnum[0] ? 11 : 24}>
                      <Form.Item
                        label={t("t_handlingFeePercentage")}
                        name="handlingFeePercentage"
                        tooltip={t("t_handlingFeeReminder")}
                      >
                        <InputNumber
                          name="handlingFeePercent"
                          min={0}
                          max={100}
                          // placeholder="0.00"
                          addonAfter="%"
                          type="number"
                          controls={false}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={showTransactionType === transactionTypeEnum[0] ? 11 : 24}>
                      <Form.Item
                        label={t("t_handlingFee")}
                        name="handlingFee"
                      >
                        <InputNumber
                          name="handlingFee"
                          min={0}
                          placeholder="0.00"
                          prefix="HK$"
                          type="number"
                          style={{ width: "100%" }}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                }
              </div>
              <div className={styles.formCol} style={{ width: "33.3%" }}>
                <Form.Item
                  label={t("t_date")}
                  name="transactionDate"
                  rules={[{ required: true }]}
                  initialValue={dayjs()}
                >
                  <DatePicker
                    name="transactionDate"
                    placeholder={"YYYY-MM-DD"}
                    allowClear={true}
                    style={{ width: "100%" }}
                    onKeyDown={(e) => {
                      if (e.code !== "Backspace" && e.target.value?.length == 4 || e.code !== "Backspace" && e.target.value?.length == 7) {
                        e.target.value += "-"
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={t("t_productName")}
                  name={`itemName${lang}`}
                  rules={[{ required: true }]}
                >
                  <Input
                    name={`itemName${lang}`}
                    placeholder={t("t_pleaseEnter")}
                  />
                </Form.Item>
                <Form.Item
                  label={t("t_onSalesPrice")}
                  name="onSalesPrice"
                >
                  <InputNumber
                    name="onSalesPrice"
                    prefix="HK$"
                    placeholder="0.00"
                    type="number"
                    controls={false}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  label={t("t_branchNum")}
                  name="branchNum"
                  initialValue={defaultBranchNum}
                >
                  <Input
                    name="branchNum"
                    placeholder={t("t_pleaseEnter")}
                  />
                </Form.Item>
              </div>
              <div className={styles.formCol} style={{ width: "33.3%" }}>
                <Form.Item
                  label={t("t_brand")}
                  // name={`brand`}
                  name="deptKey"
                  rules={[{ required: true }]}
                >
                  <CommonSelection
                    optionKey={`brand`}
                  // size={{
                  //   width: "100%",
                  //   margin: "0.5rem 0"
                  // }}
                  />
                </Form.Item>
                <Row justify="space-between">
                  <Col span={14}>
                    <Form.Item
                      label={t("t_price")}
                      name="amt"
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        name="amt"
                        prefix="HK$"
                        placeholder="0.00"
                        // type="number"
                        min={0}
                        controls={false}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={t("t_reprintTag")}
                      name="isReprintTag"
                      valuePropName={"checked"}
                    >
                       <Switch/>
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="space-between">
                    <Col span={14}>
                      <Form.Item
                        label={t("t_status")}
                        name="status"
                        rules={[{ required: true }]}
                        initialValue={"IN_STOCK"}
                      >
                        <Select
                          placeholder={t("t_pleaseSelect")}
                          options={[
                            { value: "IN_STOCK", label: t("t_inStock") },
                            { value: "SOLD", label: t("t_sold") },
                            { value: "RESERVED", label: t("t_reserved") },
                            { value: "ONLINE_RESERVED", label: t("t_onlineReserved") },
                            { value: "ONLINE_PURCHASE_RESERVED", label: t("t_onlinePurchaseReserved") },
                            { value: "CONSIGNEE_RESERVED", label: t("t_consigneeReserved") },
                            { value: "RETURNED_TO_CONSIGNEE", label: t("t_returnedToConsignee") },
                          ]}
                          disabled={modalData.singleConsignment === 0}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                    <Form.Item
                      label={t("t_stock")}
                      name="qty"
                      initialValue={1}
                    >
                      <InputNumber
                        name="qty"
                        min={1}
                        type="number"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                { isShowStockLocation() ? (
                  <Form.Item
                    label={t("t_stockLocation")}
                    name="stockLocation"
                    initialValue={defaultStockLocation}
                  >
                    <Input
                      name="stockLocation"
                      placeholder={t("t_pleaseEnter")}
                    />
                  </Form.Item>
                ) : null}
              </div>
            </div>

            <div className={styles.header}>{t("t_details")}</div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <div className={styles.formCol} style={{ width: "33.3%" }}>
                <Form.Item
                  label={t("t_category")}
                  // name={`category`}
                  name="subCatKey"
                  rules={[{ required: true }]}
                >
                  <CommonSelection
                    optionKey={`category`}
                  />
                </Form.Item>
                <Form.Item
                  label={t("t_year")}
                  name="year"
                // rules={[{ required: true }]}
                >
                  <Input
                    name="year"
                    placeholder={t("t_pleaseEnter")}
                  />
                  {/* <DatePicker.YearPicker picker="year" allowClear={true} /> */}
                </Form.Item>
                <Form.Item
                  label={t("t_color")}
                  name={`colorIds`}
                  rules={[{ required: true }]}
                >
                  <CommonSelection
                    optionKey={`colorIds`}
                    mode="multiple"
                  />
                </Form.Item>
                <Form.Item
                  label={t("t_cortex")}
                  name={`cortexIds`}
                >
                  <CommonSelection
                    optionKey={`cortexIds`}
                    mode="multiple"
                  />
                </Form.Item>
              </div>
              <div className={styles.formCol} style={{ width: "33.3%" }}>
                <Form.Item
                  label={t("t_rating")}
                  name={`ratingIds`}
                  rules={[{ required: true }]}
                >
                  <CommonSelection
                    optionKey={`ratingIds`}
                    mode="multiple"
                  />
                </Form.Item>
                <Form.Item
                  label={`${t("t_size")} (H x L x W cm)`}
                >
                  <Form.Item name="height" noStyle>
                    <InputNumber
                      min={0}
                      maxLength={sizeInputMaxLength}
                      // style={{ width: "29.5%" }}
                      style={{ width: width > 1080 ? "29.5%" : "27.3%" }}
                      step="0.1"
                      placeholder="-"
                      type="number"
                    />
                  </Form.Item>
                  <span> x </span>
                  <Form.Item name="length" noStyle>
                    <InputNumber
                      min={0}
                      maxLength={sizeInputMaxLength}
                      // style={{ width: "29.5%" }}
                      style={{ width: width > 1080 ? "29.5%" : "27.3%" }}
                      step="0.1"
                      placeholder="-"
                      type="number"
                      />
                  </Form.Item>
                  <span> x </span>
                  <Form.Item name="width" noStyle>
                    <InputNumber
                      controls="false"
                      min={0}
                      maxLength={sizeInputMaxLength}
                      // style={{ width: "29.5%" }}
                      style={{ width: width > 1080 ? "29.5%" : "27.3%" }}
                      step="0.1"
                      placeholder="-"
                      type="number"
                    />
                  </Form.Item>
                </Form.Item>

                <Form.Item label={t("t_material")} name={`materialIds`}>
                  <CommonSelection
                    optionKey={`materialIds`}
                    mode="multiple"
                  />
                </Form.Item>
                <Form.Item label={t("t_mark")} name="mark">
                  <Input
                    name="mark"
                    placeholder={t("t_pleaseEnter")}
                    maxLength={50}
                  />
                </Form.Item>
              </div>
              <div className={styles.formCol} style={{ width: "33.3%" }}>
                <Form.Item label={t("t_model")} name={"model"}>
                  <Input
                    name="model"
                    placeholder={t("t_pleaseEnter")}
                  />
                </Form.Item>
                <Form.Item
                  label={t("t_countryOfOrigin")}
                  name={`originIds`}
                >
                  <CommonSelection
                    optionKey={`originIds`}
                  />
                </Form.Item>
                <Form.Item
                  label={t("t_accessories")}
                  name={`accessoriesIds`}
                  rules={[{ required: true }]}
                >
                  <CommonSelection
                    optionKey={`accessoriesIds`}
                    mode="multiple"
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", width: "25%" }}>
            <div className={styles.header}>{t("t_uploadPhoto")}</div>
            {/* <Form.Item
              name={"itemImage"}
              // style={{ width: "250px", height: "250px" }}
            >
              <>
                <Upload
                  className="avatar"
                  listType="picture-card"
                  headers={{
                    authorization: 'authorization-text',
                    'Access-Control-Allow-Origin': '*',
                    'X-Requested-With': null
                  }}
                  fileList={fileList}
                  accept="image/png, image/jpeg, image/jpg"
                  // showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  onPreview={handlePreview}
                  disabled={disabledUploadImg}
                  customRequest={customRequest}
                  style={{ width: "100%" }}
                >
                  {fileList.length >= 1 ? null : uploadButton}
                  {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '96%' }} /> : uploadButton}
                </Upload>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="image"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </>
            </Form.Item> */}
            <Image src={getItemImgSrc(modalData?.itemNum)} />

            <div className={styles.header}>{t("t_descriptionForInternal")}</div>
            <div className={styles.descriptionContainer}>
              <Form.Item label={t("t_productName")} name="itemNameInternal">
                <Input
                  name="itemNameInternal"
                  placeholder={t("t_pleaseEnter")}
                />
              </Form.Item>

              <Form.Item label={t("t_color")} name="colorIdsInternal" >
                <CommonSelection
                  optionKey="colorIds"
                  mode="multiple"
                />
              </Form.Item>

              <Form.Item label={t("t_remark")} name="remark">
                <TextArea
                  name="remark"
                  rows={4}
                  placeholder={t("t_pleaseEnter")}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </TickLocalModal>
  );
};

export default memo(ProductModal)