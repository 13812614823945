import React, { useEffect, useState } from 'react';
import SystemPermissionApi from '../../service/systemApi/systemPermissionApi';
import SystemMenuApi from '../../service/systemApi/systemMenuApi';
import { Checkbox, message } from "antd";
import styles from "./updatePermission.module.scss"
import SystemRoleApi from '../../service/systemApi/systemRoleApi';
import { t } from 'i18next';

const UpdatePermission = (props) => {
  const { roleId, activeTab } = props;
  const [permissions, setPermissions] = useState([]);
  const [menus, setMenus] = useState([]);
  const [permissionDefaultValue, setPermissionDefaultValue] = useState([]);
  const [menuDefaultValue, setMenuDefaultValue] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  
  useEffect(() => {
    getCheckboxDefaultValue();
  }, [roleId, activeTab])

  useEffect(() => {
    getAllPermission();
  }, [activeTab])
  
  const getAllPermission = async () => {
    try {
      const permissions = await SystemPermissionApi.listAll();
      setPermissions(permissions?.data)
      const menus = await SystemMenuApi.listAll();
      setMenus(menus?.data)
    } catch (e) {
      console.error(e);
    }
  }

  const getCheckboxDefaultValue = async () => {
    try {
      let resp = await SystemRoleApi.getPermissionByRoleId(roleId);
      if (resp.msg === 'Success') {
        setMenuDefaultValue(resp.data.menus.map(menu => menu.menuId));
        setPermissionDefaultValue(resp.data.permissions.map(permission => permission.permissionId));
      }
    } catch (e) {
      console.error(e);
    }
  }

  const menuOnChange = (e) => {
    SystemMenuApi.updateRoleMenuRelation(roleId, e.target.value).then(() => {
      updateSuccess();
    }).catch((e) => {
      updateFailure();
    }).finally(() => {
      getCheckboxDefaultValue();
    })
  }

  const permissionOnChange = (e) => {
    SystemPermissionApi.updateRolePermissionRelation(roleId, e.target.value).then(() => {
      updateSuccess()
    }).catch((e) => {
      updateFailure();
    }).finally(() => {
      getCheckboxDefaultValue();
    })
  }

  const updateSuccess = () => {
    messageApi.open({
      type: 'success',
      content: t("t_updateSuccess"),
    });
  };

  const updateFailure = () => {
    messageApi.open({
      type: 'error',
      content: t("t_updateFail"),
    });
  }

  return (
    <div className={styles.permissionContainer}>
      {contextHolder}
      <div className={styles.title}>{t("t_menu")}</div>
        <Checkbox.Group className={styles.checkboxContainer} value={menuDefaultValue}>
          {menus.map((item, index) => {
              return (
                <Checkbox value={item.id} key={index} onClick={menuOnChange}>{item.name}</Checkbox>
              )
          })}
        </Checkbox.Group>
      <div className={styles.title}>{t("t_action")}</div>
      <Checkbox.Group className={styles.checkboxContainer} value={permissionDefaultValue}>
        {permissions.map((item, index) => {
            return (
              <Checkbox value={item.id} key={index} onChange={permissionOnChange}>{item.remarks ? `${item.name} (${item.remarks})` : `${item.name}`}</Checkbox>
            )
        })}
      </Checkbox.Group>
    </div>
  )
}

export default UpdatePermission;