import React, { memo, useEffect, useState } from "react";
import TitleBar from "../../components/titleBar/TitleBar";
import variables from "../../theme/variable.scss";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import styles from './statusLog.module.scss';
import { tickLog } from "../../utils/logger";
import { useDispatch, useSelector } from "react-redux"
import { closeModal, showModal } from "../../redux/tickModalSilce";
import { setMasterTableData, setStatusLogParams, setStatusLogTableData } from "../../redux/tickTableSlice";
import TickTable from "../../components/tickTable/TickTable";
import TitleBarStatusLogPaginationContent from "../../components/titleBarStatusLogPaginationContent/TitleBarStatusLogPaginationContent";
import MasterModal from "../../modals/MasterModal";
import MasterApi from "../../service/masterApi/masterApi";
import CatApi from "../../service/catApi/catApi";
import i18n from '../../i18n';
import StatusLogApi from "../../service/statusLogApi/statusLog";
import * as dayjs from "dayjs"
import { STATUS } from "../../utils/statusEnum";

const StatusLog = (props) => {
  const {  } = props
  const [dataSource, setDataSource] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [itemCats, setItemCats] = useState([]);
  const [masterTableLoading, setMasterTableLoading] = useState(false);
  const [showMasterModal, setShowMasterModal] = useState(false);
  const [buttonType, setButtonType] = useState('add');
  const [language, setLanguage] = useState(i18n.language);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const statusLogParams = useSelector(state => state.tickTable.statusLogParams);

  useEffect(() => {
    listByPage()
  }, [statusLogParams])

  const listByPage = async () => {
    setMasterTableLoading(true);
    try {
      const resp = await StatusLogApi.listByPage(statusLogParams);
      tickLog("all product data", resp)
      if (resp.success) {
        const { data, msg } = resp
        setDataSource(data.array)
        dispatch(
          setStatusLogTableData({ totalCount: data.totalCount })
        )
      }
    } catch {
    } finally {
      setMasterTableLoading(false);
    }
  }

  const onReload = () => {
    dispatch(setStatusLogParams({...statusLogParams, sortOptions: {}, input: '', pageNum: 1 }));
  }
  

  const handleSortKey = (sortOrder) => {
    let sortKey;
    if (sortOrder === 'ascend') {
      sortKey = 'ASC'
    }

    if (sortOrder === 'descend') {
      sortKey = 'DESC'
    }
    return sortKey;
  }
  
  const onChange = (pagination, filter, sorter) => {
    let sortOptions = {};
    if (Array.isArray(sorter)) {
      sorter.forEach(sortEle => {
        sortOptions[sortEle.columnKey] = handleSortKey(sortEle.order);
      })
    } else {
      sortOptions[sorter.columnKey] = handleSortKey(sorter.order);
    }
    
    if (!sorter.order) {
      delete sortOptions[sorter.columnKey]
    }

    dispatch(setStatusLogParams({
      ...statusLogParams,
      sortOptions,
    }))
  }

  const sortOrder  = (key) =>  {
    if (statusLogParams.sortOptions[key] === 'ASC') {
      return 'ascend';
    }
    if (statusLogParams.sortOptions[key] === 'DESC') {
      return 'descend';
    }
    return undefined;
  }

  const columns = [
    {
      title: t("t_itemNum"),
      dataIndex: "itemNum",
      key: "itemNum",
      sorter: {
        multiple: 3
      },
      sortOrder: sortOrder('itemNum'),
      
    },
    {
      title: t("t_description"),
      dataIndex: "description",
      key: "description",
      render: (_, record) => {
        return (
          <div>
            <span> {language === "tc_hk" ? "由" : "Changed from"} </span>
            <span className={styles[`${STATUS[`${record.oldStatus}`]?.class}`] || styles.default}>{t(STATUS?.[`${record.oldStatus}`]?.label)}</span>
            <span> {language === "tc_hk" ? "更新至" : "to"} </span>
            <span className={styles[`${STATUS[`${record.newStatus}`]?.class}`] || styles.default}>{t(STATUS?.[`${record.newStatus}`]?.label)}</span>
          </div>
        )
      },
    },
    {
      title: t("t_updateTime"),
      dataIndex: "createdOn",
      key: "createdOn",
      sorter: {
        multiple: 1
      },
      sortOrder: sortOrder('createdOn'),
      render: (_, record) => {
        return dayjs(record.createdOn).format('YYYY-MM-DD HH:mm:ss');
      },
    },
  ]

  return (
    <>
      <TitleBar hasSideBar hasShadow color={variables.themeWhite}>
        <TitleBarStatusLogPaginationContent
          reload={onReload}
        />
      </TitleBar>
      <div className={styles.container}>
        <div className={styles.tableWrapper}>
          <TickTable
            dataSource={dataSource}
            // columns={columns[`${masterTableParams.type}`] }
            columns={columns}
            rowKey={(record) => record.id}
            loading={masterTableLoading}
            onChange={onChange}
          />         
        </div>
      </div>
      {showMasterModal &&
        <MasterModal
          showMasterModal={showMasterModal}
          setShowMasterModal={setShowMasterModal}
          width="500px"
          modalData={modalData}
          buttonType={buttonType}
          listByPage={listByPage}
        />}
    </>
  );
};

export default memo(StatusLog);
