import React from 'react'
import { Table } from "antd";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { STATUS } from '../../utils/statusEnum';
import styles from '../../pages/consignment/consignment.module.scss';
import { DeleteOutlined, EditOutlined, PlusSquareOutlined } from '@ant-design/icons';
import variables from "../../theme/variable.scss";
import TickTable from '../../components/tickTable/TickTable';

const ConsignmentItemTable = (props) => {

  const { dataSource = [],
    onEditReal = () => { },
    onDeleteReal = () => { },
    selectedItemList = [],
    selectedItem = () => { },
    setShowAddModal = () => { },
  } = props;
  const { t } = useTranslation();
  const lang = i18n.language === "tc_hk" ? "Tc" : "En";

  const renderOptionColumn = (_) => {
    return _.length > 0 ? _.map(obj => obj[`name${lang}`]).join(",") : "-"
  }

  const columns = [
    {
      title: t('t_productNum'),
      dataIndex: 'transactionType',
      key: 'transactionType',
      render: _ => _[`name${lang === "Tc" ? "Tc" : "En"}`] ? _[`name${lang === "Tc" ? "Tc" : "En"}`] : _[`name${lang === "Tc" ? "En" : 'Tc'}`] || "-"
    },
    {
      title: t('t_status'),
      dataIndex: 'status',
      key: 'status',
      render: _ => <span className={styles[STATUS[_]?.class || "default"]}>{t(STATUS[_]?.label || "-")}</span>
    },
    {
      title: t('t_productNum'),
      dataIndex: 'itemNum',
      key: 'itemNum',
      render: _ => _ ? _ : "-"
    },
    {
      title: t('t_brand'),
      dataIndex: 'brand',
      key: 'brand',
      render: _ => _[`name${lang === "Tc" ? "TC" : "En"}`] ? _[`name${lang === "Tc" ? "TC" : "En"}`] : _[`name${lang === "Tc" ? "En" : "Tc"}`]
    },
    {
      title: t('t_color'),
      dataIndex: 'colors',
      key: 'colors',
      render: _ => renderOptionColumn(_)
    },
    {
      title: t('t_cortex'),
      dataIndex: 'cortexs',
      key: 'cortexs',
      render: _ => renderOptionColumn(_)
    },
    {
      title: t('t_accessories'),
      dataIndex: 'accessories',
      key: 'accessories',
      render: _ => renderOptionColumn(_)
    },
    {
      title: t('t_price'),
      dataIndex: 'amt',
      key: 'amt',
      render: _ => `$${_}` || "-"
    },
    {
      title: t("t_function"),
      dataIndex: "operate",
      key: "operate",
      fixed: 'right',
      render: (_, record) => (
        <div>
          {
            record.receiptNum ? <>
              <EditOutlined
                style={{ padding: "5px" }}
              />
              <DeleteOutlined
                style={{ padding: "5px" }}
              />
            </>
              :
              <>
                <EditOutlined
                  style={{ color: variables.themeBlue, padding: "5px", cursor: "pointer" }}
                  onClick={() => onEditReal(record?.id)}
                />
                <DeleteOutlined
                  style={{ color: variables.themeRed, padding: "5px", cursor: "pointer" }}
                  onClick={() => onDeleteReal(record?.id)}
                />
              </>
          }
          {/* <PlusSquareOutlined style={{ color: variables.themeRed, padding: "5px", cursor: "pointer" }}
            onClick={() => setShowAddModal(true)} /> */}
        </div>
      )
    }
  ]

  const rowClassName = (record, index) => {
    console.log(index)
    let className = ["selectable"];
    if (selectedItemList.length > 0) {
      selectedItemList.forEach(element => {
        if (element.id === record.id) {
          className.push("tableRowSelectedBlue")
        }
      })
    }
    return className.join(" ");
  }

  return (
    <div className='consignmentItemTable' >
      <TickTable
        dataSource={dataSource}
        columns={columns}
        rowClassName={rowClassName}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              const isTargetClicked = event.target.outerHTML.startsWith('<svg') || event.target.outerHTML.startsWith('<path')
              if (!isTargetClicked) {
                if (record.receiptNum) return
                selectedItem(record)
              }
            },
          };
        }}
        scroll={{ y: 700}}
      />
    </div>
  )
}

export default ConsignmentItemTable