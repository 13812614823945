import React, { useEffect } from 'react';
import styles from './memberModal.module.scss';
import { TickLocalModal } from '../TickLocalModal';
import { Form, Input, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { modalWidth } from '../../config/systemConfig';
import MemberApi from '../../service/memberApi/memberApi';
import { useDispatch } from 'react-redux';
import { closeModal, showModal } from '../../redux/tickModalSilce';

const MemberModal = (props) => {
  const { isVisible, setIsVisible, editRecord, setEditRecord, fetchDataSource } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!editRecord) return;
    form.setFieldsValue(editRecord);
  }, [editRecord]);

  const onEditSubmit = async () => {
    try {
      const { email, address, type, lastName, givenName, remark, ...rest } = form.getFieldsValue();

      const newMemberInfo = {
        ...rest,                          // it means remaining fields are required 
        email: email || "",
        address: address || "",
        type: type || "",
        lastName: lastName || "",
        givenName: givenName || "",
        remark: remark || ""
      };
      const resp = await MemberApi.update(newMemberInfo);
      if (!resp.success) {
        dispatch(
          showModal({
            key: "error",
            header: "Something Wrong Please Try Again!",
            msg: resp?.msg || "Failed!",
            callback: () => {
              dispatch(closeModal());
            },
          })
        );
        return;
      }
      dispatch(
        showModal({
          key: "success",
          // header: resp?.data || "Member Added Successfully!",
          header: "Member updated Successfully!",
          msg: resp?.msg || "Success!",
          callback: () => {
            dispatch(closeModal());
          },
        })
      );
      fetchDataSource();
      setEditRecord(null);
      onCancel();
    } catch (e) {
      console.error(e);
    }
  };

  const onAddSubmit = async () => {
    try {
      const { email, address, type, memberNum, lastName, givenName, remark, ...rest } = form.getFieldsValue();

      const newMemberInfo = {
        ...rest,                          // it means remaining fields are required 
        email: email || "",
        address: address || "",
        type: type || "",
        lastName: lastName || "",
        givenName: givenName || "",
        remark: remark || ""
      };
      const resp = await MemberApi.save(newMemberInfo);
      if (!resp.success) {
        dispatch(
          showModal({
            key: "error",
            header: "Something Wrong Please Try Again!",
            msg: resp?.msg || "Failed!",
            callback: () => {
              dispatch(closeModal());
            },
          })
        );
        return;
      }
      dispatch(
        showModal({
          key: "success",
          // header: resp?.data || "Member Added Successfully!",
          header: "Member Added Successfully!",
          msg: resp?.msg || "Success!",
          callback: () => {
            dispatch(closeModal());
          },
        })
      );
      fetchDataSource();
      onCancel();
    } catch (e) {
      console.error(e);
    }
  }

  const onCancel = () => {
    form.resetFields();
    setIsVisible(false);
  };

  const mobileValidate = (_, value) => {
    if (value && value.length < 8) {
      return Promise.reject(t('t_mobileLength'));
    }
    if (value && !/^[0-9]+$/.test(value)) {
      return Promise.reject(t('t_mobileNoCharacter'));
    }
    return Promise.resolve();
  };


  return (
    <TickLocalModal
      showLocalModal={isVisible}
      onCancel={onCancel}
      form={form}
      title={t(`t_${editRecord ? "editMember" : "addMember"}`)}
      width={modalWidth}
      onSubmit={editRecord ? onEditSubmit : onAddSubmit}
    >
      <Form
        layout={"vertical"}
        form={form}
      >
        <Row gutter={12}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Item label={t("t_memberNum")} name="memberNum">
              <Input
                name="memberNum"
                placeholder={t(`${editRecord ? "t_pleaseEnter" : "-"}`)}
                disabled
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Item label={t("t_fullName")} name="fullName" rules={[{ required: true }]}>
              <Input
                name="fullName"
                placeholder={t("t_pleaseEnter")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Item label={t("t_mobile")} name="mobile" rules={[{ required: true, validator: mobileValidate}]}>
              <Input
                name="mobile"
                placeholder={t("t_pleaseEnter")}
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Item label={t("t_email")} name="email" rules={[{ type: 'email' }]}>
              <Input
                name="email"
                placeholder={t("t_pleaseEnter")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Item label={t("t_lastName")} name="lastName">
              <Input
                name="lastName"
                placeholder={t("t_pleaseEnter")}
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Item label={t("t_givenName")} name="givenName" >
              <Input
                name="givenName"
                placeholder={t("t_pleaseEnter")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Item label={t("t_address")} name="address">
              <Input
                name="address"
                placeholder={t("t_pleaseEnter")}
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Form.Item label={t("t_memberType")} name="type">
              <Input
                name="type"
                placeholder={t("t_pleaseEnter")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item label={t("t_remark")} name="remark">
              <Input.TextArea
                name="remark"
                placeholder={t("t_pleaseEnter")}
                autoSize={{ minRows: 4 }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </TickLocalModal>
  )
}

export default MemberModal;