import React, { useState, useEffect } from 'react'
import styles from './member.module.scss';
import TitleBar from '../../components/titleBar/TitleBar'
import TickTable from '../../components/tickTable/TickTable';
import variables from "../../theme/variable.scss";
import TitleBarMemberPaginationContent from '../../components/titleBarMemberPaginationContent/TitleBarMemberPaginationContent';
import MemberApi from '../../service/memberApi/memberApi';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { clearMemberParams, setMemberTableData, setMemberParams } from '../../redux/tickTableSlice';
import { useDispatch, useSelector } from 'react-redux';
import MemberEditModal from '../../modals/memberModal/MemberModal';
import { closeModal, showModal } from '../../redux/tickModalSilce';
import { Tooltip } from 'antd';

const Member = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editRecord, setEditRecord] = useState(null);

  const memberTableParams = useSelector(state => state.tickTable.memberParams);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const permissions = JSON.parse(localStorage.getItem('PERMISSION'))

  useEffect(() => {
    if (memberTableParams.totalCount && memberTableParams.totalCount !== 0) return;
    fetchDataSource();
  }, [memberTableParams]);

  const fetchDataSource = async () => {
    setLoading(true);
    try {
      const resp = await MemberApi.listByPage(memberTableParams);
      const { success, data } = resp;
      if (!success) return;
      setDataSource(data.array)
      dispatch(setMemberTableData({ totalCount: data?.totalCount }));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const onReload = () => {
    dispatch(clearMemberParams());
  };

  const handleSortKey = (sortOrder) => {
    let sortKey;
    if (sortOrder === 'ascend') {
      sortKey = 'ASC'
    }

    if (sortOrder === 'descend') {
      sortKey = 'DESC'
    }
    return sortKey;
  };

  const onChange = (pagination, filter, sorter) => {
    let sortOptions = {};
    if (Array.isArray(sorter)) {
      sorter.forEach(sortEle => {
        sortOptions[sortEle.columnKey] = handleSortKey(sortEle.order);
      })
    } else {
      sortOptions[sorter.columnKey] = handleSortKey(sorter.order);
    }

    if (!sorter.order) {
      delete sortOptions[sorter.columnKey]
    }

    dispatch(setMemberParams({
      ...memberTableParams,
      sortOptions,
    }))
  };

  const sortOrder = (key) => {
    if (memberTableParams.sortOptions[key] === "ASC") {
      return "ascend";
    }
    if (memberTableParams.sortOptions[key] === "DESC") {
      return "descend";
    }
    return undefined;
  };

  const onEdit = (record) => {
    setEditRecord(record);
    setIsEditModalVisible(true);
  }

  const onDelete = async (memberNum) => {
    dispatch(
      showModal({
        key: "warning",
        header: t("t_delete"),
        msg: t("t_confirmDelete"),
        showCloseBtn: true,
        callback: async () => {
          setLoading(true);
          try {
            const resp = await MemberApi.delete(memberNum);
            if (!resp.success) {
              dispatch(
                showModal({
                  key: "error",
                  header: "Something Wrong Please Try Again!",
                  msg: resp?.msg || "Failed!",
                  // showCloseBtn: true,
                  callback: () => {
                    dispatch(closeModal());
                  },
                })
              );
              return;
            };
            dispatch(
              showModal({
                key: "success",
                header: resp?.data || "Item Deleted",
                msg: resp?.msg || "Success",
                callback: () => {
                  dispatch(closeModal());
                },
              })
            );
            fetchDataSource();
          } catch (e) {
            console.error(e);
          } finally {
            setLoading(false);
          }
        }
      })
    )
  }

  const columns = [
    {
      title: t("t_memberNum"),
      dataIndex: "memberNum",
      key: "memberNum",
      sorter: {
        multiple: 8
      },
      sortOrder: sortOrder("memberNum"),
      render: (_) => (
        <div>
          {
            _ ? _ : "-"
          }
        </div>
      )
    },
    {
      title: t("t_fullName"),
      dataIndex: "fullName",
      key: "fullName",
      sorter: {
        multiple: 7
      },
      sortOrder: sortOrder("fullName"),
      render: (_) => (
        <div>
          {
            _ ? _ : "-"
          }
        </div>
      )
    },
    {
      title: t("t_mobile"),
      dataIndex: "mobile",
      key: "mobile",
      sorter: {
        multiple: 6
      },
      sortOrder: sortOrder("mobile"),
      render: (_) => (
        <div>
          {
            _ ? _ : "-"
          }
        </div>
      )
    },
    {
      title: t("t_email"),
      dataIndex: "email",
      key: "email",
      sorter: {
        multiple: 5
      },
      sortOrder: sortOrder("email"),
      render: (_) => (
        <div>
          {
            _ ? _ : "-"
          }
        </div>
      )
    },
    {
      title: t("t_address"),
      dataIndex: "address",
      key: "address",
      sorter: {
        multiple: 4
      },
      sortOrder: sortOrder("address"),
      render: (_) => (
        <div>
          {
            _ ? _ : "-"
          }
        </div>
      )
    },
    {
      title: t("t_memberType"),
      dataIndex: "type",
      key: "type",
      sorter: {
        multiple: 3
      },
      sortOrder: sortOrder("type"),
      render: (_) => (
        <div>
          {
            _ ? _ : "-"
          }
        </div>
      )
    },
    {
      title: t("t_lastName"),
      dataIndex: "lastName",
      key: "lastName",
      sorter: {
        multiple: 2
      },
      sortOrder: sortOrder("lastName"),
      render: (_) => (
        <div>
          {
            _ ? _ : "-"
          }
        </div>
      )
    },
    {
      title: t("t_givenName"),
      dataIndex: "givenName",
      key: "givenName",
      sorter: {
        multiple: 1
      },
      sortOrder: sortOrder("givenName"),
      render: (_) => (
        <div>
          {
            _ ? _ : "-"
          }
        </div>
      )
    },
    {
      title: t("t_remark"),
      dataIndex: "remark",
      key: "remark",
      render: (_) => (
        <div className={styles.remarkField}>
          {
            _ ? _ : "-"
          }
        </div>
      )
    },
    {
      title: t("t_function"),
      dataIndex: "operate",
      key: "operate",
      fixed: 'right',
      width: 140,
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {
            permissions.findIndex((item) => item === "member:edit") > -1 ?
              // <EditOutlined style={{ color: variables.themeBlue, padding: "5px" }} onClick={() => onEdit(record)} /> :
              <EditOutlined style={{ color: variables.themeBlue, fontSize: "24px" }} onClick={() => onEdit(record)} /> :
              <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
                {/* <EditOutlined style={{ color: variables.themeTextDisableGrey, padding: "5px" }} /> */}
                <EditOutlined style={{ color: variables.themeTextDisableGrey, fontSize: "24px" }} />
              </Tooltip>
          }
          {
            permissions.findIndex((item) => item === "member:delete") > -1 ?
              // <DeleteOutlined style={{ color: variables.themeRed, padding: "5px" }} onClick={() => onDelete(record.memberNum)} /> :
              <DeleteOutlined style={{ color: variables.themeRed, fontSize: "24px" }} onClick={() => onDelete(record.memberNum)} /> :
              <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
                {/* <DeleteOutlined style={{ color: variables.themeTextDisableGrey, padding: "5px" }} /> */}
                <DeleteOutlined style={{ color: variables.themeTextDisableGrey, fontSize: "24px" }} />
              </Tooltip>
          }
        </div>
      )
    }
  ];

  return (
    <>
      <TitleBar hasSideBar hasShadow color={variables.themeWhite}>
        <TitleBarMemberPaginationContent reload={onReload} fetchDataSource={fetchDataSource} />
      </TitleBar>
      <div className={styles.container}>
        <div className={styles.tableWrapper}>
          <TickTable
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            rowKey={(record) => record.id}
            // scroll={{ x: "1200px", y: `${variables.tableMaxHeight}` }}
            onChange={onChange}
          />
        </div>
      </div>
      {
        isEditModalVisible ?
          <MemberEditModal
            isVisible={isEditModalVisible}
            setIsVisible={setIsEditModalVisible}
            editRecord={editRecord}
            setEditRecord={setEditRecord}
            fetchDataSource={fetchDataSource}
          /> : <></>
      }
    </>
  )
}

export default Member