import React, { memo, useState } from "react";
import backArrowGrey from "../../assets/img/icon/backArrowGrey.svg";
import backDoubleArrowGrey from "../../assets/img/icon/backDoubleArrowGrey.svg";
import forwardArrowGrey from "../../assets/img/icon/forwardArrowGrey.svg";
import forwardDoubleArrowGrey from "../../assets/img/icon/forwardDoubleArrowGrey.svg";
import backArrowBlack from "../../assets/img/icon/backArrowBlack.svg";
import backDoubleArrowBlack from "../../assets/img/icon/backDoubleArrowBlack.svg";
import forwardArrowBlack from "../../assets/img/icon/forwardArrowBlack.svg";
import forwardDoubleArrowBlack from "../../assets/img/icon/forwardDoubleArrowBlack.svg";
import styles from "./paginationBar.module.scss";
import { useDispatch } from "react-redux";

const PaginationBar = (props) => {
  const { dispatchParams = () => { }, params = {} } = props;
  let { pageNum, pageSize, totalPage, totalCount } = params;
  const dispatch = useDispatch();
  let [newPageNum, setNewPageNum] = useState(1)

  const onPageChange = (e, value) => {
    const { pageNum } = params;
    // console.log(e.nativeEvent.inputType == "deleteContentBackward");
    console.log(e.target.value == "");
    if ((e.target.value == "")) {
      setNewPageNum(null)
      return
    }
    console.log(parseInt(e.target.value));
    setNewPageNum(e.target.value)
    dispatch(
      dispatchParams({
        ...params,
        pageNum: parseInt(e.target.value)
      })
    )
  };

  // document.getElementById("validateNums")?.addEventListener("change", onPageChange);

  const handleIncreasePageNum = (num) => {
    dispatch(
      dispatchParams({
        ...params,
        pageNum: pageNum += num
      })
    )
  }

  const handleDecreasePageNum = (num) => {
    dispatch(
      dispatchParams({
        ...params,
        pageNum: pageNum -= num
      })
    )
  }

  const handleGoBackToFirstPage = () => {
    dispatch(
      dispatchParams(
        {
          ...params,
          pageNum: 1
        }
      )
    )
  }

  const handleGoToLastPage = () => {
    if (pageSize * totalPage < totalCount) {
      return
    }
    dispatch(
      dispatchParams(
        {
          ...params,
          pageNum: totalPage
        }
      )
    )
  }

  let firstIndex = newPageNum == null ? null : pageNum == 1 ? 1 : (pageNum - 1) * pageSize + 1
  // let lastIndex = newPageNum == null ? null : pageNum * pageSize % pageSize == 0 ? pageNum * pageSize : pageNum * pageSize % pageSize
  let lastIndex = pageNum * pageSize >= totalCount ? totalCount : pageNum * pageSize;

  return (
    <div className={styles.paginationContainer}>
      <div className={styles.paginationArrow}>
        {pageNum > 1 || pageSize * totalPage < totalCount ? (
          <>
            <img
              src={backDoubleArrowBlack}
              alt="back double arrow"
              className={styles.arrowIcon}
              onClick={handleGoBackToFirstPage}
            />
            <img
              src={backArrowBlack}
              alt="back arrow"
              className={styles.arrowIcon}
              onClick={() => handleDecreasePageNum(1)}
            />
          </>
        ) : (
          <>
            <img
              src={backDoubleArrowGrey}
              alt="back double arrow"
              className={styles.arrowIconNoAllow}
            />
            <img
              src={backArrowGrey}
              alt="back arrow"
              className={styles.arrowIconNoAllow}
            />
          </>
        )}
      </div>

      <div className={styles.paginationText}>
        {firstIndex} to {lastIndex}, Total {totalCount} 
        {/* <input
          type="number"
          className={styles.pageInput}
          value={newPageNum == null ? null : pageNum}
          defaultValue={1}
          onChange={(e) => onPageChange(e)}
          // maxLength={3}
          max={totalPage}
          // min={0}
          id="validateNums"
        /> */}
      </div>

      <div className={styles.paginationArrow}>
        {pageNum === totalPage || pageNum > totalPage? (
          <>
            <img
              src={forwardArrowGrey}
              alt="forward arrow"
              className={styles.arrowIconNoAllow}
            />
            <img
              src={forwardDoubleArrowGrey}
              alt="forward double arrow"
              className={styles.arrowIconNoAllow}
            />
          </>
        ) : (
          <>
            <img
              src={forwardArrowBlack}
              alt="forward arrow"
              className={styles.arrowIcon}
              onClick={() => handleIncreasePageNum(1)}

            />
            <img
              src={forwardDoubleArrowBlack}
              alt="forward double arrow"
              className={styles.arrowIcon}
              onClick={handleGoToLastPage}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default memo(PaginationBar);
