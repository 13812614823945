import { useTranslation } from "react-i18next";
import { TickLocalModal } from "./TickLocalModal";
import React, { useEffect, memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, showModal } from "../redux/tickModalSilce";
import TickContract from "../components/tickContract/TickContract";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { Button } from "antd";
import { usePDF } from "react-to-pdf";

const PrintContractModal = (props) => {
  const { showPrintContractModal, setShowPrintContractModal, contractData, width } = props;
  const { t } = useTranslation();
  // const dispatch = useDispatch()
  // const printRef = useRef()
  const onCloseModal = () => {
    setShowPrintContractModal(false);
  };
  
  const { toPDF, targetRef } = usePDF({
    container: {
      flex: 1,
    },
    filename: `${contractData.consignor.fullName?.replace(/\s/g, '_')}_${contractData.contractNum}.pdf`,
    page: {
      format: 'A5',
    },
  });

  const downloadPDF = () => {
    toPDF();
  }

  useEffect(() => {
    if (contractData.isAutoPrint) {
      downloadPDF();
      onCloseModal();
    }
  }, [])

  return (
    <TickLocalModal
      showLocalModal={showPrintContractModal}
      onCancel={onCloseModal}
      title={t("t_downloadContract")}
      width={"168mm"}
      centered
      footer={
        <>
          {/* <ReactToPrint
            trigger={() =>
              <Button type={"primary"}>
                {t("t_print")}
              </Button>
            }
            content={() => targetRef.current}
          /> */}
          <Button type={"primary"} onClick={downloadPDF}>
            {t("t_downloadPDF")}
          </Button>
        </>
      }
    >
      <div style={{
        width: "100%",
        maxHeight: "210mm",
        overflowY: "auto",
        overflowX: "hidden",
      }}>
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          
        }}>

          <TickContract ref={targetRef} contractData={contractData}></TickContract>
        </div>
      </div>
    </TickLocalModal>
  );
};

export default memo(PrintContractModal)