import { printer } from "../../config/envConfig";
import { tickLog } from "../../utils/logger";
import { MAG } from "../api";
import { pageSize } from "../../redux/tickTableSlice";

export default class ItemApi {
  static suffix = "/api/item";

  static listByPage(params = {}) {
    const {
      pageNum = 1,
      pageSize = pageSize,
      sortField = "id",
      sortOrder = "ASC",

      input = "",
      deptKey = "",
      transactionTypeId = "",
      bagCats = "",
      accessoryCats = "",
      isCreate,
      colorIds = "",
      status = "",
      priceMin = 0,
      priceMax = 0,
      transactionDateStart = "",
      transactionDateEnd = "",
      consignorId = 0,
      isReprintTag,
      contractNum = ""
    } = params;
    // let url = `/api/item/listByPage/${pageNum}/${pageSize}?sortField=${sortField}&&sortOrder=${sortOrder}`;
    let url = `/api/item/listByPage/${pageNum}/${pageSize}?`;
    const sortOptions = Object.entries(params.sortOptions);
    if (sortOptions.length > 0) {
      sortOptions.forEach(option => {
        url = url.concat(`&sortField=${encodeURIComponent(option[0])}&sortOrder=${encodeURIComponent(option[1])}`)
      })
    }
    if (input != "") {
      url = url.concat(`&input=${encodeURIComponent(input)}`);
    }
    if (deptKey != "") {
      deptKey.forEach((item) => {
        url = url.concat(`&deptKey=${encodeURIComponent(item)}`);
      });
    }
    if (transactionTypeId != "") {
      transactionTypeId.forEach((item) => {
        url = url.concat(`&transactionTypeIds=${encodeURIComponent(item)}`);
      });
    }
    if (colorIds != "") {
      colorIds.forEach((item) => {
        url = url.concat(`&colorIds=${encodeURIComponent(item)}`);
      });
    }
    if (status != "") {
      url = url.concat(`&status=${status}`);
    }
    if (priceMin > 0 && priceMax > 0) {
      url = url.concat(`&priceMin=${priceMin}&priceMax=${priceMax}`);
    }
    if (transactionDateStart !== "" &&  transactionDateEnd !== "") {
      url = url.concat(`&transactionDateStart=${transactionDateStart}&transactionDateEnd=${transactionDateEnd}`);
    }
    if (bagCats != "") {
      bagCats.forEach((item) => {
        url = url.concat(`&bagCats=${item}`);
      });
    }
    if (accessoryCats != "") {
      accessoryCats.forEach((item) => {
        url = url.concat(`&accessoryCats=${encodeURIComponent(item)}`);
      });
    }
    if (isCreate) {
      url = url.concat(`&isCreate=true`)
    } 
    if (consignorId !== 0) {
      url = url.concat(`&consignorId=${consignorId}`)
    }
    if (isReprintTag) {
      url = url.concat(`&isReprintTag=true`)
    }
    if (contractNum != "") {
      url = url.concat(`&contractNum=${contractNum}`)
    }

    // console.log(url);
    return MAG.get(url);
    // return MAG.post(`${this.suffix}/save`, item);
  }

  static getById(id) {
    let url = `/api/item/${id}`;
    return MAG.get(url);
  }

  static save(item) {
    // console.log("save item", item);
    return MAG.post(`${this.suffix}/save`, item);
  }

  static update(id, item) {
    // console.log("update item", item);
    return MAG.put(`${this.suffix}/update/${id}`, item);
  }

  static delete(id) {
    // console.log("delete item", item);
    return MAG.put(`${this.suffix}/delete/${id}`);
  }

  static clearConsignor(id) {
    // console.log("update item", item);
    return MAG.put(`${this.suffix}/clearConsignor/${id}`);
  }

  static export(params = {}) {
    const {
      input = "",
      deptKey = "",
      transactionTypeId = "",
      bagCats = "",
      accessoryCats = "",
      columns = "",
    } = params;
    let url = `/api/item/export?`;
    if (input != "") {
      url = url.concat(`&&input=${input}`);
    }
    if (deptKey != "") {
      deptKey.forEach((item) => {
        url = url.concat(`&&deptKey=${encodeURIComponent(item)}`);
      });
    }
    if (transactionTypeId != "") {
      transactionTypeId.forEach((item) => {
        url = url.concat(`&&transactionTypeIds=${encodeURIComponent(item)}`);
      });
    }
    if (bagCats != "") {
      bagCats.forEach((item) => {
        url = url.concat(`&&bagCats=${item}`);
      });
    }
    if (accessoryCats != "") {
      accessoryCats.forEach((item) => {
        url = url.concat(`&&accessoryCats=${encodeURIComponent(item)}`);
      });
    }
    if (columns != "") {
      url = url.concat(`&&columns=${columns}`);
    }

    console.log(url);
    
    // return MAG.get(url);
    return MAG.download(url);
  }
  
  static upload(files) {
    const formData = new FormData();
    formData.append("files", files);
    return MAG.postAndDownloadFile(`${this.suffix}/upload`, formData);
  }

  static print(printerId, itemNum) {

    // console.log(`Print ${itemNum}`)
    
    // const ws = new WebSocket(`ws://${printer.printerIp}:${printer.printerPort}/ws/request`);
    // ws.onopen = async () => {
    //   console.log("client connection open...")
    //   ws.send(JSON.stringify("Item num: " + itemNum))
    //   return { success: true, message: "Success" }
    // }
    // ws.onerror = async (e) => {
    //   return { success: false, message: e.message }
    // }
    // return fetch("http://192.168.88.162:1880/ping").then((res) => {
    //   if (res.success) console.log(res.message);
    // }).catch((e) => { console.log("Fail") })
    // return MAG.get(`${this.suffix}/print/${id}`);
    return MAG.post(`${this.suffix}/print`, { printerId, itemNum });
  }

  static getNextItemNum() {
    let url = `/api/item/getNextItemNum`;
    return MAG.get(url);
  }

}
