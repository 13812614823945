import { MAG } from "../api";

export default class MasterApi {
  static suffix = "/api/master";

  static listByPage(params = {}) {
    const {
      pageNum = 1,
      pageSize = 15,
      // sortField = "id",
      // sortOrder = "ASC",
      input = "",
      type = "category"
    } = params;
    // console.log(params);
    // let url = `/api/item/listByPage/${pageNum}/${pageSize}?sortField=${sortField}&&sortOrder=${sortOrder}`;
    let url = `/api/master/listByPage/${pageNum}/${pageSize}?`;
    const sortOptions = Object.entries(params.sortOptions);
    if (sortOptions.length > 0) {
      sortOptions.forEach(option => {
        url = url.concat(`&&sortField=${encodeURIComponent(option[0])}&&sortOrder=${encodeURIComponent(option[1])}`)
      })
    }
    if (input != "") {
      url = url.concat(`&&input=${input}`);
    }
    if (type != "") {
      url = url.concat(`&&type=${type}`);
    }
    console.log(url);
    return MAG.get(url);
    // return MAG.post(`${this.suffix}/save`, item);
  }

  static save(type, item) {
    return MAG.post(`${this.suffix}/save?type=${type}`, item);
  }

  static update(id, type, item) {
    return MAG.put(`${this.suffix}/update/${id}?type=${type}`, item);
  }

  static delete(id, type) {
    return MAG.put(`${this.suffix}/delete/${id}?type=${type}`);
  }

}
