import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import styles from "./titleBarContractInformationContent.module.scss";
import IconWithTextButton from "../iconWithTextButton/IconWithTextButton";
import { setConsignmentConsignorToInitialState, clearConsignmentRowData, editTempItem, setConsignStateToInitialState, setContractParams, clearCreateConsignmentConsignor } from "../../redux/tickTableSlice";
import { useDispatch, useSelector } from "react-redux";
import { tickLog } from "../../utils/logger";
import ConsignorApi from "../../service/consignorApi/consignorApi";
import { formatFormItemToBodyV2, formatFormItemToBodyV3 } from "../../utils/formatFormItemToBody";
import { closeModal, showModal } from "../../redux/tickModalSilce";
import dayjs from "dayjs";
import lhSideBarContractInactive from "../../assets/img/icon/lhSideBarContractInactive.svg";
import lhSideBarReceiptInactive from "../../assets/img/icon/lhSideBarReceiptInactive.svg";
import useTitleBarConsignment from "../../hooks/useTitleBarConsignment";
import ConsignmentContractApi from "../../service/consignmentContractApi/consignmentContractApi";
import { useNavigate } from "react-router";
import useQuery from "../../hooks/useQuery";

const TitleBarContractInformationContent = (props) => {
  const { 
    onCancel,
    setShowConsignmentModal,
    type,
    contractNum,
    contractType,
    onSaveClick, 
    setCreateType, 
    onReload,
    onPrint, 
    action,
  } = props
  const { t } = useTranslation();

  const tickTableState = useSelector((state) => state.tickTable);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //
  const [createDisabled, modalTitle] = useTitleBarConsignment(action)

  const onBtnClick = async () => {
    tickLog("save")

    const newConsignorInfo = tickTableState.consignment.consignor.create;
    const items = JSON.parse(localStorage.getItem("TempItems"));

    items.forEach((item) => {
      item.itemNameEn = item.itemNameTc
      // item.year = dayjs(item.year).format("YYYY")
      item.deptType = tickTableState.optionsOfBrand.find(i => i.itemDeptKey === item.brand).itemDeptType
      item.deptKey = item.brand
      item.subCatKey = item.category
      item.catKey = tickTableState.optionsOfCategory.all.find(i => i.itemSubCatKey === item.category).itemCatKey
      item.amt = parseInt(item.amt)
      item.onSalesPrice = parseInt(item.onSalesPrice) || 0
      item.colorIds = item.colorIds?.join(",")
      item.ratingIds = item.ratingIds?.join(",")
      item.materialIds = item.materialIds?.join(",")
      item.originIds = item.originIds?.toString()
      item.accessoriesIds = item.accessoriesIds?.join(",")
      item.cortexIds = item.cortexIds?.join(",");
      item.colorIdsInternal = item.colorInternal?.join(",");
      delete item.colorInternal
      delete item.brand
      delete item.category
    })

    const data = {
      consignorId : newConsignorInfo.consignorId || 0,
      contractNum,
      type: contractType,
      fullName: newConsignorInfo.fullName,
      idNo: newConsignorInfo.idNo,
      abbreviation: newConsignorInfo.abbreviation,
      bankCode: newConsignorInfo.bankCode,
      bankNum: newConsignorInfo.bankNum,
      phoneNum: newConsignorInfo.phoneNum,
      address: newConsignorInfo.address,
      paymentMethodId: newConsignorInfo.paymentMethodId,
      paymentReference: newConsignorInfo.paymentReference,
      items: items
    }

    // checking
    if (data.consignorId === 0 && (!data.fullName || !data.idNo || !data.phoneNum)) {
      dispatch(
        showModal({
          key: "error",
          header: "Error",
          msg: "Please enter all mandatory columns",
          callback: () => {
            dispatch(closeModal());
          },
        })
      );
        return
    }
    console.log("save", data)
    
    let resp = await ConsignmentContractApi.save(data);
    if (resp.success) {
      resp.data["isAutoPrint"] = true;
      onPrint(resp.data);
      
      dispatch(
        showModal({
          key: "success",
          header: type == "add" ? "Add Contract & Consignor Success" : "Update success",
          msg: resp.msg,
          callback: () => {
            dispatch(closeModal());
          },
        })
      );
      

      setTimeout(() => {
        onCancel();
        // dispatch(setConsignStateToInitialState())
        // onReload();
        dispatch(setContractParams({ ...tickTableState.contractParams }))
        dispatch(clearCreateConsignmentConsignor())
        localStorage.setItem("TempItems", JSON.stringify([]));
        dispatch(editTempItem([]))
      }, 1000)

      // onCancel();
      // dispatch(setConsignStateToInitialState())
      // onReload();
      // localStorage.setItem("TempItems", JSON.stringify([]));
      // dispatch(editTempItem([]))


    } else {
      console.log(resp.msg)
      dispatch(
        showModal({
          key: "error",
          header: resp?.msg,
          msg: resp?.msg,
          callback: () => {
            dispatch(closeModal());
          },
        })
      );
    }
  }

  let promiseDelete = async () => {
    let delResp
    if (tickTableState.consignment?.items?.create?.length > 0) {
      delResp = await Promise.all(
        tickTableState.consignment?.items?.delete?.map(async (item) => {
          let resp = await ConsignorApi.delete(item?.id)
          if (!resp.success) {
            dispatch(
              showModal({
                key: "error",
                header: "This is an error message",
                msg: resp.msg,
                callback: () => {
                  dispatch(closeModal());
                },
              })
            );
          }
        })
      )
    }
    // delResp = ""
    tickLog("delResp", delResp)
    return delResp
  }

  let create = async () => {
    let createResp
    let newConsignor = formatFormItemToBodyV3(tickTableState.consignment.consignor.create)
    if (tickTableState.consignment?.items?.create?.length > 0) {
      let newItems = tickTableState.consignment?.items?.create.map(item => formatFormItemToBodyV2(item))
      console.log(newConsignor);
      let body = { ...newConsignor, items: [...newItems] }
      createResp = await ConsignorApi.save(body)
    } else {
      createResp = await ConsignorApi.save({ ...newConsignor, items: [] })
    }
    if (createResp.success) {
      dispatch(
        showModal({
          key: "success",
          header: "Add Consignor success",
          msg: createResp?.msg,
          callback: () => {
            dispatch(closeModal());
          },
        })
      );
    } else {
      dispatch(
        showModal({
          key: "error",
          header: "This is an error message",
          msg: createResp?.msg,
          callback: () => {
            dispatch(closeModal());
          },
        })
      );
    }

    // createResp = ""
    tickLog("createResp", createResp)
    return createResp
  };

  let promiseCreate = async () => {
    let createResp
    if (tickTableState.consignment?.items?.create?.length > 0) {
      let newItems = tickTableState.consignment?.items?.create.map(item => formatFormItemToBodyV2(item))
      let newConsignor = formatFormItemToBodyV3(tickTableState.consignment?.rowData)
      console.log(newConsignor);
      let body = { ...newConsignor, items: [...newItems] }
      createResp = await ConsignorApi.save(body)
    }
    // createResp = ""
    tickLog("createResp", createResp)
    return createResp
  };

  let promiseUpdate = async () => {
    let updateResp
    if (tickTableState.consignment?.items?.update?.length > 0) {
      updateResp = await Promise.all(
        tickTableState.consignment?.items?.update?.map(async (item) => {
          await ConsignorApi.update(item?.id, formatFormItemToBodyV2(item))
        })
      ).then((value) => {
        console.log(value);
      }).catch((e) => {
        console.log(e)
      })
    }
    updateResp = ""
    tickLog("updateResp", updateResp)
    return updateResp
  };

  const onCancelBtnClick = () => {
    const tempItems = JSON.parse(localStorage.getItem("TempItems"));
    const edited = tickTableState.consignment?.consignor?.edited
    if (tempItems.length > 0 || edited) {
      dispatch( 
        showModal({
          key: "confirm",
          icon: "warning",
          header: t("t_warning"),
          msg: t("t_promptConfirmLoseProgress"),
          showCloseBtn: true,
          // showCloseBtn: true,
          callback: () => {
            dispatch(closeModal());
            onCancel();
          },
        })
      )
      return;
    } else {
      onCancel();
    }
  }

  return (
    <>
      <div className={styles.left}>
        <span className={styles.title}>{modalTitle}</span>
      </div>
      <div className={styles.right}>
        <IconWithTextButton
          onBtnClick={onCancelBtnClick}
        >
          cancel
        </IconWithTextButton>
        {type === "add" &&
          <IconWithTextButton
            // disabled={tickTableState.consignment.tempItems.length > 0 ? false : true}
            disabled={createDisabled}
            onBtnClick={onBtnClick}
          >
            {t("t_addNew")}
          </IconWithTextButton> 
        }
      </div>
    </>
  );
};

export default memo(TitleBarContractInformationContent);
