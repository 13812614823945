import React, { useRef, useEffect } from "react";
import styles from "./tickVerticalCollapse.module.scss";
// import arrowRight from "../../assets/icon/arrow_right_icon.svg";
import arrowRight from "../../assets/img/icon/arrow_right_icon.svg";
import filterIcon from "../../assets/img/icon/filter_icon.svg";
import filterDownIcon from "../../assets/img/icon/filter_down_icon.svg";
import { useTranslation } from "react-i18next";

const TickVerticalCollapse = (props) => {
  const { children, setIsFilterOpen, isFilterOpen = false } = props;
  const __container = useRef(null);
  const __collapsedTitleContainer = useRef(null);
  const __expandedTitleContainer = useRef(null);
  const __contentContainer = useRef(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    onExpandClick()
  }, [isFilterOpen])

  const onExpandClick = () => {
    // if (__container.current.getAttribute("data-expanded") === "false") {                    // check and toggle collapse or not
    if (isFilterOpen) {                    // check and toggle collapse or not
      setTimeout(() => {
        __container?.current?.setAttribute("data-expanded", "true");                          // toggle collapse or not
        __container?.current?.classList?.remove(`${styles.containerMinWidth36}`);
      }, 100)
      setTimeout(() => {
        __collapsedTitleContainer?.current?.classList?.add(`${styles.elementDisappear}`);      // for animation fade in out
      }, 200)
      setTimeout(() => {
        __expandedTitleContainer?.current?.classList?.add(`${styles.elementAppear}`);          // for animation fade in out
        __contentContainer?.current?.classList?.add(`${styles.elementAppear}`)                 // for animation fade in out
      }, 300)
    } else {
      setTimeout(() => {
        __container?.current?.setAttribute("data-expanded", "false");                         // toggle collapse or not
      }, 10)
      __collapsedTitleContainer?.current?.classList?.remove(`${styles.elementDisappear}`);     // for animation fade in out
      __expandedTitleContainer?.current?.classList?.remove(`${styles.elementAppear}`);         // for animation fade in out
      __contentContainer?.current?.classList?.remove(`${styles.elementAppear}`)                // for animation fade in out
      __container?.current?.classList?.add(`${styles.containerMinWidth36}`);
    }
  };

  const handleOnExpandByState = () => {
    setIsFilterOpen(!isFilterOpen)
  } 


  return (
    <div
      ref={__container}
      className={styles.container}
      data-expanded="false"
    >
      {/* <div className={styles.eventLayout} onClick={onExpandClick}></div> */}
      <div className={styles.eventLayout} onClick={handleOnExpandByState}></div>
      <div className={styles.wrapper}>
        <div
          className={styles.titleWrapper} 
          // onClick={onExpandClick}
          onClick={handleOnExpandByState}
        >

          <div ref={__expandedTitleContainer} className={styles.expandedTitleContainer}>
            <div className={styles.title}>{t('t_filter')}</div>
            <img src={filterDownIcon} alt="filter_icon" />
          </div>

          <img                                                                               // arrow right icon (>)
            src={arrowRight}
            alt="arrow_right_icon"
            className={styles.arrowIcon}
          />

          <div ref={__collapsedTitleContainer} className={styles.collapsedTitleContainer}>
            <div className={styles.title}>
            <img src={filterIcon} alt="filter_icon" className={i18n.language === 'en' ? styles.marginBottom14 : null}/>
            <div className={i18n.language === 'en' ? styles.rotateFilterTitle : null}>
              {t('t_filter')}
            </div>
            </div>
          </div>

        </div>
        <div ref={__contentContainer} className={styles.contentContainer}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default TickVerticalCollapse;
