import React from "react"
import styles from "./report.module.scss";
import { Context } from "./Report";
import { Tooltip } from "antd";

const ReportSideNav = () => {
  const context = React.useContext(Context);

  const handleOnClick = (tab) => {
    context.fetchSearchConfigByTabName(tab);
  };

  const renderNavTab = () => {
    return (
      context.tabList.map((item, key) => {
        return (
          <div
            className={styles[context.selectedTab === item.name ? "isSelected" : "tabBtn"]}
            key={`nav_btn_${key}`}
            onClick={() => handleOnClick(item.name)}
          >
            <Tooltip placement="right" title={item.name}>
              {item.name}
            </Tooltip>
          </div >
        )
      })
    )
  };

  return (
    <div className={styles.sideNav}>
      {renderNavTab()}
    </div>
  )
};


export default ReportSideNav