import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const ReportCommonDatePicker = (props) => {
  const { value, type } = props;
  const { extraProps } = props?.fieldDetails || {};

  const onDatePickerChange = (value) => {
    if (!value) {
      props.onChange(value);
      return;
    }
    props.onChange(dayjs(value).format());
  };

  const onDateRangePickerChange = (value) => {
    if (!value || value.length <= 0) {
      props.onChange(value);
      return; 
    }
    const newValue = value.map(date => (dayjs(date).format()));
    props.onChange(newValue);
  };


  const renderDateField = () => {
    let fieldComponent = <></>;
    switch (type) {
      case "datePicker":
        fieldComponent = <DatePicker
          style={{ width: "100%" }}
          value={value ? dayjs(value) : undefined}
          onChange={onDatePickerChange}
          {...extraProps}
        />
        break;
      case "dateRangePicker":
        fieldComponent = <DatePicker.RangePicker
          style={{ width: "100%" }}
          value={value?.length > 0 ? value.map(date => (dayjs(date))) : undefined}
          onChange={onDateRangePickerChange}
          {...extraProps}
        />
        break;
      default:
        fieldComponent = <>Miss Field Type</>
    };
    return fieldComponent;
  }


  return (
    <>
      {renderDateField()}
    </>
  )
};

export default ReportCommonDatePicker;