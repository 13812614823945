import { MAG } from "../api";

export default class CouponApi {
    static suffix = "/api/coupon";

    static listByPage(params = {}) {
        const {
            pageNum = 1,
            pageSize = 15,
            input = "",
        } = params;
        console.log(params.sortOptions, "++++++");
        let url = `${this.suffix}/listByPage/${pageNum}/${pageSize}?`;
        const sortOptions = Object.entries(params.sortOptions);
        if (sortOptions.length > 0) {
            sortOptions.forEach(option => {
                url = url.concat(`&&sortField=${encodeURIComponent(option[0])}&&sortOrder=${encodeURIComponent(option[1])}`)
            })
        }
        if (input != "") {
            url = url.concat(`&&input=${encodeURIComponent(input)}`);
        }
        return MAG.get(url);
    }

    static update(id, item) {   
        return MAG.put(`${this.suffix}/update/${id}`, item);
    }
}