import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as SearchIon } from "../../assets/img/icon/search.svg";
import { Form, Input } from "antd";
import styles from "./quickSearch.module.scss";
import { useDispatch,useSelector } from "react-redux";
import { setConsignmentParams, setShowItemDrawer, setItemParams, setMasterParams, setStatusLogParams, setSettingParams, setContractParams, setReceiptParams, setCouponParams, setMemberParams } from "../../redux/tickTableSlice";
import { useLocation } from "react-router-dom"

const QuickSearch = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tickTableState = useSelector((state) => state.tickTable);
  const itemTableParams = useSelector(state => state.tickTable.itemParams);
  const consignmentTableParams = useSelector(state => state.tickTable.consignParams);
  const contractTableParams = useSelector(state => state.tickTable.contractParams);
  const receiptTableParams = useSelector(state => state.tickTable.receiptParams);
  const couponTableParams = useSelector(state => state.tickTable.couponParams)
  const masterTableParams = useSelector(state => state.tickTable.masterParams);
  const statusLogTableParams = useSelector(state => state.tickTable.statusLogParams);
  const settingTableParams = useSelector(state => state.tickTable.settingParams);
  const memberTableParams = useSelector(state => state.tickTable.memberParams);
  const isItemDrawerOpen = useSelector(state => state.tickTable.isItemDrawerOpen);
  const [form] = Form.useForm();

  const onSearch = () => {
    let input;
    form.validateFields().then(async (resp) => {
      input = await resp.input;
      if (location.pathname == "/main/item") {
        dispatch(setItemParams({ ...itemTableParams, input, pageNum: 1, isCreate: false }))
        if (isItemDrawerOpen) {
          dispatch(setShowItemDrawer(false));
        }
      } else if (location.pathname == "/main/consignment") {
        dispatch(setConsignmentParams({ ...consignmentTableParams, input, pageNum: 1 }))
      } else if (location.pathname == "/main/contract") {
        dispatch(setContractParams({ ...contractTableParams, input, pageNum: 1 }))
      } else if (location.pathname == "/main/member") {
        dispatch(setMemberParams({ ...memberTableParams, input, pageNum: 1 }))
      } else if (location.pathname == "/main/receipt") {
        dispatch(setReceiptParams({ ...receiptTableParams, input, pageNum: 1 }))
      } else if (location.pathname == "/main/coupon") {
        dispatch(setCouponParams({ ...couponTableParams, input, pageNum: 1 }))
      } else if (location.pathname == "/main/master") {
        dispatch(setMasterParams({ ...masterTableParams, input, pageNum: 1 }))
      } else if (location.pathname == "/main/statusLog") {
        dispatch(setStatusLogParams({ ...statusLogTableParams, input, pageNum: 1 }))
      } else if (location.pathname == "/main/setting") {
        dispatch(setSettingParams({ ...settingTableParams, input, pageNum: 1 }))
      }
    });
  };

  // useEffect(() => {
  //   form.resetFields();
  //   if (location.pathname == "/main/item") {
  //     dispatch(setItemParams({ ...itemTableParams, input: "", pageNum: 1 }))
  //   } else if (location.pathname == "/main/consignment") {
  //     dispatch(setConsignmentParams({ ...consignmentTableParams, input: "", pageNum: 1 }))
  //   }
  // }, [location]);

  useEffect(() => { //  this useEffect for the quick search inputted value still will appear previous value when the sub page changed and changed back 
    const { setFieldsValue } = form;
    if (location.pathname.includes("/main/item")) {
      setFieldsValue({input: itemTableParams.input})
    } else if (location.pathname.includes("/main/consignment")) {
      setFieldsValue({input: consignmentTableParams.input})
    } else if (location.pathname.includes("/main/contract")) {
      setFieldsValue({input: contractTableParams.input})
    } else if (location.pathname.includes("/main/member")) {
      setFieldsValue({input: memberTableParams.input})
    } else if (location.pathname.includes("/main/receipt")) {
      setFieldsValue({input: receiptTableParams.input})
    } else if (location.pathname.includes("/main/coupon")) {
      setFieldsValue({input: couponTableParams.input})
    } else if (location.pathname.includes("/main/master")) {
      setFieldsValue({input: masterTableParams.input})
    } else if (location.pathname.includes("/main/statusLog")) {
      setFieldsValue({input: statusLogTableParams.input})
    } else if (location.pathname.includes("/main/setting")) {
      setFieldsValue({input: settingTableParams.input})
    }
  }, [itemTableParams.input, consignmentTableParams.input, contractTableParams.input, receiptTableParams.input, masterTableParams.input, statusLogTableParams.input, memberTableParams.input, settingTableParams.input, couponTableParams.input, location ])

  // useEffect(()=>{
  //   if(tickTableState.itemParams.input == ""){
  //     form.resetFields()
  //   }
  // },[tickTableState])

  return (
    <div className={styles.quickSearchForm}>
      <Form form={form}>
        <Form.Item
          name={"input"}
          //   rules={[
          //     {
          //       required: true,
          //     },
          //   ]}
        >
          <Input
            size="middle"
            placeholder={
              location.pathname == "/main/item"
                ? t("t_quickSearchPlaceholderItem")
                : location.pathname === "/main/consignment"
                ? t("t_quickSearchPlaceholderConsign")
                : location.pathname === "/main/contract"
                ? t("t_quickSearchPlaceholderContract")
                : location.pathname === "/main/receipt"
                ? t("t_quickSearchPlaceholderReceipt")
                : location.pathname === "/main/coupon"
                ? t("t_quickSearchPlaceholderCoupon")
                : location.pathname === "/main/master"
                ? t("t_quickSearchPlaceholderMaster")
                : location.pathname === "/main/statusLog"
                ? t("t_itemNum")
                : location.pathname === "/main/setting"
                ? t("t_quickSearchPlaceholderMaster")
                : location.pathname === "/main/member"
                ? t("t_quickSearchPlaceholderMember")
                : null
            }
            prefix={<SearchIon />}
            className={styles.quickSearchInput}
            onPressEnter={onSearch}
            allowClear={true}
            onChange={(e) => {
              if (e.target.value === "") {
                onSearch(); // fetch again when clear value (no need to press enter again)
              }
            }}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default memo(QuickSearch);
