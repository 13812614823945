import { useTranslation } from "react-i18next";
import { TickLocalModal } from "./TickLocalModal";
import styles from "./consignmentModalV3.module.scss";
import { Form, Input, Space } from "antd";
import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonSelection from "../components/commonSelection/CommonSelection";
import ConsignorApi from "../service/consignorApi/consignorApi";


const ConsignmentModalV3 = (props) => {
  const { showConsignmentModal, onSubmit, onCancel, setShowConsignmentModal, brandOptions, subCatOptions, btn = "add", setBtn, contents, width, modalData, listByPage } = props;
  const { TextArea } = Input;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [bankNum, setBankNum] = useState(modalData.bankNum);
  const [bankCode, setBankCode] = useState(modalData.bankCode);

  const tickTableState = useSelector((state) => state.tickTable)
  const dispatch = useDispatch()


  useEffect(() => {
    // console.log(props)
    if (tickTableState?.consignment?.rowData?.consignor != {}) {
      form.setFieldsValue(tickTableState.consignment.rowData.consignor)
    }
  }, [tickTableState.consignment.rowData])

  useEffect(() => {
    form.setFieldsValue({
      fullName: modalData.fullName,
      idNum: modalData.idNo,
      shortName: modalData.abbreviation,
      bankNum: modalData.bankCode,
      phoneNum: modalData.phoneNum,
      address: modalData.address,
    })
  }, [modalData])

  const onCloseModal = () => {
    form.resetFields();
    setShowConsignmentModal(false);
  };

  const onHandleSubmit = async (e) => {
    const result = form.getFieldsValue()
    const data = {
      fullName: result.fullName,
      idNo: result.idNum,
      abbreviation: result.shortName,
      bankCode: bankCode || '',
      bankNum: bankNum || '',
      phoneNum: result.phoneNum,
      address: result.address,
    }
    await ConsignorApi.update(modalData.id, data).then(() => {
      listByPage()
      setShowConsignmentModal(false)
    })
  };

  return (
    <TickLocalModal
      onSubmit={onHandleSubmit}
      showLocalModal={showConsignmentModal}
      onCancel={onCloseModal}
      title={t("t_editItem")}
      form={form}
      width={width}
      setVisible={setShowConsignmentModal}
    >
      <div className={styles.drawerContainer}>
        {/* <div className={styles.title}>{t("t_consigneeInformation")}</div> */}
        <div className={styles.border} />
        <Form
          layout={'vertical'}
          form={form}
        // onValuesChange={formOnChangeHandler}
        >
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div style={{ width: "100%", margin: "10px" }}>
              <Form.Item
                label={t("t_fullName")}
                name="fullName"
              >
                <Input
                  name="fullName"
                  placeholder={t("t_pleaseEnter")}

                />
              </Form.Item>
              <Form.Item
                label={t("t_idNum")}
                name="idNum"
              >
                <Input
                  name="idNum"
                  placeholder={t("t_pleaseEnter")}

                />
              </Form.Item>
              <Form.Item
                label={t("t_shortName")}
                name="shortName"
              >
                <Input
                  name="shortName"
                  placeholder={t("t_pleaseEnter")}

                />
              </Form.Item>
            </div>
            <div style={{ width: "100%", margin: "10px" }}>
              <Form.Item
                label={t("t_bankNum")}
              >
                <Space.Compact label={t("t_bankNum")} style={{ width: '100%' }}>
                  <CommonSelection
                    optionKey={"bankCode"}
                    style={{ width: '50%' }}
                    name={"bankCode"}
                    defaultValue={modalData.bankCode}
                    onChange={e => setBankCode(e)}
                  />
                  <Input name={"bankNum"} defaultValue={modalData.bankNum} onChange={e => setBankNum(e.target.value)} />
                </Space.Compact>
              </Form.Item>
              <Form.Item
                label={t("t_phoneNum")}
                name="phoneNum"
              >
                <Input
                  name="phoneNum"
                  placeholder={t("t_pleaseEnter")}

                />
              </Form.Item>
              <Form.Item
                label={t("t_address")}
                name="address"
              >
                <Input
                  name="address"
                  placeholder={t("t_pleaseEnter")}

                />
              </Form.Item>
            </div>
          </div>
        </Form >
      </div>
      {/* </div> */}
    </TickLocalModal>
  );
};

export default memo(ConsignmentModalV3)