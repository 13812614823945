import React from "react";
import { Divider, Row, Col } from "antd";
import styles from "./tickConsignorInfo.module.scss";
import useTabsContentFields from "../../hooks/useTabsContentFields";
import { useTranslation } from "react-i18next";

const TickConsignorInfo = () => {
  const {getConsignorInfoField} = useTabsContentFields();
  const defaultContentFields = getConsignorInfoField();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Row>
        {defaultContentFields.top.map((item, index) => (
          <Col {...item.grid} key={`top_item_${index}`}>
            <div className={styles[item.titleStyles]}>{t(item.title)}</div>
            <div className={styles[item.valueStyles]}>{item.value}</div>
          </Col>
        ))}
      </Row>
      <Divider />
      <Row>
        {defaultContentFields.middle.map((item, index) => (
          <Col {...item.grid} key={`middle_item_${index}`}>
            <div className={styles[item.titleStyles]}>{t(item.title)}</div>
            <div className={styles[item.valueStyles]}>{item.value}</div>
          </Col>
        ))}
      </Row>
      <Divider />
      <Row>
        {defaultContentFields.end.map((item, index) => (
          <Col {...item.grid} key={`end_item_${index}`}>
            <div className={styles[item.titleStyles]}>{t(item.title)}</div>
            <div className={styles[item.valueStyles]}>{item.value}</div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default TickConsignorInfo;
