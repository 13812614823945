import React, { memo, useEffect, useState } from 'react';
import styles from "./tickDrawerV2.module.scss";
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Space } from "antd";
import { useDispatch } from 'react-redux';
import { setCreateConsignmentConsignor, setConsignmentConsignorEdited } from '../../redux/tickTableSlice';
import CommonSelection from '../commonSelection/CommonSelection';
import { useParams } from 'react-router';
import useQuery from '../../hooks/useQuery';

const TickDrawerV2 = (props) => {
    const {
      isModal = false, 
      setNewConsignorInfo = () => {}, 
      parentForm, 
      setConsignorEdited = () => {},
      action = "consignor",
      contractType
    } = props;
    const { t } = useTranslation()

    const [form] = Form.useForm()
    const { TextArea } = Input;

    const [bankNum, setBankNum] = useState("");
    const [bankCode, setBankCode] = useState("");
    const [isConsignorSelected, setIsConsignorSelected] = useState(false);

    const dispatch = useDispatch();
    const { consignorId } = useQuery();

    const consignorIdOnChange = (consignorId) => {
      if (consignorId > 0) {
          setIsConsignorSelected(true);
          form.setFieldsValue({
              fullName: undefined,
              idNo: undefined,
              abbreviation: undefined,
              bankCode: undefined,
              bankNum: undefined,
              phoneNum: undefined,
              address: undefined
          });
      } else {
          setIsConsignorSelected(false);
      }
    }

    const hasConsignorDropdown = action === "contract" || action === "receipt";

    useEffect(() => {
      if (consignorId) {
        form.setFieldsValue({ consignorId })
        setIsConsignorSelected(true);
        dispatch(setCreateConsignmentConsignor({ consignorId }))
      } else {
        dispatch(setConsignmentConsignorEdited(false));
      }
      return () => {
        dispatch(setConsignmentConsignorEdited(false));
      }
    }, [])

    // const consignmentContractComponent = () => (
    //     <div className={styles[!isModal ? 'drawerContainer': 'noShadow']} >
    //         <div className={styles.title}>{t("t_consigneeInformation")}</div>
    //         <div className={styles.border} />
    //         <Form
    //             layout={'vertical'}
    //             form={!isModal ? form : parentForm}
    //             onValuesChange={async (changedValues, allValues) => {
    //             let hasChanged = false;
    //             for (let value of Object.values(allValues)) {
    //                 if (value && value !== "") hasChanged = true;
    //             }
    //             dispatch(setConsignmentConsignorEdited(hasChanged));
    //             if (!isModal) {
    //                 dispatch(setCreateConsignmentConsignor({ ...allValues, bankCode, bankNum }))
    //             } else {
    //                 setNewConsignorInfo({...allValues, bankCode, bankNum});
    //             }
    //             }}
    //         >
    //         {
    //             hasConsignorDropdown &&
    //             <Form.Item
    //                 label={t("t_consignor")}
    //                 name="consignorId"
    //             >
    //                 <CommonSelection
    //                     onChange={consignorIdOnChange}
    //                     optionKey={`consignor`}
    //                 />
    //             </Form.Item>
    //         }
    //             <Form.Item
    //                 label={t("t_fullName")}
    //                 name="fullName"
    //                 rules={[{ required: !isConsignorSelected }]}
    //             >
    //                 <Input
    //                     name="fullName"
    //                     placeholder={t("t_pleaseEnter")}
    //                     disabled={isConsignorSelected}
    //                 />
    //             </Form.Item>
    //             <Form.Item
    //                 label={t("t_idNum")}
    //                 name="idNo"
    //                 rules={[{ required: !isConsignorSelected }]}
    //             >
    //                 <Input
    //                     name="idNo"
    //                     placeholder={t("t_pleaseEnter")}
    //                     disabled={isConsignorSelected}
    //                 />
    //             </Form.Item>
    //             <Form.Item
    //                 label={t("t_shortName")}
    //                 name="abbreviation"
    //                 // rules={[{ required: true }]}
    //             >
    //                 <Input
    //                     name="abbreviation"
    //                     placeholder={t("t_pleaseEnter")}
    //                     disabled={isConsignorSelected}
    //                 />
    //             </Form.Item>
    //             {/* <Form.Item
    //                 label={t("t_bankNum")}
    //                 name="bankNum"
    //             >
    //                 <Input
    //                     name="bankNum"
    //                     placeholder={t("t_pleaseEnter")}

    //                 />
    //             </Form.Item> */}
    //             <Form.Item
    //                 label={t("t_bankNum")}
    //                 rules={[{ required: !isConsignorSelected }]}
    //             >
    //                 <Space.Compact label={t("t_bankNum")} style={{ width: '100%' }}>
    //                     <CommonSelection
    //                         optionKey={"bankCode"}
    //                         style={{ width: '50%' }}
    //                         name={"bankCode"}
    //                         onChange={e => setBankCode(e)}
    //                         disabled={isConsignorSelected}
    //                     />
    //                     <Input 
    //                         name={"bankNum"} 
    //                         onChange={e => setBankNum(e.target.value)} 
    //                         disabled={isConsignorSelected}
    //                     />
    //                 </Space.Compact>
    //             </Form.Item>
    //             <Form.Item
    //                 label={t("t_phoneNum")}
    //                 name="phoneNum"
    //                 rules={[{ required: !isConsignorSelected }]}
    //             >
    //                 <Input
    //                     name="phoneNum"
    //                     placeholder={t("t_pleaseEnter")}
    //                     disabled={isConsignorSelected}
    //                 />
    //             </Form.Item>
    //             <Form.Item
    //                 label={t("t_address")}
    //                 name="address"
    //                 // rules={[{ required: true }]}
    //             >
    //                 <TextArea
    //                     name="address"
    //                     rows={4}
    //                     placeholder={t("t_pleaseEnter")}
    //                     disabled={isConsignorSelected}
    //                 />
    //             </Form.Item>
    //         </Form >
    //     </div>
    // )
	
    // const purchaseContractComponent = () => (
    //     <Collapse defaultActiveKey={['1']} ghost items={items} />;
    //     <div className={styles[!isModal ? 'drawerContainer': 'noShadow']} >
    //         <div className={styles.title}>{t("t_consigneeInformation")}</div>
    //         <div className={styles.border} />
    //         <Form
    //             layout={'vertical'}
    //             form={!isModal ? form : parentForm}
    //             onValuesChange={async (changedValues, allValues) => {
    //               let hasChanged = false;
    //               for (let value of Object.values(allValues)) {
    //                 if (value && value !== "") hasChanged = true;
    //               }
    //               dispatch(setConsignmentConsignorEdited(hasChanged));
    //               if (!isModal) {
    //                 dispatch(setCreateConsignmentConsignor({ ...allValues, bankCode, bankNum }))
    //               } else {
    //                 setNewConsignorInfo({...allValues, bankCode, bankNum});
    //               }
    //             }}
    //         >
    //           {
    //             hasConsignorDropdown &&
    //             <Form.Item
    //                 label={t("t_consignor")}
    //                 name="consignorId"
    //             >
    //                 <CommonSelection
    //                     onChange={consignorIdOnChange}
    //                     optionKey={`consignor`}
    //                 />
    //             </Form.Item>
    //           }
    //             <Form.Item
    //                 label={t("t_fullName")}
    //                 name="fullName"
    //                 rules={[{ required: !isConsignorSelected }]}
    //             >
    //                 <Input
    //                     name="fullName"
    //                     placeholder={t("t_pleaseEnter")}
    //                     disabled={isConsignorSelected}
    //                 />
    //             </Form.Item>
    //             <Form.Item
    //                 label={t("t_idNum")}
    //                 name="idNo"
    //                 rules={[{ required: !isConsignorSelected }]}
    //             >
    //                 <Input
    //                     name="idNo"
    //                     placeholder={t("t_pleaseEnter")}
    //                     disabled={isConsignorSelected}
    //                 />
    //             </Form.Item>
    //             <Form.Item
    //                 label={t("t_shortName")}
    //                 name="abbreviation"
    //                 // rules={[{ required: true }]}
    //             >
    //                 <Input
    //                     name="abbreviation"
    //                     placeholder={t("t_pleaseEnter")}
    //                     disabled={isConsignorSelected}
    //                 />
    //             </Form.Item>
    //             {/* <Form.Item
    //                 label={t("t_bankNum")}
    //                 name="bankNum"
    //             >
    //                 <Input
    //                     name="bankNum"
    //                     placeholder={t("t_pleaseEnter")}

    //                 />
    //             </Form.Item> */}
    //             <Form.Item
    //                 label={t("t_bankNum")}
    //                 rules={[{ required: !isConsignorSelected }]}
    //             >
    //                 <Space.Compact label={t("t_bankNum")} style={{ width: '100%' }}>
    //                     <CommonSelection
    //                         optionKey={"bankCode"}
    //                         style={{ width: '50%' }}
    //                         name={"bankCode"}
    //                         onChange={e => setBankCode(e)}
    //                         disabled={isConsignorSelected}
    //                     />
    //                     <Input 
    //                         name={"bankNum"} 
    //                         onChange={e => setBankNum(e.target.value)} 
    //                         disabled={isConsignorSelected}
    //                     />
    //                 </Space.Compact>
    //             </Form.Item>
    //             <Form.Item
    //                 label={t("t_phoneNum")}
    //                 name="phoneNum"
    //                 rules={[{ required: !isConsignorSelected }]}
    //             >
    //                 <Input
    //                     name="phoneNum"
    //                     placeholder={t("t_pleaseEnter")}
    //                     disabled={isConsignorSelected}
    //                 />
    //             </Form.Item>
    //             <Form.Item
    //                 label={t("t_address")}
    //                 name="address"
    //                 // rules={[{ required: true }]}
    //             >
    //                 <TextArea
    //                     name="address"
    //                     rows={4}
    //                     placeholder={t("t_pleaseEnter")}
    //                     disabled={isConsignorSelected}
    //                 />
    //             </Form.Item>
    //             {
    //                 contractType === "purchase" && (
    //                 <>
    //                     <div className={styles.title}>{t("t_receiptInfo")}</div>
    //                     <div className={styles.border} />
    //                     <Form.Item
    //                         label={t("t_paymentMethod")}
    //                         name="paymentMethodId"
    //                     >
    //                         <CommonSelection optionKey="paymentCat"/>
    //                     </Form.Item>
    //                     <Form.Item
    //                         label={t("t_paymentReference")}
    //                         name="paymentReference"
    //                     >
    //                         <Input
    //                         name="paymentReference"
    //                         placeholder={t("t_pleaseEnter")}
    //                         />
    //                     </Form.Item>
    //                 </>
    //                 )
    //             }
    //         </Form >
    //     </div>        
    // )

    return (
        <div className={styles[!isModal ? 'drawerContainer': 'noShadow']} >
            <div className={styles.title}>{t("t_consigneeInformation")}</div>
            <div className={styles.border} />
            <Form
                layout={'vertical'}
                form={!isModal ? form : parentForm}
                onValuesChange={async (changedValues, allValues) => {
                  let hasChanged = false;
                  for (let value of Object.values(allValues)) {
                    if (value && value !== "") hasChanged = true;
                  }
                  dispatch(setConsignmentConsignorEdited(hasChanged));
                  if (!isModal) {
                    dispatch(setCreateConsignmentConsignor({ ...allValues, bankCode, bankNum }))
                  } else {
                    setNewConsignorInfo({...allValues, bankCode, bankNum});
                  }
                }}
            >
              {
                hasConsignorDropdown &&
                <Form.Item
                    label={t("t_consignor")}
                    name="consignorId"
                >
                    <CommonSelection
                        onChange={consignorIdOnChange}
                        optionKey={`consignor`}
                    />
                </Form.Item>
              }
                <Form.Item
                    label={t("t_fullName")}
                    name="fullName"
                    rules={[{ required: !isConsignorSelected }]}
                >
                    <Input
                        name="fullName"
                        placeholder={t("t_pleaseEnter")}
                        disabled={isConsignorSelected}
                    />
                </Form.Item>
                <Form.Item
                    label={t("t_idNum")}
                    name="idNo"
                    rules={[{ required: !isConsignorSelected }]}
                >
                    <Input
                        name="idNo"
                        placeholder={t("t_pleaseEnter")}
                        disabled={isConsignorSelected}
                    />
                </Form.Item>
								{
									contractType === "purchase" && (
										<>
											{/* <div className={styles.title}>{t("t_receiptInfo")}</div>
											<div className={styles.border} /> */}
											<Form.Item
												label={t("t_paymentMethod")}
												name="paymentMethodId"
											>
													<CommonSelection optionKey="paymentCat"/>
											</Form.Item>
											<Form.Item
												label={t("t_paymentReference")}
												name="paymentReference"
											>
												<Input
													name="paymentReference"
													placeholder={t("t_pleaseEnter")}
												/>
											</Form.Item>
										</>
									)
                }
                <Form.Item
                    label={t("t_shortName")}
                    name="abbreviation"
                    // rules={[{ required: true }]}
                >
                    <Input
                        name="abbreviation"
                        placeholder={t("t_pleaseEnter")}
                        disabled={isConsignorSelected}
                    />
                </Form.Item>
                {/* <Form.Item
                    label={t("t_bankNum")}
                    name="bankNum"
                >
                    <Input
                        name="bankNum"
                        placeholder={t("t_pleaseEnter")}

                    />
                </Form.Item> */}
                <Form.Item
                    label={t("t_bankNum")}
                    rules={[{ required: !isConsignorSelected }]}
                >
                    <Space.Compact label={t("t_bankNum")} style={{ width: '100%' }}>
                        <CommonSelection
                            optionKey={"bankCode"}
                            style={{ width: '50%' }}
                            name={"bankCode"}
                            onChange={e => setBankCode(e)}
                            disabled={isConsignorSelected}
                        />
                        <Input 
                            name={"bankNum"} 
                            onChange={e => setBankNum(e.target.value)} 
                            disabled={isConsignorSelected}
                        />
                    </Space.Compact>
                </Form.Item>
                <Form.Item
                    label={t("t_phoneNum")}
                    name="phoneNum"
                    rules={[{ required: !isConsignorSelected }]}
                >
                    <Input
                        name="phoneNum"
                        placeholder={t("t_pleaseEnter")}
                        disabled={isConsignorSelected}
                    />
                </Form.Item>
                <Form.Item
                    label={t("t_address")}
                    name="address"
                    // rules={[{ required: true }]}
                >
                    <TextArea
                        name="address"
                        rows={4}
                        placeholder={t("t_pleaseEnter")}
                        disabled={isConsignorSelected}
                    />
                </Form.Item>
            </Form >
        </div>
    )
}

export default memo(TickDrawerV2)