import { MAG } from "../api";

export default class ConsignmentReceiptApi {
  static suffix = "/api/consignmentReceipt";

  static listByPage(params = {}) {
    const {
      pageNum = 1,
      pageSize = 15,
      input = "",
    } = params;
    let url = `${this.suffix}/listByPage/${pageNum}/${pageSize}?`;
    const sortOptions = Object.entries(params.sortOptions);
    if (sortOptions.length > 0) {
      sortOptions.forEach(option => {
        url = url.concat(`&&sortField=${encodeURIComponent(option[0])}&&sortOrder=${encodeURIComponent(option[1])}`)
      })
    }
    if (input != "") {
      url = url.concat(`&&input=${encodeURIComponent(input)}`);
    }
    return MAG.get(url);
  }
  
  static update(id, item) {   
    return MAG.put(`${this.suffix}/update/${id}`, item);
  }

  static delete(id) {
    return MAG.put(`${this.suffix}/delete/${id}`);
  }
  
  static save(item) {
    // console.log("save item", item);
    return MAG.post(`${this.suffix}/save`, item);
  }
  
  // static getById(id) {
  //   let url = `/api/item/${id}`;
  //   return MAG.get(url);
  // }
}
