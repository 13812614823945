import { createSlice } from '@reduxjs/toolkit';
import { init } from 'i18next';

export const initialState = {
  show: false,
  confirmOnClose: false,
  // name: null,
  index: 0,
  prevAction: () => {},
  nextAction: () => {},
};

/**
 * All sub pages would need to apply this redux for prev & next page action
 */
export const tickSubPageSlice = createSlice({
  name: 'tickSubPage',
  initialState: {
    show: false,
    confirmOnClose: false,
    // name: null,
    index: 0,
    prevAction: () => {},
    nextAction: () => {},
  },
  reducers: {
    reset: () => initialState,
    /** payload: { prevAction: () => void, nextAction: () => void } */
    showSubPage: (state, newState) => {
      state.show = true;
      // state.name = newState.payload.name;
      if (state.index <= 0) {
        state.prevAction = newState.payload.prevAction;
        state.nextAction = newState.payload.nextAction;
      } else {
        state.prevAction = state.nextAction;
        state.nextAction = newState.payload.nextAction;
      }
      state.confirmOnClose = newState.payload.confirmOnClose;
      state.index = state.index + 1;
    },
    /** payload */
    closeSubPage: (state, newState) => {
      // reset condition
      if (state.index - 1 <= 0) {
        state.show = initialState.show;
        state.confirmOnClose = false;
        state.prevAction = initialState.prevAction;
        state.nextAction = initialState.nextAction;
        state.index = 0;
      }
      state.index = state.index - 1;
    },
    setConfirmOnClose: (state, newState) => {
      state.confirmOnClose = newState.payload;
    }
  },
});

export const { reset, showSubPage, closeSubPage, setConfirmOnClose } = tickSubPageSlice.actions;

export default tickSubPageSlice.reducer;
