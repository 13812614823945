export const formatDatetime = (date, format = "YYYY-MM-DD") => {
  if (typeof date == "string") {
    return;
  }
  if (date === undefined || date === null || date === "") {
    return "";
  }
  // console.log(date.format(format));
  return date.format(format);
};

export const formatter = (string) => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ color: "red", marginRight: "5px" }}>*</div>
      {string}
    </div>
  );
};
