import { MAG } from "../api";

export default class ActionLogApi {
  static suffix = "/api/actionLog";

  static listByPage(params = {}) {
    const {
      pageNum = 1,
      pageSize = 15,
      // sortField = "id",
      // sortOrder = "ASC",
      input = "",
    } = params;
    let url = `${this.suffix}/listByPage/${pageNum}/${pageSize}?`;
    const sortOptions = Object.entries(params.sortOptions);
    if (sortOptions.length > 0) {
      sortOptions.forEach(option => {
        url = url.concat(`&&sortField=${encodeURIComponent(option[0])}&&sortOrder=${encodeURIComponent(option[1])}`)
      })
    }
    if (input != "") {
      url = url.concat(`&&input=${input}`);
    }
    return MAG.get(url);
  }
}
