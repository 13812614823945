import React from "react";
import styles from "./report.module.scss";
import TitleBar from "../../components/titleBar/TitleBar";
import variables from "../../theme/variable.scss";
import TitleBarReportPaginationContent from "../../components/titleBarReportPaginationContent/TitleBarReportPaginationContent";
import { Row } from "antd";
import ReportSideNav from "./ReportSideNav";
import ReportSearchForm from "./ReportSearchForm";
import ReportAdminBar from "./ReportAdminBar";
import ReportCommonApi from "./reportServices";
import ReportAdminModal from "./ReportAdminModal";
import TickTable from "../../components/tickTable/TickTable";
import { useDispatch } from "react-redux";
import { closeModal, showModal } from "../../redux/tickModalSilce";
import ReportUtilsBar from "./ReportUtilsBar";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import ReportExportModal from "./ReportExportModal";


export const Context = React.createContext(null);

const Report = () => {
  const [tabList, setTabList] = React.useState([]);
  const [selectedTab, setSelectedTab] = React.useState("");
  const [isEditModalVisible, setIsEditModalVisible] = React.useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = React.useState(false);
  const [adminFormConfigs, setAdminFormConfigs] = React.useState(null);
  const [isExportModalVisible, setIsExportModalVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [tableColumns, setTableColumns] = React.useState([
    {
      title: 'Missing Order Field',
    }
  ]);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    showTitle: true,
    showQuickJumper: true,
    showSizeChanger: true,
    total: 0,
  });
  const [dataSource, setDataSource] = React.useState([]);
  const [params, setParams] = React.useState({
    sortField: null,
    sortOrder: null,
    searchParams: null
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const permissions = JSON.parse(localStorage.getItem('PERMISSION'));

  React.useEffect(() => {
    fetchAllTabOptions();
  }, []);

  React.useEffect(() => {
    if (!adminFormConfigs?.id) return;
    handleColumns();
  }, [params.sortOrder, params.sortField])

  const handleColumns = (order = adminFormConfigs.order) => {
    const columns = [];
    const keys = order?.split(',') || [];
    keys.forEach((item, index) =>
      columns.push({
        title: item,
        dataIndex: item,
        key: index,
        width: 100,
        align: 'center',
        sorter: {
          multiple: index
        },
        sortOrder: params.sortField === item && params.sortOrder,

        render: (_) => (_ ? _ : "-")
      })
    )
    setTableColumns(columns);
  };

  const fetchAllTabOptions = async () => {
    try {
      const resp = await ReportCommonApi.listAllTabOptions();
      if (!resp.success) return;
      const handledList = [];
      resp?.data.forEach(item => {
        handledList.push({ ...item, name: item.name.charAt(0).toUpperCase() + item.name.slice(1) });
      })
      setTabList(handledList);
    } catch (e) {
      console.error(`This error occurred in 'Report's fetchAllCategoryOptions'::::`, e)
    }
  };

  const fetchSearchConfigByTabName = async (tab = selectedTab) => {
    try {
      const resp = await ReportCommonApi.listSearchConfigByTabName(tab);
      if (!resp.success) return;
      setAdminFormConfigs({
        ...resp?.data,
        searchConfig: JSON.parse(resp?.data?.searchConfig)
      });
      handleColumns(resp?.data?.order)
      fetchTableData(1, 10, resp?.data?.id, {
        sortField: null,
        sortOrder: null,
        searchParams: null
      });
      setSelectedTab(tab);

    } catch (e) {
      console.error(`This error occurred in 'Report's fetchSearchConfigByTabName'::::`, e)
    }
  };

  const fetchTableData = async (page = pagination.current, pageSize = pagination.pageSize, id = adminFormConfigs?.id, args = params) => {
    try {
      if (!id) return;
      const resp = await ReportCommonApi.listByPage(page, pageSize, id, args);
      if (!resp.success) {
        dispatch(
          showModal({
            key: "error",
            header: "Something Wrong!",
            msg: resp?.msg || "Error",
            callback: () => {
              dispatch(closeModal());
            },
          })
        );
        return;
      }
      setDataSource(resp?.data?.array);
      setPagination(prev => ({ ...prev, current: page, pageSize, total: resp?.data?.totalCount || 0 }));
      setParams(prev => ({ ...prev, ...args }));
    } catch (e) {
      console.error(`This error occurred in 'Report's fetchTableData'::::`, e)
    }
  };

  const onTableChange = (pagination, filters, sorter) => {
    if (Array.isArray(sorter)) {
      sorter.forEach(sort => {
        const { column, ...rest } = sort;
      })
    } else {
      const { column, ...rest } = sorter;
      sorter = rest
    }
    fetchTableData(pagination.current,
      pagination.pageSize,
      adminFormConfigs.id,
      { ...params, sortField: sorter.field, sortOrder: sorter.order }
    )
  };

  const onExportClick = (columns) => {
    setLoading(true)
    setIsExportModalVisible(false);
    ReportCommonApi.export({ searchParams: params.searchParams, reportId: adminFormConfigs.id, columns })
      .then((res) => {
        let blob = new Blob([res.data], { type: res.contentType });
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = blobURL;
        tempLink.setAttribute(
          "download",
          decodeURI(
            res.contentDisposition.split(";")[1].split("=")[1]
          )
        );
        if (typeof tempLink.download === "undefined") {
          tempLink.setAttribute("target", "_blank");
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
      }).finally(() => {
        setLoading(false)
      })
  };

  function onDelete() {
    dispatch(
      showModal({
        key: "warning",
        header: t("t_delete"),
        msg: t("t_confirmDelete"),
        showCloseBtn: true,
        callback: async () => {
          try {
            let resp = await ReportCommonApi.delete(adminFormConfigs.id)
            const { msg } = resp
            if (!resp.success) {
              dispatch(
                showModal({
                  key: "error",
                  header: "This is an error message",
                  msg: msg,
                  // showCloseBtn: true,
                  callback: () => {
                    dispatch(closeModal());
                  },
                })
              );
              return
            }
            dispatch(
              showModal({
                key: "success",
                header: "item deleted",
                msg: msg,
                // showCloseBtn: true,
                callback: () => {
                  dispatch(closeModal());
                },
              })
            );
            setSelectedTab("");
            setAdminFormConfigs(null);
            fetchAllTabOptions();
          } catch (e) {
            console.log(e);
          } finally {
            dispatch(closeModal());
          }
        },
      })
    );
  }

  const RenderReportContent = () => {
    if (!adminFormConfigs) return <></>;
    return (
      <>
        {
          adminFormConfigs.searchConfig &&
          <ReportSearchForm />
        }
        <div className={styles.tableContainer} >
          <ReportUtilsBar />
          <div className={styles.tableWrapper} >
            <TickTable
              columns={tableColumns}
              dataSource={dataSource}
              rowKey={(record) => uuidv4()}
              pagination={pagination}
              scroll={{ y: 10000, x: 400 }}
              onChange={onTableChange}
              loading={loading}
            />
          </div>
        </div>
      </>
    )
  };

  const contextValue = {
    isEditModalVisible,
    setIsEditModalVisible,
    isAddModalVisible,
    setIsAddModalVisible,
    selectedTab,
    setSelectedTab,
    tabList,
    setTabList,
    adminFormConfigs,
    fetchAllTabOptions,
    fetchSearchConfigByTabName,
    params,
    setParams,
    fetchTableData,
    onExportClick,
    onDelete,
    isExportModalVisible,
    setIsExportModalVisible,
    pagination
  }

  return (
    <Context.Provider value={contextValue} >
      <TitleBar hasSideBar hasShadow color={variables.themeWhite}>
        <TitleBarReportPaginationContent />
      </TitleBar>
      <div className={styles.container}>
        <ReportSideNav />
        <div className={styles.content}>
          <Row gutter={[0, 12]}>
            {
              permissions.findIndex((item) => item === "report:adminBar") > -1 &&
              <ReportAdminBar />
            }
            {RenderReportContent()}
          </Row>
        </div>
      </div>
      <ReportAdminModal />
      <ReportExportModal />
    </Context.Provider>
  )
}

export default Report;