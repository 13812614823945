import React, { memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { Navigate } from "react-router-dom";
import variables from "../../theme/variable.scss";
import styles from "./login.module.scss";
import LoginForm from "../../components/loginForm/LoginForm";
import Footer from "../../components/footer/Footer";
import TitleBar from "../../components/titleBar/TitleBar";
import TitleBarMainContent from "../../components/titleBarMainContent/TitleBarMainContent";

const Login = () => {
  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <>
      {/* <TitleBar color={variables.themeBlue}>
        <TitleBarMainContent />
      </TitleBar> */}
      <LoginForm
        // hasTitleBar
        backgroundImg={true}
        // backgroundColor={variables.themeBlue}
        loginImg={"lhLoginImg.svg"}
        loginLogo={"lhLoginLogo.svg"}
      >
        <Footer color={variables.themeWhite} />
      </LoginForm>
    </>
  );
};

export default memo(Login);
