import { createSlice } from "@reduxjs/toolkit";
import { itemTableParams } from "../tableParams/itemTable";

import { formatDatetime } from "../utils/formatDatetime";
import dayjs from "dayjs";
import { formatFormItemToBody } from "../utils/formatFormItemToBody";
import { formatBodyToFormItem } from "../utils/formatBodyToFormItem";

export const pageSize = 50;
export const itemPageSize = 100;

const initialState = {
  itemTableData: { array: [], totalCount: 0 },
  itemParams: {
    pageNum: 1,
    pageSize: itemPageSize,
    totalPage: null,
    totalCount: 0,
    // sortField: "id",
    // sortOrder: "ASC",
    input: "",
    deptKey: "",
    transactionTypeId: "",
    colorIds: [],
    status: "",
    priceMin: 0,
    priceMax: 0,
    transactionDateStart: "",
    transactionDateEnd: "",
    bagCats: [],
    accessoryCats: [],
    sortOptions: {},
    isCreate: false,
    isReprintTag: false
  },
  isItemDrawerOpen: false,                    // drawer toggle - drawer means show the item details from right side
  isItemFilterOpen: false,                    // filter toggle - left side 
  itemsDrawerProductDetails: {},
  itemsDrawerConsignorDetails: {},
  consignTableData: { array: [], totalCount: 0 },
  consignParams: {
    pageNum: 1,
    pageSize: pageSize,
    totalPage: null,
    // sortField: "consignorNum",
    // sortOrder: "ASC",
    input: "",
    sortOptions: {}
  },
  contractParams: {
    pageNum: 1,
    pageSize: pageSize,
    totalPage: null,
    input: "",
    sortOptions: {},
  },
  receiptParams: {
    pageNum: 1,
    pageSize: pageSize,
    totalPage: null,
    input: "",
    sortOptions: {},
  },
  couponParams: {
    pageNum: 1,
    pageSize: pageSize,
    totalPage: null,
    input: "",
    sortOptions: {},
  },
  item: {
    // tableData: [],
    submitData: {},
  },
  consignment: {
    // tableData: [],
    // rowData: { items: [] },
    rowData: [],
    tempItems: [],
    selectedItems: [],
    items: {
      create: [],
      update: [],
      delete: [],
    },
    consignor: {
      create: {},
      edited: false,
      // update: {},
    },
  },
  masterTableData: { array: [], totalCount: 0 },
  masterParams: {
    pageNum: 1,
    pageSize: pageSize,
    totalPage: null,
    input: "",
    sortOptions: {},
    type: "colorCategory"
  },
  statusLogTableData: { array: [], totalCount: 0 },
  statusLogParams: {
    pageNum: 1,
    pageSize: pageSize,
    totalPage: null,
    input: "",
    sortOptions: {},
  },
  settingTableData: { array: [], totalCount: 0 },
  settingParams: {
    pageNum: 1,
    pageSize: pageSize,
    totalPage: null,
    input: "",
    sortOptions: {},
  },
  tempItemData: {},
  optionsOfAccessory: [],
  optionsOfCortex: [],
  optionsOfBrand: [],
  optionsOfCategory: { bags: [], accessories: [] },
  optionsOfColor: [],
  optionsOfMaterial: [],
  optionsOfOrigin: [],
  optionsOfRating: [],
  optionsOfTransactionType: [],
  optionsOfConsignor: [],
  isChangePwModalOpen: false,
  language: null,

  // Member initial state
  memberParams: {
    pageNum: 1,
    pageSize: pageSize,
    totalPage: null,
    totalCount: 0,
    input: "",
    sortOptions: {}
  },
  memberTableData: { array: [], totalCount: 0 },

};

export const tickTableSlice = createSlice({
  name: "tickTable",
  initialState: {
    itemTableData: { array: [], totalCount: 0 },
    itemParams: {
      pageNum: 1,
      pageSize: itemPageSize,
      totalPage: null,
      totalCount: 0,
      // sortField: "id",
      // sortOrder: "ASC",
      input: "",
      deptKey: "",
      transactionTypeId: "",
      colorIds: [],
      status: "",
      priceMin: 0,
      priceMax: 0,
      transactionDateStart: "",
      transactionDateEnd: "",
      bagCats: [],
      accessoryCats: [],
      sortOptions: {},
      isCreate: false,
      isReprintTag: false
    },
    isItemDrawerOpen: false,                    // drawer toggle - drawer means show the item details from right side
    isItemFilterOpen: false,                    // filter toggle - left side 
    itemsDrawerProductDetails: {},
    itemsDrawerConsignorDetails: {},
    consignTableData: { array: [], totalCount: 0 },
    consignParams: {
      pageNum: 1,
      pageSize: pageSize,
      totalPage: null,
      // sortField: "consignorNum",
      // sortOrder: "ASC",
      input: "",
      sortOptions: {}
    },
    item: {
      // tableData: [],
      submitData: {},
    },
    consignment: {
      // tableData: [],
      // rowData: { items: [] },
      rowData: [],
      tempItems: [],
      selectedItems: [],
      items: {
        create: [],
        update: [],
        delete: [],
      },
      consignor: {
        create: {},
        edited: false
        // update: {},
      },
    },
    contractTableData: { array: [], totalCount: 0 },
    contractParams: {
      pageNum: 1,
      pageSize: pageSize,
      totalPage: null,
      input: "",
      sortOptions: {},
    },
    receiptTableData: { array: [], totalCount: 0 },
    receiptParams: {
      pageNum: 1,
      pageSize: pageSize,
      totalPage: null,
      input: "",
      sortOptions: {},
    },
    couponTableData: { array: [], totalCount: 0 },
    couponParams: {
      pageNum: 1,
      pageSize: pageSize,
      totalPage: null,
      input: "",
      sortOptions: {},
    },
    masterTableData: { array: [], totalCount: 0 },
    masterParams: {
      pageNum: 1,
      pageSize: pageSize,
      totalPage: null,
      input: "",
      sortOptions: {},
      type: "colorCategory"
    },
    statusLogTableData: { array: [], totalCount: 0 },
    statusLogParams: {
      pageNum: 1,
      pageSize: pageSize,
      totalPage: null,
      input: "",
      sortOptions: {},
    },
    settingTableData: { array: [], totalCount: 0 },
    settingParams: {
      pageNum: 1,
      pageSize: pageSize,
      totalPage: null,
      input: "",
      sortOptions: {},
    },
    tempItemData: {},
    optionsOfAccessory: [],
    optionsOfCortex: [],
    optionsOfBrand: [],
    optionsOfCategory: { bags: [], accessories: [] },
    optionsOfColor: [],
    optionsOfMaterial: [],
    optionsOfOrigin: [],
    optionsOfRating: [],
    optionsOfTransactionType: [],
    optionsOfConsignor: [],
    isChangePwModalOpen: false,
    language: null,
    // Member initial state
    memberParams: {
      pageNum: 1,
      pageSize: pageSize,
      totalPage: null,
      totalCount: 0,
      input: "",
      sortOptions: {},
    },
    memberTableData: { array: [], totalCount: 0 },
  },
  reducers: {
    setItemsDrawerProductDetails: (state, newState) => {
      state.itemsDrawerProductDetails = newState.payload
    },
    setItemsDrawerConsignorDetails: (state, newState) => {
      state.itemsDrawerConsignorDetails = newState.payload
    },
    setItemsStateToInitialState: (state, newState) => {
      state.itemParams = { ...initialState.itemParams, isCreate: newState.payload?.isCreate ? true : false }; // general for reload
    },
    setConsignStateToInitialState: (state) => {
      state.consignParams = initialState.consignParams; // general for reload
    },
    setMasterStateToInitialState: (state) => {
      state.masterParams = initialState.masterParams; // general for reload
    },
    setShowItemDrawer: (state, newState) => {
      state.isItemDrawerOpen = newState.payload;
    },
    setShowFilterCollapse: (state, newState) => {
      state.isItemFilterOpen = newState.payload;
    },
    setTempItemData: (state, newState) => {
      const checkIfExist = (value) => {
        let isExist = false;
        if (value != null && value != undefined && value != "") {
          isExist = true;
        }
        return isExist;
      };

      if (checkIfExist(newState.payload.onSalesPrice)) {
        newState.payload.onSalesPrice = parseInt(
          newState.payload?.onSalesPrice
        );
      }

      // single dropdown

      const mapToLabelValue = (arr) => {
        if (arr?.length == 0) {
          return "";
        } else {
          return arr.map((item) => {
            return {
              label: item[`name${state.language}`],
              value: item.id,
            };
          });
        }
      };

      let b = {};
      let newDeptKey;
      if (checkIfExist(newState.payload.brand)) {
        let idIsNum = newState.payload.brand?.id;
        b = state.optionsOfBrand?.find((item) => item.id == idIsNum);
        newDeptKey = b?.itemDeptKey;
      } else {
        newDeptKey = "";
      }

      let ca = {};
      let newCatKey;
      let newSubCatKey;
      if (checkIfExist(newState.payload.category)) {
        let idIsNum = newState.payload.category?.id;
        let isNotFind =
          state.optionsOfCategory.bags?.find((item) => item.id == idIsNum) ==
          undefined;
        let key = isNotFind ? ["accessories"] : ["bags"];
        ca = state.optionsOfCategory?.[`${key}`].find(
          (item) => item.id == idIsNum
        );
        newCatKey = ca?.itemCatKey;
        newSubCatKey = ca?.itemSubCatKey;
      } else {
        newCatKey = "";
        newSubCatKey = "";
      }

      state.item.rowData = {
        ...newState.payload,
        brand: {
          ...newState.payload.brand,
          label: newState.payload?.brand?.[`name${state.language}`],
          value: newState.payload.brand?.itemDeptKey,
        },

        category: {
          ...newState.payload.category,
          label: newState.payload?.category?.[`name${state.language}`],
          value: newState.payload.category?.id,
        },

        accessoriesIds: mapToLabelValue(newState.payload.accessories),
        colorIds: mapToLabelValue(newState.payload.colors),
        materialIds: mapToLabelValue(newState.payload.materials),
        originIds: mapToLabelValue(newState.payload.origins),
        ratingIds: mapToLabelValue(newState.payload.ratings),
        transactionDate: newState.payload.transactionDate,
        year: newState.payload.year,
      };
    },
    clearTempItemData: (state) => {
      state.tempItemData = {};
    },
    //item
    setItemParams: (state, newState) => {
      // console.log("set item params", newState.payload);
      const turnValueToEmptyString = (value) => {
        if (value == null || value == undefined) {
          value = "";
        }
        return value;
      };

      const turnValueToNumber = (value, key) => {
        if (value == null || value == undefined || value == "") {
          return state.itemParams?.[`${key}`];
        }
        return value;
      };

      let newTotalPage = Math.ceil(
        state.itemTableData?.totalCount / state.itemParams?.pageSize
      );
      // if (newState.payload?.pageNum > newTotalPage) {
      //   return;
      // }

      state.itemParams = {
        pageNum: newState.payload?.pageNum,
        pageSize: state.itemParams.pageSize,
        totalPage: newTotalPage,
        sortField: turnValueToEmptyString(newState.payload?.sortField),
        sortOrder: turnValueToEmptyString(newState.payload?.sortOrder),
        input: turnValueToEmptyString(newState.payload?.input),
        deptKey: turnValueToEmptyString(newState.payload?.deptKey),
        transactionTypeId: turnValueToNumber(
          newState.payload?.transactionTypeId
        ),
        bagCats: newState.payload?.bagCats,
        accessoryCats: newState.payload?.accessoryCats,
        sortOptions: newState.payload.sortOptions,
        isCreate: newState.payload?.isCreate ? true : false,
        colorIds: newState.payload?.colorIds,
        status: newState.payload?.status,
        priceMin: newState.payload?.priceMin,
        priceMax: newState.payload?.priceMax,
        transactionDateStart: newState.payload?.transactionDateStart,
        transactionDateEnd: newState.payload?.transactionDateEnd,
        isReprintTag: newState.payload?.isReprintTag,
      };
    },
    clearItemParams: (state) => {
      state.itemParams = initialState.itemParams
    },
    setItemTableData: (state, newState) => {
      state.itemTableData.totalCount = newState.payload.totalCount;
      state.itemParams.totalPage = Math.ceil(
        newState.payload.totalCount / state.itemParams.pageSize
      );
      state.itemParams.totalCount = newState.payload.totalCount;
    },
    //consignment
    setConsignmentTableData: (state, newState) => {
      state.consignment.tableData = newState.payload;
      state.consignParams.totalPage = Math.ceil(
        newState.payload.totalCount / state.consignParams.pageSize
      );
      state.consignParams.totalCount = newState.payload.totalCount;
    },
    setConsignmentParams: (state, newState) => {
      // console.log("set consign params");
      const turnValueToEmptyString = (value) => {
        if (value == null || value == undefined) {
          value = "";
        }
        return value;
      };

      let newTotalPage = Math.round(
        state.itemTableData?.totalCount / state.itemParams?.pageSize
      );
      // if (newState.payload?.pageNum > newTotalPage) {
      //   return;
      // }

      state.consignParams = {
        pageNum: newState.payload?.pageNum,
        pageSize: pageSize,
        totalPage: newTotalPage,
        sortField: turnValueToEmptyString(newState.payload?.sortField),
        sortOrder: turnValueToEmptyString(newState.payload?.sortOrder),
        input: turnValueToEmptyString(newState.payload?.input),
        sortOptions: newState.payload.sortOptions
      };
    },
    setConsignmentSelectedData: (state, newState) => {
      state.consignment.selectedItems = newState.payload;
    },
    setConsignmentRowData: (state, newState) => {
      let newItems = newState.payload.map((i) => {
        return {
          ...i,
          brand: {
            ...i.brand,
            label: i?.brand?.[`name${state.language}`],
            value: i?.brand?.id,
          },
          category: {
            ...i.category,
            label: i?.category?.[`name${state.language}`],
            value: i?.category?.id,
          },
        };
      });
      // state.consignment.rowData = {
      //   ...newState.payload,
      // };
      // state.consignment.tempItems = newItems;
      state.consignment.rowData = newItems;
    },
    clearConsignmentRowData: (state) => {
      state.consignment.rowData = [];
      state.consignment.tempItems = [];
    },
    setConsignTempItem: (state, newState) => {
      const tempState = { ...state.tempItemData };
      console.log("consign temp item", newState.payload);

      const checkIfExist = (value) => {
        let isExist = false;
        if (value != null && value != undefined && value != "") {
          isExist = true;
        }
        return isExist;
      };

      let newTransDate =
        newState.payload.transactionDate != tempState?.transactionDate
          ? formatDatetime(newState.payload.transactionDate)
          : tempState?.transactionDate;

      if (checkIfExist(newState.payload.onSalesPrice)) {
        newState.payload.onSalesPrice = parseInt(
          newState.payload?.onSalesPrice
        );
      }
      // newState.payload.year = formatDatetime(newState.payload.year, "YYYY");

      newState.payload.deptType = tempState?.deptType;

      let b = {};
      let newDeptKey;
      if (checkIfExist(newState.payload.brand)) {
        let id = newState.payload.brand.id;
        if (id == undefined || id == null) {
          newDeptKey = newState.payload.brand;
          b = state.optionsOfBrand?.find(
            (item) => item.itemDeptKey == newState.payload.brand
          );
        } else {
          b = state.optionsOfBrand?.find((item) => item.id == id);
          newDeptKey = b?.itemDeptKey;
        }
      }

      let ca = {};
      let newCatKey;
      let newSubCatKey;
      if (checkIfExist(newState.payload.category)) {
        let id = newState.payload.category.id;
        if (id == undefined || id == null) {
          id = newState.payload.category;
        }
        let isNotFind =
          state.optionsOfCategory.bags?.find((item) => item.id == id) ==
          undefined;
        let key = isNotFind ? "accessories" : "bags";
        ca = state.optionsOfCategory?.[`${key}`]?.find((item) => item.id == id);
        newCatKey = ca?.itemCatKey;
        newSubCatKey = ca?.itemSubCatKey;
      }

      const mapToValue = (ids) => {
        let arr = [];
        if (ids != null || ids != undefined) {
          ids = "";
        } else {
          ids?.forEach((item) => {
            arr.push(item.value);
          });
          ids = arr?.join();
        }
        return ids;
      };

      newState.payload.accessoriesIds = mapToValue(
        newState.payload.accessoriesIds
      );
      newState.payload.colorIds = mapToValue(newState.payload.colorIds);
      newState.payload.materialIds = mapToValue(newState.payload.materialIds);
      newState.payload.originIds = mapToValue(newState.payload.originIds);
      newState.payload.ratingIds = mapToValue(newState.payload.ratingIds);

      const turnValueToEmptyString = (value) => {
        if (value == null || value == undefined) {
          value = "";
        }
        return value;
      };

      const turnValueToNumber = (value) => {
        if (value == null || value == undefined || value == "") {
          value = 0;
        }
        return value;
      };

      let newObj = {
        ...tempState,
        // id: tempState?.id,
        accessoriesIds: turnValueToEmptyString(newState.payload.accessoriesIds),
        amt: turnValueToNumber(newState.payload.amt),
        brand: {
          label: b?.[`name${state.language}`],
          value: b?.id,
        },
        catKey: turnValueToEmptyString(newCatKey),
        category: {
          label: ca?.[`name${state.language}`],
          value: ca?.id,
        },
        colorIds: turnValueToEmptyString(newState.payload.colorIds),
        // colorInternal: turnValueToEmptyString(newState.payload.colorInternal),
        deptType: turnValueToEmptyString(newState.payload.deptType),
        deptKey: turnValueToEmptyString(newDeptKey),
        handlingFee: turnValueToNumber(
          newState.payload.handlingFee
        ),
        height: turnValueToNumber(newState.payload.height),
        itemNameEn: turnValueToEmptyString(newState.payload[`itemName${state.language}`]),
        itemNameTc: turnValueToEmptyString(newState.payload[`itemName${state.language}`]),
        itemNum: turnValueToEmptyString(newState.payload.itemNum),
        length: turnValueToNumber(newState.payload.length),
        materialIds: turnValueToEmptyString(newState.payload.materialIds),
        model: turnValueToEmptyString(newState.payload.model),
        onSalesPrice: turnValueToNumber(newState.payload.onSalesPrice),
        originIds: turnValueToEmptyString(newState.payload.originIds),
        // photo: turnValueToEmptyString(newState.payload.photo),
        // productNameInternal: turnValueToEmptyString(
        //   newState.payload.productNameInternal
        // ),
        qty: turnValueToNumber(newState.payload.qty),
        ratingIds: turnValueToEmptyString(newState.payload.ratingIds),
        remark: turnValueToEmptyString(newState.payload.remark),
        subCatKey: turnValueToEmptyString(newSubCatKey),
        transactionDate: turnValueToEmptyString(newTransDate),
        transactionTypeId: turnValueToEmptyString(
          newState.payload.transactionTypeId
        ),
        width: turnValueToNumber(newState.payload.width),
        year: turnValueToEmptyString(newState.payload.year),
      };

      state.consignment.tempItems = state.consignment.tempItems.map((i) => {
        if (i?.id == newState.payload.id) {
          console.log("match");
          console.log(newObj);
          return newObj;
        } else {
          return i;
        }
      });
      state.tempItemData = newObj;
    },
    //temp save, temp add
    setCreateConsignmentItems: (state, newState) => {
      // console.log("create", newState.payload);
      let notCreated =
        state.consignment.items.create.find(
          (i) => i?.id == state.tempItemData.id
        ) == undefined;

      if (notCreated) {
        state.consignment.items.create.push(newState.payload);
        state.consignment.tempItems.push(newState.payload);
      }
    },
    clearCreateConsignmentItems: (state, newState) => {
      state.consignment.items.create = [];
    },
    //temp save
    setUpdateConsignmentItems: (state, newState) => {
      let notUpdated =
        state.consignment.items.update.find(
          (i) => i?.id == state.tempItemData.id
        ) == undefined;

      if (notUpdated) {
        console.log("not update");
        console.log(newState.payload);
        state.consignment.items.update.push(newState.payload);
      } else {
        console.log("has update");
        state.consignment.items.update = state.consignment.items.update.map(
          (i) => {
            if (i?.id == state.tempItemData.id) {
              return newState.payload;
            } else {
              return i;
            }
          }
        );
      }
      state.consignment.tempItems = state.consignment.tempItems.map((i) => {
        if (i?.id == newState.payload.id) {
          return newState.payload;
        } else {
          return i;
        }
      });
    },
    clearUpdateConsignmentItems: (state, newState) => {
      state.consignment.items.update = [];
    },
    setDeleteConsignmentItems: (state, newState) => {
      // console.log("delete", newState.payload);
      let notDeleted =
        state.consignment.items.delete.find(
          (i) => i?.id == newState.payload.id
        ) == undefined;

      if (notDeleted) {
        state.consignment.items.delete.push(newState.payload);
        let delItemIndex = state.consignment.tempItems.findIndex(
          (i) => i?.id != newState.payload.id
        );
        state.consignment.tempItems.splice(delItemIndex - 1, 1);
      }
    },
    clearDeleteConsignmentItems: (state, newState) => {
      state.consignment.items.delete = [];
    },
    //temp add
    setCreateConsignmentConsignor: (state, newState) => {
      state.consignment.consignor.create = newState.payload;
    },
    setConsignmentConsignorEdited: (state, newState) => {
      state.consignment.consignor.edited = newState.payload;
    },
    clearCreateConsignmentConsignor: (state, newState) => {
      state.consignment.consignor.create = {};
    },
    setConsignmentConsignorToInitialState: (state) => {
      state.consignment = initialState.consignment;
    },
    // clearConsignmentSubmitData: (state) => {},
    setOptionsOfAccessory: (state, newState) => {
      state.optionsOfAccessory = newState.payload;
    },
    setOptionsOfBrand: (state, newState) => {
      let newBrand = newState.payload.map((item) => {
        return {
          ...item,
          label: item[`name${state.language}`],
          value: item.itemDeptKey,
        };
      });
      state.optionsOfBrand = newBrand;
    },
    setOptionsOfCategory: (state, newState) => {
      // console.log(newState.payload);
      let newBags = newState.payload.bags.map((item) => {
        return {
          ...item,
          label: item[`name${state.language}`],
          value: item.itemSubCatKey,
        };
      });
      let newAccessories = newState.payload.accessories.map((item) => {
        return {
          ...item,
          label: item[`name${state.language}`],
          value: item.itemSubCatKey,
        };
      });
      state.optionsOfCategory.bags = newBags;
      state.optionsOfCategory.accessories = newAccessories;
      state.optionsOfCategory.all = [...newBags, ...newAccessories]
    },
    setOptionsOfColor: (state, newState) => {
      state.optionsOfColor = newState.payload;
    },
    setOptionsOfMaterial: (state, newState) => {
      state.optionsOfMaterial = newState.payload;
    },
    setOptionsOfCortex: (state, newState) => {
      state.optionsOfCortex = newState.payload;
    },
    setOptionsOfOrigin: (state, newState) => {
      state.optionsOfOrigin = newState.payload;
    },
    setOptionsOfRating: (state, newState) => {
      state.optionsOfRating = newState.payload;
    },
    setOptionsOfTransactionType: (state, newState) => {
      state.optionsOfTransactionType = newState.payload;
    },
    setOptionsOfConsignor: (state, newState) => {
      let newConsignorSets = newState.payload.map(item => (
        {
          label: `${item.fullName}`,
          value: item?.id,
        }
      ));
      state.optionsOfConsignor = newConsignorSets;
    },
    editTempItem: (state, newState) => {
      state.consignment.tempItems = newState.payload
    },
    setIsChangePwModalOpen: (state, newState) => {
      state.isChangePwModalOpen = newState.payload
    },
    // contract
    setContractParams: (state, newState) => {
      const turnValueToEmptyString = (value) => {
        if (value == null || value == undefined) {
          value = "";
        }
        return value;
      };

      let newTotalPage = Math.round(
        state.contractTableData?.totalCount / state.contractParams?.pageSize
      );

      state.contractParams = {
        pageNum: newState.payload?.pageNum,
        pageSize: pageSize,
        totalPage: newTotalPage,
        input: turnValueToEmptyString(newState.payload?.input),
        sortOptions: newState.payload.sortOptions,
      };
    },
    setContractTableData: (state, newState) => {
      state.contractTableData.totalCount = newState.payload.totalCount;
      state.contractParams.totalPage = Math.ceil(
        newState.payload.totalCount / state.contractParams.pageSize
      );
      state.contractParams.totalCount = newState.payload.totalCount;
    },
    // receipt
    setReceiptParams: (state, newState) => {
      const turnValueToEmptyString = (value) => {
        if (value == null || value == undefined) {
          value = "";
        }
        return value;
      };

      let newTotalPage = Math.round(
        state.receiptTableData?.totalCount / state.receiptParams?.pageSize
      );

      state.receiptParams = {
        pageNum: newState.payload?.pageNum,
        pageSize: pageSize,
        totalPage: newTotalPage,
        input: turnValueToEmptyString(newState.payload?.input),
        sortOptions: newState.payload.sortOptions,
      };
    },
    setReceiptTableData: (state, newState) => {
      console.log(state, newState, "+=======");
      state.receiptTableData.totalCount = newState.payload.totalCount;
      state.receiptParams.totalPage = Math.ceil(
        newState.payload.totalCount / state.receiptParams.pageSize
      );
      state.receiptParams.totalCount = newState.payload.totalCount;
    },
    // coupon
    setCouponParams: (state, newState) => {
      const turnValueToEmptyString = (value) => {
        if (value == null || value == undefined) {
          value = "";
        }
        return value;
      };

      let newTotalPage = Math.round(
        state.couponTableData?.totalCount / state.couponParams?.pageSize
      );

      state.couponParams = {
        pageNum: newState.payload?.pageNum,
        pageSize: pageSize,
        totalPage: newTotalPage,
        input: turnValueToEmptyString(newState.payload?.input),
        sortOptions: newState.payload.sortOptions,
      };
    },
    setCouponTableData: (state, newState) => {
      state.couponTableData.totalCount = newState.payload.totalCount;
      state.couponParams.totalPage = Math.ceil(
        newState.payload.totalCount / state.couponParams.pageSize
      );
      state.couponParams.totalCount = newState.payload.totalCount;
    },
    //master
    setMasterParams: (state, newState) => {
      const turnValueToEmptyString = (value) => {
        if (value == null || value == undefined) {
          value = "";
        }
        return value;
      };

      let newTotalPage = Math.round(
        state.itemTableData?.totalCount / state.itemParams?.pageSize
      );
      // if (newState.payload?.pageNum > newTotalPage) {
      //   return;
      // }

      state.masterParams = {
        pageNum: newState.payload?.pageNum,
        pageSize: pageSize,
        totalPage: newTotalPage,
        // sortField: turnValueToEmptyString(newState.payload?.sortField),
        // sortOrder: turnValueToEmptyString(newState.payload?.sortOrder),
        input: turnValueToEmptyString(newState.payload?.input),
        sortOptions: newState.payload.sortOptions,
        type: newState.payload?.type
      };
    },
    setMasterTableData: (state, newState) => {
      state.masterTableData.totalCount = newState.payload.totalCount;
      state.masterParams.totalPage = Math.ceil(
        newState.payload.totalCount / state.masterParams.pageSize
      );
      state.masterParams.totalCount = newState.payload.totalCount;
    },
    setStatusLogParams: (state, newState) => {
      const turnValueToEmptyString = (value) => {
        if (value == null || value == undefined) {
          value = "";
        }
        return value;
      };

      let newTotalPage = Math.round(
        state.itemTableData?.totalCount / state.itemParams?.pageSize
      );
      // if (newState.payload?.pageNum > newTotalPage) {
      //   return;
      // }

      state.statusLogParams = {
        pageNum: newState.payload?.pageNum,
        pageSize: pageSize,
        totalPage: newTotalPage,
        // sortField: turnValueToEmptyString(newState.payload?.sortField),
        // sortOrder: turnValueToEmptyString(newState.payload?.sortOrder),
        input: turnValueToEmptyString(newState.payload?.input),
        sortOptions: newState.payload.sortOptions,
      };
    },
    setStatusLogTableData: (state, newState) => {
      state.statusLogTableData.totalCount = newState.payload.totalCount;
      state.statusLogParams.totalPage = Math.ceil(
        newState.payload.totalCount / state.masterParams.pageSize
      );
      state.statusLogParams.totalCount = newState.payload.totalCount;
    },

    // setting
    setSettingParams: (state, newState) => {
      const turnValueToEmptyString = (value) => {
        if (value == null || value == undefined) {
          value = "";
        }
        return value;
      };

      let newTotalPage = Math.round(
        state.itemTableData?.totalCount / state.itemParams?.pageSize
      );
      // if (newState.payload?.pageNum > newTotalPage) {
      //   return;
      // }

      state.settingParams = {
        pageNum: newState.payload?.pageNum,
        pageSize: pageSize,
        totalPage: newTotalPage,
        // sortField: turnValueToEmptyString(newState.payload?.sortField),
        // sortOrder: turnValueToEmptyString(newState.payload?.sortOrder),
        input: turnValueToEmptyString(newState.payload?.input),
        sortOptions: newState.payload.sortOptions,
      };
    },
    setSettingTableData: (state, newState) => {
      state.settingTableData.totalCount = newState.payload.totalCount;
      state.settingParams.totalPage = Math.ceil(
        newState.payload.totalCount / state.settingParams.pageSize
      );
      state.settingParams.totalCount = newState.payload.totalCount;
    },
    setLanguage: (state, newState) => {
      state.language = newState.payload;
    },
    reset: (state) => initialState,
     // Member
    setMemberParams: (state, newState) => {
      const turnValueToEmptyString = (value) => {
        if (value == null || value == undefined) {
          value = "";
        }
        return value;
      };

      let newTotalPage = Math.ceil(
        state?.memberParams.totalCount / state.itemParams?.pageSize
      );
      state.memberParams = {
        pageNum: newState.payload?.pageNum,
        pageSize: pageSize,
        totalPage: newTotalPage,
        input: turnValueToEmptyString(newState.payload?.input),
        sortOptions: newState.payload?.sortOptions
      };
    },

    setMemberTableData: (state, newState) => {
      state.memberTableData.totalCount = newState.payload.totalCount;
      state.memberParams.totalPage = Math.ceil(
        newState.payload.totalCount / state.memberParams.pageSize
      );
      state.memberParams.totalCount = newState.payload.totalCount;
    },

    clearMemberParams: (state) => {
      state.memberParams = initialState.memberParams;
    }
  },
});

export const {
  setItemsDrawerProductDetails,
  setItemsDrawerConsignorDetails,
  setItemsStateToInitialState,
  setConsignStateToInitialState,
  setConsignmentConsignorToInitialState,

  setShowItemDrawer,
  setShowFilterCollapse,

  setTempItemData,
  clearTempItemData,
  //item
  setItemTableData,
  setAddItemData,
  setUpdateItemData,
  clearItemSubmitData,
  //consignment
  setConsignmentTableData,
  setConsignmentRowData,
  setConsignmentSelectedData,
  // setConsignTempItems,
  setConsignTempItem,
  setCreateConsignmentItems,
  clearCreateConsignmentItems,
  setUpdateConsignmentItems,
  clearUpdateConsignmentItems,
  setDeleteConsignmentItems,
  clearDeleteConsignmentItems,
  // setSaveSubmitData,

  setCreateConsignmentConsignor,
  setConsignmentConsignorEdited,
  clearCreateConsignmentConsignor,

  clearConsignmentRowData,
  setOptionsOfAccessory,
  setOptionsOfBrand,
  setOptionsOfCategory,
  setOptionsOfColor,
  setOptionsOfMaterial,
  setOptionsOfOrigin,
  setOptionsOfRating,
  setOptionsOfTransactionType,
  setConsignmentParams,
  setItemParams,
  clearItemParams,
  setOptionsOfConsignor,
  editTempItem,
  setIsChangePwModalOpen,
  setContractParams,
  setContractTableData,
  setReceiptParams,
  setReceiptTableData,
  setCouponParams,
  setCouponTableData,
  setMasterParams,
  setMasterTableData,
  setMasterStateToInitialState,
  setOptionsOfCortex,
  setStatusLogParams,
  setStatusLogTableData,
  setSettingParams,
  setSettingTableData,
  // Language
  setLanguage,
  // Member
  setMemberParams,
  setMemberTableData,
  clearMemberParams,

  reset,
} = tickTableSlice.actions;

export default tickTableSlice.reducer;
