import React, { useState, useRef, useEffect, memo } from "react";
import styles from "./titleBar.module.scss";

const TitleBar = (props) => {
  const { color, hasSideBar = false, hasShadow = false, children } = props;

  return (
    <>
      <div
        className={`${styles.titleBar} ${
          hasSideBar ? styles.widthWithSideBar : styles.widthWithoutSideBar
        } ${hasShadow ? styles.withShadow : null}`}
        style={{ backgroundColor: color }}
      >
        {children}
      </div>
    </>
  );
};

export default memo(TitleBar);
