/**
 *
 * @param {array} itemRecord
 * @param {string} language
 * @return {string}
 */
export const handleTableMultiOptions = (itemRecord, language) => {
  if (!Array.isArray(itemRecord)) return "-";
  if (itemRecord.length === 0) return "-";
  if (!language) return "-";

  return itemRecord?.map(
    (item, index) =>
      `${
        item?.[language === "tc_hk" ? "nameTc" : "nameEn"] ||
        item?.[language === "tc_hk" ? "nameEn" : "nameTc"] ||
        "-"
      }${index === itemRecord.length - 1 ? "" : "/"}`
  );
};


/**
 *
 * @param {function} fn
 * @param {string} language
 * @return {function}
 */
export function debounce(fn, delay = 500) {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}
