import React, { memo, useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import i18n from "../../i18n";
import { closeModal, showModal } from "../../redux/tickModalSilce";
// Components//
import FontSize from "../../components/fontSize/FontSize";
import ProductModal from "../../modals/ProductModal";
import { TickSuccessModal } from "../../modals/TickSuccessModal";
import { TickWarningModal } from "../../modals/TickWarningModal";
import { TickDeleteModal } from "../../modals/TickDeleteModal";
import { TickGlobalModal } from "../../modals/TickGlobalModal";
import { TickInfoModal } from "../../modals/TickInfoModal";
import { TickErrorModal } from "../../modals/TickErrorModal";
import { TickLogoutModal } from "../../modals/TickLogoutModal";
// CSS
import variables from "../../theme/variable.scss";
import styles from "./unitTest.module.scss";
import IconWithTextButton from "../../components/iconWithTextButton/IconWithTextButton";
import { systemConfig } from "../../config/systemConfig";

const UnitTest = () => {
  const dispatch = useDispatch();
  const tickGlobalModalState = useSelector((state) => state.tickModal);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [language, setLanguage] = useState(i18n.language);
  const [showProductModal, setShowProductModal] = useState(false);
  i18n.on("languageChanged", (lng) => setLanguage(i18n.language));

  const langOptions = ["tc_hk", "en"];

  const changeLocale = (l) => {
    if (language !== l) {
      i18n.changeLanguage(l);
    }
  };

  const changeLanguage = (lang) => {
    console.log("lang", lang);

    setLanguage(t(`t_${lang}`));
    changeLocale(lang);
  };
  return (
    <>
      <header className={styles.header}>
        Unit Test Page {`(${systemConfig.version})`}
      </header>
      <div className={[styles.title, styles.flexPerLine]}>
        <div className={styles.flexPerLine}>
          Settings:
          <span className={styles.flexPerLine}>
            {langOptions.map((lang, index) => {
              return (
                <IconWithTextButton
                  type={
                    language === lang
                      ? "primary"
                      : "default"
                  }
                  onBtnClick={() => changeLanguage(lang)}
                >
                  {`${lang === "tc_hk" ? "中" : "EN"}`}
                </IconWithTextButton>
              );
            })}
            <FontSize />
          </span>
        </div>
      </div>

      <div className={styles.flexPerLine} style={{ justifyContent: "center" }}>
        <div
          className={styles.fullBtn}
          onClick={() => {
            navigate("/main/item");
          }}
        >
          Back to Main Item Page
        </div>
      </div>
      <hr style={{ margin: "20px 20px" }} />
      <div className={styles.flexPerLine}>Testing Components:</div>
      <div
        className={styles.btnContainer}
      // style={{ display: "flex", alignItems: "center" }}
      >
        <IconWithTextButton
          onBtnClick={() => {
            setShowProductModal(true);
          }}
        >
          Show Product Modal
        </IconWithTextButton>
        <IconWithTextButton
          onBtnClick={() => {
            dispatch(
              showModal({
                key: "success",
                header: "Some task has completed!",
                msg: "Some content...",
                // showCloseBtn: true,
                callback: () => {
                  dispatch(closeModal());
                },
              })
            );
          }}
        >
          Show Success Modal
        </IconWithTextButton>
        <IconWithTextButton
          onBtnClick={() => {
            dispatch(
              showModal({
                key: "success",
                header: "Some task has completed!",
                msg: "Some content...",
                // showCloseBtn: true,
                callback: () => {
                  dispatch(closeModal());
                },
              })
            );
          }}
        >
          Show Success Modal
        </IconWithTextButton>
        <IconWithTextButton
          onBtnClick={() => {
            dispatch(
              showModal({
                key: "warning",
                header: "This is a warning message",
                msg: "Some content...",
                // showCloseBtn: true,
                callback: () => {
                  dispatch(closeModal());
                },
              })
            );
          }}
        >
          Show Warning Modal
        </IconWithTextButton>

        <IconWithTextButton
          onBtnClick={() => {
            dispatch(
              showModal({
                key: "delete",
                header: "刪除",
                msg: "確定刪除該項目嗎？",
                showCloseBtn: true,
                callback: () => {
                  dispatch(closeModal());
                },
              })
            );
          }}
        >
          Show Delete Modal
        </IconWithTextButton>
        <IconWithTextButton
          onBtnClick={() => {
            dispatch(
              showModal({
                header: "DEFAULT MODAL",
                callback: () => {
                  dispatch(closeModal());
                },
              })
            );
          }}
        >
          Show default Modal
        </IconWithTextButton>
        <IconWithTextButton
          onBtnClick={() => {
            dispatch(
              showModal({
                key: "info",
                header: "This is some info",
                msg: "some content...",
                // showCloseBtn: true,
                callback: () => {
                  dispatch(closeModal());
                },
              })
            );
          }}
        >
          Show Info Modal
        </IconWithTextButton>
        <IconWithTextButton
          onBtnClick={() => {
            dispatch(
              showModal({
                key: "error",
                header: "This is an error message",
                msg: "some content...",
                // showCloseBtn: true,
                callback: () => {
                  dispatch(closeModal());
                },
              })
            );
          }}
        >
          Show Error Modal
        </IconWithTextButton>
        <IconWithTextButton
          onBtnClick={() => {
            dispatch(
              showModal({
                key: "logout",
                header: "Logout",
                msg: "Are you sure you want to logout ?",
                showCloseBtn: true,
                callback: () => {
                  dispatch(closeModal());
                },
              })
            );
          }}
        >
          Show Logout Modal
        </IconWithTextButton>
      </div>

      {showProductModal && (
        <ProductModal
          showProductModal={showProductModal}
          setShowProductModal={setShowProductModal}
        // onCancel={onProductModalCancel}
        // onSubmit={onProductModalSubmit}
        />
      )}

      {tickGlobalModalState.show ? <TickGlobalModal /> : null}

      {tickGlobalModalState.show &&
        tickGlobalModalState?.params?.key === "success" ? (
        <TickSuccessModal />
      ) : null}

      {tickGlobalModalState.show &&
        tickGlobalModalState?.params?.key === "warning" ? (
        <TickWarningModal />
      ) : null}

      {tickGlobalModalState.show &&
        tickGlobalModalState?.params?.key === "delete" ? (
        <TickDeleteModal />
      ) : null}

      {tickGlobalModalState.show &&
        tickGlobalModalState?.params?.key === "info" ? (
        <TickInfoModal />
      ) : null}

      {tickGlobalModalState.show &&
        tickGlobalModalState?.params?.key === "error" ? (
        <TickErrorModal />
      ) : null}

      {tickGlobalModalState.show &&
        tickGlobalModalState?.params?.key === "logout" ? (
        <TickLogoutModal />
      ) : null}
    </>
  );
};

export default memo(UnitTest);
