import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { ReloadOutlined, UploadOutlined } from "@ant-design/icons";
import PaginationBar from "../paginationBar/PaginationBar";
import styles from "./titleBarReceiptPaginationContent.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { setReceiptParams } from "../../redux/tickTableSlice";
import IconWithTextButton from "../iconWithTextButton/IconWithTextButton";

const TitleBarReceiptPaginationContent = (props) => {
  const { reload, setShowImportModal } = props;
  const receiptParams = useSelector(state => state.tickTable.receiptParams);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.left}>
        <span className={styles.title}>{t("t_consignmentReceipt")}</span>
      </div>
      <div className={styles.middle}>
        <PaginationBar dispatchParams={setReceiptParams} params={receiptParams} />
      </div>
      <div className={styles.right}>
        {/* <IconWithTextButton type="primary" onBtnClick={() => { setShowMasterModal(true); setButtonType('add'); }}>
          <span>
            <PlusOutlined style={{ pointerEvents: "cursor" }} />
            {t("t_add")}
          </span>
        </IconWithTextButton> */}
        <ReloadOutlined className={styles.reloadIcon} onClick={reload} />
      </div>
    </>
  );
};

export default memo(TitleBarReceiptPaginationContent);
