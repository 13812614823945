import { useTranslation } from "react-i18next";
import { TickLocalModal } from "./TickLocalModal";
import styles from "./consignmentModalV3.module.scss";
import { Form, Input, DatePicker, Select  } from "antd";
import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonSelection from "../components/commonSelection/CommonSelection";
import MasterApi from "../service/masterApi/masterApi";
import { closeModal, showModal } from "../redux/tickModalSilce";
import dayjs from 'dayjs';
import ConsignmentContractApi from "../service/consignmentContractApi/consignmentContractApi";
import { CONTRACT_STATUS } from "../utils/contractStatusEnum";


const ContractEditModal = (props) => {
  const { showMasterModal, onSubmit, onCancel, setShowMasterModal, subCatOptions, buttonType, width, modalData, listByPage } = props;
  const { TextArea } = Input;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const tickTableState = useSelector((state) => state.tickTable)
  const masterParams = useSelector((state) => state.tickTable.masterParams)
  const dispatch = useDispatch()


  useEffect(() => {
    if (tickTableState?.consignment?.rowData?.consignor != {}) {
      form.setFieldsValue(tickTableState.consignment.rowData.consignor)
    }
  }, [tickTableState.consignment.rowData])

  useEffect(() => {
    if (buttonType === 'add') {
      console.log("THIS IS ADD");
    } else {
      console.log("THIS IS UPDATE");
      console.log(modalData);
      form.setFieldsValue({
        status: modalData.status,
        contractDate: dayjs(modalData.contractDate),
        remark: modalData.remark,
      })
    }
  }, [modalData])

  const onCloseModal = () => {
    form.resetFields();
    setShowMasterModal(false);
  };

  const onHandleSubmit = async (e) => {
    if (buttonType === 'add') {
      // console.log("THIS IS SUBMIT WITH ADD");
      const result = form.getFieldsValue()
      // await MasterApi.save(masterParams.type, result).then((res) => {
      //   setShowMasterModal(false);
      //   listByPage();
      //   if (res.success) {
      //     dispatch(
      //       showModal({
      //         key: "success",
      //         header: "Add successfully",
      //         callback: () => {
      //           dispatch(closeModal());
      //         },
      //       })
      //     );
      //   } else {
      //     dispatch(
      //       showModal({
      //         key: "error",
      //         header: "This is an error message",
      //         msg: res.msg,
      //         callback: () => {
      //           dispatch(closeModal());
      //         },
      //       })
      //     );
      //   }
      // })
    } else {
      // console.log("THIS IS SUBMIT WITH UPDATE");
      const result = form.getFieldsValue()
      await ConsignmentContractApi.update(modalData.id, result).then((res) => {
        console.log(res);
        setShowMasterModal(false);
        listByPage();
        if (res.success) {
          dispatch(
            showModal({
              key: "success",
              header: "Update successfully",
              callback: () => {
                dispatch(closeModal());
              },
            })
          );
        } else {
          dispatch(
            showModal({
              key: "error",
              header: "This is an error message",
              msg: res.msg,
              callback: () => {
                dispatch(closeModal());
              },
            })
          );
        }
      })
    }
  };

  return (
    <TickLocalModal
      onSubmit={onHandleSubmit}
      showLocalModal={showMasterModal}
      onCancel={onCloseModal}
      title={t("t_edit")}
      form={form}
      width={width}
      setVisible={setShowMasterModal}
    >
      <div className={styles.drawerContainer}>
        <div className={styles.border} />
        <Form
          layout={'vertical'}
          form={form}
        >
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div style={{ width: "100%", margin: "10px" }}>
              <Form.Item
                label={t("t_status")}
                name="status"
                rules={[{ required: true }]}
              >
                <Select
                  // defaultValue="lucy"
                  // style={{ width: 120 }}
                  // onChange={handleChange}
                  placeholder={t("t_pleaseSelect")}
                  options={Object.keys(CONTRACT_STATUS).map(status => {
                    return { 
                      label: t(CONTRACT_STATUS[status].label),
                      value: status
                    }
                  })}
                />
              </Form.Item>
              <Form.Item
                label={t("t_contractDate")}
                name="contractDate"
                rules={[{ required: true }]}
              >
                <DatePicker
                  name="nameEn"
                  placeholder={t("t_pleaseEnter")}
                  style={{ width: "100%"}}
                />
              </Form.Item>
              <Form.Item
                label={t("t_remark")}
                name="remark"
                >
                <TextArea
                  name="remark"
                  placeholder={t("t_pleaseEnter")}
                  rows={4}
                />
              </Form.Item>
            </div>
          </div>
        </Form >
      </div>
      {/* </div> */}
    </TickLocalModal>
  );
};

export default memo(ContractEditModal)