import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined, ReloadOutlined, DownloadOutlined, UploadOutlined, PrinterOutlined } from "@ant-design/icons";
import PaginationBar from "../paginationBar/PaginationBar";
import styles from "./titleBarItemPaginationContent.module.scss";
import IconWithTextButton from "../iconWithTextButton/IconWithTextButton";
import { useSelector } from "react-redux";
import { setItemParams } from "../../redux/tickTableSlice";
import ItemExportModal from "../../modals/ItemExportModal";
import { Popconfirm, Button, Grid, Dropdown } from 'antd';
import ItemApi from "../../service/itemApi/itemApi";
import CommonPermissionButton from "../tickPermissionButton/CommonPermissionButton";

const { useBreakpoint } = Grid;

const TitleBarItemPaginationContent = (props) => {
  const { setShowProductModal, setBtn, reload, setShowImportModal, onBatchReprintClick } = props;
  const itemTableParams = useSelector(state => state.tickTable.itemParams);
  const totalCount = useSelector(state => state.tickTable.itemTableData.totalCount);
  const [exportLoading, setExportLoading] = useState(false);
  const [isShowExportModal, setIsShowExportModal] = useState(false);
  const permissions = JSON.parse(localStorage.getItem('PERMISSION'))

  const { t } = useTranslation();
  const screens = useBreakpoint();

  const onExportClick = () => {
    setExportLoading(true)

    ItemApi.export(itemTableParams)
      .then((res) => {
        let blob = new Blob([res.data], { type: res.contentType });
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = blobURL;
        tempLink.setAttribute(
          "download",
          decodeURI(
            res.contentDisposition.split(";")[1].split("=")[1]
          )
        );
        if (typeof tempLink.download === "undefined") {
          tempLink.setAttribute("target", "_blank");
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
      })
      .finally(() => {
        setExportLoading(false)
      });
  };

  const renderOptions = (isVertical = false) => {
    return (
      <div style={{display: "flex", flexDirection: isVertical? "column" : "row"}}>
        {/* <Popconfirm title={totalCount ? `Sure to export? (${totalCount} items, may take a while...)` : `Sure to export?`} onConfirm={onExportClick} disabled={totalCount < 500 || !(permissions.findIndex((item) => item === "item:export") > -1)}>
          <Button className={styles.button} type="default" loading={exportLoading} onClick={totalCount < 500 ? onExportClick : null} disabled={!(permissions.findIndex((item) => item === "item:export") > -1)}>
            {t("t_export")}
          </Button>
        </Popconfirm> */}

        <Popconfirm title={t("t_promptBoxNeedPrintTwice")} onConfirm={() => { onBatchReprintClick(true); }} onCancel={() => { onBatchReprintClick(false); }}>
          <Button className={styles.button} type="default" 
            // onClick={onBatchReprintClick} 
            // hidden={!itemTableParams.isReprintTag} disabled={!itemTableParams.isReprintTag}
          >
            <span>
              <PrinterOutlined style={{ marginRight: '4px' }} />
              {t("t_batchReprint")}
            </span>
          </Button>
        </Popconfirm>
        <Button className={styles.button} type="default" loading={exportLoading} onClick={() => setIsShowExportModal(true)} disabled={!(permissions.findIndex((item) => item === "item:export") > -1)}>
          <span>
            <DownloadOutlined style={{ marginRight: '4px' }} />
            {t("t_export")}
          </span>
        </Button>
        <IconWithTextButton  onBtnClick={() => { setShowImportModal(true) }}>
          <span>
            <UploadOutlined style={{ marginRight: '4px' }} />
            {t("t_import")}
          </span>
        </IconWithTextButton>
        <IconWithTextButton type="primary" permission="item:add" onBtnClick={() => { setShowProductModal(true); setBtn("add") }}>
          <span>
            <PlusOutlined style={{ marginRight: '4px' }} />
            {t("t_addItem")}
          </span>
        </IconWithTextButton>
      </div>
    )
  };

  const items = [
    {
      label: renderOptions(true),
      key: '1',
    },
  ];

  return (
    <>
      <div className={styles.left}>
        <span className={styles.title}>{t("t_itemList")}</span>
      </div>
      <div className={styles.middle}>
        <PaginationBar dispatchParams={setItemParams} params={itemTableParams} />
      </div>
      <div className={styles.right}>
        {
          screens.lg ?
            renderOptions()
            : 
            <Dropdown
              menu={{ items }} 
              children={<div>{t("t_menu")}</div>}
              trigger={['click']}
              className={styles.cmDropdown} 
              overlayClassName={styles.cmMenu}
            />
        }
        <ReloadOutlined className={styles.reloadIcon} onClick={reload} />
      </div>
      {isShowExportModal &&
        <ItemExportModal
          isShowExportModal={isShowExportModal}
          setIsShowExportModal={setIsShowExportModal}
          setExportLoading={setExportLoading}
          width="500px"
        />}
    </>
  );
};

export default memo(TitleBarItemPaginationContent);
