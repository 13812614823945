import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { ReloadOutlined, PlusOutlined } from "@ant-design/icons";
import PaginationBar from "../paginationBar/PaginationBar";
import styles from "./titleBarSettingPaginationContent.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { setSettingParams } from "../../redux/tickTableSlice";
import { Select } from 'antd';
import IconWithTextButton from "../iconWithTextButton/IconWithTextButton";

const TitleBarSettingPaginationContent = (props) => {
  const { setShowRoleModal, setButtonType, reload, activeTab, setShowPermissionModal, setShowAccountModal } = props;
  const settingParams = useSelector(state => state.tickTable.settingParams);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tabWithoutAddButtons = [1, 4];

  return (
    <>
      <div className={styles.left}>
        <span className={styles.title}>{t("t_setting")}</span>
      </div>
      <div className={styles.middle}>
        <PaginationBar dispatchParams={setSettingParams} params={settingParams} />
      </div>
      <div className={styles.right}>
        {tabWithoutAddButtons.includes(activeTab) ? null :
          <IconWithTextButton type="primary" onBtnClick={() => {
            // if (activeTab === 1) {setShowAccountModal(true)}
            if (activeTab === 2) {setShowRoleModal(true)}
            if (activeTab === 3) {setShowPermissionModal(true)}
            setButtonType('add'); 
          }}>
            <span>
              <PlusOutlined style={{ pointerEvents: "cursor" }} />
              {t("t_add")}
            </span>
          </IconWithTextButton>
        }
        <ReloadOutlined className={styles.reloadIcon} onClick={reload} />
      </div>
    </>
  );
};

export default memo(TitleBarSettingPaginationContent);
