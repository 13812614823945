import { useTranslation } from "react-i18next";
import { TickLocalModal } from "./TickLocalModal";
import styles from "./consignmentModalV3.module.scss";
import { Form, Checkbox, Button } from "antd";
import React, { useState, memo } from "react";
import ItemApi from "../service/itemApi/itemApi";
import { useSelector } from "react-redux";
import i18n from "../i18n";

const ItemExportModal = (props) => {
  const { isShowExportModal, setIsShowExportModal, width, setExportLoading } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const CheckboxGroup = Checkbox.Group;
  const itemTableParams = useSelector(state => state.tickTable.itemParams);
  const totalCount = useSelector(state => state.tickTable.itemTableData.totalCount);
  const [language, setLanguage] = useState(i18n.language);
  const plainOptions = [
    { label: t("t_itemNum"), value: 'itemNum', disabled: true },
    { label: t("t_brand"), value: 'brand' },
    { label: t("t_productName"), value: 'itemName' },
    { label: t("t_color"), value: 'color' },
    { label: t("t_status"), value: 'status' },
    { label: t("t_stockLocation"), value: 'stockLocation' },
    { label: t("t_transactionType"), value: 'transactionType' },
    { label: t("t_transactionDate"), value: 'transactionDate' },
    { label: t("t_category"), value: 'category' },
    { label: t("t_stock"), value: 'stock' },
    { label: t("t_price"), value: 'price' },
    { label: t("t_onSalesPrice"), value: 'onSalesPrice' },
    { label: t("t_handlingFee"), value: 'handlingFee' },
    { label: t("t_model"), value: 'model' },
    { label: t("t_size"), value: 'size' },
    { label: t("t_accessory"), value: 'accessory' },
    { label: t("t_material"), value: 'material' },
    { label: t("t_rating"), value: 'rating' },
    { label: t("t_origin"), value: 'origin' },
    { label: t("t_cortex"), value: 'cortex' },
    { label: t("t_mark"), value: 'mark' },
    { label: t("t_year"), value: 'year' },
    { label: t("t_remark"), value: 'remark' },
    { label: t("t_itemNameInternal"), value: 'itemNameInternal' },
    { label: t("t_colorInternal"), value: 'colorInternal' },
    { label: t("t_consignorNum"), value: 'consignorNum' },
    { label: t("t_consignorFullName"), value: 'consignorFullName' },
    { label: t("t_consignorAbbreviation"), value: 'consignorAbbreviation' },
    { label: t("t_consignorIdCardNum"), value: 'consignorIdCardNum' },
    { label: t("t_consignorBankAccount"), value: 'consignorBankAccount' },
    { label: t("t_consignorPhoneNum"), value: 'consignorPhoneNum' },
    { label: t("t_consignorAddress"), value: 'consignorAddress' },
  ];
  const defaultCheckedList = [
    "itemNum",
    "brand",
    "itemName",
    "color",
    "status",
    "stockLocation",
    "transactionType",
    "transactionDate",
    "category",
    "stock",
    "price",
    "onSalesPrice",
    "handlingFee",
    "model",
    "size",
    "accessory",
    "material",
    "rating",
    "origin",
    "cortex",
    "mark",
    "year",
    "remark",
    "itemNameInternal",
    "colorInternal",
    "consignorNum",
    "consignorFullName",
    "consignorAbbreviation",
    "consignorIdCardNum",
    "consignorBankAccount",
    "consignorPhoneNum",
    "consignorAddress",
  ];
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const onCloseModal = () => {
    form.resetFields();
    setIsShowExportModal(false);
  };

  const onHandleSubmit = async (e) => {
    setExportLoading(true);
    setIsShowExportModal(false);
    let params = {...itemTableParams, columns: checkedList.join(",")};

    ItemApi.export(params)
      .then((res) => {
        let blob = new Blob([res.data], { type: res.contentType });
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = blobURL;
        tempLink.setAttribute(
          "download",
          decodeURI(
            res.contentDisposition.split(";")[1].split("=")[1]
          )
        );
        if (typeof tempLink.download === "undefined") {
          tempLink.setAttribute("target", "_blank");
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
      })
      .finally(() => {
        setExportLoading(false);
      });
  };

  const onChange = (list) => {
    setCheckedList(list);
  };

  return (
    <TickLocalModal
      onSubmit={onHandleSubmit}
      showLocalModal={isShowExportModal}
      onCancel={onCloseModal}
      title={t("t_exportSettings")}
      form={form}
      width={width}
      setVisible={setIsShowExportModal}
      okText={t("t_export")}
    >
      <div className={styles.drawerContainer}>
        <div className={styles.border} />
        <Form
          layout={'vertical'}
          form={form}
        >
          <div style={{ marginBottom: '20px' }}>
            <Button style={{ marginRight: '10px'}} onClick={() => setCheckedList(defaultCheckedList)}>{t("t_checkAll")}</Button>
            <Button onClick={() => setCheckedList(["itemNum"])}>{t("t_uncheckAll")}</Button>
          </div>
          <div className={styles.checkboxContainer}>
            <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
          </div>
          {totalCount > 500 &&
            <b style={{ display: 'flex', justifyContent: 'space-around', color: 'red'}}>
              {language === 'en' ? 
                `***${totalCount} items will be export (may take a while)***` :
                `***${totalCount}個項目將會匯出，此動作需時***`
              }
            </b>
          }
        </Form >
      </div>
    </TickLocalModal>
  );
};

export default memo(ItemExportModal)