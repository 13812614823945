import React, { memo, useEffect, useState } from "react";
import TitleBar from "../../components/titleBar/TitleBar";
import variables from "../../theme/variable.scss";
import { EditOutlined, DeleteOutlined, SecurityScanOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import styles from './setting.module.scss';
import { useDispatch, useSelector } from "react-redux"
import { closeModal, showModal } from "../../redux/tickModalSilce";
import { setSettingParams, setSettingTableData } from "../../redux/tickTableSlice";
import TickTable from "../../components/tickTable/TickTable";
import i18n from '../../i18n';
import { Tabs, Switch, Tag, Form, Select } from "antd";
import SystemUserApi from "../../service/systemApi/systemUserApi";
import SystemRoleApi from "../../service/systemApi/systemRoleApi";
import TitleBarSettingPaginationContent from "../../components/titleBarSettingPaginationContent/TitleBarSettingPaginationContent";
import SystemPermissionApi from "../../service/systemApi/systemPermissionApi";
import TickDrawer from "../../components/tickDrawer/TickDrawer"
import UpdatePermission from "../../components/updatePermission/UpdatePermission";
import RoleModal from "../../modals/RoleModal";
import PermissionModal from "../../modals/PermissionModal";
import AccountModal from "../../modals/AccountModal";
import printerApi from "../../service/systemApi/printerApi";
import { printer } from "../../config/envConfig";

const Setting = (props) => {
  const {  } = props
  const [dataSource, setDataSource] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [modalData, setModalData] = useState([]);
  const [itemCats, setItemCats] = useState([]);
  const [masterTableLoading, setMasterTableLoading] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [buttonType, setButtonType] = useState('add');
  const [language, setLanguage] = useState(i18n.language);
  const [tableLoading, setTableLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerId, setDrawerId] = useState(0);
  
  // Gary
  const [form] = Form.useForm(); // Other settings form
  const [printerOptions, setPrinterOptions] = useState([]);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const settingParams = useSelector(state => state.tickTable.settingParams);

  const tableTabs = [1, 2, 3];

  useEffect(() => {
    if (tableTabs.includes(activeTab)) getTableData()
    else { 
      getPrinterOptions();
    }
  }, [settingParams, activeTab])

  const getTableData = async () => {
    try {
      setTableLoading(true);
      let resp
      switch (activeTab) {
        case 1:
          resp = await SystemUserApi.listByPage({pageNum: settingParams.pageNum, pageSize: settingParams.pageSize, input: settingParams.input});
          break;
        case 2:
          resp = await SystemRoleApi.listByPage({pageNum: settingParams.pageNum, pageSize: settingParams.pageSize, input: settingParams.input});
          break;
        case 3:
          resp = await SystemPermissionApi.listByPage({pageNum: settingParams.pageNum, pageSize: settingParams.pageSize, input: settingParams.input});
          break;
      }
      setDataSource(resp.data.array);
      dispatch(
        setSettingTableData({ totalCount: resp.data.totalCount })
      )
    } catch (e) {
      console.error(e);
    } finally {
      setTableLoading(false);
    }
  }

  const getPrinterOptions = async () => {
    try {
      const resp = await printerApi.getPrinters();
      if (resp.success) {
        console.log(resp.data);
        let printers = [];
        for (let printer of resp.data) {
          printers.push({ label: printer.printerDesc, value: printer.printerId })
        }
        setPrinterOptions(printers);
      } else {
        throw resp.message
      }
    } catch (e) {
      console.error(e)
    }
  }

  const onReload = () => {
    dispatch(setSettingParams({...settingParams, sortOptions: {}, input: '', pageNum: 1 }));
  }
  

  const handleSortKey = (sortOrder) => {
    let sortKey;
    if (sortOrder === 'ascend') {
      sortKey = 'ASC'
    }

    if (sortOrder === 'descend') {
      sortKey = 'DESC'
    }
    return sortKey;
  }
  
  const onChange = (pagination, filter, sorter) => {
    let sortOptions = {};
    if (Array.isArray(sorter)) {
      sorter.forEach(sortEle => {
        sortOptions[sortEle.columnKey] = handleSortKey(sortEle.order);
      })
    } else {
      sortOptions[sorter.columnKey] = handleSortKey(sorter.order);
    }
    
    if (!sorter.order) {
      delete sortOptions[sorter.columnKey]
    }

    dispatch(setSettingParams({
      ...settingParams,
      sortOptions,
    }))
  }

  const sortOrder  = (key) =>  {
    if (settingParams.sortOptions[key] === 'ASC') {
      return 'ascend';
    }
    if (settingParams.sortOptions[key] === 'DESC') {
      return 'descend';
    }
    return undefined;
  }
  
  const onFieldsChange = (changedFields, allFields) => {
    console.log(changedFields)
    const ls = JSON.parse(localStorage.getItem("settings"));
    for (let field of changedFields) {
      if (field.name.length === 1) {
        ls[field.name[0]] = field.value;
      } else {
        ls[field.name[field.name.length - 1]] = field.value;
      }
    }
    localStorage.setItem("settings", JSON.stringify(ls));
  }

  const columns = {
    systemAccountColumn: [
      // {
      //   title: 'Active',
      //   dataIndex: 'active',
      //   key: 'active',
      //   render: (_, record) => {
      //     return <Switch checked={record.active} onChange={(e) => {}} />
      //   },
      //   // width: 100,
      // },
      {
        title: t("t_username"),
        dataIndex: 'username',
        key: 'username',
        // width: 150,
        render: (_) => (
          <div>{_ || "-"}</div>
        )
      },
      {
        title: t("t_name"),
        dataIndex: 'name',
        key: 'name',
        // width: 150,
        render: (_) => (
          <div>{_ || "-"}</div>
        )
      },
      {
        title: t("t_role"),
        key: 'role',
        dataIndex: 'role',
        render: (_, record) => {
          return <div>{record.roleName ? <Tag color="green">{record.roleName}</Tag> : '-'}</div>
        },
        // width: 200,
      },
      // {
      //   title: t("t_function"),
      //   key: 'actions',
      //   dataIndex: 'actions',
      //   render: (_,
      //     record
      //   ) =>
      //     <>
      //       <EditOutlined style={{ color: variables.themeBlue, padding: "5px" }} onClick={() => onAccountEdit(record)}/>
      //       <DeleteOutlined style={{ color: variables.themeRed, padding: "5px" }} onClick={() => onAccountDelete(record)}/>
      //     </>
      //   // width: 200,
      // }
    ],
    roleColumn: [
      {
        title: t("t_roleName"),
        dataIndex: 'name',
        key: 'name',
        // width: 100,
        render: (_) => (
          <div>{_ || "-"}</div>
        )
      },
      {
        title: t("t_remark"),
        dataIndex: 'remark',
        key: 'remark',
        // width: 100,
        render: (_) => (
          <div>{_ || "-"}</div>
        )
      },
      {
        title: t("t_sort"),
        dataIndex: 'ordinal',
        key: 'ordinal',
        // width: 100,
        render: (_) => (
          <div>{_ || "-"}</div>
        )
      },
      {
        title: t("t_status"),
        dataIndex: 'active',
        key: 'active',
        fixed: "right",
        width: 20,
        render: (_, record) => {
          return <Switch checked={record.active === 1} onChange={(e) => {roleActiveOnChange(record, e)}} />
        },
      },
      {
        title: t("t_function"),
        dataIndex: 'action',
        key: 'action',
        fixed: "right",
        width: 60,
        render: (_,
          record
        ) =>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            {/* <EditOutlined style={{ color: variables.themeBlue, padding: "5px" }} onClick={() => onRoleEdit(record)}/> */}
            <EditOutlined style={{ color: variables.themeBlue, fontSize: "24px" }} onClick={() => onRoleEdit(record)}/>
            {/* <SecurityScanOutlined style={{ color: variables.themeRed, padding: "5px" }} onClick={() => onPermissionChange(record)}/> */}
            <SecurityScanOutlined style={{ color: variables.themeRed, fontSize: "24px" }} onClick={() => onPermissionChange(record)}/>
            {/* <DeleteOutlined style={{ color: variables.themeRed, padding: "5px" }} onClick={() => onRoleDelete(record)}/> */}
            <DeleteOutlined style={{ color: variables.themeRed, fontSize: "24px" }} onClick={() => onRoleDelete(record)}/>
          </div>
        // width: 100,
      },
    ],
    permissionColumn: [
      {
        title: t("t_name"),
        dataIndex: 'name',
        key: 'name',
        // width: 100,
        render: (_) => (
          <div>{_ || "-"}</div>
        )
      },
      {
        title: t("t_remark"),
        dataIndex: 'remarks',
        key: 'remarks',
        // width: 100,
        render: (_) => (
          <div>{_ || "-"}</div>
        )
      },
      {
        title: t("t_status"),
        dataIndex: 'active',
        key: 'active',
        fixed: "right",
        width: 20,
        render: (_, record) => {
          return <Switch checked={record.active === 1} onChange={(e) => {permissionActiveOnChange(record, e)}} />
        },
      },
      {
        title: t("t_function"),
        dataIndex: 'action',
        key: 'action',
        fixed: "right",
        width: 30,
        render: (_, record) => (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            {/* <EditOutlined style={{ color: variables.themeBlue, padding: "5px" }} onClick={() => onPermissionEdit(record)}/> */}
            <EditOutlined style={{ color: variables.themeBlue, fontSize: "24px" }} onClick={() => onPermissionEdit(record)}/>
            {/* <DeleteOutlined style={{ color: variables.themeRed, padding: "5px" }} onClick={() => onPermissionDelete(record)}/> */}
            <DeleteOutlined style={{ color: variables.themeRed, fontSize: "24px" }} onClick={() => onPermissionDelete(record)}/>
          </div>
        ),
        // width: 100,
      },
    ]
  }
  
  const tabs = [
    {
      id: 1,
      title: t("t_systemAccount"),
      element: 
        <TickTable
          dataSource={dataSource}
          columns={columns.systemAccountColumn}
          rowKey={(record) => record.id}
          loading={tableLoading}
        /> 
    },
    {
      id: 2,
      title: t("t_role"),
      element: 
        <TickTable
          dataSource={dataSource}
          columns={columns.roleColumn}
          rowKey={(record) => record.id}
          loading={tableLoading}
        /> 
    },
    {
      id: 3,
      title: t("t_permission"),
      element: 
        <TickTable
          dataSource={dataSource}
          columns={columns.permissionColumn}
          rowKey={(record) => record.id}
          loading={tableLoading}
        /> 
    },
    {
      id: 4,
      title: t("t_other"),
      element:
        <>
          <div className={styles.title}>{t("t_otherSettings")}</div>
          <div className={styles.border} />
          <div className={styles.otherSettingsContainer}>
            <Form
              form={form}
              onFieldsChange={onFieldsChange}
            >
              <Form.Item
                label={t("t_tagPrinterSetting")}
                name={"printerId"}
                rules={[{ required: true }]}
                initialValue={
                  JSON.parse(localStorage.getItem("settings"))?.printerId || 1
                }
              >
                <Select
                  placeholder={t("t_pleaseSelect")}
                  options={printerOptions}
                />
              </Form.Item>
            </Form>
          </div>
        </>
    }
  ]

  const onAccountEdit = (record) => {
    setShowAccountModal(true);
    setModalData(record);
    setButtonType("update");
  }

  const onAccountDelete = (record) => {
    dispatch(
      showModal({
        key: "warning",
        header: t("t_delete"),
        msg: t("t_confirmDelete"),
        showCloseBtn: true,
        callback: async () => {
          SystemRoleApi.delete(record.id).then(() => {
            getTableData();
            dispatch(
              showModal({
                key: "success",
                header: "Delete successfully",
                callback: () => {
                  dispatch(closeModal());
                },
              })
            );
          })
        },
      })
    );
  }

  const roleActiveOnChange = async (record, e) => {
    try {
      setTableLoading(true);
      await SystemRoleApi.activeOnChange(record.id).then(() => {
        dispatch(
          showModal({
            key: "success",
            header: "Status changed successfully",
            callback: () => {
              dispatch(closeModal());
            },
          })
        );
      })
    } catch (e) {
    } finally {
      setTableLoading(false);
      getTableData()
    }
  }

  const onRoleEdit = (record) => {
    setShowRoleModal(true);
    setModalData(record);
    setButtonType("update");
  }

  const onPermissionChange = (record) => {
    setDrawerId(record.id);
    setIsDrawerOpen(true);
  }
  
  const onRoleDelete = (record) => {
    dispatch(
      showModal({
        key: "warning",
        header: t("t_delete"),
        msg: t("t_confirmDelete"),
        showCloseBtn: true,
        callback: async () => {
          SystemRoleApi.delete(record.id).then(() => {
            getTableData();
            dispatch(
              showModal({
                key: "success",
                header: "Delete successfully",
                callback: () => {
                  dispatch(closeModal());
                },
              })
            );
          })
        },
      })
    );
  }


  const onPermissionEdit = (record) => {
    setShowPermissionModal(true);
    setModalData(record);
    setButtonType("update");
  }

  const onPermissionDelete = (record) => {
    dispatch(
      showModal({
        key: "warning",
        header: t("t_delete"),
        msg: t("t_confirmDelete"),
        showCloseBtn: true,
        callback: async () => {
          SystemPermissionApi.delete(record.id).then(() => {
            getTableData();
            dispatch(
              showModal({
                key: "success",
                header: "Delete successfully",
                callback: () => {
                  dispatch(closeModal());
                },
              })
            );
          })
        },
      })
    );
  }

  const permissionActiveOnChange = async (record, e) => {
    try {
      setTableLoading(true);
      await SystemPermissionApi.activeOnChange(record.id).then(() => {
        dispatch(
          showModal({
            key: "success",
            header: "Status changed successfully",
            callback: () => {
              dispatch(closeModal());
            },
          })
        );
      })
    } catch (e) {
    } finally {
      setTableLoading(false);
      getTableData()
    }
  }

  return (
    <>
      <TitleBar hasSideBar hasShadow color={variables.themeWhite}>
        <TitleBarSettingPaginationContent
          reload={onReload}
          setShowRoleModal={setShowRoleModal}
          setShowPermissionModal={setShowPermissionModal}
          setShowAccountModal={setShowAccountModal}
          setButtonType={setButtonType}
          activeTab={activeTab}
        />
      </TitleBar>
      <div className={styles.container}>
        <div className={styles.tableWrapper}>
          <Tabs
            className={styles.miniBoxTabContainer}
            defaultActiveKey="1"
            tabPosition={"left"}
            tabBarStyle={{ width: "200px", marginTop: "16px"}}
            items={tabs.map((item) => {
              return {
                label: item.title,
                key: item.id,
                children: item.element
              };
            })}
            onTabClick={(e) => {
              setActiveTab(e);
              console.log(e);
              if (e !== 2 ) {
                setIsDrawerOpen(false);
              }
            }}
            // onChange={onChange}
          />
        </div>
          <TickDrawer title={t("t_updatePermission")} open={isDrawerOpen} setOpen={setIsDrawerOpen}>
            <UpdatePermission roleId={drawerId} activeTab={activeTab}/>
          </TickDrawer>
      </div>
      {showAccountModal &&
        <AccountModal
          showMasterModal={showAccountModal}
          setShowModal={setShowAccountModal}
          width="500px"
          activeTab={activeTab}
          modalData={modalData}
          buttonType={buttonType}
          listByPage={getTableData}
        />}
      {showRoleModal &&
        <RoleModal
          showMasterModal={showRoleModal}
          setShowModal={setShowRoleModal}
          width="500px"
          activeTab={activeTab}
          modalData={modalData}
          buttonType={buttonType}
          listByPage={getTableData}
        />}
      {showPermissionModal &&
        <PermissionModal
          showMasterModal={showPermissionModal}
          setShowModal={setShowPermissionModal}
          width="500px"
          activeTab={activeTab}
          modalData={modalData}
          buttonType={buttonType}
          listByPage={getTableData}
        />}
    </>
  );
};

export default memo(Setting);
