import React, { useEffect, useImperativeHandle, forwardRef, useState } from "react";
import styles from './tickContractInfo.module.scss';
import { Input, DatePicker, Form, Radio, Space } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import ContractItemDetail from "../contractItemDetail/ContractItemDetail";
import CommonSelection from "../commonSelection/CommonSelection";

const TickContractInfo = forwardRef((props, ref) => {
  const [form] = Form.useForm()
  const { selectedItemList, isReadOnly, itemDetails, createType, setReceiptType, receiptType } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    handleSave() {
      return form.getFieldsValue()
    }
  }));

  useEffect(() => {
    if (itemDetails) {
      form.setFieldsValue({
        contractDate: dayjs(itemDetails.contractDate),
        remark: itemDetails.remark,
        paymentMethodId: itemDetails.paymentMethodId,
        paymentReference: itemDetails.paymentReference,
        receiptType: itemDetails.receiptType
      })
    }
  }, [itemDetails])

  const onChange = (e) => {
    setReceiptType(e.target.value);
  };

  return (
    <div className={styles.container}>
      {
        selectedItemList?.map((item, index) => {
          return (
            <ContractItemDetail key={index} item={item} isReadOnly={isReadOnly} receiptType={receiptType} createType={createType}/>
          )
        })
      }
      <div className={styles.form}>
        <Form
          form={form}
          // onFinish={onFinish}
        >
          {createType === "contract" &&
            <>
              <Form.Item
                label={t("t_contractDate")}
                name="contractDate"
                initialValue={dayjs()}
                rules={[{required: true}]}
              >
                <DatePicker disabled={isReadOnly}/>
              </Form.Item>
              <Form.Item
                label={t("t_remark")}
                name="remark"
              >
                <Input.TextArea rows={3} disabled={isReadOnly}/>
              </Form.Item>
            </>
          }
          {createType === "receipt" &&
            <>
              <Form.Item 
                label={t("t_receiptType")}
                name="receiptType" 
              >
                <Radio.Group defaultValue={"directIn"} onChange={onChange} disabled={isReadOnly}>
                  <Space>
                    <Radio value={"directIn"}>{t("t_directIn")}</Radio>
                    <Radio value={"sold"}>{t("t_normalSold")}</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label={t("t_paymentMethod")}
                name="paymentMethodId"
              >
                <CommonSelection optionKey="paymentCat" disabled={isReadOnly}/>
              </Form.Item>
              <Form.Item
                label={t("t_paymentReference")}
                name="paymentReference"
                >
                <Input
                  name="paymentReference"
                  placeholder={t("t_pleaseEnter")}
                  disabled={isReadOnly}
                />
              </Form.Item>
            </>
          }
        </Form>
      </div>
    </div>
  );
})

export default TickContractInfo;
