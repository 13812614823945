import React from 'react'
import { TickGlobalModal } from './TickGlobalModal'
import styles from './modal.module.scss'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import deleteIcon from "../assets/img/icon/modalDeleteIcon.svg";
import infoIcon from "../assets/img/icon/modalInfoIcon.svg";
import warningIcon from "../assets/img/icon/modalWarningIcon.svg";

export const TickConfirmModal = () => {
    const tickGlobalModalState = useSelector((state) => state.tickModal);
    
    const { t } = useTranslation();
    
    const icons = {
      info: infoIcon,
      warning: warningIcon,
      delete: deleteIcon,
    }

    return (
        <TickGlobalModal 
            header={
                <>
                    <img src={icons[tickGlobalModalState.params.icon]} alt={tickGlobalModalState.icon} className={styles.icon} />
                    {tickGlobalModalState?.params?.header}
                </>
            }
            okText={t("t_confirm")}
            cancelText={t("t_cancel")}
            >
            <div className={styles.content}>{tickGlobalModalState?.params?.msg}</div>
        </TickGlobalModal>
    )
}