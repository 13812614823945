import React, { useEffect, useState, memo } from "react";
import { useLocation, useNavigate } from "react-router";
import lhSidebarLogo from "../../assets/img/logo/lhSideBarLogo.svg";
import variables from "../../theme/variable.scss";
import styles from "./sideBar.module.scss";
import { tickLog } from "../../utils/logger";
import lhSideBarItemActive from "../../assets/img/icon/lhSideBarItemActive.svg";
import lhSideBarItemInactive from "../../assets/img/icon/lhSideBarItemInactive.svg";
import lhSideBarConsignmentActive from "../../assets/img/icon/lhSideBarConsignmentActive.svg";
import lhSideBarConsignmentInactive from "../../assets/img/icon/lhSideBarConsignmentInactive.svg";
import lhSideBarMasterActive from "../../assets/img/icon/lhSideBarMasterActive.svg";
import lhSideBarMasterInactive from "../../assets/img/icon/lhSideBarMasterInactive.svg";
import lhSideBarStatusLogActive from "../../assets/img/icon/lhSideBarStatusLogActive.svg";
import lhSideBarStatusLogInactive from "../../assets/img/icon/lhSideBarStatusLogInactive.svg";
import lhSideBarSettingActive from "../../assets/img/icon/lhSideBarSettingActive.svg";
import lhSideBarSettingInactive from "../../assets/img/icon/lhSideBarSettingInactive.svg";
import lhSideBarContractActive from "../../assets/img/icon/lhSideBarContractActive.svg";
import lhSideBarContractInactive from "../../assets/img/icon/lhSideBarContractInactive.svg";
import lhSideBarReceiptActive from "../../assets/img/icon/lhSideBarReceiptActive.svg";
import lhSideBarReceiptInactive from "../../assets/img/icon/lhSideBarReceiptInactive.svg";
import lhSideBarCouponActive from "../../assets/img/icon/lhSideBarCouponActive.svg";
import lhSideBarCouponInactive from "../../assets/img/icon/lhSideBarCouponInactive.svg";
import lhSideBarMemberActive from "../../assets/img/icon/lhSideBarMemberActive.svg";
import lhSideBarMemberInactive from "../../assets/img/icon/lhSideBarMemberInactive.svg";
import lhSideBarReportActive from "../../assets/img/icon/lhSideBarReportActive.svg";
import lhSideBarReportInactive from "../../assets/img/icon/lhSideBarReportInactive.svg";

import { NavLink } from "react-router-dom";
import { Tooltip } from "antd";
import packageJson from '../../../package.json';
import { useDispatch } from "react-redux";
import { setItemTableData, setMemberTableData } from "../../redux/tickTableSlice";

const SideBar = () => {
  const location = useLocation();

  const [menu, setMenu] = useState([]);
  const navigate = useNavigate();
  let count = 0;

  const dispatch = useDispatch();

  useEffect(() => {
    fetchSideBar();
  }, []);

  const onSideBarItemClick = (index, path) => {
    dispatch(setItemTableData({ totalCount: 0 }))
    dispatch(setMemberTableData({ totalCount: 0}))
    navigate(`${path}`);
  };

  const logoOnClick = () => {
    let triggerTimes = 2;
    //Step 1. Add 1 to counter
    count++;
    //Step 2 if counter == 1, setTimeOut to 4s to clear the counter
    if (count == 1) {
      setTimeout(() => {
        count = 0;
      }, 4000);
    }
    //Step 3. Counter reach 10 times within 4 sec, then go to step 3, else return
    if (count != triggerTimes) {
      if (count > triggerTimes) {
        count = 0;
      }
      return;
    }
    //Step 4. navigate to UnitTestScreen
    navigate("/unitTest");
  };

  const fetchSideBar = async () => {
    const menuValue = JSON.parse(localStorage.getItem("MENU"));
    setMenu(menuValue);
  };

  const menuList = {
    Item: {
      isClicked: lhSideBarItemActive,
      notClicked: lhSideBarItemInactive,
      path: `/main/item`,
    },
    Consignment: {
      isClicked: lhSideBarConsignmentActive,
      notClicked: lhSideBarConsignmentInactive,
      path: `/main/consignment`,
    },
    Member: {
      isClicked: lhSideBarMemberActive,
      notClicked: lhSideBarMemberInactive,
      path: `/main/member`,
    },
    Contract: {
      isClicked: lhSideBarContractActive,
      notClicked: lhSideBarContractInactive,
      path: `/main/contract`,
    },
    Receipt: {
      isClicked: lhSideBarReceiptActive,
      notClicked: lhSideBarReceiptInactive,
      path: `/main/receipt`,
    },
    Coupon: {
      isClicked: lhSideBarCouponActive,
      notClicked: lhSideBarCouponInactive,
      path: `/main/coupon`,
    },
    Master: {
      isClicked: lhSideBarMasterActive,
      notClicked: lhSideBarMasterInactive,
      path: `/main/master`,
    },
    StatusLog: {
      isClicked: lhSideBarStatusLogActive,
      notClicked: lhSideBarStatusLogInactive,
      path: `/main/statusLog`,
    },
    Setting: {
      isClicked: lhSideBarSettingActive,
      notClicked: lhSideBarSettingInactive,
      path: `/main/setting`,
    },
    Report: {
      isClicked: lhSideBarReportActive,
      notClicked: lhSideBarReportInactive,
      path: `/main/report`,
    },
  };

  return (
    <div className={styles.sideBar}>
      <img
        src={lhSidebarLogo}
        alt="side bar logo"
        className={styles.sideBarLogo}
        onClick={logoOnClick}
      />
      {menu?.map((item, index) => {
        return (
          <NavLink to={item?.path} key={`side_bar_each_btn_${index}`}>
            <div
              className={styles.sideBarItemContainer}
              style={{
                borderLeft:
                  location.pathname.includes(item.path)
                    ? `solid ${variables.themeBlue} 2px`
                    : `solid ${variables.themeWhite} 2px`,
              }}
            >
              <img
                className={styles.sidebarItem}
                src={
                  location.pathname.includes(item.path)
                    ? menuList[`${item.name}`].isClicked
                    : menuList[`${item.name}`].notClicked
                }
                alt={item.path}
                onClick={() => onSideBarItemClick(index, item.path)}
              />
            </div>
          </NavLink>
        );
      })}
      <Tooltip placement="right" title={`V ${packageJson.version}`}>
        <div className={styles.version}>Version</div>
      </Tooltip>
    </div>
  );
};

export default memo(SideBar);
