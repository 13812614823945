import React from 'react'
import { TickGlobalModal } from './TickGlobalModal'
import styles from './modal.module.scss'
import warningIcon from "../assets/img/icon/modalWarningIcon.svg";
import { useSelector } from 'react-redux';

export const TickWarningModal = () => {
  const tickGlobalModalState = useSelector((state) => state.tickModal);
  return (
    <TickGlobalModal
      header={
        <>
          <img src={warningIcon} alt="warning-icon" className={styles.icon} />
          {tickGlobalModalState?.params?.header}
        </>
      }>
      <div className={styles.content}>{tickGlobalModalState?.params?.msg}</div>
    </TickGlobalModal>
  )
}
