import React, { memo, useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import styles from "./contractModal.module.scss"
import TickDrawer from '../../components/tickDrawer/TickDrawer';
import Card from '../../components/card/Card';
import TickDrawerV2 from '../../components/tickDrawerV2/TickDrawerV2';
import sampleImg from "../../assets/img/sample1.png";
import ConsignmentModalV2 from '../ConsignmentModalV2';
import { getItemImgSrc, lang } from '../../config/systemConfig';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import variables from "../../theme/variable.scss"
import { closeModal, showModal } from '../../redux/tickModalSilce';
import { editTempItem, setConsignmentRowData, setShowItemDrawer, setConsignmentSelectedData, clearConsignmentRowData, setConsignStateToInitialState, setConsignmentConsignorToInitialState, setContractParams } from '../../redux/tickTableSlice';
import dayjs from 'dayjs';
import { Button, Image } from "antd";
import TitleBar from '../../components/titleBar/TitleBar'
import { showSubPage, closeSubPage, setConfirmOnClose } from '../../redux/tickSubPageSlice';
import ConsignmentContractApi from '../../service/consignmentContractApi/consignmentContractApi';
import TickContractInfo from '../../components/tickContractInfo/TickContractInfo';
import { tickLog } from '../../utils/logger';
import ItemApi from '../../service/itemApi/itemApi';
import useQuery from '../../hooks/useQuery';
import TitleBarContractInformationContent from '../../components/titleBarContractInformationContent/TitleBarContractInformationContent';
import { STATUS } from '../../utils/statusEnum';

// Clone modal of ConsignmentModal
// Using different item card

const action = "contract";

const ContractModal = (props) => {
    const { 
      showConsignmentModal, 
      setShowConsignmentModal, 
      contents, 
      btn, 
      setBtn,
      setContents,
      modalType, 
      onReload, 
      contract = undefined,
      contractType,
      setContract,
      onSync = () => {},
      onPrint = () => {}
    } = props
    const [showAddModal, setShowAddModal] = useState(false)
    const [tempItemsArray, setTempItemsArray] = useState([]);
    const [itemIndex, setItemIndex] = useState(-1);
    const [item, setItem] = useState({})
    const [selectedItemList, setSelectedItemList] = useState([])
    const [receiptType, setReceiptType] = useState("directIn")
    const [createType, setCreateType] = useState("")

    const childRef = useRef(null);
    const open = useSelector(state => state.tickTable.isItemDrawerOpen);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const tickTableState = useSelector((state) => state.tickTable);
    const rowData = useSelector((state) => state.tickTable.consignment.rowData);
    
    const tempItems = JSON.parse(localStorage.getItem("TempItems"));

    // Component property
    // const consignorId = contract?.consignor?.id || 0;
    const { consignorId, type } = useQuery();
    const { id } = useParams();
    const [contractNum, setContractNum] = useState(contract?.contractNum || undefined);
    const [refresh, setRefresh] = useState(false);

    // Stand for contractType
    const [ct, setCt] = useState();

    const fetchConsignorItems = async () => {
        const response = await ItemApi.listByPage({
            pageNum: 1,
            pageSize: 999,
            // consignorId,
            contractNum,
            sortOptions: {}
        });
        if (response.success) {
            dispatch(setConsignmentRowData(response.data.array))
            setItemIndex(response.data.array.length);
        } else {
            tickLog("Fetching consignor items failed")
        }
    }

    const fetchNextContractNum = async (type) => {
      // type: "consignment" | "purchase"
      const response = await ConsignmentContractApi.nextContractNum(type);
      if (response.success) {
        setContractNum(response.data)
      } else {
        tickLog("fetchNextContractNum", "Error")
      }
      // let alphabet = String.fromCharCode(65 + index);
      // form.setFieldsValue({
      //   itemNum: latestItemNum.data + alphabet
      // })
    } 

    useEffect(() => {
      setOpen(false)
      setSelectedItemList(contract?.items)
      setTempItemsArray(tempItems)

      if (consignorId) setCt(type);
      else setCt(contractType);
    }, [])

    // useEffect(() => {
    //     dispatch(setConsignmentSelectedData(selectedItemList));
    //     if (selectedItemList.length === 0) setOpen(false);
    // }, [selectedItemList])

    useEffect(() => {
      // If no contract type is setting yet (for create mode) & If no contract data (edit mode)
      if (!ct) return;
      if (modalType === "create") {
        fetchNextContractNum(ct)
      }
    }, [ct])

    useEffect(() => {
			if (!contract) return
			if (modalType === "edit") fetchConsignorItems();
    }, [])

    useEffect(() => {
      if (!refresh) return;
      fetchConsignorItems();
      setRefresh(false)
    }, [refresh])

    const onEditTemp = (index) => {
        setShowAddModal(true)
        setBtn("update")
        setItemIndex(index)
    }

    const onDeleteTemp = (itemNum) => {
        dispatch(
            showModal({
                key: "warning",
                header: t("t_delete"),
                msg: t("t_confirmDelete"),
                showCloseBtn: true,
                callback: () => {
                    let storedData = JSON.parse(localStorage.getItem("TempItems"));
                    let deletedArray = storedData.filter(item => item.itemNum !== itemNum)
                    setTempItemsArray(deletedArray)
                    localStorage.setItem("TempItems", JSON.stringify(deletedArray));
                    dispatch(editTempItem(deletedArray))
                    dispatch(closeModal());
                },
            })
        );
    }

    const onEditReal = async (id) => {
        setSelectedItemList([])
        setShowAddModal(true)
        setBtn("update")
        setItemIndex(id)
    }

    const onDeleteReal = async (id) => {
        console.log(tickTableState.consignment.tempItems.filter(item => item.id != id))

        dispatch(
            showModal({
                key: "warning",
                header: t("t_delete"),
                msg: t("t_confirmDelete"),
                showCloseBtn: true,
                callback: async () => {
                    await ItemApi.clearConsignor(id).then(() => {
                        dispatch(editTempItem(tickTableState.consignment.tempItems.filter(item => item.id != id)))
                        dispatch(closeModal());
                    })
                },
            })
        );
    }

    const setOpen = (open = false) => {
        dispatch(setShowItemDrawer(open))
    }

    const selectedItem = (item) => {
        if (selectedItemList.includes(item)) {
            setSelectedItemList((prevSelectedData) => prevSelectedData.filter((selectedItem) => selectedItem !== item))
        } else {
            // if (selectedItemList.length === 3) {
            //     dispatch(
            //         showModal({
            //             key: "warning",
            //             header: "Each contract/receipt can add up to three item",
            //             callback: () => {
            //                 dispatch(closeModal());
            //             },
            //         })
            //     );
            //     return
            // }
            setSelectedItemList((prevSelectedData) => [...prevSelectedData, item])
        }
    }

    const onSaveClick = async () => {
        if (createType === "contract") {
            const contractInfo = childRef.current.handleSave()
            const itemNums = selectedItemList.map(item => item.itemNum);
            let data = {
                itemNums,
                consignorId,
                contractDate: dayjs(contractInfo.contractDate),
                remark: contractInfo.remark
            }
    
            const resp = await ConsignmentContractApi.save(data)
            if (resp.success) {
                setOpen(false)
                setSelectedItemList([])
                dispatch(
                    showModal({
                        key: "success",
                        header: "Create Success",
                        msg: resp.msg,
                        callback: () => {
                            dispatch(closeModal());
                            navigate("/main/contract")
                        },
                    })
                );
            } else {
                // setOpen(false)
                // setSelectedItemList([])
                dispatch(
                    showModal({
                        key: "error",
                        header: "This is an error message",
                        msg: resp.msg,
                        callback: () => {
                            dispatch(closeModal());
                        },
                    })
                );
            }
        }
    }

    const onCancel = () => {
      // If enter the page from /main/consignment?consignorId
      if (consignorId) { navigate(-1) }
      else { setShowConsignmentModal(false); }
      if (id) navigate(-1)
      
      // dispatch(clearConsignmentRowData())
      // setContents([])
      setOpen(false);
      setContract(undefined);
      localStorage.setItem("TempItems", JSON.stringify([]));
      // dispatch(setConsignmentConsignorToInitialState())
      dispatch(setContractParams({ ...tickTableState?.contractParams }));
    };

    // Sub page logic
    // useEffect(() => {
    //   if (showConsignmentModal) {
    //     dispatch(showSubPage({ prevAction, nextAction, confirmOnClose: modalType === "create" && ( tempItems.length > 0 || consignorEdited ) }))
    //   }
    // }, [setShowConsignmentModal])

    // // Extra effect for changing the confirmOnClose
    // useEffect(() => {
    //   if (!tempItems) return;
    //   dispatch(setConfirmOnClose(tempItems.length > 0 || consignorEdited))
    // }, [tempItems, consignorEdited])

    // const prevAction = (hasConfirm = true) => {
    //   if (hasConfirm) {
    //     dispatch( 
    //       showModal({
    //         key: "confirm",
    //         icon: "warning",
    //         header: t("t_warning"),
    //         msg: t("t_promptConfirmLoseProgress"),
    //         showCloseBtn: true,
    //         // showCloseBtn: true,
    //         callback: () => {
    //           dispatch(closeModal());
    //           onCancel();
    //           dispatch(closeSubPage());
    //         },
    //       })
    //     )
    //     if (qs.value?.action === "create") {
    //       navigate(-1)
    //     }
    //   } else {
    //     onCancel();
    //     dispatch(closeSubPage());
    //     if (qs.value?.action === "create") {
    //       navigate(-1)
    //     }
    //   }
    // }

    // const nextAction = () => {
    //   // Action to show this page
    //   setShowConsignmentModal(true);
    //   dispatch(showSubPage({ prevAction, nextAction, confirmOnClose: true }))
    // }

    return contractNum ? (
        // showConsignmentModal && tickTableState?.consignment?.rowData?.items?.length > 0 ?
        // tempItems.length > 0 ?
        modalType === "create" ?
            <>
                <div
                    className={styles.ConsignmentModal}
                >
                    <div className={styles.modalContainer}>
                        <TitleBar hasShadow hasSideBar>
                            <TitleBarContractInformationContent
                                setShowConsignmentModal={setShowConsignmentModal}
                                type={"add"}
                                contractNum={contractNum}
                                contractType={ct}
                                onCancel={onCancel}
                                onReload={onReload}
                                onPrint={onPrint}
                                action={action}
                            />
                        </TitleBar>
                        <div className={styles.consignmentContent}>
                            <div className={styles.cardsContainer}>
                                <div className={styles.title}>{`${t("t_new")}${t("t_contract")} ${contractNum} - ${t("t_items")}`}</div>
                                <div className={styles.border} />
                                <div className={styles.cardsContainerContent}>
                                    {/* {tickTableState?.consignment?.tempItems?.map((item, index) => */}
                                    {tempItems?.map((item, index) =>
                                        <Card
                                            title={item?.itemNameTc}
                                            setShowAddModal={setShowAddModal}
                                            setBtn={setBtn}
                                            content={item}
                                            key={index}
                                        >
                                            <div className={styles.cardContainer}>
                                                <div style={{ textAlign: "center" }}><img src={item.itemImage || sampleImg} alt="item_detail_img" style={{ height: "140px" }} /></div>
                                                <div style={{ padding: "15px" }}>
                                                    <div className={styles.line}>{t("t_transactionType")}: {t("t_consignment")}</div>
                                                    <div className={styles.line}>{t("t_status")}:  {t(STATUS[item?.status]?.label || "-")}</div>
                                                    <div className={styles.line}>{t("t_productNum")}: {item.itemNum}</div>
                                                    <div className={styles.line}>{t("t_brand")}: {item.brand ? tickTableState.optionsOfBrand.find(obj => obj.itemDeptKey === item.brand).nameEn : " - " }</div>
                                                    {/* <div className={styles.line}>{t("t_year")}:  {item.year}</div> */}
                                                    {/* <div className={styles.line}>{t("t_rating")}: {item.ratingIds?.length > 0 ? item.ratingIds.map(id => tickTableState.optionsOfRating.find(obj => obj.id === id).nameTc).join(", ") : " - "}</div> */}
                                                    <div className={styles.line}>{t("t_color")}: {item.colorIds?.length > 0 ? item.colorIds.map(id => tickTableState.optionsOfColor.find(obj => obj.id === id).nameTc).join(", ") : " - "}</div>
                                                    <div className={styles.line}>{t("t_cortex")}: {item?.cortexIds?.length > 0 ? item.cortexIds.map(id => tickTableState.optionsOfCortex.find(obj => obj.id === id)?.[`name${lang}`] ? tickTableState.optionsOfCortex.find(obj => obj.id === id)?.[`name${lang === "Tc" ? "Tc" : "En" }`] : tickTableState.optionsOfCortex.find(obj => obj.id === id)?.[`name${lang === "Tc" ? "En" : "Tc" }`]).join(", ") : " - "}</div>
                                                    {/* <div className={styles.line}>{t("t_material")}: {item.materialIds?.length > 0 ? item.materialIds.map(id => tickTableState.optionsOfMaterial.find(obj => obj.id === id).nameTc).join(", ") : " - "}</div> */}
                                                    {/* <div className={styles.line}>{t("t_size")}:  {item?.width || "0.0"} x {item?.height || "0.0"} x {item?.length || "0.0"} cm </div> */}
                                                    <div className={styles.line}>{t("t_accessories")}: {item.accessoriesIds?.length > 0 ? item.accessoriesIds.map(id => tickTableState.optionsOfAccessory.find(obj => obj.id === id).nameTc).join(", ") : " - "}</div>
                                                    <div className={styles.line}>{t("t_price")}: {item?.amt || "-"}</div>
                                                </div>
                                            </div>
                                            <div className={styles.footerBorder} />
                                            <div className={styles.footer}>
                                                <div className={styles.buttonContainerLeft}>
                                                    <EditOutlined style={{ color: variables.themeBlue, padding: "5px" }} onClick={() => onEditTemp(index)} />
                                                </div>
                                                <div className={styles.buttonContainerRight}>
                                                    <DeleteOutlined style={{ color: variables.themeRed, padding: "5px" }} onClick={() => onDeleteTemp(item.itemNum)} />
                                                </div>
                                            </div>
                                        </Card>)}
                                    <Card setShowAddModal={setShowAddModal} setBtn={setBtn} setSelectedItemList={setSelectedItemList} />
                                </div>
                            </div>
                            <TickDrawerV2 action={action} contractType={ct} />
                        </div>
                    </div>
                </div>
                {
                  contractNum &&
                  <div style={{ zIndex: 99999 }}>
                    <ConsignmentModalV2
                        showConsignmentModal={showAddModal}
                        setShowConsignmentModal={setShowAddModal}
                        btn={btn}
                        itemIndex={itemIndex}
                        setTempItemsArray={setTempItemsArray}
                        modalType={"temp"}
                        newContractNum={contractNum}
                        contractType={ct}
                        action={action}
                    />
                  </div>
                }
            </>
            :
            <>
                <div
                    className={styles.ConsignmentModal}
                >
                    <div className={styles.modalContainer}>
                        <TitleBar hasShadow hasSideBar>
                            <TitleBarContractInformationContent
                                setShowConsignmentModal={setShowConsignmentModal}
                                type={"update"}
                                onSaveClick={() => {setOpen(true)}}
                                setCreateType={setCreateType}
                                onCancel={onCancel}
                                action={action}
                            />
                        </TitleBar>
                        <div className={styles.consignmentContent}>
                            <div className={styles.cardsContainer}>
                                <div className={styles.title}>{`${t("t_contract")} ${contractNum} - ${t("t_items")}`}</div>
                                <div className={styles.border} />
                                <div className={styles.cardsContainerContent}>
                                    {tickTableState?.consignment?.rowData != [] && tickTableState?.consignment?.rowData?.map((item, index) => {
                                        const isSelected = selectedItemList.some(i => i.itemNum === item.itemNum)
                                        return (
                                            <Card
                                                key={index}
                                                title={`${item?.itemNameTc}`}
                                                setShowAddModal={setShowAddModal}
                                                setBtn={setBtn}
                                                content={item}
                                                isSelected={isSelected}
                                                action={action}
                                                receipted={item.receiptNum || null}
                                            >
                                                <div 
                                                  className={styles.cardContainer} 
                                                  // onClick={() => { selectedItem(item) }}
                                                >
                                                    {/* <div style={{ textAlign: "center", marginTop: "10px" }}><img src={item.itemImage || sampleImg} alt="item_detail_img" style={{ height: "140px" }} /></div> */}
                                                    <div style={{ textAlign: "center" }}>
                                                      <Image style={{ height: "140px", width: "140px" }} src={getItemImgSrc(item?.itemNum)} />
                                                    </div>
                                                    <div style={{ padding: "15px" }}>
                                                        <div className={styles.line}>{t("t_transactionType")}:  {item?.transactionType?.[`name${lang}`] || "-"}</div>
                                                        <div className={styles.line}>{t("t_status")}:  {t(STATUS[item?.status]?.label || "-")}</div>
                                                        <div className={styles.line}>{t("t_productNum")}:  {item?.itemNum || "-"}</div>
                                                        <div className={styles.line}>{t("t_brand")}:  {item?.brand[`name${lang}`] || "-"}</div>
                                                        {/* <div className={styles.line}>{t("t_year")}:  {item?.year}</div> */}
                                                        {/* <div className={styles.line}>{t("t_rating")}:  {item?.ratings?.map((i, index) => index != item?.ratings?.length - 1 ? <>{i?.[`name${lang}`]},</> : <>{i?.[`name${lang}`]}</>)}</div> */}
                                                        <div className={styles.line}>{t("t_color")}:  {item?.colors?.length >= 1 ? item?.colors?.map((i, index) => index != item?.colors?.length - 1 ? <>{i?.[`name${lang}`]},</> : <>{i?.[`name${lang}`]}</>) : "-"}</div>
                                                        <div className={styles.line}>{t("t_cortex")}:  {item?.cortexs.length >= 1 ? item?.cortexs?.map((i, index) => <>{i?.[`name${lang === "Tc" ? "Tc" : "En"}`] ? i?.[`name${lang === "Tc" ? "Tc" : "En" }`] : i?.[`name${lang === "Tc" ? "En" : "Tc"}`] ||  "-"}{index != item?.cortexs?.length - 1 ? "," : ""}</> ) : "-"} </div>
                                                                                                                {/* <div className={styles.line}>{t("t_material")}:  {item?.materials?.map((i, index) => index != item?.materials?.length - 1 ? <>{i?.[`name${lang}`]},</> : <>{i?.[`name${lang}`]}</>)}</div> */}
                                                        {/* <div className={styles.line}>{t("t_size")}:  {item?.width || "0.0"} x {item?.height || "0.0"} x {item?.length || "0.0"} cm </div> */}
                                                        <div className={styles.line}>{t("t_accessories")}:  {item?.accessories?.map((i, index) => index != item?.accessories?.length - 1 ? <>{i?.[`name${lang}`]},</> : <>{i?.[`name${lang}`]}</>)}</div>
                                                        <div className={styles.line}>{t("t_price")}:  {item?.amt || "-"}</div>
                                                    </div>
                                                </div>
                                                <div className={styles.footerBorder} />
                                                <div className={styles.footer}>
                                                    <div className={styles.buttonContainerLeft}>
                                                        <EditOutlined
                                                            style={{ color: variables.themeBlue, padding: "5px" }}
                                                            onClick={() => onEditReal(item.id)}
                                                        />
                                                    </div>
                                                    <div className={styles.buttonContainerRight}>
                                                        <DeleteOutlined
                                                            style={{ color: variables.themeRed, padding: "5px" }}
                                                            onClick={() => onDeleteReal(item.id)}
                                                        />
                                                    </div>
                                                </div>
                                            </Card>
                                        )
                                    })}
                                    <Card setShowAddModal={setShowAddModal} setBtn={setBtn} setSelectedItemList={setSelectedItemList} />
                                </div>
                            </div>
                            {/* <TickDrawer title={t("t_contractInfo")} open={open} setOpen={setOpen}>
                                <TickContractInfo ref={childRef} selectedItemList={selectedItemList} page={"consignmentModal"} createType={action} />
                                <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '15px'}}>
                                    <Button type="primary" onClick={onSaveClick}>{t("t_add")}</Button>
                                </div>
                            </TickDrawer> */}
                        </div>
                    </div>
                </div>
                <div style={{ zIndex: 99999 }}>
                    <ConsignmentModalV2
                        showConsignmentModal={showAddModal}
                        setShowConsignmentModal={setShowAddModal}
                        btn={btn}
                        itemIndex={itemIndex}
                        modalType={"real"}
                        // consignorId={consignorId}
                        contract={contract}
                        contractType={ct}
                        submitCallback={fetchConsignorItems}
                        setRefresh={setRefresh}
                        action={action}
                    />
                </div>
            </>
    ) : <></>
}

export default memo(ContractModal)

