import React, { memo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Routes, Route, useLocation, Navigate } from "react-router";
import variables from "../../theme/variable.scss";
import styles from "./main.module.scss";
import Item from "../item/Item";
import Consignment from "../consignment/Consignment";
import SideBar from "../../components/sideBar/SideBar";
import TitleBar from "../../components/titleBar/TitleBar";
import TitleBarMainContent from "../../components/titleBarMainContent/TitleBarMainContent";
import TitleBarItemPaginationContent from "../../components/titleBarItemPaginationContent/TitleBarItemPaginationContent";
import ProductModal from "../../modals/ProductModal";
import CategoryApi from "../../service/categoryApi/categoryApi";
import AccessoryApi from "../../service/accessoryApi/accessoryApi";
import ColorApi from "../../service/colorApi/colorApi";
import MaterialApi from "../../service/materialApi/materialApi";
import OriginApi from "../../service/originApi/originApi";
import RatingApi from "../../service/ratingApi/ratingApi";
import BrandApi from "../../service/brandApi/brandApi";
import TransactionTypeApi from "../../service/transactionTypeApi/transactionTypeApi";
import { setOptionsOfAccessory, setOptionsOfBrand, setOptionsOfCategory, setOptionsOfColor, setOptionsOfConsignor, setOptionsOfMaterial, setOptionsOfRating, setOptionsOfTransactionType, setOptionsOfOrigin, setOptionsOfCortex, setLanguage, setItemTableData, setMemberTableData } from "../../redux/tickTableSlice";
import ConsignorApi from "../../service/consignorApi/consignorApi";
import { TICK } from "../../config/envConfig";
import ChangePwModal from "../../modals/changePwModal/ChangePwModal";
import Master from "../master/master";
import CortexApi from "../../service/cortexApi/cortexApi";
import { App } from "antd";
import StatusLog from "../statusLog/StatusLog";
import LanguageApi from "../../service/systemApi/languageApi";
import { tickLog } from "../../utils/logger";
import Setting from "../setting/Setting";
import Contract from "../contract/Contract";
import Receipt from "../receipt/Receipt";
import Coupon from "../coupon/Coupon";
import ActionLog from "../actionLog/ActionLog";
import Member from "../member/Member";
import { useBlocker } from "react-router";
import { closeModal, showModal } from "../../redux/tickModalSilce";
import { useTranslation } from "react-i18next";
import { reset } from "../../redux/tickSubPageSlice";
import Report from "../report/Report";

const Main = () => {
  const [showProductModal, setShowProductModal] = useState(false);
  const [btn, setBtn] = useState("add")
  const [isReload, setIsReload] = useState(false)
  const [modalData, setModalData] = useState({})
  const menuValue = JSON.parse(localStorage.getItem("MENU"));

  const dispatch = useDispatch();

  const language = useSelector(state => state.tickTable.language);
  const isChangePwModalOpen = useSelector(state => state.tickTable.isChangePwModalOpen);

  const tempItems = JSON.parse(localStorage.getItem("TempItems"));
  const consignorEdited = useSelector((state) => state.tickTable.consignment?.consignor?.edited);

  const { t } = useTranslation();

  const location = useLocation();
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => {
      return JSON.parse(localStorage.getItem("TempItems"))?.length > 0 || consignorEdited
    }
  );

  useEffect(() => {
    LanguageApi.getLanguage().then((resp) => {
      if (resp.success) {
        const l = resp.data?.lang?.split("_")[0]; // tc or en
        dispatch(setLanguage(l[0].toUpperCase() + l.substring(1, l.length)))
      } else {
        tickLog("System Language", resp.data?.lang);
      }
    })
  }, [])

  useEffect(() => {
    if (!language) return;
    AccessoryApi.listSelectOptions().then((resp) => {
      if (resp.success) {
        dispatch(setOptionsOfAccessory(resp.data))
      }
    })
    BrandApi.listSelectOptions().then((resp) => {
      if (resp.success) {
        dispatch(setOptionsOfBrand(resp.data))
      }
    })
    CategoryApi.listSelectOptions().then((resp) => {
      if (resp.success) {
        dispatch(setOptionsOfCategory(resp.data))
      }
    })
    ColorApi.listSelectOptions().then((resp) => {
      if (resp.success) {
        dispatch(setOptionsOfColor(resp.data))
      }
    })
    MaterialApi.listSelectOptions().then((resp) => {
      if (resp.success) {
        dispatch(setOptionsOfMaterial(resp.data))
      }
    })
    OriginApi.listSelectOptions().then((resp) => {
      if (resp.success) {
        dispatch(setOptionsOfOrigin(resp.data))
      }
    })
    RatingApi.listSelectOptions().then((resp) => {
      if (resp.success) {
        dispatch(setOptionsOfRating(resp.data))
      }
    })
    TransactionTypeApi.listSelectOptions().then((resp) => {
      if (resp.success) {
        dispatch(setOptionsOfTransactionType(resp.data))
      }
    })
    // ConsignorApi.listSelectionOptions().then(resp => {
    //   if (resp.success) {
    //     dispatch(setOptionsOfConsignor(resp.data))
    //   }
    // })
    CortexApi.listSelectOptions().then(resp => {
      if (resp.success) {
        dispatch(setOptionsOfCortex(resp.data))
      }
    })
  }, [language])

  useEffect(() => {
    if (location.pathname === "/main/item") {
      dispatch(setItemTableData({ totalCount: 0 }))
    } else if (location.pathname === "/main/member") {
      dispatch(setMemberTableData({ totalCount: 0 }))
    }
  }, [location])

  useEffect(() => {
    console.log(blocker)

    if (blocker.state === "blocked") {
      dispatch(
        showModal({
          key: "confirm",
          icon: "warning",
          header: t("t_warning"),
          msg: t("t_promptConfirmLoseProgress"),
          showCloseBtn: true,
          // showCloseBtn: true,
          callback: () => {
            localStorage.setItem("TempItems", JSON.stringify([]));
            dispatch(reset());
            dispatch(closeModal());
            blocker?.proceed();
          },
          cancelCallback: () => {
            blocker?.reset();  
          }
        })
      )
    }
  }, [blocker.state])

  return (
    <>
      <div className={styles.container}>
        <SideBar />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TitleBar hasSideBar color={TICK.env === "dev3" ? variables.themeDev : TICK.env === "uat" ? variables.themeUat : variables.themeBlue}>
            <TitleBarMainContent />
          </TitleBar>
          <div className={styles.content}>
            <Routes>
              {menuValue?.find(item => item.name === 'Item') ?
                <Route
                  path="item"
                  element={
                    <App>
                      <Item
                        setShowProductModal={setShowProductModal}
                        setBtn={setBtn}
                        isReload={isReload}
                        setModalData={setModalData}
                      />
                    </App>
                  }
                /> : <></>
              }
              {
                menuValue.find(item => item.name === 'Consignment') ? (
                  <Route path="consignment" element={<Consignment />}>
                    <Route path=":id" element={<Consignment />} />
                  </Route> 
                ) : <></>
              }
              {
                menuValue.find(item => item.name === 'Contract') ? (
                  <Route path="contract" element={<Contract />}>
                    <Route path=":id" element={<Contract />} />
                    <Route path=":consignorId" element={<Contract />} />
                  </Route> 
                ) : <></>
              }
              {menuValue.find(item => item.name === 'Contract') ? <Route path="contract" element={<Contract />} /> : <></>}
              {/* {menuValue.find(item => item.name === 'Consignment') ? <Route path="consignment" element={<Consignment />} /> : <></>} */}
              {menuValue.find(item => item.name === 'Member') ? <Route path="member" element={<Member/>}/> : <></>}
              {menuValue.find(item => item.name === 'Receipt') ? <Route path="receipt" element={<Receipt />} /> : <></>}
              {menuValue.find(item => item.name === 'Coupon') ? <Route path="coupon" element={<Coupon />} /> : <></>}
              {menuValue.find(item => item.name === 'Master') ? <Route path="master" element={<Master />} /> : <></>}
              {menuValue.find(item => item.name === 'StatusLog') ? <Route path="statusLog" element={<ActionLog />} /> : <></>}
              {menuValue.find(item => item.name === 'Setting') ? <Route path="setting" element={<Setting />} /> : <></>}
              {menuValue.find(item => item.name === 'Report') ? <Route path="report" element={<Report />} /> : <></>}
              <Route path="item/*" element={<Navigate to="/main/item" />} />
              <Route path="consignment/*" element={<Navigate to="/main/consignment" />}/>
              <Route path="member/*" element={<Navigate to="/main/member" />}/>
              <Route path="contract/*" element={<Navigate to="/main/contract" />}/>
              <Route path="receipt/*" element={<Navigate to="/main/receipt" />}/>
              <Route path="coupon/*" element={<Navigate to="/main/coupon" />}/>
              <Route path="master/*" element={<Navigate to="/main/master" />}/>
              <Route path="statusLog/*" element={<Navigate to="/main/statusLog" />}/>
              <Route path="setting/*" element={<Navigate to="/main/setting" />}/>
              <Route path="report/*" element={<Navigate to="/main/report" />}/>
              <Route path="*" element={<Navigate to='/main/item' />} />
            </Routes>
          </div>
        </div>
      </div>
      {showProductModal && (
        <ProductModal
          showProductModal={showProductModal}
          setShowProductModal={setShowProductModal}
          btn={btn}
          setIsReload={setIsReload}
          modalData={modalData}
          setModalData={setModalData}
        />
      )}
      {
        isChangePwModalOpen ?
          <ChangePwModal /> : null
      }
    </>
  );
};

export default memo(Main);
