import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  show: false,
  params: {
    key: 'none',
    header: 'none',
    showCloseBtn: false,
    msg: 'default msg',
    // autoClose: 1000,
    // iconKey: '',
    callback: null,
    children: null,
  },
};

export const tickModalSlice = createSlice({
  name: 'tickModal',
  initialState: {
    show: false,
    params: {
      key: 'none',
      header: 'none',
      showCloseBtn: false,
      msg: 'default msg',
      // autoClose: 1000,
      // iconKey: '',
      callback: null,
      children: null,
    },
  },
  reducers: {
    showModal: (state, newState) => {
      state.show = true;
      state.params = newState.payload;
    },
    closeSpecificModal: (state, newState) => {
      state.show = false;
      state.params = newState.payload;
    },
     closeModal: () => initialState,
  },
});

export const { showModal, closeSpecificModal, closeModal } = tickModalSlice.actions;

export default tickModalSlice.reducer;
