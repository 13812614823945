import React from 'react';
import { useSelector } from 'react-redux';
import { lang } from '../config/systemConfig';
import dayjs from 'dayjs';
import { tickLog } from '../utils/logger';

const useHandleItemPostBody = () => {
  const optionsOfCategory = useSelector(state => state.tickTable.optionsOfCategory);

  const handleCatKey = (data) => {          // format helper function
    const filteredResult = optionsOfCategory.all.filter(item => item.itemSubCatKey === data.subCatKey )
    if (filteredResult.length > 0) {
      const [ result ] = filteredResult;
      return result.itemCatKey;
    }
    return ""
  }

  const handleItemPostBody = (data) => {
    let body = {
      branchNum: data?.branchNum,
      itemNum : data?.itemNum || "", 
      itemNameEn : data?.[`itemName${lang}`] || "",
      itemNameTc : data?.[`itemName${lang}`] ||  "",
      // itemNameEn : lang === "En" ? data?.[`itemName${lang}`] || "" : "",
      // itemNameTc : lang === "Tc" ? data?.[`itemName${lang}`] || "" : "",
      transactionTypeId : data?.transactionTypeId? data?.transactionTypeId : 2, // default type number
      transactionDate : data?.transactionDate ? dayjs(data?.transactionDate ? data?.transactionDate : undefined).format("YYYY-MM-DD") : "", 
      deptType : "brand", //default value "brand"
      deptKey : data?.deptKey || "", 
      catKey : handleCatKey(data),
      subCatKey : data?.subCatKey || "",
      amt: data?.amt === null || data?.amt === undefined ? null : data?.amt,
      onSalesPrice : data?.onSalesPrice || null,  
      handlingFee : data?.handlingFee || 0,
      handlingFeePercentage: data?.handlingFeePercentage || 0,
      directInCost : data?.directInCost || 0,
      directInDate : data?.directInDate ? dayjs(data.directInDate).format("YYYY-MM-DD") : "",
      qty : data?.qty || 1, // default type number
      // year : data?.year ? dayjs(data?.year ? data.year : undefined).format("YYYY") : "", //-
      year : data?.year ||  "", //-
      width : data?.width || 0,
      height : data?.height || 0,
      length : data?.length || 0,
      colorIds : data?.colorIds? data.colorIds.toString() : "", //-
      ratingIds : data.ratingIds ? data.ratingIds.toString() : "",
      materialIds : data?.materialIds ? data.materialIds.toString() : "", //-
      originIds : data?.originIds?  data?.originIds.toString() : "",
      accessoriesIds : data?.accessoriesIds ? data.accessoriesIds.toString() : "", //-,
      cortexIds : data?.cortexIds ? data.cortexIds.toString() : "", //-,
      remark : data?.remark || "",
      model : data?.model || "",
      itemNameInternal: data?.itemNameInternal || "",
      colorIdsInternal: data?.colorIdsInternal?.toString() || "",
      status: data?.status,
      stockLocation: data?.stockLocation,
      itemImage: data?.itemImage,
      mark: data?.mark || "",
      isReprintTag: data?.isReprintTag || false
    }

    if (data.consignorId) {
      body.consignorId = data?.consignorId || 0 ; // default type number
    }

    return body;
  }

  const handleItemPostUpdateBody = (data) => {
    let body = {};
    console.log(body)
    
    if (data?.itemNum !== undefined) {
      body.itemNum = data.itemNum;
    }

    if (data?.itemNameEn !== undefined) {
      body.itemNameEn = data.itemNameEn
    }

    if (data?.itemNameTc !== undefined) {
      body.itemNameTc = data.itemNameTc
    }

    if (data?.transactionTypeId  !== undefined) {
      body.transactionTypeId = parseInt(data.transactionTypeId); // default type number
    }

    if (data?.transactionDate  !== undefined) {
      body.transactionDate = dayjs(data.transactionDate).format("YYYY-MM-DD")
    }

    if (data?.deptKey  !== undefined) {
      body.deptKey = data.deptKey
    }

    if (data?.catKey  !== undefined) {
      body.catKey = handleCatKey(data)
    }

    if (data?.subCatKey  !== undefined) {
      body.subCatKey = data.subCatKey
    }

    if (data?.amt  !== undefined) {
      body.amt = data.amt
    }

    if (data?.onSalesPrice  !== undefined) {
      body.onSalesPrice = data.onSalesPrice
    }

    if (data?.qty  !== undefined) {
      body.qty = parseInt(data.qty); // default type number
    }

    if (data?.handlingFee  !== undefined) {
      body.handlingFee = data.handlingFee
    }

    if (data?.handlingFeePercentage  !== undefined) {
      body.handlingFeePercentage = data.handlingFeePercentage || 0
      body.handlingFee = data.handlingFee
    }

    if (data?.directInCost !== undefined) {
      body.directInCost = data.directInCost
    }

    if (data?.directInDate !== undefined) {
      body.directInDate = dayjs(data.directInDate).format("YYYY-MM-DD")
    }

    if (data?.year  !== undefined) {
      // body.year = dayjs(data.year).format("YYYY");
      body.year = data.year
    }

    if (data?.width  !== undefined) {
      body.width = data.width
    }

    if (data?.height  !== undefined) {
      body.height = data.height;
    }

    if (data?.length  !== undefined) {
      body.length = data.length
    }

    if (data?.colorIds  !== undefined) {
      body.colorIds = data.colorIds.toString(); // multi selection and change it to string
    }

    if (data?.ratingIds  !== undefined) {
      body.ratingIds = data.ratingIds.toString(); // multi selection and change it to string
    }

    if (data?.materialIds  !== undefined) {
      body.materialIds = data.materialIds.toString(); // multi selection and change it to string
    }

    if (Object.keys(data).includes("originIds")) {
      body.originIds = data?.originIds?.toString() || null;
    }

    if (Object.keys(data).includes("cortexIds")) {
      body.cortexIds = data?.cortexIds?.toString() || null;
    }

    if (data?.accessoriesIds  !== undefined) {
      body.accessoriesIds = data.accessoriesIds.toString(); // multi selection and change it to string
    }

    if (data?.remark  !== undefined) {
      body.remark = data.remark
    }

    if (Object.keys(data).includes("model")) {
      body.model = data?.model || "";
    }

    if (data?.consignorId  !== undefined) {
      body.consignorId = parseInt(data.consignorId); // default type number
    }
    if (data?.itemNameInternal  !== undefined) {
      body.itemNameInternal = data?.itemNameInternal || null
    }
    if (data?.colorIdsInternal  !== undefined) {
      body.colorIdsInternal = data?.colorIdsInternal?.toString() || null ; // default type number
    }

    if (data?.status  !== undefined) {
      body.status = data.status
    }

    if (data?.branchNum  !== undefined) {
      body.branchNum = data.branchNum
    }

    if (data?.stockLocation  !== undefined) {
      body.stockLocation = data.stockLocation
    }

    if (data?.mark  !== undefined) {
      body.mark = data?.mark || ""
    }

    if (data?.isReprintTag !== undefined) {
      body.isReprintTag = data?.isReprintTag || false
    }
    
    console.log(data)
    body.itemImage = data.itemImage;

    return body;
  }
  return { handleItemPostBody, handleItemPostUpdateBody}
}

export default useHandleItemPostBody