import React, { memo, useEffect, useState, useRef } from "react";
import TitleBar from "../../components/titleBar/TitleBar";
import variables from "../../theme/variable.scss";
import { useTranslation } from "react-i18next";
import styles from './receipt.module.scss';
import { tickLog } from "../../utils/logger";
import { useDispatch, useSelector } from "react-redux"
import { closeModal, showModal } from "../../redux/tickModalSilce";
import { setReceiptParams, setReceiptTableData, setShowItemDrawer } from "../../redux/tickTableSlice";
import TickTable from "../../components/tickTable/TickTable";
import i18n from '../../i18n';
import {
  EditOutlined,
  DownloadOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { Tooltip } from 'antd';
import TickContractInfo from "../../components/tickContractInfo/TickContractInfo";
import TickDrawer from "../../components/tickDrawer/TickDrawer";
import PrintContractModal from "../../modals/PrintContractModal";
import TitleBarReceiptPaginationContent from "../../components/titleBarReceiptPaginationContent/TitleBarReceiptPaginationContent";
import ConsignmentReceiptApi from "../../service/consignmentReceiptApi/consignmentReceiptApi";
import ReceiptEditModal from "../../modals/ReceiptEditModal";
import { useNavigate } from "react-router";
import Link from "antd/es/typography/Link";
import PrintReceiptModal from "../../modals/PrintReceiptModal";

const Receipt = (props) => {
  const {  } = props
  const [dataSource, setDataSource] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [buttonType, setButtonType] = useState('add');
  const [language, setLanguage] = useState(i18n.language);
  const permissions = JSON.parse(localStorage.getItem('PERMISSION'))
  const [selectedItemList, setSelectedItemList] = useState([])
  const [itemDetails, setItemDetails] = useState([])
  const [showPrintReceiptModal, setShowPrintReceiptModal] = useState(false)
  const [contractData, setContractData] = useState()
  const open = useSelector(state => state.tickTable.isItemDrawerOpen);
  const childRef = useRef(null);
  const [receiptType, setReceiptType] = useState("directIn")
  const isDrawerOpen = useSelector(state => state.tickTable.isItemDrawerOpen)

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const receiptParams = useSelector(state => state.tickTable.receiptParams);

  useEffect(() => {
    listByPage()
    dispatch(setShowItemDrawer(false))
  }, [receiptParams])

  const setOpen = (open = false) => {
    dispatch(setShowItemDrawer(open))
}

  const listByPage = async () => {
    setTableLoading(true);
    try {
      const resp = await ConsignmentReceiptApi.listByPage(receiptParams);
      tickLog("all product data", resp)
      if (resp.success) {
        const { data, msg } = resp
        setDataSource(data.array)
        dispatch(
          setReceiptTableData({ totalCount: data.totalCount })
        )
      }
    } catch {
    } finally {
      setTableLoading(false);
    }
  }

  const onReload = () => {
    dispatch(setReceiptParams({...receiptParams, sortOptions: {}, input: '', pageNum: 1 }));
  }
  

  const handleSortKey = (sortOrder) => {
    let sortKey;
    if (sortOrder === 'ascend') {
      sortKey = 'ASC'
    }

    if (sortOrder === 'descend') {
      sortKey = 'DESC'
    }
    return sortKey;
  }
  
  const onChange = (pagination, filter, sorter) => {
    let sortOptions = {};
    if (Array.isArray(sorter)) {
      sorter.forEach(sortEle => {
        sortOptions[sortEle.columnKey] = handleSortKey(sortEle.order);
      })
    } else {
      sortOptions[sorter.columnKey] = handleSortKey(sorter.order);
    }
    
    if (!sorter.order) {
      delete sortOptions[sorter.columnKey]
    }

    dispatch(setReceiptParams({
      ...receiptParams,
      sortOptions,
    }))
  }

  const sortOrder  = (key) =>  {
    if (receiptParams.sortOptions[key] === 'ASC') {
      return 'ascend';
    }
    if (receiptParams.sortOptions[key] === 'DESC') {
      return 'descend';
    }
    return undefined;
  }

  function handleDetails(record) {
    console.log(record);
    setOpen(true);
    setItemDetails(record);
    setSelectedItemList(record.items);
    setReceiptType(record.receiptType);
  }

  const columns = [
    {
      title: t("t_receiptNum"),
      dataIndex: "receiptNum",
      key: "receiptNum",
      sorter: {
        multiple: 8
      },
      sortOrder: sortOrder('receiptNum'),
      render: (_, record) => (
        <div
          // style={{ cursor: "pointer" }}
          // onClick={() => handleDetails(record)}
        >
          {_ || "-"}
        </div>
      )
    },
    {
      title: t("t_consignorNum"),
      dataIndex: "consignorNum",
      key: "consignorNum",
      sorter: {
        multiple: 7
      },
      sortOrder: sortOrder('consignorNum'),
      render: (_, record) => (
        record.consignor ? 
        <Link onClick={() => navigate(`/main/consignment/${record.consignor.id}`)}>
          {record.consignor.consignorNum}
        </Link> : "-"
      )
    },
    {
      title: t("t_receiptType"),
      dataIndex: "receiptType",
      key: "receiptType",
      sorter: {
        multiple: 6
      },
      sortOrder: sortOrder('receiptType'),
      render: (_, record) => (
        <div
          // style={{ cursor: "pointer" }}
          // onClick={() => handleDetails(record)}
        >
          {_ === "directIn" ? t("t_directIn") : t("t_normalSold")}
        </div>
      )
    },
    {
      title: t("t_fullName"),
      dataIndex: "consignorFullName",
      key: "consignorFullName",
      sorter: {
        multiple: 5
      },
      sortOrder: sortOrder('consignorFullName'),
      render: (_, record) => (
        <div
          // style={{ cursor: "pointer" }}
          // onClick={() => handleDetails(record)}
        >
          {_ || "-"}
        </div>
      )
    },
    {
      title: t("t_phoneNum"),
      dataIndex: "consignorPhoneNum",
      key: "consignorPhoneNum",
      sorter: {
        multiple: 4
      },
      sortOrder: sortOrder('consignorPhoneNum'),
      render: (_, record) => (
        <div
          // style={{ cursor: "pointer" }}
          // onClick={() => handleDetails(record)}
        >
          {_ || "-"}
        </div>
      )
    },
    {
      title: t("t_amount"),
      dataIndex: "paidAmount",
      key: "paidAmount",
      sorter: {
        multiple: 3
      },
      sortOrder: sortOrder('paidAmount'),
      render: (_, record) => (
        <div
          // style={{ cursor: "pointer" }}
          // onClick={() => handleDetails(record)}
        >
          {_ || "-"}
        </div>
      )
    },
    {
      title: t("t_paymentMethod"),
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      sorter: {
        multiple: 2
      },
      sortOrder: sortOrder('paymentMethod'),
      render: (_, record) => (
        <div
          // style={{ cursor: "pointer" }}
          // onClick={() => handleDetails(record)}
        >
          {_?.[language === "tc_hk" ? "titleTc" : "titleEn"] || "-"}
        </div>
      )
    },
    {
      title: t("t_paymentReference"),
      dataIndex: "paymentReference",
      key: "paymentReference",
      sorter: {
        multiple: 1
      },
      sortOrder: sortOrder('paymentReference'),
      render: (_, record) => (
        <div
          // style={{ cursor: "pointer", whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
          // onClick={() => handleDetails(record)}
        >
          {_ || "-"}
        </div>
      ),
      // ellipsis: true 
    },
    {
      title: t("t_function"),
      dataIndex: "operate",
      key: "operate",
      fixed: 'right',
      width: 140,
      render: (_,
        record
      ) =>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {
            permissions.findIndex((item) => item === "receipt:print") > -1 ? 
            <DownloadOutlined style={{ color: variables.themeBlue, fontSize: '24px' }} onClick={() => onPrint(record)} /> :
            <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
              <DownloadOutlined style={{ color: "grey", fontSize: "24px" }}/>
            </Tooltip>
          }
          {
            permissions.findIndex((item) => item === "receipt:edit") > -1 ? 
            // <EditOutlined style={{ color: variables.themeBlue, padding: "5px" }} onClick={() => onEdit(record)} /> :
            <EditOutlined style={{ color: variables.themeBlue, fontSize: "24px" }} onClick={() => onEdit(record)} /> :
            <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
              {/* <EditOutlined style={{ color: "grey", padding: "5px" }}/> */}
              <EditOutlined style={{ color: "grey", fontSize: "24px" }}/>
            </Tooltip>
          }
          {
            permissions.findIndex((item) => item === "receipt:delete") > -1 ? 
            // <DeleteOutlined style={{ color: variables.themeRed, padding: "5px" }} onClick={() => onDelete(record)} /> :
            <DeleteOutlined style={{ color: variables.themeRed, fontSize: "24px" }} onClick={() => onDelete(record)} /> :
            <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
              {/* <DeleteOutlined style={{ color: "grey", padding: "5px" }} />  */}
              <DeleteOutlined style={{ color: "grey", fontSize: "24px" }} /> 
            </Tooltip>
          }
        </div>
    }
  ]

  const onEdit = (record) => {
    setShowEditModal(true);
    setButtonType('update');
    setModalData(record);
  }

  const onPrint = (record) => {
    setModalData(record)
    setShowPrintReceiptModal(true)
  }

  const onDelete = async (record) => {
    dispatch(
      showModal({
        key: "warning",
        header: t("t_delete"),
        msg: t("t_confirmDelete"),
        showCloseBtn: true,
        callback: async () => {
          await ConsignmentReceiptApi.delete(record.id).then((res) => {
            console.log(res);
            listByPage();
            if (res.success) {
              dispatch(
                showModal({
                  key: "success",
                  header: "Update successfully",
                  callback: () => {
                    dispatch(closeModal());
                  },
                })
              );
            } else {
              dispatch(
                showModal({
                  key: "error",
                  header: "This is an error message",
                  msg: res.msg,
                  callback: () => {
                    dispatch(closeModal());
                  },
                })
              );
            }
          })
        }
      })
    );
  }

  return (
    <>
      <TitleBar hasSideBar hasShadow color={variables.themeWhite}>
        <TitleBarReceiptPaginationContent
          reload={onReload}
        />
      </TitleBar>
      <div className={styles.container}>
        <div className={styles.tableWrapper}>
          <TickTable
            dataSource={dataSource}
            columns={columns}
            rowKey={(record) => record.id}
            loading={tableLoading}
            onChange={onChange}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  const isTargetClicked = event.target.outerHTML.startsWith('<svg') || event.target.outerHTML.startsWith('<path')
                  if (!isTargetClicked) {
                    if (isDrawerOpen && record.id === itemDetails.id) {
                      setOpen(false);
                    } else {
                      setOpen(true);
                      setItemDetails(record);
                      setSelectedItemList(record.items)
                    }
                  }
                },
              };
            }}
          />         
        </div>
      <TickDrawer title={`${t("t_receiptInfo")} (${itemDetails.receiptNum})`} open={open} setOpen={setOpen}>
        <TickContractInfo ref={childRef} selectedItemList={selectedItemList} isReadOnly={true} itemDetails={itemDetails} createType="receipt" receiptType={receiptType}/>
      </TickDrawer>
      </div>
      {showEditModal &&
        <ReceiptEditModal
          showMasterModal={showEditModal}
          setShowMasterModal={setShowEditModal}
          width="500px"
          modalData={modalData}
          buttonType={buttonType}
          listByPage={listByPage}
      />}
      {showPrintReceiptModal &&
        <PrintReceiptModal 
          showPrintReceiptModal={showPrintReceiptModal}
          setShowPrintReceiptModal={setShowPrintReceiptModal}
          receiptData={modalData}
        />
      }
    </>
  );
};

export default memo(Receipt);
