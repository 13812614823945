import React from "react";
import { data } from "./dummyConfig";
import { Button, Checkbox, Col, DatePicker, Form, Input, Radio, Row, Select, Switch } from "antd";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import styles from "./report.module.scss";
import ReportCommonGroupField from "./ReportCommonGroupField";
import ReportCommonDatePicker from "./ReportCommonDatePicker";
import { Context } from "./Report";

const ReportSearchForm = () => {
  const context = React.useContext(Context);

  const [form] = Form.useForm();
  const { t } = useTranslation();

  React.useEffect(() => {
    handleSetDefaultValue();
  }, [context.adminFormConfigs.searchConfig]);

  const handleSetDefaultValue = () => {
    if (!context.adminFormConfigs.searchConfig) return;
    const allConfigs = Object.entries(context.adminFormConfigs.searchConfig);
    let fieldsAndValues = {}
    for (const config of allConfigs) {
      if (config[0] === "submit" || config[0] === "confirm" || config[0] === "reset") continue;
      fieldsAndValues = { ...fieldsAndValues, [config[0]]: config[1].defaultValue }
    }
    form.setFieldsValue(fieldsAndValues);
    context.fetchTableData(1, 10, context.adminFormConfigs.id, { ...context.params.searchParams, searchParams: fieldsAndValues });
  }

  const onFormSubmit = () => {
    const fieldsAndValues = form.getFieldsValue();
    context.fetchTableData(1, 10, context.adminFormConfigs.id, { ...context.params.searchParams, searchParams: fieldsAndValues });
  }

  const onFormReset = () => {
    form.resetFields();
    context.fetchTableData(1, 10, context.adminFormConfigs.id, {
      ...context.params.searchParams, ...{
        sortField: null,
        sortOrder: null,
        searchParams: null
      }
    });
  }

  const handleWidth = (obj, num) => {
    const maxSpan = 24;
    const maxCol = 4;
    let newNum = 0;

    if (obj?.span) {
      if (obj?.span >= maxSpan) {
        return maxSpan;
      }
      return obj?.span;
    }

    if (num >= maxSpan) {
      newNum = maxSpan;
    }

    if (num > maxCol) {
      newNum = maxSpan / maxCol
    } else {
      newNum = maxSpan / num
    }

    return newNum;
  };

  const handleTranslation = (obj) => {
    let label = "";

    if (i18n.language === 'en') {
      label = obj['labelEn'] || obj['labelTc']
    }

    if (i18n.language === 'tc_hk') {
      label = obj['labelTc'] || obj['labelEn']
    }
    return label;
  };

  const renderSearchFieldType = (fieldDetails) => {
    const { type } = fieldDetails;
    let field = <></>
    switch (type) {
      case "input":
        field = <Input {...fieldDetails?.extraProps || {}} placeholder={t("t_pleaseInputYourInfo")} />
        break;
      case "textArea":
        field = <Input.TextArea  {...fieldDetails?.extraProps || {}} />
        break;
      case "select":
      case "checkBox":
      case "radio":
        field = <ReportCommonGroupField fieldDetails={fieldDetails} type={type} />
        break;
      case "datePicker":
      case "dateRangePicker":
        field = <ReportCommonDatePicker fieldDetails={fieldDetails} type={type} />
        break;
      case "switch":
        field = <Switch checked={undefined} defaultChecked={fieldDetails?.defaultValue || false} {...fieldDetails?.extraProps || {}} />
        break;
      default:
        field = <Input {...fieldDetails?.extraProps || {}} />
    }
    return field;
  };

  const renderSearchFields = () => {
    if (!context.adminFormConfigs) return <></>;
    const objEntries = context.adminFormConfigs.searchConfig ? Object.entries(context.adminFormConfigs.searchConfig) : [];

    let sortedObjEntries = [];
    let btnIdx = -1;
    let searchTypesQuantity = 0;

    objEntries.forEach((item, index) => {
      const [objKey, objValue] = item;
      if (Object.keys(objValue).length === 0) return;
      if (!objValue.labelEn && objKey !== "submit" && !objValue.labelEn && objKey !== "confirm") return;
      if (objKey === "submit" || objKey === "confirm") {
        btnIdx = index;
        return;
      };

      if (objValue?.span !== 24) {
        searchTypesQuantity += 1;
      }

      sortedObjEntries.push(item);
    })

    if (btnIdx !== -1) {
      sortedObjEntries.push(objEntries[btnIdx])
    }

    return sortedObjEntries.map((item, index) => {
      const [objKey, objValue] = item;
      const width = handleWidth(objValue, searchTypesQuantity);

      if (objKey === "submit" || objKey === "confirm") {
        return (
          <Col span={24} style={{ textAlign: "end" }} key={`each_col_${index}`}>
            <div className={styles.btnWrapper}>
              <Button type="default" onClick={onFormReset}>
                {t("t_reset")}
              </Button>
              <Button type="primary" htmlType="submit" onClick={onFormSubmit}>
                {t("t_reportSearch")}
              </Button>
            </div>
          </Col>
        )
      };

      return (
        <Col
          span={width}
          key={`each_col_${index}`
          }
        >
          <Form.Item
            label={handleTranslation(objValue)}
            name={objKey}
          // initialValue={handleInitialValue(objValue)}
          // initialValue={!objValue.defaultValue && objValue?.type === "switch" ? false : objValue.defaultValue ? objValue.defaultValue : undefined}
          // valuePropName={objValue?.type === "switch" ? "checked" : undefined} // still testing last week
          >
            {renderSearchFieldType(objValue)}
          </Form.Item>
        </Col >
      )
    })
  };

  return (
    <div className={styles.searchFormContainer}>
      <Form
        form={form}
      >
        <Row gutter={[32]}>
          {renderSearchFields()}
        </Row>
      </Form>
    </div>
  )
};

export default ReportSearchForm;