import React, { memo, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Checkbox, Form, Select, Col, Row, InputNumber, DatePicker, Switch } from 'antd';
import styles from "./tickFilterForm.module.scss"
import variables from "../../theme/variable.scss"
import { useTranslation } from "react-i18next"
import TickCollapse from '../tickCollapse/tickCollapse';
import CommonSelection from '../commonSelection/CommonSelection';
import { setItemParams } from '../../redux/tickTableSlice';
import i18n from '../../i18n';
import TickFilterBrandSelection from '../TickFilterSelection/TickFilterBrandSelection';
import TickFilterColorSelection from '../TickFilterSelection/TickFilterColorSelection';
import * as dayjs from "dayjs";


const TickFilterForm = () => {
    const tickTableState = useSelector((state) => state.tickTable);
    const itemTableParams = useSelector(state => state.tickTable.itemParams);
  
    const [form] = Form.useForm();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { RangePicker } = DatePicker;

    useEffect(() => {
        const {setFieldsValue} = form;
        setFieldsValue({
            transactionTypeId: itemTableParams?.transactionTypeId,
            status: itemTableParams?.status || undefined,
            // priceMin: itemTableParams?.priceMin !== null || itemTableParams?.priceMin !== undefined ? 
            //   itemTableParams?.priceMin : undefined,
            priceMin: itemTableParams?.priceMin || undefined,
            priceMax: itemTableParams?.priceMax || undefined,
            isReprintTag: itemTableParams?.isReprintTag || undefined
        }) 
        if (!itemTableParams?.transactionDateStart && !itemTableParams?.transactionDateEnd) {
            setFieldsValue({
                transactionDateRange: [undefined, undefined]
            }) 
        }
    }, [itemTableParams])

    const onSelectAll = (type = "bags") => {
        const key = type === 'bags' ? 'bagCats' : 'accessoryCats';
        let allSelectedOptions = tickTableState.optionsOfCategory[type].map(item => item.itemSubCatKey)
        if (itemTableParams[key].length === allSelectedOptions.length) {
            dispatch(setItemParams({ ...itemTableParams, pageNum: 1, [`${key}`]: [], isCreate: false }));
            return;
        }
        if (itemTableParams[key].length > 0) {
            allSelectedOptions = Array.from(new Set(itemTableParams[key].concat(allSelectedOptions)));
        }

        dispatch(setItemParams({ ...itemTableParams, pageNum: 1, [`${key}`]: allSelectedOptions, isCreate: false }))
    }

    const onBagsSelect = (item) => {
        const { itemSubCatKey } = item;
        const { bagCats } = itemTableParams;
        if (bagCats.length === 0) {
            dispatch(setItemParams({ ...itemTableParams, pageNum: 1, bagCats: [itemSubCatKey], isCreate: false }))
            return;
        }
        const isSelectedBags = bagCats.filter(selected => selected == itemSubCatKey)
        if (isSelectedBags.length >= 1) {
            const removeSelectedBags = bagCats.filter(selected => selected !== itemSubCatKey);
            dispatch(setItemParams({ ...itemTableParams, pageNum: 1, bagCats: removeSelectedBags, isCreate: false }));
            return;
        }
        const bagsArr = [...bagCats, itemSubCatKey];
        dispatch(setItemParams({ ...itemTableParams, pageNum: 1, bagCats: bagsArr, isCreate: false }))
    }

    const onAccessoriesSelect = (item) => {
        const { itemSubCatKey } = item;
        const { accessoryCats } = itemTableParams;

        if (accessoryCats.length === 0) {
            dispatch(setItemParams({ ...itemTableParams, pageNum: 1, accessoryCats: [itemSubCatKey], isCreate: false }))
            return;
        };
        const isSelectedAccessories = accessoryCats.filter(selected => selected == itemSubCatKey)
        if (isSelectedAccessories.length >= 1) {
            const removeSelectedAccessories = accessoryCats.filter(selected => selected !== itemSubCatKey);
            dispatch(setItemParams({ ...itemTableParams, pageNum: 1, accessoryCats: removeSelectedAccessories, isCreate: false }));
            return;
        }
        const accessoriesArr = [...accessoryCats, itemSubCatKey]
        dispatch(setItemParams({ ...itemTableParams, pageNum: 1, accessoryCats: accessoriesArr, isCreate: false }))
    }

    // const onCategorySelect = (label, value, isBag = true) => {
    //     let field = isBag ? tickTableState.optionsOfCategory?.bags : tickTableState.optionsOfCategory?.accessories
    //     let fieldKey = isBag ? "bags" : "accessories"
    //     let categorySelect = category[`${fieldKey}`]

    //     let isInclude = categorySelect.filter(item => item.label === label)
    //     if (label === "全部") {
    //         if (field.length === categorySelect.length) {
    //             // console.log("categorySelect", categorySelect)
    //             categorySelect = isBag ? { "accessories": category["accessories"], "bags": [] } : { "accessories": [], "bags": category["bags"] }
    //             setCategory(categorySelect)
    //             return
    //         }
    //         categorySelect = isBag ? { accessories: category["accessories"], bags: [...tickTableState.optionsOfCategory?.bags] } : { accessories: [...tickTableState.optionsOfCategory?.accessories], bags: category["bags"] }
    //         setCategory(categorySelect)
    //         return;
    //     }
    //     if (isInclude.length < 1) { // which mean no include
    //         let newItem = {
    //             label,
    //             value
    //         }
    //         categorySelect.push(newItem);
    //         category[`${fieldKey}`] = categorySelect
    //         setCategory({ ...category })
    //     } else {
    //         let position;
    //         categorySelect.forEach((item, index) => {
    //             if (item.label === label) {
    //                 position = index
    //                 return;
    //             }
    //         })
    //         categorySelect.splice(position, 1);
    //         category[`${fieldKey}`] = categorySelect
    //         setCategory({ ...category })
    //     }
    // }

    // useEffect(() => {
    //     // console.log("category", category)
    //     if (category.bags.length >= 1) {
    //         // console.log("bags+++++++++++++++++++", category?.bags?.length >= 1);
    //         let newBagCats = category?.bags?.map((item) => item.label)
    //         dispatch(setItemParams({ ...itemTableParams, pageNum: 1, bagCats: newBagCats }))
    //     }
    //     if (category?.accessories.length >= 1) {
    //         // console.log("acc+++++++++++++++++++", category?.accessories?.length >= 1);
    //         let newAccessoryCats = category?.accessories?.map((item) => item.itemSubCatKey)
    //         dispatch(setItemParams({ ...itemTableParams, pageNum: 1, accessoryCats: newAccessoryCats }))
    //     }
    // }, [category])

    const formOnChangeHandler = (changedValues, allValues) => {
        const changedValueKey = Object.keys(changedValues);
        let changedObj = {};
        if (changedValueKey.includes("brand")) {
            changedObj.deptKey = changedValues.brand;
        }
        if (changedValueKey.includes("color")) {
            changedObj.colorIds = changedValues.color;
        }
        if (changedValueKey.includes("status")) {
            changedObj.status = changedValues.status;
        }
        if (changedValueKey.includes("transactionTypeId")) {
            changedObj.transactionTypeId = changedValues.transactionTypeId
        }
        if (changedValueKey.includes("priceMin")) {
            changedObj.priceMin = changedValues.priceMin
        }
        if (changedValueKey.includes("priceMax")) {
            changedObj.priceMax = changedValues.priceMax
        }
        if (changedValueKey.includes("transactionDateRange")) {
            if (changedValues.transactionDateRange === null) {
                changedObj.transactionDateStart = ""
                changedObj.transactionDateEnd = ""
            } else {
                changedObj.transactionDateStart = dayjs(changedValues?.transactionDateRange[0]).format('YYYY-MM-DD') || ""
                changedObj.transactionDateEnd = dayjs(changedValues?.transactionDateRange[1]).format('YYYY-MM-DD') || ""
            }
        }
        if (changedValueKey.includes("isReprintTag")) {
            changedObj.isReprintTag = changedValues.isReprintTag
        }
        dispatch(setItemParams({ ...itemTableParams, pageNum: 1, isCreate: false, ...changedObj }))
    }


    return (
        // <div style={{ width: variables.sideBarWidthExpand }}>
        <div className={styles.filterFormContainer}>

            <Form
                layout={'vertical'}
                form={form}
                onValuesChange={formOnChangeHandler}
            >
                <Form.Item
                    label={t("t_brand")}
                    name="brand"
                >
                    {/* <CommonSelection
                        optionKey={`brand`}
                        mode="multiple"
                    /> */}
                    <TickFilterBrandSelection
                        mode="multiple"
                    />
                </Form.Item>
                <Form.Item
                    label={t("t_color")}
                    name="color"
                >
                    <TickFilterColorSelection
                        mode="multiple"
                    />
                </Form.Item>
                <Form.Item
                  label={t("t_status")}
                  name="status"
                >
                  <Select
                    placeholder={t("t_pleaseSelect")}
                    options={[
                      { value: "IN_STOCK", label: t("t_inStock") },
                      { value: "SOLD", label: t("t_sold") },
                      { value: "RESERVED", label: t("t_reserved") },
                      { value: "ONLINE_RESERVED", label: t("t_onlineReserved") },
                      { value: "ONLINE_PURCHASE_RESERVED", label: t("t_onlinePurchaseReserved") },
                      { value: "UNLOCK", label: t("t_contractStatusUnlock") },
                      { value: "LOCKED", label: t("t_contractStatusLocked") },
                      { value: "CANCELLED", label: t("t_contractStatusCancelled") },
                    ]}
                    allowClear
                  />
                </Form.Item>
                <div style={{marginBottom: "8px"}}>{t("t_priceRange")}</div>
                <Row justify="space-between">
                    <Col>
                        <Form.Item name="priceMin">
                            <InputNumber prefix="$" min={0} max={form.getFieldValue().priceMax} controls={false}/>
                        </Form.Item>
                    </Col>
                    <Col style={{marginTop: "4px"}}>-</Col>
                    <Col>
                        <Form.Item name="priceMax">
                            <InputNumber prefix="$" min={form.getFieldValue().priceMin} controls={false}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label={t("t_transactionDateRange")}
                    name="transactionDateRange"
                >
                    <RangePicker
                        placeholder={[t("t_date"), t("t_date")]}
                        format={'YYYY-MM-DD'}
                        defaultPickerValue={[dayjs().subtract(1, "month"), dayjs().subtract(1, "month")]}
                    />
                </Form.Item>
                <Form.Item
                    label={t("t_transactionType")}
                    name="transactionTypeId"
                >
                    <Checkbox.Group
                        name="transactionTypeId"
                        options={tickTableState?.optionsOfTransactionType.map(item => ({
                            label: item[`name${tickTableState?.language}`],
                            value: item.id
                          }))}
                    />
                </Form.Item>
                <Form.Item
                    label={t("t_category")}
                    name="category"
                >
                    <div style={{ width: "100%" }}>
                        <TickCollapse header={t("t_bags")}>
                            <div
                                style={{ display: "flex", flexDirection: "column" }}
                            >
                                <button
                                    className={`${styles.categoryBtn} ${tickTableState.optionsOfCategory?.bags?.length === itemTableParams?.bagCats?.length ? styles.matchBtn : styles.notMatchBtn}`}
                                    value={t("t_all")}
                                    // onClick={(e) => onCategorySelect(e.target.value, e.target.value, true)}
                                    onClick={() => onSelectAll('bags')}
                                >
                                    {t("t_all")}
                                </button>
                                {/* <div style={{ display: 'flex', flexWrap: "wrap" }}> */}
                                <Row>
                                    {tickTableState.optionsOfCategory?.bags?.map((bag, index) => {
                                        let isMatch = itemTableParams.bagCats?.filter(item => item === bag?.itemSubCatKey).length > 0
                                        return (
                                            <Col xs={11} key={`category_bag_${bag.value}`}>
                                                <button
                                                    // onClick={() => onCategorySelect(bag.label, bag.value, true)}
                                                    onClick={() => onBagsSelect(bag)}
                                                    className={`${styles.categoryBtn} ${isMatch ? styles.matchBtn : styles.notMatchBtn}`}
                                                >
                                                    {bag.label}
                                                </button>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </div>
                        </TickCollapse>
                    </div>
                    <div style={{ width: "100%" }}>
                        <TickCollapse header={t("t_subCatsAccessories")}>
                            <button
                                className={`${styles.categoryBtn} ${tickTableState.optionsOfCategory?.accessories.length ===  itemTableParams?.accessoryCats?.length ? styles.matchBtn : styles.notMatchBtn}`}
                                value={t("t_all")}
                                // onClick={(e) => onCategorySelect(e.target.value, e.target.value, false)}
                                onClick={(e) => onSelectAll('accessories')}
                            >
                                {t("t_all")}
                            </button>
                            <Row>
                                {tickTableState.optionsOfCategory?.accessories?.map((accessory, index) => {
                                    let isMatch = itemTableParams?.accessoryCats?.filter(item => item === accessory?.itemSubCatKey).length > 0
                                    return (
                                        <Col xs={11} key={`cat_name_${accessory.value} `}>
                                            <button
                                                // onClick={() => onCategorySelect(accessory.label, accessory.value, false)}
                                                onClick={() => onAccessoriesSelect(accessory)}
                                                className={`${styles.categoryBtn} ${isMatch ? styles.matchBtn : styles.notMatchBtn}`}
                                            >
                                                {accessory.label}
                                            </button>
                                        </Col>)
                                })}
                            </Row>
                        </TickCollapse>
                    </div>
                </Form.Item >
                <Form.Item
                    label={t("t_reprintTag")}
                    name="isReprintTag"
                    valuePropName={"checked"}
                >
                    <Switch />
                </Form.Item>
            </Form >
        </div >
    )
}

export default memo(TickFilterForm)