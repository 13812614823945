import { useTranslation } from "react-i18next";
import { TickLocalModal } from "./TickLocalModal";
import styles from "./consignmentModalV3.module.scss";
import { Form, Input, InputNumber } from "antd";
import React, { useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, showModal } from "../redux/tickModalSilce";
import SystemRoleApi from "../service/systemApi/systemRoleApi";
import SystemPermissionApi from "../service/systemApi/systemPermissionApi";


const PermissionModal = (props) => {
  const { showMasterModal, setShowModal, buttonType, width, modalData, listByPage, activeTab } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch()

  useEffect(() => {
    if (buttonType === 'update') {
      form.setFieldsValue({
        name: modalData.name,
        remark: modalData.remarks,
      })
    }
  }, [modalData])

  const onCloseModal = () => {
    form.resetFields();
    setShowModal(false);
  };

  const onHandleSubmit = async (e) => {
    if (buttonType === 'add') {
      const result = form.getFieldsValue()
      await SystemPermissionApi.save(result).then((res) => {
        setShowModal(false);
        listByPage();
        if (res.success) {
          dispatch(
            showModal({
              key: "success",
              header: "Add successfully",
              callback: () => {
                dispatch(closeModal());
              },
            })
          );
        } else {
          dispatch(
            showModal({
              key: "error",
              header: "This is an error message",
              msg: res.msg,
              callback: () => {
                dispatch(closeModal());
              },
            })
          );
        }
      })
    } else {
      const result = form.getFieldsValue()
      await SystemPermissionApi.update(modalData.id, result).then((res) => {
        setShowModal(false);
        listByPage();
        if (res.success) {
          dispatch(
            showModal({
              key: "success",
              header: "Update successfully",
              callback: () => {
                dispatch(closeModal());
              },
            })
          );
        } else {
          dispatch(
            showModal({
              key: "error",
              header: "This is an error message",
              msg: res.msg,
              callback: () => {
                dispatch(closeModal());
              },
            })
          );
        }
      })
    }
  };

  return (
    <TickLocalModal
      onSubmit={onHandleSubmit}
      showLocalModal={showMasterModal}
      onCancel={onCloseModal}
      title={buttonType === 'add' ? `${t("t_add")} (${t("t_permission")})` : `${t("t_edit")} (${t("t_permission")})`}
      form={form}
      width={width}
      setVisible={setShowModal}
    >
      <div className={styles.drawerContainer}>
        <div className={styles.border} />
        <Form
          layout={'vertical'}
          form={form}
        >
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div style={{ width: "100%", margin: "10px" }}>
              <Form.Item
                label={t("t_name")}
                name="name"
                rules={[{ required: true }]}
              >
                <Input
                  name="name"
                  placeholder={t("t_pleaseEnter")}
                />
              </Form.Item>
              <Form.Item
                label={t("t_remark")}
                name="remark"
                // rules={[{ required: true }]}
              >
                <Input
                  name="remark"
                  placeholder={t("t_pleaseEnter")}
                />
              </Form.Item>
            </div>
          </div>
        </Form >
      </div>
      {/* </div> */}
    </TickLocalModal>
  );
};

export default memo(PermissionModal)