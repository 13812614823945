import React, { useEffect } from 'react'
import { TickGlobalModal } from './TickGlobalModal'
import styles from './modal.module.scss'
import errorIcon from "../assets/img/icon/modalErrorIcon.svg";
import { useSelector } from 'react-redux';

export const TickErrorModal = () => {
    const tickGlobalModalState = useSelector((state) => state.tickModal);
    useEffect(() => {
        console.log("tickGlobalModalState", tickGlobalModalState)
    }, [tickGlobalModalState])
    return (
        <TickGlobalModal
            header={
                <>
                    <img src={errorIcon} alt="error-icon" className={styles.icon} />
                    {tickGlobalModalState?.params?.header}
                </>
            }>
            {Array.isArray(tickGlobalModalState?.params?.msg) ? tickGlobalModalState?.params?.msg.map((msg, index) =>
                <div className={styles.content} key={`msg_${index}`}>{msg}</div>
            ) :
                <div className={styles.content}>{tickGlobalModalState?.params?.msg}</div>
            }
        </TickGlobalModal>
    )
}