import React from 'react'
import { TickGlobalModal } from './TickGlobalModal'
import styles from './modal.module.scss'
import successIcon from "../assets/img/icon/modalSuccessIcon.svg";
import { useSelector } from 'react-redux';

export const TickSuccessModal = () => {
    const tickGlobalModalState = useSelector((state) => state.tickModal);
    return (
        <TickGlobalModal
            header={
                <>
                    <img src={successIcon} alt="success-icon" className={styles.icon} />
                    {tickGlobalModalState?.params?.header}
                </>
            }>
            <div className={styles.content}>{tickGlobalModalState?.params?.msg}</div>
        </TickGlobalModal>
    )
}
