import React, { memo } from "react";
import { footerText } from "../../config/systemConfig";
import styles from "./footer.module.scss";

const Footer = (props) => {
  const { color } = props;
  return (
    <div className={styles.footer} style={{ color: color }}>
      {footerText}
    </div>
  );
};
export default memo(Footer);
