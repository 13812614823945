import React from "react";
import styles from "./report.module.scss";
import { Button, Col, Form, Input, InputNumber, Row, Select, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { fieldsTypeEnums } from "./dummyConfig";
import { debounce } from "./../../utils/commonUtils"

// const ReportCommonFieldTypes = (props) => {
//   const options = [];
//   for (const [key, value] of Object.entries(fieldsTypeEnums)) {
//     options.push({ value: key, label: value })
//   }
//   return (
//     <Select options={options} onChange={(e) => props.onChange?.(e)} />
//   );
// }

const searchTem = {
  "labelEn": "",
  "labelTc": "",
  "type": "input",
  "defaultValue": "",
  "service": "",
  "span": "",
  "extraProps": {},
  "sqlObj": {
    "sqlValue": ""
  }
};

const subTem =
  `,"submit": {
    "api": ""
}}`


const ReportAdminForm = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    if (error) {
      props.form.setFields([{
        name: "searchConfig",
        errors: ["Invalid JSON Format"]
      }])
    }
  }, [error])

  const handleOnNameChange = (e) => {
    let value = e.target.value;
    let sqlValue = props.form.getFieldValue("sql");
    let newSql;
    if (value.length > 0) {
      value = value.charAt(0).toLowerCase() + value.slice(1)
      if (!value.includes("_") && !value.includes(" ") && !value.includes(",")) {
        value = value.split(/(?=[A-Z])/)
        value.forEach((str, index) => {
          value[index] = str.charAt(0).toLowerCase() + str.slice(1)
        })
        value = value.join("_")
      } else {
        value = value.split(new RegExp(`[" ,"]`))
        if (value.length > 1) {
          value.forEach((str, index) => {
            value[index] = str.charAt(0).toLowerCase() + str.slice(1)
          })
          value = value.join("_");
        }
      }
    }
    const match = /\bFROM\b/.exec(sqlValue);
    if (match) {
      newSql = sqlValue.slice(0, match.index) + sqlValue.slice(match.index).split(" ")[0] + " " + value;
    } else {
      if (!sqlValue) sqlValue = "";
      newSql = sqlValue + " FROM " + value
    }
    props.form.setFieldsValue({ sql: newSql })
  };

  const handleOnOrderChange = (e) => {
    setError(false);
    let value = e.target.value;
    let sqlValue = props.form.getFieldValue("sql");
    let newSql;
    let newConfig = "";
    if (value.length > 0) {
      let valueArr = value.split(",");
      valueArr.forEach((str, index) => {
        let dbKey = ""
        if (str.charAt(0) === str.charAt(0).toUpperCase() && !str.includes("-") && !str.includes("_")
          || str.includes(" ") && !str.includes("-") && !str.includes("_")) {
          let plainText = str.charAt(0).toLowerCase() + str.slice(1);
          plainText = plainText.split(" ");
          plainText.forEach((subStr, subIndex) => {
            plainText[subIndex] = subStr.charAt(0).toLowerCase() + subStr.slice(1)
          })
          plainText = plainText.join("_")
          plainText = plainText.split(/(?=[A-Z])/);
          if (plainText.length > 1) {
            plainText.forEach((subStr, subIndex) => {
              plainText[subIndex] = subStr.charAt(0).toLowerCase() + subStr.slice(1)
            })
            plainText = plainText.join("_")
          }
          valueArr[index] = `${plainText} AS "${str}"`
          dbKey = plainText
        } else {
          let plainText = str.split(/(?=[A-Z])/);
          if (plainText.length > 1) {
            plainText.forEach((subStr, subIndex) => {
              plainText[subIndex] = subStr.charAt(0).toLowerCase() + subStr.slice(1)
            })
            plainText = plainText.join("_")
            valueArr[index] = `${plainText} AS "${str}"`
            dbKey = plainText
          } else {
            valueArr[index] = str.charAt(0).toLowerCase() + str.slice(1)
          }
        }

        searchTem.labelEn = str;
        searchTem.labelTc = str;
        searchTem.sqlObj.sqlValue = `AND ${dbKey? dbKey : str} LIKE :${str}`
        const localTem = JSON.stringify(searchTem);
        newConfig += `${index === 0 ? "{" : ""} "${str}": ${localTem}${index !== valueArr.length - 1 ? "," : subTem}`;

      })
      value = valueArr.join(",")
    }

    const matchSelect = /\bSELECT\b/.exec(sqlValue);
    const matchFrom = /\bFROM\b/.exec(sqlValue);

    if (matchSelect) {
      if (matchFrom) {
        newSql = sqlValue.slice(matchSelect.index).split(" ")[0] + " " + value + " " + sqlValue.slice(matchFrom.index);
      } else {
        newSql = sqlValue.slice(matchSelect.index).split(" ")[0] + " " + value;
      }
    } else {
      if (matchFrom) {
        newSql = "SELECT " + value + " " + sqlValue.slice(matchFrom.index)
      } else {
        newSql = "SELECT " + value
      }
    }
    if (newConfig) {
      try {
        newConfig = JSON.stringify(JSON.parse(newConfig), null, 8)
      } catch (e) {
        setError(true)
      }
    }
    props.form.setFieldsValue({ sql: newSql, searchConfig: newConfig })
  };


  const handleOnSearchConfigChange = debounce((e) => {
    const inputValue = e.target.value;
    setError(false);
    try {
      const parsedValue = JSON.parse(inputValue);
      const formattedValue = JSON.stringify(parsedValue, null, 8);
      props.form.setFieldsValue({ searchConfig: formattedValue })
    } catch (error) {
      props.form.setFieldsValue({ searchConfig: inputValue })
      setError(true);
    }
  }, 1000)

  return (
    <div className={styles.adminForm}>
      <div className={styles.formWrapper}>
        <Form
          form={props.form}
          layout="vertical"
        >
          <Form.Item
            label={t("t_name")}
            rules={[{ required: true }]}
            name="name"
          >
            <Input onChange={(e) => props.isAutoGenConfig ? handleOnNameChange(e) : undefined} />
          </Form.Item>
          <Form.Item
            label={t("t_order")}
            rules={[{ required: true }]}
            name="order"
          >
            <Input onChange={(e) => props.isAutoGenConfig ? handleOnOrderChange(e) : undefined} />
          </Form.Item>
          <Form.Item
            label={t("t_sql")}
            rules={[{ required: true }]}
            name="sql"
          >
            <Input.TextArea autoSize={{ minRows: 10, maxRows: 10 }} />
          </Form.Item>
          <Form.Item
            label={t("t_searchConfig")}
            // rules={[{ required: true }]}
            name="searchConfig"
          >
            <Input.TextArea autoSize={{ minRows: 20, maxRows: 20 }} onChange={handleOnSearchConfigChange} />
          </Form.Item>
        </Form>
      </div>
      <Row gutter={[8, 0]} className={styles.functionalConfigsContainer}>
        <Col>Auto Generate Configs:</Col>
        <Col>
          <Switch checked={props.isAutoGenConfig} onChange={() => props.setIsAutoGenConfig(!props.isAutoGenConfig)} />
        </Col>
      </Row>
    </div>
  )
  // const [fields, setFields] = React.useState([]);
  // const { t } = useTranslation();
  // const formRefs = React.useRef([]);
  // React.useImperativeHandle(ref, () => formRefs.current)

  // const addNewField = () => {
  //   setFields(prev => [...prev, RenderFormItem(prev.length)])
  // };

  // const removeFieldByIndex = (index) => {
  //   setFields(prev => {
  //     const newArray = [...prev];
  //     newArray.splice(index, 1);
  //     return newArray;
  //   });
  // };

  // const addFormRef = (formRef) => {
  //   formRefs.current.push(formRef);
  // };


  // const RenderFormItem = (index) => {
  //   return (
  //     <div className={styles.formItemSet} key={`formItem_${index}`}>
  //       <Form
  //         layout="vertical"
  //         ref={addFormRef}
  //       >
  //         <Row justify={"space-between"} gutter={[8, 0]}>
  //           <Col span={12}>
  //             <Form.Item
  //               label={t("t_searchFieldType")}
  //               rules={[{ required: true }]}
  //               name={`type${index}`}
  //             >
  //               <ReportCommonFieldTypes />
  //             </Form.Item>
  //           </Col>
  //           <Col span={12}>
  //             <Form.Item
  //               label={t("t_columnName")}
  //               rules={[{ required: true }]}
  //               name={`key${index}`}
  //             >
  //               <Input />
  //             </Form.Item>
  //           </Col>
  //           <Col span={12}>
  //             <Form.Item
  //               label={t("t_labelEn")}
  //               rules={[{ required: true }]}
  //               name={`labelEn${index}`}
  //             >
  //               <Input />
  //             </Form.Item>
  //           </Col>
  //           <Col span={12}>
  //             <Form.Item
  //               label={t("t_labelTc")}
  //               rules={[{ required: true }]}
  //               name={`labelTc${index}`}
  //             >
  //               <Input />
  //             </Form.Item>
  //           </Col>
  //           <Col span={12}> {/* conditional */}
  //             <Form.Item
  //               label={t("t_service")}
  //               rules={[{ required: true }]}
  //               name={`service${index}`}
  //             >
  //               <Input />
  //             </Form.Item>
  //           </Col>
  //           <Col span={12}> {/* conditional */}
  //             <Form.Item
  //               label={t("t_options")}
  //               rules={[{ required: true }]}
  //               name={`options${index}`}
  //             >
  //               <Input />
  //             </Form.Item>
  //           </Col>
  //           <Col span={12}>
  //             <Form.Item
  //               label={t("t_defaultValue")}
  //               // rules={[{ required: true }]}
  //               name={`defaultValue${index}`}
  //             >
  //               <Input />
  //             </Form.Item>
  //           </Col>
  //           <Col span={24}>
  //             <Form.Item
  //               label={t("t_extraProps")}
  //               // rules={[{ required: true }]}
  //               name={`extraProps${index}`}
  //             >
  //               <Input.TextArea />
  //             </Form.Item>
  //           </Col>
  //           <Col span={12}>
  //             <Form.Item
  //               label={t("t_span")}
  //               name={`span${index}`}
  //             >
  //               <InputNumber min={0} max={24} />
  //             </Form.Item>
  //           </Col>
  //         </Row>
  //         <Row>
  //           <Button type="primary" danger style={{ width: "100%" }} onClick={() => removeFieldByIndex(index)}>X</Button>
  //         </Row>
  //       </Form>
  //     </div>
  //   )
  // };

  // const RenderFormItemList = () => {
  //   return fields.map(item => (item))
  // };

  // return (
  //   <div className={styles.adminForm}>
  //     <div className={styles.formWrapper}>
  //       {RenderFormItemList()}
  //       <div className={styles.addNewItem}>
  //         <div className={styles.addNewItemWrapper}>
  //           <span>{t("t_addNewRow")}</span>
  //           <Button type="primary" onClick={addNewField}>{t("t_add")}</Button>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )
});

export default ReportAdminForm;