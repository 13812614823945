import { endpoint } from "../../config/envConfig";
import { MAG } from "../../service/api";
const prefix = "api";
const suffix = "listSelectOptions";

export default class ReportCommonApi {
  static listSelectOptions(service) {
    if (!service) return null;
    let uri = `/${prefix}/${service}/${suffix}`;
    // if (service.includes("/")) {
    //   uri = service;
    // }

    return MAG.get(uri);
  }

  static listAllTabOptions() {
    const uri = `/${prefix}/report/listAllTabOptions`;
    return MAG.get(uri);
  }

  static listSearchConfigByTabName(tabName) {
    let uri = `/${prefix}/report/listSearchConfigByTabName?`;
    if (tabName) {
      uri += `&tabName=${encodeURIComponent(tabName)}`;
    }
    return MAG.get(uri);
  }

  static save(body) {
    const uri = `/${prefix}/report/save`;
    return MAG.post(uri, body);
  }

  static update(id, body) {
    const uri = `/${prefix}/report/update/${id}`;
    return MAG.post(uri, body);
  }

  static listByPage(page, pageSize, id, params) {
    let uri = `/${prefix}/report/listByPage/${page}/${pageSize}/${id}?`;
    if (params) {
      const keys = Object.keys(params);
      keys.forEach((item) => {
        if (params[item] || params[item] === "") {
          if (item === "searchParams") {
            uri = uri.concat(
              `&${item}=${encodeURIComponent(JSON.stringify(params[item]))}`
            );
          } else {
            uri = uri.concat(`&${item}=${encodeURIComponent(params[item])}`);
          }
        }
      });
    }
    return MAG.get(uri);
  }

  static delete(id) {
    let uri = `/${prefix}/report/delete/${id}`;
    return MAG.put(uri);
  }

  static export(params) {
    let uri = `/${prefix}/report/export?`;
    const keys = Object.keys(params);
    keys.forEach((item) => {
      if (params[item] || params[item] === "") {
        if (item === "searchParams") {
          uri = uri.concat(
            `&${item}=${encodeURIComponent(JSON.stringify(params[item]))}`
          );
        } else {
          uri = uri.concat(`&${item}=${encodeURIComponent(params[item])}`);
        }
      }
    });
    return MAG.download(uri);
  }
}
