import { Modal, Form, Input, Divider, Button } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setIsChangePwModalOpen } from '../../redux/tickTableSlice';
import { useTranslation } from 'react-i18next';
import { showModal, closeModal, closeSpecificModal } from '../../redux/tickModalSilce';
import styles from './changePwModal.module.scss';
import SystemApi from '../../service/systemApi/systemApi';

const ChangePwModal = () => {
  const dispatch = useDispatch();
  const isChangePwModalOpen = useSelector(state => state.tickTable.isChangePwModalOpen);
  const [isDisabled, setIsDisabled] = useState(true);

  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onCancel = () => {
    dispatch(setIsChangePwModalOpen(false));
  }

  const handleSubmit = async () => {
    const { oldPassword, newPassword } = form.getFieldsValue();
    const userId = JSON.parse(localStorage.getItem("USERINFO"))?.id;
    let postBody = { userId, oldPassword, newPassword };
    try {
      const resp = await SystemApi.update(postBody);
      if (!resp.success) {
        dispatch(
          showModal({
            key: "error",
            header: "Password Incorrect",
            msg: resp?.msg,
            callback: () => {
                dispatch(closeSpecificModal({key: 'error'}));
              }
            }
          )
        )
        return;
      }
      dispatch(
        showModal({
          key: 'success',
          header: 'Password has been changed!',
          msg: resp?.msg,
          callback: () => {
            dispatch(closeModal());
            dispatch(setIsChangePwModalOpen(false));
          }
        })
      )
    } catch (e) {
      console.error(e)
    }
  }

  const passwordRules = [
    {require: true,
      message: "Please input your password!"
    },
    { 
      min: 8,
      message: 'Password length must be at least 8 characters long'
    }
  ]

  return (
    <Modal
      open={isChangePwModalOpen}
      maskClosable={false}
      title={t("t_changePw")}
      onCancel={onCancel}
      footer={[<Button className={styles.saveBtn} disabled={isDisabled} onClick={handleSubmit} children={t("t_save")} key="close_btn"/>]}
      centered
    >
      <Divider />
      <Form
        layout='vertical'
        form={form}
      >
        <Form.Item label={t("t_oldPassword")} name="oldPassword" rules={[{ required: true }]}>
          <Input.Password placeholder={t("t_pleaseEnterOldPassword")} />
        </Form.Item>
        <Form.Item label={t("t_newPassword")} name="newPassword" rules={passwordRules}>
          <Input.Password placeholder={t("t_pleaseEnterNewPassword")} />
        </Form.Item>
        <Form.Item label={t("t_inputPasswordAgain")} name="confirmPassword" hasFeedback dependencies={["password"]} rules={[...passwordRules, { required: true, message: "Please confirm your password!" }, ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('newPassword') !== value) {
              setIsDisabled(true)
              return Promise.reject(new Error('The new password that you entered do not match!'));
            }
            setIsDisabled(false)
            return Promise.resolve();
          },
        }),]}>
          <Input.Password placeholder={t("t_pleaseEnterNewPassword")}/>
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default ChangePwModal