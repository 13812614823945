import React from 'react';
import { Modal, Upload, Button } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import styles from './tickUploadModal.module.scss';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx/xlsx.mjs';
import { excelColsObj, itemImport } from '../../utils/excelFieldChecker';
import dayjs from 'dayjs';

const TickUploadModal = (props) => {
  const { open, onOk, onCancel, uploadProps , onChange, loading, onSubmitDisabled} = props;
  const [ template, setTemplate ] = React.useState(null);
  const { t } = useTranslation();

  function generateExcelFile() {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    
    // Create a worksheet
    // const worksheet = XLSX.utils.json_to_sheet([excelColsObj]);
    const worksheet = XLSX.utils.json_to_sheet(itemImport);
    
    const columnWidths = new Array(Object.keys(excelColsObj).length).fill({wch: 20});
    worksheet['!cols'] = columnWidths;

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
    // Generate the Excel file
    XLSX.writeFile(workbook, `import_template.xlsx`);
  }

  return (
    <Modal
      title={t('t_upload')}
      width={500}
      open={open}
      okText={t('t_save')}
      onOk={onOk}
      cancelText={t('t_cancel')}
      onCancel={onCancel}
      centered
      maskClosable={false}
      className={styles.tickModal}
      confirmLoading={loading}
      okButtonProps={
        {
          disabled: onSubmitDisabled
        }
      }
    >
      <div className={styles.btnContainer}>
        <Button onClick={generateExcelFile}>{t('t_downloadTemplate')}</Button>
      </div>
      <Upload.Dragger {...uploadProps} onChange={onChange}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or other
          band files
        </p>
      </Upload.Dragger>
      <div>
    </div>
    </Modal>
  )
}

export default TickUploadModal