import React, { memo, useEffect, useState } from "react";
import TitleBar from "../../components/titleBar/TitleBar";
import variables from "../../theme/variable.scss";
import TitleBarItemPaginationContent from "../../components/titleBarItemPaginationContent/TitleBarItemPaginationContent";
import TickVerticalCollapse from "../../components/tickVerticalCollapse/TickVerticalCollapse";
import {
  EditOutlined,
  DeleteOutlined,
  PrinterOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import TickDrawer from "../../components/tickDrawer/TickDrawer";
import TickTabsContent from "../../components/tickTabContent/TickTabsContent";
import { useTranslation } from "react-i18next";
import styles from './item.module.scss';
import TickFilterForm from "../../components/tickFilterForm/TickFilterForm";
import { tickLog } from "../../utils/logger";
import { useDispatch, useSelector } from "react-redux"
import { closeModal, showModal } from "../../redux/tickModalSilce";
import { alertBatchPrintCount, batchPrintDelay, lang } from "../../config/systemConfig";
import ItemApi from "../../service/itemApi/itemApi";
import { setItemTableData, setItemsDrawerProductDetails, setItemsStateToInitialState, setShowItemDrawer, setShowFilterCollapse, setItemParams } from "../../redux/tickTableSlice";
import TickTable from "../../components/tickTable/TickTable";
import { STATUS } from "../../utils/statusEnum";
import i18n from "../../i18n";
import TickUploadModal from "../../components/tickModal/TickUploadModal";
import { App, Grid, Tooltip, Typography } from 'antd';
import { excelFieldChecker } from "../../utils/excelFieldChecker";
import printerApi from "../../service/systemApi/printerApi";
import { handleTableMultiOptions } from "../../utils/commonUtils";
import { useNavigate } from "react-router";

const Item = (props) => {
  const { setShowProductModal, setBtn, isReload, setModalData } = props
  // const [open, setOpen] = useState(false); // dummy-drawer: Component usage guide - open drawer
  const [details, setDetails] = useState(null); // dummy-drawer: Component usage guide - content's of drawer
  const [dataSource, setDataSource] = useState([]);
  const [itemTableLoading, setItemTableLoading] = useState(false);
  const [language, setLanguage] = useState(i18n.language);
  const [showImportModal, setShowImportModal] = useState(false);
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { message } = App.useApp();
  const permissions = JSON.parse(localStorage.getItem('PERMISSION'))

  const dispatch = useDispatch();
  const itemTableParams = useSelector(state => state.tickTable.itemParams);
  const open = useSelector(state => state.tickTable.isItemDrawerOpen);
  const isFilterOpen = useSelector(state => state.tickTable.isItemFilterOpen);

  const itemsDrawerProductDetails = useSelector((state) => state.tickTable.itemsDrawerProductDetails);
  const isDrawerOpen = useSelector(state => state.tickTable.isItemDrawerOpen);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setShowItemDrawer(false))
    if (itemTableParams.totalCount && itemTableParams.totalCount !== 0) return;
    setDummyDataSource()
  }, [itemTableParams])

  const setDummyDataSource = async () => {
    setItemTableLoading(true);
    try {
      const resp = await ItemApi.listByPage(itemTableParams);
      tickLog("all product data", resp)
      if (resp.success) {
        const { data, msg } = resp
        setDataSource(data.array)
        // dispatch(
        //   showModal({
        //     key: "success",
        //     header: "fetch all products has completed!",
        //     msg: msg,
        //     // showCloseBtn: true,
        //     callback: () => {
        //       dispatch(closeModal());
        //     },
        //   })
        // );

        dispatch(
          setItemTableData({ totalCount: data.totalCount })
        )
      }
    } catch {
    } finally {
      setItemTableLoading(false);
    }
    // dispatch(setItemTableData(dataSource))
  }

  const setOpen = (open = false) => {
    dispatch(setShowItemDrawer(open))
  }

  const setIsFilterOpen = (open = false) => {
    dispatch(setShowFilterCollapse(open))
  }

  function handleDetails(record) {                   // dummy-drawer: Component usage guide -  open drawer and set content function
    setOpen(true);
    dispatch(setItemsDrawerProductDetails(record))
    // dispatch(setTempItemData(record))
    // setDetails(record);
  }

  function onEdit(record) {
    setModalData(record)
    setShowProductModal(true)
    setBtn("update")
  }

  function onDelete(record) {
    dispatch(
      showModal({
        key: "warning",
        header: t("t_delete"),
        msg: t("t_confirmDelete"),
        showCloseBtn: true,
        callback: async () => {

          try {
            let resp = await ItemApi.delete(record.id)
            if (resp.success) {
              const { msg } = resp
              dispatch(
                showModal({
                  key: "success",
                  header: "item deleted",
                  msg: msg,
                  // showCloseBtn: true,
                  callback: () => {
                    dispatch(closeModal());
                  },
                })
              );
              setDummyDataSource()
              dispatch(setShowItemDrawer(false));
            } else {
              const { msg } = resp
              dispatch(
                showModal({
                  key: "error",
                  header: "This is an error message",
                  msg: msg,
                  // showCloseBtn: true,
                  callback: () => {
                    dispatch(closeModal());
                  },
                })
              );
            }
          } catch (e) {
            console.log(e);
          } finally {
            dispatch(closeModal());
          }
        },
      })
    );
  }

  const onReload = () => {
    dispatch(setItemsStateToInitialState());
    dispatch(setShowItemDrawer(false))
    // dispatch(clearItemParams())
    // setDummyDataSource() 
  }

  const onPrint = async (record) => {
    setItemTableLoading(true);
    
    const printerId = JSON.parse(localStorage.getItem("settings")).printerId;
    try {
      const resp = await ItemApi.print(printerId, record.itemNum);
      if (!resp.success) {
        dispatch(
          showModal({
            key: "error",
            header: "Fail",
            msg: resp.msg,
            // showCloseBtn: true,
            callback: () => {
              dispatch(closeModal());
            },
          })
        );
      } else {
        tickLog("Print Tag", "Success")
      }
    } catch (e) {
      console.log(e);
    } finally {
      setItemTableLoading(false);
    }
    // try {
    //   const resp = await printerApi.printLabel(record.itemNum);
    //   if (!resp.success) {
    //     const errMsg = resp.msg.split('|');
    //     const missingKey = errMsg.length > 1 ?  errMsg[1].split(',').map(value => {let msg = ''; msg += `${t(value)} `; return msg;}) : null;
    //     dispatch(
    //       showModal({
    //         key: "error",
    //         header: errMsg.length > 1 ? errMsg[0]: resp.msg,
    //         msg: missingKey,
    //         // showCloseBtn: true,
    //         callback: () => {
    //           dispatch(closeModal());
    //         },
    //       })
    //     );
    //   } else {
    //     dispatch(
    //       showModal({
    //         key: "success",
    //         header: "Success",
    //         msg: "",
    //         // showCloseBtn: true,
    //         callback: () => {
    //           dispatch(closeModal());
    //         },
    //       })
    //     );
    //   }
    // } catch (e) {
    //   console.log(e);
    // } finally {
    //   setItemTableLoading(false);
    // }
  }

  const handleSortKey = (sortOrder) => {
    let sortKey;
    if (sortOrder === 'ascend') {
      sortKey = 'ASC'
    }

    if (sortOrder === 'descend') {
      sortKey = 'DESC'
    }
    return sortKey;
  }

  const onChange = (pagination, filter, sorter) => {
    let sortOptions = {};
    if (Array.isArray(sorter)) {
      sorter.forEach(sortEle => {
        sortOptions[sortEle.columnKey] = handleSortKey(sortEle.order);
      })
    } else {
      sortOptions[sorter.columnKey] = handleSortKey(sorter.order);
    }

    if (!sorter.order) {
      delete sortOptions[sorter.columnKey]
    }

    dispatch(setItemParams({
      ...itemTableParams,
      sortOptions,
      isCreate: false
    }))
  }

  const sortOrder = (key) => {
    if (itemTableParams.sortOptions[key] === 'ASC') {
      return 'ascend';
    }
    if (itemTableParams.sortOptions[key] === 'DESC') {
      return 'descend';
    }
    return undefined;
  }

  const columns = [
    {
      title: t("t_productNum"),
      dataIndex: "itemNum",
      key: "itemNum",
      sorter: {
        multiple: 10
      },
      // width: "18vh",
      width: 200,
      sortOrder: sortOrder('itemNum'),
      render: (
        _,
        record                                      // dummy-drawer: Component usage guide -  use table to open drawer
      ) => (
        <Typography.Text copyable={{ text: record.itemNum }}>{ record.itemNum || "-" }</Typography.Text>
      ),
      // ellipsis: true,
    },
    {
      title: t("t_brand"),
      dataIndex: "brand",
      key: "brand",
      sorter: {
        multiple: 9
      },
      sortOrder: sortOrder('brand'),
      render: (_, record) => (
        <div
        className={styles.tableCell}
        onClick={() => handleDetails(record)}
        >
          {_?.[`name${lang}`] || "-"}
        </div>
      ),
      // ellipsis: true,
    },
    {
      title: t("t_productName"),
      dataIndex: `itemName${lang}`,
      key: `itemName${lang}`,
      sorter: {
        multiple: 8
      },
      sortOrder: sortOrder(`itemName${lang}`),
      render: (_, record) => (
        <div
        className={styles.tableCell}
        onClick={() => handleDetails(record)}
        >
          {_ || "-"}
        </div>
      ),
      // ellipsis: true,
    },
    {
      title: t("t_color"),
      dataIndex: 'color',
      key: 'color',
      sorter: {
        multiple: 7
      },
      sortOrder: sortOrder('color'),
      render: (_, record) => (
        <div
          className={styles.tableCell}
          onClick={() => handleDetails(record)}
        >
          {handleTableMultiOptions(record?.colors, language)}
          {/* {record?.colors.length > 0 ? record?.colors?.map((color, index) => `${color?.[language === "tc_hk" ? "nameTc" : "nameEn"] || "-"}${index === record?.colors?.length - 1 ? "" : "/"}`) : "-"} */}
        </div>
      ),
      // ellipsis: true,
    },
    {
      title: t("t_cortex"),
      dataIndex: 'cortex',
      key: 'cortex',
      sorter: {
        multiple: 6
      },
      sortOrder: sortOrder('cortex'),
      render: (_, record) => (
        <div
          className={styles.tableCell}
          onClick={() => handleDetails(record)}
        >
          {handleTableMultiOptions(record?.cortexs, language)}
          {/* {record?.cortexs.length > 0 ? record?.cortexs?.map((cortex, index) => `${cortex?.[language === "tc_hk" ? "nameTc" : "nameEn"] || "-"}${index === record?.cortexs?.length - 1 ? "" : "/"}`) : "-"} */}
        </div>
      ),
      // ellipsis: true,
    },
    {
      title: t("t_status"),
      dataIndex: 'status',
      key: 'status',
      sorter: {
        multiple: 5
      },
      sortOrder: sortOrder('status'),
      render: (_, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleDetails(record)}
          className={`${styles.tableCell} ${styles[`${STATUS[`${_}`]?.class}`] || styles.default}`}
        >
          {t(STATUS?.[`${_}`]?.label || "-")}
        </div>
      ),
      // ellipsis: true,
    },
    // {
    //   title: t("t_branchNum"),
    //   dataIndex: 'branchNum',
    //   key: 'branchNum',
    //   sorter: {
    //     multiple: 5
    //   },
    //   sortOrder: sortOrder('branchNum'),
    //   render: (_, record) => (
    //     <div
    //       style={{ cursor: "pointer" }}
    //       onClick={() => handleDetails(record)}
    //     >
    //       {_ === '9999' ? '-' :  _ || "-" }
    //     </div>
    //   )
    // },
    {
      title: t("t_category"),
      dataIndex: "category", //category
      key: "category", //category
      sorter: {
        multiple: 4
      },
      sortOrder: sortOrder('category'),
      render: (_, record) => {
        return (<div
          className={styles.tableCell}
          onClick={() => handleDetails(record)}
        >{_?.[language === "tc_hk" ? "nameTc" : "nameEn"] || "-"}</div>)
      },
      // ellipsis: true,
    },
    {
      title: t("t_stockLocation"),
      dataIndex: 'stockLocation',
      key: 'stockLocation',
      sorter: {
        multiple: 3
      },
      sortOrder: sortOrder('stockLocation'),
      render: (_, record) => (
        <div
          className={styles.tableCell}
          onClick={() => handleDetails(record)}
        >
          {_ ? _ : "-"}
        </div>
      ),
      // ellipsis: true,
    },
    {
      title: t("t_price"),
      dataIndex: "amt",
      key: "amt",
      sorter: {
        multiple: 2
      },
      sortOrder: sortOrder('amt'),
      render: (_, record) => (
        <div
          className={styles.tableCell}
          onClick={() => handleDetails(record)}
        >
          {_ || "-"}
        </div>
      ),
      // ellipsis: true,
      // render: (_, record) => {
      //   <>HK$ {record} </>
      // }
    },
    // {
    //   title: t("t_stock"),
    //   dataIndex: "qty",
    //   key: "qty",
    //   sorter: {
    //     multiple: 2
    //   },
    //   sortOrder: sortOrder('qty'),
    //   render: (_, record) => (
    //     <div
    //       className={styles.tableCell}
    //       onClick={() => handleDetails(record)}
    //     >
    //       {record?.status === 'SOLD' ? 0 : _ || "-"}
    //     </div>
    //   ),
    //   // ellipsis: true,
    // },
    {
      title: t("t_transactionType"),
      dataIndex: "transactionType",
      key: "transactionType",
      sorter: true,
      sorter: {
        multiple: 1
      },
      sortOrder: sortOrder('transactionType'),
      render: (_, record) => (
        <div
          className={styles.tableCell}
          onClick={() => handleDetails(record)}
        >
          {_?.[language === "tc_hk" ? "nameTc" : "nameEn"] || "-"}
        </div>
      ),
      // ellipsis: true,
    },
    {
      title: t("t_function"),
      dataIndex: "operate",
      key: "operate",
      fixed: 'right',
      width: 200,
      render: (_,
        record
      ) =>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {
            permissions.findIndex((item) => item === "item:edit") > -1 ?
              // <EditOutlined style={{ color: variables.themeBlue, padding: "5px"}} onClick={() => onEdit(record)} /> :
              <EditOutlined style={{ color: variables.themeBlue, fontSize: 24 }} onClick={() => onEdit(record)} /> :
              <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
                {/* <EditOutlined style={{ color: "grey", padding: "5px" }} /> */}
                <EditOutlined style={{ color: "grey", fontSize: 24}} />
              </Tooltip>
          }
          {
            record.consignorId ?
              // <EditOutlined style={{ color: variables.themeBlue, padding: "5px"}} onClick={() => onEdit(record)} /> :
              <Tooltip title={t("t_consignor")} mouseEnterDelay={0.2}>
                <TeamOutlined style={{ color: variables.themeBlue, fontSize: 24 }} onClick={() => navigate(`/main/consignment/${record.consignorId}`)} />
              </Tooltip> :
              <Tooltip title={`沒有${t("t_consignor")}`} color={"#8c8c8c"} mouseEnterDelay={0.2}>
                {/* <EditOutlined style={{ color: "grey", padding: "5px" }} /> */}
                <TeamOutlined style={{ color: "grey", fontSize: 24}} />
              </Tooltip>
          }
          {
            permissions.findIndex((item) => item === "item:printTag") > -1 ?
              <PrinterOutlined style={{ color: variables.themeBlue, fontSize: 24 }} onClick={() => onPrint(record)} /> :
              // <PrinterOutlined style={{ color: variables.themeBlue, padding: "5px" }} onClick={() => onPrint(record)} /> :
              <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
                {/* <PrinterOutlined style={{ color: "grey", padding: "5px" }} /> */}
                <PrinterOutlined style={{ color: "grey", fontSize: 24 }} />
              </Tooltip>
          }
          {
            permissions.findIndex((item) => item === "item:delete") > -1 ?
              // <DeleteOutlined style={{ color: variables.themeRed, padding: "5px" }} onClick={() => onDelete(record)} /> :
              <DeleteOutlined style={{ color: variables.themeRed, fontSize: 24 }} onClick={() => onDelete(record)} /> :
              <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
                {/* <DeleteOutlined style={{ color: "grey", padding: "5px" }} /> */}
                <DeleteOutlined style={{ color: "grey", fontSize: 24 }} />
              </Tooltip>
          }
        </div>,
      // ellipsis: true,
    }];

  const rowClassName = (record) => (
    record.id === itemsDrawerProductDetails.id && open ? "tableRowSelected" : ""
  )

  const uploadProps = {
    accept: ".xls,.xlsx",
    name: 'file',
    fileList: file,
    customRequest: async (options) => {
      const { file, onError, onSuccess } = options;
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        message.error('File must smaller than 10MB!!');
        onError("File must smaller than 10MB!!", file);
        return
      }
      const isAllFields = await excelFieldChecker(file);
      if (!isAllFields) {
        message.error("Fields provided in the excel mismatch with the template")
        onError("Fields provided in the excel mismatch with the template", file);
        return
      }
      setFile([file]);

    },
    onRemove: () => {
      setFile(null);
    },
    maxCount: 1,
  };

  const postFileDate = () => {
    setLoading(true);
    try {
      ItemApi.upload(file[0]).then((res) => {
        setShowImportModal(false);
        setFile(null);
        dispatch(setItemsStateToInitialState({ isCreate: true }));

        if (res.contentType === undefined) { // All data are normal, already insert to table
          message.success('Import Success!')
          return
        }

        let blob = new Blob([res.data], { type: res.contentType });
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = blobURL;
        tempLink.setAttribute(
          "download",
          decodeURI(
            res.contentDisposition.split(";")[1].split("=")[1]
          )
        );
        if (typeof tempLink.download === "undefined") {
          tempLink.setAttribute("target", "_blank");
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
        message.warning('Partly imported, please check remaining.')
      })

    } catch (e) {
      message.error('File Import Failed!');
    } finally {
      setLoading(false);
    }
  }
  const printWithDelay = (data, delay) => 
    // setTimeout(() => {
    //   console.log("gary", data);
    //   onPrint(data);
    // }, delay);
    new Promise((resolve) => {
      onPrint(data);
      setTimeout(() => resolve(null), delay);
    });
  
  const onBatchReprintClick = async (twiceMode = false) => {
    
    if (dataSource.length > alertBatchPrintCount) {
      dispatch(
        showModal({
          key: "confirm",
          icon: "warning",
          header: t("t_warning"),
          msg: t("t_sureToBatchPrint", { count: dataSource.length }),
          showCloseBtn: true,
          // showCloseBtn: true,
          callback: async () => {
            dispatch(closeModal());
            for (let data of dataSource) {
              if (data.accessories.find(accessory => accessory.id === 4) && twiceMode) {
                await printWithDelay(data, batchPrintDelay);
              }
              await printWithDelay(data, batchPrintDelay);
            }
            // let hasBox = []
            // dataSource.forEach((data, index) => {
            //   // if (!data.isReprintTag) return
            //   const delay = index * 2000;
            //   if (data.accessories.find(accessory => accessory.id === 4) && twiceMode)
            //     hasBox.push(index)
            //   printWithDelay(data, delay);
            // });
            // if (hasBox.length === 0) return
            // setTimeout(() => {
            //   hasBox.forEach((id, index ) => {
            //     const delay = index * 2000;
            //     printWithDelay(dataSource[id], delay)
            //   })
            // }, dataSource.length * 2000);
          },
        })
      )
    } else {
      dispatch(closeModal());
      for (let data of dataSource) {
        if (data.accessories.find(accessory => accessory.id === 4) && twiceMode) {
          await printWithDelay(data, batchPrintDelay);
        }
        await printWithDelay(data, batchPrintDelay);
      }
    }
  };

  return (
    <>
      <TitleBar hasSideBar hasShadow color={variables.themeWhite}>
        <TitleBarItemPaginationContent
          setShowProductModal={setShowProductModal}
          setShowImportModal={setShowImportModal}
          setBtn={setBtn}
          reload={onReload}
          onBatchReprintClick={onBatchReprintClick}
        />
      </TitleBar>
      <div className={styles.container}>
        <TickVerticalCollapse setIsFilterOpen={setIsFilterOpen} isFilterOpen={isFilterOpen}>
          <TickFilterForm />
        </TickVerticalCollapse>
        <div className={styles.tableWrapper}>
          <TickTable
            dataSource={dataSource}
            columns={columns}
            rowKey={(record) => record.id}
            loading={itemTableLoading}
            onChange={onChange}
            rowClassName={rowClassName}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  const isTargetClicked = event.target.outerHTML.startsWith('<svg') || event.target.outerHTML.startsWith('<path')
                  if (!isTargetClicked) {
                    if (isDrawerOpen && record.id === itemsDrawerProductDetails.id) {
                      setOpen(false);
                    } else {
                      setOpen(true);
                      dispatch(setItemsDrawerProductDetails(record))
                    }
                  }
                },
              };
            }}
          />
        </div>
        <TickDrawer title={`${t("t_product_info")} (${itemsDrawerProductDetails.itemNum})`} open={open} setOpen={setOpen}>             {/* // dummy-drawer: Component usage guide -  pass props to drawer */}
          <TickTabsContent />
        </TickDrawer>
      </div>
      <TickUploadModal
        open={showImportModal}
        onOk={postFileDate}
        onCancel={() => setShowImportModal(false)}
        uploadProps={uploadProps}
        loading={loading}
        onSubmitDisabled={!file ? true : false}
      />
    </>
  );
};

export default memo(Item);
