export const STATUS = {
  SOLD: {
    label: 't_sold',
    class: 'red'
  },
  IN_STOCK: {
    label: "t_inStock",
    class: 'green'
  },
  RESERVED: {
    label: "t_reserved",
    class: 'yellow'
  },
  ONLINE_RESERVED: {
    label: "t_onlineReserved",
    class: 'yellow'
  },
  ONLINE_PURCHASE_RESERVED: {
    label: "t_onlinePurchaseReserved",
    class: 'yellow'
  },
  CONSIGNEE_RESERVED: {
    label: "t_consigneeReserved",
    class: 'yellow'
  },
  RETURNED_TO_CONSIGNEE: {
    label: "t_returnedToConsignee",
    class: 'red'
  },
}