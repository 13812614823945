import * as XLSX from 'xlsx/xlsx.mjs';

// const excelCols = [
//   "branchNum",
//   "itemNum",
//   "itemNameEn", 
//   "itemNameTc",
//   "transactionTypeId", 
//   "transactionDate",
//   "deptType",
//   "deptKey",
//   "catKey",
//   "subCatKey", 
//   "amt",
//   "onSalesPrice", 
//   "handlingPercentage", 
//   "qty",
//   "year", 
//   "width", 
//   "height", 
//   "length",
//   "colorIds", 
//   "ratingIds", 
//   "materialIds", 
//   "originIds",
//   "accessoriesIds", 
//   "remark",
//   "model",
//   "consignorId", 
//   "colorIdsInternal", 
//   "itemNameInternal",
//   "status",
//   "css",
//   "stockLocation", 
// ];

export const excelColsObj = {
  'itemNum': '商品號碼',
  'itemNameTc': '商品名稱  中文',
  'itemNameEn': '商品名稱  英文',
  'itemDescTc': '商品介紹  中文',
  'itemDescEn': '商品介紹  英文',
  'deptKey': '品牌簡稱',
  'catName': '商品大分類  中文',
  'subCatName': '商品小分類  中文',
  'subCatKey': '商品小分類  英文',
  'amt': '商品定價',
  'ratingTc': '評級',
  'model': '商品描述 / 型號',
  'colorTc': '商品顏色  中文',
  'colorEn': '商品顏色  英文',
  'width': '商品尺寸  闊 (cm)',
  'height': '商品尺寸  高 (cm)',
  'length': '商品尺寸  長 (cm)',
  'weight': '商品重量 (kg)',
  'materialTc': '商品材料  中文',
  'materialEn': '商品材料  英文',
  'accessoriesTc': '商品配件  中文',
  'accessoriesEn': '商品配件  英文',
  'originTc': '原產地  中文',
  'stockLocation': '商品存倉位置',
  'transactionDate': '商品上架日期',
  'expDate': '商品下架日期'
};

export const itemImport = [
  {
    itemNum: '商品號碼',
    itemNameTc: '商品名稱  中文',
    itemNameEn: '商品名稱  英文',
    itemDescTc: '商品介紹  中文',
    itemDescEn: '商品介紹  英文',
    deptKey: '品牌簡稱',
    catName: '商品大分類  中文',
    subCatName: '商品小分類  中文',
    subCatKey: '商品小分類  英文',
    amt: '商品定價',
    ratingTc: '評級',
    model: '商品描述 / 型號',
    colorTc: '商品顏色  中文',
    colorEn: '商品顏色  英文',
    width: '商品尺寸  闊 (cm)',
    height: '商品尺寸  高 (cm)',
    length: '商品尺寸  長 (cm)',
    weight: '商品重量 (kg)',
    materialTc: '商品材料  中文',
    materialEn: '商品材料  英文',
    accessoriesTc: '商品配件  中文',
    accessoriesEn: '商品配件  英文',
    originTc: '原產地  中文',
    stockLocation: '商品存倉位置',
    transactionDate: '商品上架日期',
    expDate: '商品下架日期'
  },
  {
    itemNum: 'templateItem',
    itemNameTc: '測試01',
    itemNameEn: 'test01',
    itemDescTc: '這是測試01',
    itemDescEn: 'this is test01',
    deptKey: 'Bottega Veneta',
    catName: '手袋',
    subCatName: '太陽眼鏡',
    subCatKey: 'subCat',
    amt: '500',
    ratingTc: '未使用品',
    model: 'testModel01',
    colorTc: '藍色',
    colorEn: 'blue',
    width: '10',
    height: '10',
    length: '10',
    weight: '10',
    materialTc: '牛皮',
    materialEn: 'CalfSkin',
    accessoriesTc: '牌仔',
    accessoriesEn: 'tag',
    originTc: '法國',
    stockLocation: '',
    transactionDate: new Date('2023/10/31'),
    expDate: new Date('2023/11/30')
  },
];


export const excelFieldChecker = async (file) => {
  if (!file) return false;
  let isMatch = true;
  try {
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const json = XLSX.utils.sheet_to_json(
      workbook.Sheets[workbook.SheetNames[0]]
      );
    if (json.length < 1 || checkImportFields(json[0]) ) {
      isMatch = false;
    } 
  } catch (e) {
    isMatch = false;
  }
    return isMatch;
}

const checkImportFields = (data) => {
  const dataKeys = Object.keys(data);
  let count = 0;
  for (let key of dataKeys) {
    if (!Object.keys(excelColsObj).includes(key)) {
      continue;
    }
    count++;
  }
  return count !== Object.keys(excelColsObj).length;
}