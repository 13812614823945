import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import backArrowWhite from "../../assets/img/icon/backArrowWhite.svg";
import forwardArrowWhite from "../../assets/img/icon/forwardArrowWhite.svg";
import { useNavigate } from "react-router-dom";
import styles from "./navigationBar.module.scss";
import { closeModal, showModal } from "../../redux/tickModalSilce";
import { useTranslation } from "react-i18next";

const NavigationBar = () => {
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tickTableState = useSelector((state) => state.tickTable);

  const onBack = () => {
    const tempItems = JSON.parse(localStorage.getItem("TempItems"));
    const consignorEdited = tickTableState.consignment?.consignor?.edited;
    if (tempItems.length > 0 || consignorEdited) {
      dispatch( 
        showModal({
          key: "confirm",
          icon: "warning",
          header: t("t_warning"),
          msg: t("t_promptConfirmLoseProgress"),
          showCloseBtn: true,
          // showCloseBtn: true,
          callback: () => {
            dispatch(closeModal());
            localStorage.setItem("TempItems", JSON.stringify([]))
            navigate(-1);
          },
        })
      )
      return;
    } else {
      navigate(-1);
      localStorage.setItem("TempItems", JSON.stringify([]))
    }
  }

  const onForward = () => {
    const tempItems = JSON.parse(localStorage.getItem("TempItems"));
    const consignorEdited = tickTableState.consignment?.consignor?.edited;
    if (tempItems.length > 0 || consignorEdited) {
      dispatch( 
        showModal({
          key: "confirm",
          icon: "warning",
          header: t("t_warning"),
          msg: t("t_promptConfirmLoseProgress"),
          showCloseBtn: true,
          // showCloseBtn: true,
          callback: () => {
            dispatch(closeModal());
            localStorage.setItem("TempItems", JSON.stringify([]))
            navigate(1);
          },
        })
      )
      return;
    } else {
      navigate(1);
      localStorage.setItem("TempItems", JSON.stringify([]))
    }
  }
  return (
    <div className={styles.navigationBarContainer}>
      <img
        className={styles.navigationIcon}
        alt="navigation back"
        src={backArrowWhite}
        onClick={onBack}
      />
      <img
        className={styles.navigationIcon}
        alt="navigation forward"
        src={forwardArrowWhite}
        onClick={onForward}
      />
    </div>
  );
};

export default memo(NavigationBar);
