import React, { memo, useEffect, useState } from "react";
import TitleBar from "../../components/titleBar/TitleBar";
import TitleBarConsignmentPaginationContent from "../../components/titleBarConsignmentPaginationContent/TitleBarConsignmentPaginationContent";
import variables from "../../theme/variable.scss";
import { Tooltip } from "antd";
import {
  EditOutlined,
  ShoppingOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styles from "./consignment.module.scss";
import ConsignmentModal from "../../modals/ConsignmentModal";
import { closeModal, showModal } from "../../redux/tickModalSilce";
import ConsignmentModalV3 from "../../modals/ConsignmentModalV3";
import { clearConsignmentRowData, setConsignmentParams, setConsignmentRowData, setConsignmentTableData, setConsignStateToInitialState } from "../../redux/tickTableSlice";
import { tickLog } from "../../utils/logger";
import ConsignorApi from "../../service/consignorApi/consignorApi";
import TickTable from "../../components/tickTable/TickTable";
import { modalWidth } from "../../config/systemConfig";
import { useNavigate, useParams } from "react-router";

const Consignment = (props) => {
  // const { setShowConsignmentModal } = props
  const [showConsignmentModal, setShowConsignmentModal] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [btn, setBtn] = useState("")
  const [showEditModal, setShowEditModal] = useState(false)
  const [consignorTableLoading, setConsignorTableLoading] = useState(false);
  const [consignorModalData, setConsignorModalData] = useState(null);
  const [modalType, setModalType] = useState("");
  const [consignorId, setConsignorId] = useState(-1);

  const consignTableParams = useSelector(state => state.tickTable.consignParams)
  const permissions = JSON.parse(localStorage.getItem('PERMISSION'))

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => { 
    if (id) {
      setShowConsignmentModal(true)
      setModalType("edit")
      setConsignorId(id)
    } else {
      setShowConsignmentModal(false);
      setConsignorId(-1);
      dispatch(clearConsignmentRowData());
    }
  }, [id])

  useEffect(() => {
    listByPage()
  }, [consignTableParams])

  const listByPage = async () => {
    const resp = await ConsignorApi.listByPage(consignTableParams);
    tickLog("all consignor data", resp)
    try {
      if (resp.success) {
        const { data, msg } = resp
        setDataSource(data.array)
        dispatch(
          setConsignmentTableData({ totalCount: data.totalCount })
        )
        
        // if (consignorId > 0) {
        //   let result = data.array.find(item => item.id === consignorId)
        //   dispatch(setConsignmentRowData(result))
        // }
      }
    } catch {

    } finally {
      setConsignorTableLoading(false);
    }
  }

  const onEdit = (value) => {
    setConsignorModalData(value)
    setShowEditModal(true)
  }

  const onDelete = (record) => {
    dispatch(
      showModal({
        key: "warning",
        header: t("t_delete"),
        msg: t("t_confirmDelete"),
        showCloseBtn: true,
        callback: async () => {

          await ConsignorApi.delete(record.id).then(() => {
            listByPage()
            dispatch(closeModal());
          })
        },
      })
    );
  }

  const handleSortKey = (sortOrder) => {
    let sortKey;
    if (sortOrder === 'ascend') {
      sortKey = 'ASC'
    }

    if (sortOrder === 'descend') {
      sortKey = 'DESC'
    }
    return sortKey;
  }

  const onChange = (pagination, filter, sorter) => {
    let sortOptions = {};
    if (Array.isArray(sorter)) {
      sorter.forEach(sortEle => {
        sortOptions[sortEle.columnKey] = handleSortKey(sortEle.order);
      })
    } else {
      sortOptions[sorter.columnKey] = handleSortKey(sorter.order);
    }
    
    if (!sorter.order) {
      delete sortOptions[sorter.columnKey]
    }
    dispatch(setConsignmentParams({
      ...consignTableParams,
      sortOptions
    }))
  }

  const columns = [
    {
      title: t("t_consignorNum"),
      dataIndex: "consignorNum",
      key: "consignorNum",
      sorter: {
        multiple: 7
      },
      render: (_) => (
        <div>
          {
            _ ? _ : "-"
          }
        </div>
      ),
      // ellipsis: true
    },
    {
      title: t("t_fullName"),
      dataIndex: "fullName",
      key: "fullName",
      sorter: {
        multiple: 6
      },
      render: (_) => (
        <div>
          {
            _ ? _ : "-"
          }
        </div>
      )
      ,
      // ellipsis: true
    },
    {
      title: t("t_idNum"),
      dataIndex: "idNo",
      key: "idNo",
      sorter: {
        multiple: 5
      },
      render: (_, record) => {
        if (permissions.findIndex((item) => item === "consignor:showHKID") > -1) {
          return (
            <div>
              {
                record.idNo ? record.idNo : "-" 
              }
            </div>
          )
        } else {
          return (
            <div>
              {
                record.idNo ? record.idNo.slice(0, 4) + "****" : "-"
              }
            </div>
          )
        }
      },
      // ellipsis: true
    },
    {
      title: t("t_shortName"),
      dataIndex: "abbreviation",
      key: "abbreviation",
      sorter: {
        multiple: 4
      },
      render: (_) => (
        <div>
          {
            _ ? _ : "-"
          }
        </div>
      ),
      // ellipsis: true
    },
    {
      title: t("t_bankNum"),
      dataIndex: "bankCode",
      key: "bankCode",
      render: (_, record) => {
        let display = "-";
        if (record.bankNum) {
          display = `${record.bankCode || ''} ${record.bankNum || ''}`
        }
        return (
          <div>
            {
              display
            }
          </div>
        )
      },
      sorter: {
        multiple: 3
      },
      // ellipsis: true
    },
    {
      title: t("t_phoneNum"),
      dataIndex: "phoneNum",
      key: "phoneNum",
      sorter: {
        multiple: 2
      },
      render: (_) => (
        <div>
          {
            _ ? _ : "-"
          }
        </div>
      ),
      // ellipsis: true
    },
    {
      title: t("t_address"),
      dataIndex: "address",
      key: "address",
      sorter: {
        multiple: 1
      },
      render: (_) => (
        <div>
          {
            _ ? _ : "-"
          }
        </div>
      ),
      // ellipsis: true
    },
    {
      title: t("t_function"),
      dataIndex: "function",
      key: "function",
      fixed: 'right',
      width: 140,
      render: (_,
        record
      ) =>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {
            permissions.findIndex((item) => item === "consignor:edit") > -1 ? 
            // <EditOutlined style={{ color: variables.themeBlue, padding: "5px" }} onClick={() => onEdit(record)} /> :
            <EditOutlined style={{ color: variables.themeBlue, fontSize: 24 }} onClick={() => onEdit(record)} /> :
            <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
              {/* <EditOutlined style={{ color: "grey", padding: "5px" }}/> */}
              <EditOutlined style={{ color: "grey", fontSize: 24 }}/>
            </Tooltip>
          }
          {
            permissions.findIndex((item) => item === "consignor:itemEdit") > -1 ? 
            // <ShoppingOutlined style={{ color: variables.themeBlue, padding: "5px" }}
            <ShoppingOutlined style={{ color: variables.themeBlue, fontSize: 24 }}
              onClick={() => {
                // dispatch(setConsignmentRowData(record))
                // dispatch(setUpdateConsignmentConsignor({ id: record.id }))
                navigate(`/main/consignment/${record.id}`)
              }}
          /> :
            <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
              {/* <ShoppingOutlined style={{ color:"grey", padding: "5px" }}/> */}
              <ShoppingOutlined style={{ color:"grey", fontSize: 24 }}/>
            </Tooltip>
          }
          {
            permissions.findIndex((item) => item === "consignor:delete") > -1 ? 
            // <DeleteOutlined style={{ color: variables.themeRed, padding: "5px" }} onClick={() => onDelete(record)} /> :
            <DeleteOutlined style={{ color: variables.themeRed, fontSize: 24 }} onClick={() => onDelete(record)} /> :
            <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
              {/* <DeleteOutlined style={{ color: "grey", padding: "5px" }}/> */}
              <DeleteOutlined style={{ color: "grey", fontSize: 24}}/>
            </Tooltip>
          }
        </div>,
        // ellipsis: true
    },
  ];

  const onReload = () => {
    dispatch(setConsignStateToInitialState());
  }

  return (
    <>
      <TitleBar hasSideBar hasShadow color={variables.themeWhite}>
        <TitleBarConsignmentPaginationContent
          setShowConsignmentModal={setShowConsignmentModal}
          setBtn={setBtn}
          reload={onReload}
          setModalType={setModalType}
          // listByPage={listByPage}
        />
      </TitleBar>
      <div className={styles.container}>
        <div className={styles.tableWrapper}>
          <TickTable
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            rowKey={(record) => record.id}
            loading={consignorTableLoading}
            // scroll={{x: "1200px" , y: `${variables.tableMaxHeight}`}}
            onChange={onChange}
          />
        </div>
      </div>
      {showConsignmentModal && (
        <ConsignmentModal
          showConsignmentModal={showConsignmentModal}
          setShowConsignmentModal={setShowConsignmentModal}
          btn={btn}
          setBtn={setBtn}
          modalType={modalType}
          onReload={onReload}
          consignorId={consignorId}
          creatable={false}
        />
      )}
      {showEditModal &&
        <ConsignmentModalV3
          showConsignmentModal={showEditModal}
          setShowConsignmentModal={setShowEditModal}
          width={modalWidth}
          modalData={consignorModalData}
          listByPage={listByPage}
        />}
    </>
  );
};

export default memo(Consignment);
