import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { ReloadOutlined, PlusOutlined } from "@ant-design/icons";
import PaginationBar from "../paginationBar/PaginationBar";
import styles from "./titleBarContractPaginationContent.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { setContractParams, clearConsignmentRowData } from "../../redux/tickTableSlice";
import IconWithTextButton from "../iconWithTextButton/IconWithTextButton";
import { useNavigate } from "react-router";

const TitleBarContractPaginationContent = (props) => {
  const { setShowConsignmentModal, setBtn, reload, setModalType, setContractType } = props;
  const contractParams = useSelector(state => state.tickTable.contractParams);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate(); 

  const addNewConsignmentContractOnClick = () => {
    setShowConsignmentModal(true)
    dispatch(clearConsignmentRowData())
    setBtn("add")
    setModalType("create")
    setContractType("consignment")
    // navigate(`/main/contract?action=create&type=consignment`)
  };

  const addNewPurchaseContractOnClick = () => {
    setShowConsignmentModal(true)
    dispatch(clearConsignmentRowData())
    setBtn("add")
    setModalType("create");
    setContractType("purchase")
    // navigate(`/main/contract?action=create&type=purchase`)
  };

  return (
    <>
      <div className={styles.left}>
        <span className={styles.title}>{t("t_consignmentContract")}</span>
      </div>
      <div className={styles.middle}>
        <PaginationBar dispatchParams={setContractParams} params={contractParams} />
      </div>
      <div className={styles.right}>
        {/* <IconWithTextButton type="primary" onBtnClick={() => { setShowMasterModal(true); setButtonType('add'); }}>
          <span>
            <PlusOutlined style={{ pointerEvents: "cursor" }} />
            {t("t_add")}
          </span>
        </IconWithTextButton> */}
        <IconWithTextButton permission="contract:add" onBtnClick={addNewConsignmentContractOnClick}>
          <span>
            <PlusOutlined style={{ pointerEvents: "cursor" }} />
            {t("t_addConsignmentContract")}
          </span>
        </IconWithTextButton>
        <IconWithTextButton permission="contract:add" onBtnClick={addNewPurchaseContractOnClick}>
          <span>
            <PlusOutlined style={{ pointerEvents: "cursor" }} />
            {t("t_addPurchaseContract")}
          </span>
        </IconWithTextButton>
        <ReloadOutlined className={styles.reloadIcon} onClick={reload} />
      </div>
    </>
  );
};

export default memo(TitleBarContractPaginationContent);
