import React, { memo, useEffect } from "react";
import { Collapse } from "antd";
import styles from "./tickCollapse.module.scss";

const { Panel } = Collapse;

const TickCollapse = (props) => {
  const { header = "Input title", children, customStyle = {} } = props;
  const items = [
    {
      key: 1,
      label: header,
      children: children
    }
  ]

  return (
    <div className={styles.container}>
      <Collapse ghost expandIconPosition="start" className={customStyle} items={items} />
    </div>
  );
};

export default memo(TickCollapse);
