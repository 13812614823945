import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { ReloadOutlined } from "@ant-design/icons";
import PaginationBar from "../paginationBar/PaginationBar";
import styles from "./titleBarStatusLogPaginationContent.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { setStatusLogParams } from "../../redux/tickTableSlice";
import { Select } from 'antd';

const TitleBarStatusLogPaginationContent = (props) => {
  const { setShowMasterModal, setButtonType, reload } = props;
  // const itemTableParams = useSelector(state => state.tickTable.itemParams);
  // const totalCount = useSelector(state => state.tickTable.itemTableData.totalCount);
  const statusLogParams = useSelector(state => state.tickTable.statusLogParams);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.left}>
        <span className={styles.title}>{t("t_actionLog")}</span>
      </div>
      <div className={styles.middle}>
        <PaginationBar dispatchParams={setStatusLogParams} params={statusLogParams} />
      </div>
      <div className={styles.right}>
        {/* <IconWithTextButton type="primary" onBtnClick={() => { setShowMasterModal(true); setButtonType('add'); }}>
          <span>
            <PlusOutlined style={{ pointerEvents: "cursor" }} />
            {t("t_add")}
          </span>
        </IconWithTextButton> */}
        <ReloadOutlined className={styles.reloadIcon} onClick={reload} />
      </div>
    </>
  );
};

export default memo(TitleBarStatusLogPaginationContent);
