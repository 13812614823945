import { MAG } from "../api";

export default class MemberApi {
  static suffix = "/api/member";

  static listByPage(args) {
    let url = `${this.suffix}/listByPage/${args.pageNum}/${args.pageSize}?`;
    if (args.sortOptions) {
      const sortOptions = Object.entries(args?.sortOptions);
      if (sortOptions.length > 0) {
        sortOptions.forEach(option => {
          url = url.concat(`&&sortField=${encodeURIComponent(option[0])}&&sortOrder=${encodeURIComponent(option[1])}`);
        })
      }
    }
    if (args.input != "") {
      url = url.concat(`&&input=${encodeURIComponent(args.input)}`);
    }
    return MAG.get(url);
  }

  static delete(memberNum) {
    return MAG.put(`${this.suffix}/delete/${memberNum}`);
  }

  static update(args) {
    const { memberNum, ...rest } = args; 
    return MAG.put(`${this.suffix}/update/${memberNum}`, rest);
  }

  static save(args) {
    return MAG.post(`${this.suffix}/save`, args);
  }
}