import React, { useState } from 'react';
import { Modal, Divider, Button, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, showModal } from "../redux/tickModalSilce";


export const TickLocalModal = (props) => {
  const {
    onSubmit,
    showLocalModal,
    onCancel,
    children,
    okText = "Submit",
    cancelText = "Cancel",
    setVisible,
    title,
    form,
    width,
    footer
  } = props;

  const dispatch = useDispatch()

  return (
    <Modal
      className='localModal'
      title={title}
      centered
      open={showLocalModal}
      // onOk={onSubmit}
      onCancel={onCancel}
      maskClosable={false}
      keyboard={false}
      width={width ? width : '1150px'}
      footer={ footer ? footer : [
        // <Button key="cancel" onClick={() => { setVisible(false); onCancel() }}>
        //   Cancel
        // </Button>,
        <Button
          key="submit"
          type="primary"
          // loading={postData.loading}
          onClick={() => {
            // async (changedValues, allValues) => {
            form
              .validateFields()
              .then((values, err) => {
                // console.log("values", values)
                // console.log("err", err)
                // console.log("allValues 11111111", allValues)
                if (err == undefined) {
                  onSubmit();
                }
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
                let newErrMsg = info?.errorFields?.map((err) => err.errors)
                dispatch(
                  showModal({
                    key: "error",
                    header: "This is an error message",
                    msg: newErrMsg,
                    callback: () => {
                      dispatch(closeModal());
                    },
                  })
                );
                // onCancel()
              });
          }
          }
        >
          {/* Submit */}
          {okText}
        </Button>
      ]}
    >
      <Divider style={{ opacity: 0.5 }} />
      {children}
    </Modal>
  );
};