export const handleHandlingFee = (amt) => {
  let fee = 0;
  if (isNaN(amt)) return fee;
  if(!amt) return fee;
  if (amt <= 2000) {
    fee = 300;
  } else if (amt >= 2001 && amt <= 49999) {
    fee = (amt * 15) / 100
  } else {
    fee = (amt * 10) / 100
  }
  return fee
}