import React from 'react';
import styles from './titleBarReportPaginationContent.module.scss';
import { useTranslation } from "react-i18next";
import { ReloadOutlined } from "@ant-design/icons";


const TitleBarReportPaginationContent = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.left}>
        <span className={styles.title}>{t("t_report")}</span>
      </div>
      {/* <div className={styles.middle}> */}
        {/* <PaginationBar dispatchParams={setSettingParams} params={settingParams} /> */}
      {/* </div> */}
      {/* <div className={styles.right}> */}
        {/* <ReloadOutlined className={styles.reloadIcon} onClick={reload} /> */}
      {/* </div> */}
    </>
  )
}

export default TitleBarReportPaginationContent;