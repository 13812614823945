import React, { useState } from 'react';
import { Modal, Form } from 'antd';
import TickDrawerV2 from '../tickDrawerV2/TickDrawerV2';
import ConsignorApi from '../../service/consignorApi/consignorApi';
import { useDispatch } from 'react-redux';
import { closeModal, showModal } from '../../redux/tickModalSilce';

const AddConsigneeModal = ({ open, onCancel }) => {
  const [newConsignorInfo, setNewConsignorInfo] = useState(null);
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const onSubmit = async() => {
    const data = {
      consignorId: 0,
      fullName: newConsignorInfo.fullName,
      idNo: newConsignorInfo.idNo,
      abbreviation: newConsignorInfo.abbreviation,
      bankCode: newConsignorInfo.bankCode,
      bankNum: newConsignorInfo.bankNum,
      phoneNum: newConsignorInfo.phoneNum,
      address: newConsignorInfo.address,
      items: []
    }

    let resp = await ConsignorApi.save(data);
    if(resp.success) {
      onCancel();
      form.resetFields();
    }
  }

  const onOk = async () => {
    form
      .validateFields()
      .then((values, err) => {
        if (err == undefined) {
          onSubmit()
        }
      })
      .catch((info) => {
        let newErrMsg = info?.errorFields?.map((err) => err.errors)
        dispatch(
          showModal({
            key: "error",
            header: "This is an error message",
            msg: newErrMsg,
            callback: () => {
              dispatch(closeModal());
            },
          })
        );
      });
  }

  return (
    <Modal open={open} onCancel={() => { onCancel(); form.resetFields();}} onOk={onOk}>
      <TickDrawerV2 isModal setNewConsignorInfo={setNewConsignorInfo} parentForm={form} />
    </Modal>
  )
}

export default AddConsigneeModal