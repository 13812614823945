import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import PaginationBar from "../paginationBar/PaginationBar";
import styles from "./titleBarConsignmentPaginationContent.module.scss";
import IconWithTextButton from "../iconWithTextButton/IconWithTextButton";
import { clearConsignmentRowData, setConsignmentParams } from "../../redux/tickTableSlice";
import { useDispatch, useSelector } from "react-redux";

const TitleBarConsignmentPaginationContent = (props) => {
  const { setShowConsignmentModal, setContents, setBtn, setDummyDataSource, listByPage, reload, setModalType } = props
  const consignTableParams = useSelector(state => state.tickTable.consignParams);

  const dispatch = useDispatch()

  const { t } = useTranslation();

  const addNewConsignmentOnClick = () => {
    setShowConsignmentModal(true)
    dispatch(clearConsignmentRowData())
    setBtn("add")
    setModalType("create")
  };

  return (
    <>
      <div className={styles.left}>
        <span className={styles.title}>{t("t_consignorList")}</span>
      </div>
      <div className={styles.middle}>
        <PaginationBar dispatchParams={setConsignmentParams} params={consignTableParams} />
      </div>
      <div className={styles.right}>
        <IconWithTextButton onBtnClick={addNewConsignmentOnClick} permission="consignor:add">
          <PlusOutlined />
          {t("t_addConsignment")}
        </IconWithTextButton>
        {/* <ReloadOutlined className={styles.reloadIcon} onClick={listByPage} /> */}
        <ReloadOutlined className={styles.reloadIcon} onClick={reload} />
      </div>
    </>
  );
};

export default memo(TitleBarConsignmentPaginationContent);
