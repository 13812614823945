import { useTranslation } from "react-i18next";
import { TickLocalModal } from "./TickLocalModal";
import React, { useEffect, memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, showModal } from "../redux/tickModalSilce";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { Button } from "antd";
import { usePDF } from "react-to-pdf";
import TickConsignmentReceipt from "../components/tickConsignmentReceipt/TickConsignmentReceipt";

const PrintReceiptModal = (props) => {
  const { showPrintReceiptModal, setShowPrintReceiptModal, receiptData, width } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const printRef = useRef()

  const onCloseModal = () => {
    setShowPrintReceiptModal(false);
  };
  
  const { toPDF, targetRef } = usePDF({
    container: {
      flex: 1,
    },
    filename: `${receiptData.consignor.fullName?.replace(/\s/g, '_')}_${receiptData.receiptNum}.pdf`,
    page: {
      format: 'A5',
    },
  });

  const downloadPDF = () => {
    toPDF();
  }

  useEffect(() => {
    // if (receiptData.isAutoPrint) {
    //   downloadPDF();
    //   onCloseModal();
    // }
  }, [])

  return (
    <TickLocalModal
      showLocalModal={showPrintReceiptModal}
      onCancel={onCloseModal}
      title={t("t_downloadReceiptPDF")}
      width={"168mm"}
      centered
      footer={
        <>
          <Button type={"primary"} onClick={downloadPDF}>
            {t("t_downloadPDF")}
          </Button>
        </>
      }
    >
      <div style={{
        width: "100%",
        maxHeight: "210mm",
        overflowY: "auto",
        overflowX: "hidden",
      }}>
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>

          <TickConsignmentReceipt ref={targetRef} receiptData={receiptData}></TickConsignmentReceipt>
        </div>
      </div>
    </TickLocalModal>
  );
};

export default memo(PrintReceiptModal)