import { MAG } from "../api";

export default class SystemApi {
  static suffix = "/api/system";

  static login(body) {
    return MAG.post(`${this.suffix}/user/login`, body);
  }

  static logout() {
    return MAG.post(`${this.suffix}/user/logout`, {});
  }

  static update(body) {
    const newBody = {
      oldPassword: body.oldPassword,
      newPassword: body.newPassword
    }
    return MAG.put(`${this.suffix}/user/update/${encodeURIComponent(body?.userId)}`, newBody)
  }
}