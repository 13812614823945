import logo from "./logo.svg";
import "./App.scss";
import { Provider, useSelector } from "react-redux";
import store from "./redux/store.js";
import i18n from "./i18n";
import { RouterProvider } from "react-router-dom";
import UnitTest from "./pages/unitTest/UnitTest";
import { router } from "./routes";
import Login from "./pages/login/Login";
import { ConfigProvider } from "antd";
import variables from "./theme/variable.scss";
import "./theme/variable.scss";

import { TickSuccessModal } from "./modals/TickSuccessModal";
import { TickWarningModal } from "./modals/TickWarningModal";
import { TickDeleteModal } from "./modals/TickDeleteModal";
import { TickInfoModal } from "./modals/TickInfoModal";
import { TickErrorModal } from "./modals/TickErrorModal";
import { useEffect } from "react";
import LanguageApi from "./service/systemApi/languageApi";
import { tickLog } from "./utils/logger";
import printerApi from "./service/systemApi/printerApi";
import { printer } from "./config/envConfig";
import { TickConfirmModal } from "./modals/TickConfirmModal.jsx";


function App() {
  return (
    <>
      <Provider store={store}>
        <Home />
      </Provider>
    </>
  );
}

const Home = () => {
  const tickGlobalModalState = useSelector((state) => state.tickModal);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const isLoginThroughPortal = urlParams.get("isLoginThroughPortal");
  localStorage.setItem("isLoginThroughPortal", isLoginThroughPortal);
  // console.log("isLoginThroughPortal", isLoginThroughPortal);

  useEffect(() => {
    LanguageApi.getLanguage().then((res) => {
      tickLog("System Language", res.data?.lang);
      if (res.success) {
        i18n.changeLanguage(res.data?.lang);
      } else {
        tickLog("System Language", res.msg);
      }
    })
    printerApi.fetchMachineIp(1).then(res => {
      if (res.success) {
        printer.printerIp = res.data?.printerIp;
        printer.printerPort = res.data?.printerPort;
        printer.printerId = 1;

        // Set Local Storage
        localStorage.setItem("settings", JSON.stringify({
          printerId: printer.printerId
        }))
      } else {
        tickLog("printer ip", res.msg);
      }
    })
  }, [])

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: variables.themeBlue,
          },
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
      {tickGlobalModalState.show &&
      tickGlobalModalState.params.key == "success" ? (
        <TickSuccessModal />
      ) : null}
      {tickGlobalModalState.show &&
      tickGlobalModalState.params.key == "warning" ? (
        <TickWarningModal />
      ) : null}
      {tickGlobalModalState.show &&
      tickGlobalModalState.params.key == "delete" ? (
        <TickDeleteModal />
      ) : null}
      {tickGlobalModalState.show &&
      tickGlobalModalState.params.key == "info" ? (
        <TickInfoModal />
      ) : null}
      {tickGlobalModalState.show &&
      tickGlobalModalState.params.key == "error" ? (
        <TickErrorModal />
      ) : null}
      {tickGlobalModalState.show &&
      tickGlobalModalState.params.key == "confirm" ? (
        <TickConfirmModal />
      ) : null}
    </>
  );
};

export default App;
