import { createBrowserRouter, Navigate, Route, Routes, redirect } from "react-router-dom";
import Login from "./pages/login/Login";
import Main from "./pages/main/Main";
import Dashboard from "./pages/Dashboard";
import About from "./pages/About";
import UnitTest from "./pages/unitTest/UnitTest";
import Item from "./pages/item/Item";
import Consignment from "./pages/consignment/Consignment";

// localhost
let basePath = "";

// dev
// let basePath = "/tickWebBase";

const routes = [
  {
    path: basePath + "/login",
    name: "login",
    element: <Login />,
  },
  {
    path: basePath + "/main/*",
    name: "main",
    element: <Main />,
    children: [
      {
        path: "item",
      },
      {
        path: "consignment",
      },
      {
        path: "members",
      },
      {
        path: "contract",
      },
      {
        path: "coupon",
      },
      {
        path: "master",
      },
      {
        path: "statusLog",
      },
      {
        path: "setting",
      },
      {
        path: "report",
      },
    ],
    loader: () => {
      const menuValue = JSON.parse(localStorage.getItem("MENU"));
      if (!menuValue) {
        throw redirect("/login");
      }
      return null
    }
  },
  {
    path: basePath + "/main",
    name: "main",
    element: <Navigate to="/main/item" />,
  },
  {
    path: basePath + "/unitTest",
    name: "unitTest",
    element: <UnitTest />,
  },
  {
    path: basePath + "*",
    name: "",
    element: <Navigate to="/login" replace />,
  },
];

export const router = createBrowserRouter(routes);
