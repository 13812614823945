import { MAG } from "../api";

export default class SystemMenuApi {
  static suffix = "/api/system/menus";

  static listAll() {
    let url = `${this.suffix}/listAll`;
    return MAG.get(url);
  }

  static updateRoleMenuRelation(roleId, menuId) {
    let url = `${this.suffix}/updateRoleMenuRelation/${roleId}/${menuId}`;
    return MAG.put(url);
  }
}