import { MAG } from "../api";

export default class LanguageApi {
  static suffix = "/api/system/language";

  static getLanguage() {
    return MAG.get(this.suffix);
  }

  static changeLanguage(lang) {
    const newBody = { lang }
    return MAG.put(`${this.suffix}`, newBody)
  }
}