import React from "react";
import { Context } from './Report';
import { Modal } from "antd";
import ReportAdminForm from "./ReportAdminForm";
import { Form, Button } from "antd";
import { useTranslation } from "react-i18next";
import ReportCommonApi from "./reportServices";
import { useDispatch } from "react-redux";
import { closeModal, showModal } from "../../redux/tickModalSilce";

const ReportAdminModal = () => {
  const context = React.useContext(Context);
  const [isAutoGenConfig, setIsAutoGenConfig] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  const [form] = Form.useForm();
  const { t } = useTranslation();
  const childCPFormRefs = React.useRef([]); // CP standing for component
  const dispatch = useDispatch();


  React.useEffect(() => {
    if (context.adminFormConfigs && context.isEditModalVisible)
      form.setFieldsValue({
        name: context.adminFormConfigs.name,
        order: context.adminFormConfigs.order,
        sql: context.adminFormConfigs.sql,
        searchConfig: context.adminFormConfigs.searchConfig ? JSON.stringify(context.adminFormConfigs.searchConfig, null, 8) : "",
      });
  }, [context.isEditModalVisible])

  React.useEffect(() => {
    handleOpen();
  }, [context.isEditModalVisible, context.isAddModalVisible])

  const handleOpen = () => {
    let open = false;
    if (context.isAddModalVisible || context.isEditModalVisible) {
      open = true;
    }
    setIsAutoGenConfig(!context.isEditModalVisible)
    setOpen(open);
  };

  const handleCancel = () => {
    form.resetFields();
    if (context.isAddModalVisible) {
      context.setIsAddModalVisible(!context.isAddModalVisible);
      return;
    }
    context.setIsEditModalVisible(!context.isEditModalVisible);
  };

  const handleAddNewReport = async (values) => {
    try {
      const resp = await ReportCommonApi.save(values);
      if (!resp.success) {
        dispatch(
          showModal({
            key: "error",
            header: "Something Wrong!",
            msg: resp?.msg || "Error",
            callback: () => {
              dispatch(closeModal());
            },
          })
        );
        return;
      }
      handleCancel();
      context.fetchAllTabOptions();
      context.fetchSearchConfigByTabName();
    } catch (e) {
      console.error(`This error occurred in 'Report's handleAddNewReport'::::`, e)
    }
  };

  const handleEditReport = async (values) => {
    try {
      const resp = await ReportCommonApi.update(context.adminFormConfigs.id, values);
      if (!resp.success) {
        dispatch(
          showModal({
            key: "error",
            header: "Something Wrong!",
            msg: resp?.msg || "Error",
            callback: () => {
              dispatch(closeModal());
            },
          })
        );
        return;
      }
      handleCancel();
      context.fetchAllTabOptions();
      context.fetchSearchConfigByTabName();
    } catch (e) {
      console.error(`This error occurred in 'Report's handleEditReport'::::`, e)
    }
  };

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      centered
      maskClosable={false}
      width={"80vw"}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            form.validateFields().then((values) => {
              try {
                if (context.isEditModalVisible) {
                  handleEditReport({ ...values, searchConfig: values.searchConfig ? JSON.stringify(JSON.parse(values.searchConfig)) : null });
                  return;
                }
                handleAddNewReport(values);
              } catch (e) {
                dispatch(
                  showModal({
                    key: "error",
                    header: "Invalid JSON Format!",
                    msg: "Error",
                    callback: () => {
                      dispatch(closeModal());
                    },
                  })
                );
              }
            })
              .catch((errors) => {
                console.error('Form validation errors:', errors);
              });

          }}
        // onClick={() => {
        //   Promise.all(childCPFormRefs.current.map((formRef) => formRef?.validateFields()))
        //     .then((values) => {
        //       // Perform your submission logic with the validated form values
        //     })
        //     .catch((errors) => {
        //       // Handle form validation errors
        //       console.error('Form validation errors:', errors);
        //     });

        // }}
        >
          {t("t_submit")}
        </Button>
      ]}
    >
      <ReportAdminForm
        form={form}
        isAutoGenConfig={isAutoGenConfig}
        setIsAutoGenConfig={setIsAutoGenConfig}
      />
    </Modal>
  )
}

export default ReportAdminModal;