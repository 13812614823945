import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import PaginationBar from "../paginationBar/PaginationBar";
import styles from "./titleBarMasterPaginationContent.module.scss";
import IconWithTextButton from "../iconWithTextButton/IconWithTextButton";
import { useSelector, useDispatch } from "react-redux";
import { setMasterParams } from "../../redux/tickTableSlice";
import { Select } from 'antd';

const TitleBarItemPaginationContent = (props) => {
  const { setShowMasterModal, setButtonType, reload } = props;
  // const itemTableParams = useSelector(state => state.tickTable.itemParams);
  // const totalCount = useSelector(state => state.tickTable.itemTableData.totalCount);
  const masterTableParams = useSelector(state => state.tickTable.masterParams);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const options = [
    {
      value: 'colorCategory',
      label: t("t_colorCategory"),
    },
    {
      value: 'colorOption',
      label: t("t_colorOption"),
    },
    {
      value: 'category',
      label: t("t_category"),
    },
    {
      value: 'brand',
      label: t("t_brand"),
    },
    {
      value: 'rating',
      label: t("t_rating"),
    },
    {
      value: 'origin',
      label: t("t_origin"),
    },
    {
      value: 'material',
      label: t("t_material"),
    },
    {
      value: 'accessory',
      label: t("t_accessory"),
    },
    {
      value: 'cortex',
      label: t("t_cortex"),
    },
  ]

  const onChange = (e) => {
    dispatch(setMasterParams({...masterTableParams, type: e, sortOptions: {}, input: '', pageNum: 1 }));
  }


  return (
    <>
      <div className={styles.left}>
        <span className={styles.title}>{t("t_masterList")}</span>
      </div>
      <div className={styles.middle}>
        <PaginationBar dispatchParams={setMasterParams} params={masterTableParams} />
      </div>
      <div className={styles.right}>
      <Select
        showSearch
        style={{ width: 150 }}
        placeholder="Search to Select"
        defaultValue={masterTableParams.type}
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
        options={options}
        onChange={onChange}
      />
        <IconWithTextButton type="primary" onBtnClick={() => { setShowMasterModal(true); setButtonType('add'); }} permission="master:add">
          <span>
            <PlusOutlined style={{ pointerEvents: "cursor" }} />
            {t("t_add")}
          </span>
        </IconWithTextButton>
        <ReloadOutlined className={styles.reloadIcon} onClick={reload} />
      </div>
    </>
  );
};

export default memo(TitleBarItemPaginationContent);
