import React, { useEffect, useState } from "react";
import styles from './contractItemDetail.module.scss';
import sampleImg from "../../assets/img/sample1.png";
import { useTranslation } from "react-i18next";
import { Tag, InputNumber, Form, Button, Row, Image } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { setConsignmentRowData } from "../../redux/tickTableSlice";
import { getItemImgSrc } from "../../config/systemConfig";
import { PrefixEnum } from "../../utils/prefixEnum";

const ContractItemDetail = ({ item, isReadOnly, receiptType, createType }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm()
    const dispatch = useDispatch();
    const [isDirectInCostEditMode, setIsDirectInCostEditMode] = useState(false);
    const [isSoldAmtEditMode, setIsSoldAmtEditMode] = useState(false);
    const rowData = useSelector((state) => state.tickTable.consignment.rowData);

    const directInCostOnChange = (e) => {
        const newRowData = rowData.map(data => data.itemNum === item.itemNum ? {...item, directInCost: e} : data)
        dispatch(setConsignmentRowData(newRowData))
    }

    const soldAmtOnChange = (e) => {
        const newRowData = rowData.map(data => data.itemNum === item.itemNum ? {...item, soldAmt: e} : data)
        dispatch(setConsignmentRowData(newRowData))
    }

    useEffect(() => {
        form.setFieldsValue({
            directInCost: item.directInCost || item.amt,
            soldAmt: item.soldAmt || item.amt
        })
    }, [])
    
    return (
        <>
            <div className={styles.itemInfo}>
                {isReadOnly ? 
                <>
                    <div className={styles.itemPhoto}>
                        {/* <img src={item.itemImage || sampleImg} alt="" width={142} /> */}
                        <Image src={getItemImgSrc(item.itemNum)} />
                    </div>
                    <div className={styles.itemDetail}>
                        <div className={styles.line}>{t("t_productName")}: {item.itemNameEn}</div>
                        <div className={styles.line}>{t("t_productNum")}: {item.itemNum}</div>
                        <div className={styles.line}>{t("t_brand")}: {item.brand}</div>
                        <div className={styles.flex}>
                            {
                                item.rating?.split(',').map((rating) => 
                                    <div className={styles.line}><Tag color="default">{rating}</Tag></div>
                                )
                            }
                            {
                                item.color?.split(',').map((color) =>
                                    <div className={styles.line}><Tag color="default">{color}</Tag></div>
                                )
                            }
                        </div>
                        {createType === "contract" &&
                            <div className={styles.line}>{t("t_amount")}: {item.amt ? `HKD ${item.amt}` : "N/A"}</div>
                        }
                        {receiptType === "directIn" &&
                            <div className={styles.line}>{t("t_directInCost")}: {item.directInCost ? `HKD ${item.directInCost}` : "N/A"}</div>
                        }
                        {receiptType === "sold" &&
                            <div className={styles.line}>{t("t_actualSoldAmount")}: {item.soldAmt ? `HKD ${item.soldAmt}` : "N/A"}</div>
                        }
                        { (createType === "contract" && item.directInCost) &&
                            <div className={styles.line}>{t("t_directInCost")}: HKD {item.directInCost}</div>
                        }
                    </div>
                </>
                :
                <>
                    <div className={styles.itemPhoto}>
                        {/* <img src={item.itemImage || sampleImg} alt="" width={142} /> */}
                        <Image src={getItemImgSrc(item.itemNum)} />
                    </div>
                    <div className={styles.itemDetail}>
                        <div className={styles.line}>{t("t_productName")}: {item.itemNameEn}</div>
                        <div className={styles.line}>{t("t_productNum")}: {
                            receiptType === "directIn" && item.itemNum.indexOf(PrefixEnum.DIRECT_IN_ITEM) === -1 ? 
                            `${PrefixEnum.DIRECT_IN_ITEM}${item.itemNum}` : item.itemNum
                        }</div>
                        <div className={styles.line}>{t("t_brand")}: {item.brand?.nameEn}</div>
                        <div className={styles.flex}>
                            {
                                item.rating?.split(',').map((rating) => 
                                    <div className={styles.line}><Tag color="default">{rating}</Tag></div>
                                )
                            }
                            {
                                item.color?.split(',').map((color) =>
                                    <div className={styles.line}><Tag color="default">{color}</Tag></div>
                                )
                            }
                        </div>
                        {createType === "contract" &&
                            <div className={styles.line}>{t("t_amount")}: HKD {item.amt}</div>
                        }
                        {createType === "receipt" && receiptType === "directIn" && isDirectInCostEditMode === false &&
                            <Row>
                                <div className={styles.line}>{t("t_directInCost")}: HKD {item.directInCost || item.amt}</div>
                                <Button size="small" onClick={() => setIsDirectInCostEditMode(true)}> {t("t_changeDirectInCost")}</Button>
                            </Row>
                        }
                        {createType === "receipt" && receiptType === "directIn" && isDirectInCostEditMode === true &&
                            <Form
                                form={form}
                            >
                                <Form.Item
                                    label={t("t_directInCost")}
                                    name="directInCost"
                                >
                                    <InputNumber 
                                        size="small" 
                                        prefix={"HKD"} 
                                        controls={false}
                                        onChange={directInCostOnChange}
                                    />
                                </Form.Item>
                            </Form>
                        }
                        {createType === "receipt" && receiptType === "sold" && isSoldAmtEditMode === false &&
                            <Row>
                                <div className={styles.line}>{t("t_actualSoldAmount")}: HKD {item.soldAmt || item.amt}</div>
                                <Button size="small" onClick={() => setIsSoldAmtEditMode(true)}> {t("t_changeSoldAmt")}</Button>
                            </Row>
                        }
                        {createType === "receipt" && receiptType === "sold" && isSoldAmtEditMode === true &&
                            <Form
                                form={form}
                            >
                                <Form.Item
                                    label={t("t_actualSoldAmount")}
                                    name="soldAmt"
                                >
                                    <InputNumber 
                                        size="small" 
                                        prefix={"HKD"} 
                                        controls={false}
                                        onChange={soldAmtOnChange}
                                    />
                                </Form.Item>
                            </Form>
                        }
                    </div>
                </>
                
                }
            </div>
        </>
    );
}

export default ContractItemDetail;
