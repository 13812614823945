import React from "react";
import { Button } from "antd";
import styles from "./report.module.scss";
import { Context } from "./Report";
import { useTranslation } from "react-i18next";

const ReportAdminBar = () => {
  const context = React.useContext(Context);
  const { t } = useTranslation();

  return (
    <div className={styles.reportEditBarContainer}>
      <span>{t("t_adminEditBar")}</span>
      <div className={styles.functionWrapper}>
        <Button type="primary" onClick={() => context.setIsAddModalVisible(!context.isAddModalVisible)}>Add</Button>
        {
          context.selectedTab && <>
            <Button type="default" onClick={() => context.setIsEditModalVisible(!context.isEditModalVisible)}>Edit</Button>
            <Button type="primary" danger onClick={context.onDelete}>Delete</Button>
          </>
        }
      </div>
    </div>
  )
}

export default ReportAdminBar