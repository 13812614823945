import { configureStore } from "@reduxjs/toolkit";
import tickTableSlice from "./tickTableSlice";
import tickModalSilce from "./tickModalSilce";
import tickSubPageSlice from "./tickSubPageSlice";

export default configureStore({
  reducer: {
    tickTable: tickTableSlice,
    tickModal: tickModalSilce,
    tickSubPage: tickSubPageSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActionPaths: [
      //     "payload.callback", 
      //     "payload.prevAction", 
      //     "payload.nextAction"
      //   ],
      //   ignorePaths: [
      //     "tickTable.params.callback",
      //     "tickSubPage.prevAction",
      //     "tickSubPage.nextAction"
      //   ],
      // },
      serializableCheck: false
    }),
});
