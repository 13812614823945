import React from 'react'
import { TickGlobalModal } from './TickGlobalModal'
import styles from './modal.module.scss'
import infoIcon from "../assets/img/icon/modalInfoIcon.svg";
import { useSelector } from 'react-redux';

export const TickInfoModal = () => {
    const tickGlobalModalState = useSelector((state) => state.tickModal);
    return (
        <TickGlobalModal
            
            header={
                <>
                    <img src={infoIcon} alt="infoIcon-icon" className={styles.icon} />
                    {tickGlobalModalState?.params?.header}
                </>
            }>
            <div className={styles.content}>{tickGlobalModalState?.params?.msg}</div>
        </TickGlobalModal>
    )
}