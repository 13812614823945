export const CONTRACT_STATUS = {
  UNLOCK: {
    label: 't_contractStatusUnlock',
    class: 'green'
  },
  LOCKED: {
    label: "t_contractStatusLocked",
    class: 'red'
  },
  CANCELLED: {
    label: "t_contractStatusCancelled",
    class: 'grey'
  },
}