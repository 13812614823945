import React, { memo, useEffect, useState } from "react";
import TitleBar from "../../components/titleBar/TitleBar";
import variables from "../../theme/variable.scss";
import { useTranslation } from "react-i18next";
import styles from './actionLog.module.scss';
import { tickLog } from "../../utils/logger";
import { useDispatch, useSelector } from "react-redux"
import { setStatusLogParams, setStatusLogTableData } from "../../redux/tickTableSlice";
import TickTable from "../../components/tickTable/TickTable";
import TitleBarStatusLogPaginationContent from "../../components/titleBarStatusLogPaginationContent/TitleBarStatusLogPaginationContent";
import * as dayjs from "dayjs"
import ActionLogApi from "../../service/actionLogApi/actionLog";
import { Tag } from "antd"
import TickDrawer from "../../components/tickDrawer/TickDrawer";
import JsonFormatter from 'react-json-formatter'

const StatusLog = (props) => {
  const {  } = props
  const [dataSource, setDataSource] = useState([]);
  const [masterTableLoading, setMasterTableLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [json, setJson] = useState('');

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const statusLogParams = useSelector(state => state.tickTable.statusLogParams);

  useEffect(() => {
    listByPage()
  }, [statusLogParams])

  const listByPage = async () => {
    setMasterTableLoading(true);
    try {
      const resp = await ActionLogApi.listByPage(statusLogParams);
      tickLog("all product data", resp)
      if (resp.success) {
        const { data, msg } = resp
        setDataSource(data.array)
        dispatch(
          setStatusLogTableData({ totalCount: data.totalCount })
        )
      }
    } catch {
    } finally {
      setMasterTableLoading(false);
    }
  }

  const onReload = () => {
    dispatch(setStatusLogParams({...statusLogParams, sortOptions: {}, input: '', pageNum: 1 }));
  }
  

  const handleSortKey = (sortOrder) => {
    let sortKey;
    if (sortOrder === 'ascend') {
      sortKey = 'ASC'
    }

    if (sortOrder === 'descend') {
      sortKey = 'DESC'
    }
    return sortKey;
  }
  
  const onChange = (pagination, filter, sorter) => {
    let sortOptions = {};
    if (Array.isArray(sorter)) {
      sorter.forEach(sortEle => {
        sortOptions[sortEle.columnKey] = handleSortKey(sortEle.order);
      })
    } else {
      sortOptions[sorter.columnKey] = handleSortKey(sorter.order);
    }
    
    if (!sorter.order) {
      delete sortOptions[sorter.columnKey]
    }

    dispatch(setStatusLogParams({
      ...statusLogParams,
      sortOptions,
    }))
  }

  const sortOrder  = (key) =>  {
    if (statusLogParams.sortOptions[key] === 'ASC') {
      return 'ascend';
    }
    if (statusLogParams.sortOptions[key] === 'DESC') {
      return 'descend';
    }
    return undefined;
  }

  const columns = [
    {
      title: t("t_actionType"),
      dataIndex: "action",
      key: "action",
      sorter: {
        multiple: 7
      },
      sortOrder: sortOrder('action'),
      render: (type) => {
        if (type === 'create') { return <Tag color="green">Create</Tag> }
        if (type === 'update') { return <Tag color="blue">Update</Tag> }
        if (type === 'delete') { return <Tag color="red">Delete</Tag> }
        return '-'
      }
    },
    {
      title: t("t_page"),
      dataIndex: "page",
      key: "page",
      sorter: {
        multiple: 6
      },
      sortOrder: sortOrder('page'),
      render: (_) => (
        <div>{_ || "-"}</div>
      )
    },
    {
      title: t("t_item"),
      dataIndex: "uniqueValue",
      key: "uniqueValue",
      sorter: {
        multiple: 5
      },
      sortOrder: sortOrder('uniqueValue'),
      render: (_) => (
        <div>{_ || "-"}</div>
      )
    },
    {
      title: t("t_api"),
      dataIndex: "api",
      key: "api",
      sorter: {
        multiple: 4
      },
      sortOrder: sortOrder('api'),
      render: (_) => (
        <div>{_ || "-"}</div>
      )
    },
    {
      title: t("t_time"),
      dataIndex: "createdOn",
      key: "createdOn",
      sorter: {
        multiple: 3
      },
      sortOrder: sortOrder('createdOn'),
      render: (_, record) => {
        return <div>{dayjs(record.createdOn).format('YYYY-MM-DD HH:mm:ss')}</div>;
      },
    },
    {
      title: t("t_updateBy"),
      dataIndex: "createdBy",
      key: "createdBy",
      sorter: {
        multiple: 2
      },
      sortOrder: sortOrder('createdBy'),
      render: (_) => (
        <div>{_ || "-"}</div>
      )
    },
    {
      title: t("t_remarks"),
      dataIndex: "remarks",
      key: "remarks",
      sorter: {
        multiple: 1
      },
      sortOrder: sortOrder('remarks'),
      render: (_, record) => {
        return <div>{record.remarks ? record.remarks : '-'}</div>
      },
    },
  ]

  const jsonStyle = {
    propertyStyle: { color: 'red' },
    stringStyle: { color: 'green' },
    numberStyle: { color: 'blue' }
  }


  return (
    <>
      <TitleBar hasSideBar hasShadow color={variables.themeWhite}>
        <TitleBarStatusLogPaginationContent
          reload={onReload}
        />
      </TitleBar>
      <div className={styles.container}>
        <div className={styles.tableWrapper}>
          <TickTable
            dataSource={dataSource}
            // columns={columns[`${masterTableParams.type}`] }
            columns={columns}
            rowKey={(record) => record.id}
            loading={masterTableLoading}
            onChange={onChange}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  if (isDrawerOpen === true && json === record.changes) {
                    setIsDrawerOpen(false);
                  } else {
                    setIsDrawerOpen(true);
                    setJson(record.changes);
                  }
                },
              };
            }}
          />         
        </div>
        <TickDrawer title={t("t_changes")} open={isDrawerOpen} setOpen={setIsDrawerOpen}>
          <div className={styles.drawerContainer}>
            <JsonFormatter json={json} tabWith={4} jsonStyle={jsonStyle} />
          </div>
        </TickDrawer>
      </div>
    </>
  );
};

export default memo(StatusLog);
