import React, { memo, useEffect, useState, useRef } from "react";
import TitleBar from "../../components/titleBar/TitleBar";
import variables from "../../theme/variable.scss";
import { useTranslation } from "react-i18next";
import styles from './contract.module.scss';
import { tickLog } from "../../utils/logger";
import { useDispatch, useSelector } from "react-redux"
import { closeModal, showModal } from "../../redux/tickModalSilce";
import { clearConsignmentRowData, setConsignmentRowData, setContractParams, setContractTableData, setShowItemDrawer } from "../../redux/tickTableSlice";
import TickTable from "../../components/tickTable/TickTable";
import MasterModal from "../../modals/MasterModal";
import i18n from '../../i18n';
import StatusLogApi from "../../service/statusLogApi/statusLog";
import * as dayjs from "dayjs"
import { STATUS } from "../../utils/statusEnum";
import TitleBarContractPaginationContent from "../../components/titleBarContractPaginationContent/TitleBarContractPaginationContent";
import ConsignmentContractApi from "../../service/consignmentContractApi/consignmentContractApi";
import {
  EditOutlined,
  DownloadOutlined,
  DeleteOutlined,
  ShoppingOutlined,
  SyncOutlined
} from "@ant-design/icons";
import { CONTRACT_STATUS } from "../../utils/contractStatusEnum";
import { Tooltip, message } from 'antd';
import ContractEditModal from "../../modals/ContractEditModal";
import TickContractInfo from "../../components/tickContractInfo/TickContractInfo";
import TickDrawer from "../../components/tickDrawer/TickDrawer";
import PrintContractModal from "../../modals/PrintContractModal";
import ContractModal from "../../modals/contractModal/ContractModal";
import ConsignmentModal from "../../modals/ConsignmentModal";
import useQuery from "../../hooks/useQuery"
import { useNavigate, useParams } from "react-router";
import Link from "antd/es/typography/Link";

const editableStatus = ["UNLOCK"];

const isEditable = (status) => {
  return editableStatus.includes(status);
}

const Contract = (props) => {
  const {  } = props
  const [dataSource, setDataSource] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [buttonType, setButtonType] = useState('add');
  const [language, setLanguage] = useState(i18n.language);
  const permissions = JSON.parse(localStorage.getItem('PERMISSION'))
  const [selectedItemList, setSelectedItemList] = useState([])
  const [itemDetails, setItemDetails] = useState([])
  const [contractData, setContractData] = useState()
  const open = useSelector(state => state.tickTable.isItemDrawerOpen);
  const childRef = useRef(null);
  
  // Visibility state
  const [showTable, setShowTable] = useState(false);
  const [showConsignmentModal, setShowConsignmentModal] = useState(false);
  const [showPrintContractModal, setShowPrintContractModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false);

  const [btn, setBtn] = useState("");
  const [modalType, setModalType] = useState("");
  const [contractType, setContractType] = useState("");

  //
  // const [consignorId, setConsignorId] = useState(-1);
  // const [contractNum, setContractNum] = useState("");
  const { consignorId } = useQuery();

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const contractParams = useSelector(state => state.tickTable.contractParams);
  const isDrawerOpen = useSelector(state => state.tickTable.isItemDrawerOpen)

  useEffect(() => {
    localStorage.setItem("TempItems", JSON.stringify([]));
  }, [])

  useEffect(() => { 
    if (id) {
      fetchThenEditContract(id)
    } else {
      dispatch(clearConsignmentRowData());
      setShowConsignmentModal(false);
    }
  }, [id])


  useEffect(() => {
    if (consignorId) {
      setShowConsignmentModal(true)
      dispatch(clearConsignmentRowData())
      setBtn("add")
      setModalType("create")
    } 
    listByPage()
    dispatch(setShowItemDrawer(false))
  }, [contractParams])

  // useEffect(() => {
  //   if (!showConsignmentModal) {
  //     setModalType("")
  //     setBtn("")
  //   }
  // }, [showConsignmentModal])

  const setOpen = (open = false) => {
    dispatch(setShowItemDrawer(open))
  }

  const listByPage = async () => {
    setTableLoading(true);
    try {
      const resp = await ConsignmentContractApi.listByPage(contractParams);
      tickLog("all product data", resp)
      if (resp.success) {
        const { data, msg } = resp
        setDataSource(data.array)
        dispatch(
          setContractTableData({ totalCount: data.totalCount })
        )
      }
    } catch {
    } finally {
      setTableLoading(false);
    }
  }

  const fetchThenEditContract = async (id) => {
    try {
      const response = await ConsignmentContractApi.getById(id);
      if (response.success) {
        // Do the state changing action after the return of the api
        setContractData(response.data)
        setContractType(response.data.type)
        setShowConsignmentModal(true)
        setModalType("edit")
      } 
    } catch (e) {
      dispatch(
        showModal({
          key: "error",
          header: "This is an error message",
          msg: e.message,
          callback: () => {
            dispatch(closeModal());
          },
        })
      );
    }
  }

  const onReload = () => {
    dispatch(setContractParams({...contractParams, sortOptions: {}, input: '', pageNum: 1 }));
  }
  

  const handleSortKey = (sortOrder) => {
    let sortKey;
    if (sortOrder === 'ascend') {
      sortKey = 'ASC'
    }

    if (sortOrder === 'descend') {
      sortKey = 'DESC'
    }
    return sortKey;
  }
  
  const onChange = (pagination, filter, sorter) => {
    let sortOptions = {};
    if (Array.isArray(sorter)) {
      sorter.forEach(sortEle => {
        sortOptions[sortEle.columnKey] = handleSortKey(sortEle.order);
      })
    } else {
      sortOptions[sorter.columnKey] = handleSortKey(sorter.order);
    }
    
    if (!sorter.order) {
      delete sortOptions[sorter.columnKey]
    }

    dispatch(setContractParams({
      ...contractParams,
      sortOptions,
    }))
  }

  const sortOrder  = (key) =>  {
    if (contractParams.sortOptions[key] === 'ASC') {
      return 'ascend';
    }
    if (contractParams.sortOptions[key] === 'DESC') {
      return 'descend';
    }
    return undefined;
  }

  function handleDetails(record) {
    console.log(record);
    setOpen(true);
    setItemDetails(record);
    setSelectedItemList(record.items)
  }

  const columns = [
    {
      title: t("t_contractNum"),
      dataIndex: "contractNum",
      key: "contractNum",
      sorter: {
        multiple: 8
      },
      sortOrder: sortOrder('contractNum'),
      render: (_, record) => (
        record.status === "CANCELED" ?
        <div>{_ || "-"}</div> :
        <div
          // style={{ cursor: "pointer" }}
          // onClick={() => handleDetails(record)}
        >
          {_ || "-"}
        </div>
      )
    },
    {
      title: t("t_consignorNum"),
      dataIndex: "consignorNum",
      key: "consignorNum",
      sorter: {
        multiple: 7
      },
      sortOrder: sortOrder('consignorNum'),
      render: (_, record) => (
        // record.consignor ? <Link href={`/main/consignment/${record.consignor.id}`}>{record.consignor.consignorNum}</Link> : "-"
        record.consignor ? 
        <Link onClick={() => { navigate(`/main/consignment/${record.consignor.id}`) }}>
          {record.consignor.consignorNum}
        </Link> : "-"
      ) 
    },
    {
      title: t("t_fullName"),
      dataIndex: "consignorFullName",
      key: "consignorFullName",
      sorter: {
        multiple: 6
      },
      sortOrder: sortOrder('consignorFullName'),
      render: (_, record) => (
        record.status === "CANCELED" ?
        <div>{_ || "-"}</div> :
        <div
          // style={{ cursor: "pointer" }}
          // onClick={() => handleDetails(record)}
        >
          {_ || "-"}
        </div>
      )
    },
    {
      title: t("t_phoneNum"),
      dataIndex: "consignorPhoneNum",
      key: "consignorPhoneNum",
      sorter: {
        multiple: 5
      },
      sortOrder: sortOrder('consignorPhoneNum'),
      render: (_, record) => (
        record.status === "CANCELED" ?
        <div>{_ || "-"}</div> :
        <div
          // style={{ cursor: "pointer" }}
          // onClick={() => handleDetails(record)}
        >
          {_ || "-"}
        </div>
      )
    },
    {
      title: t("t_status"),
      dataIndex: "status",
      key: "status",
      sorter: {
        multiple: 4
      },
      sortOrder: sortOrder('status'),
      render: (_, record) => (
        <div
          className={styles[`${CONTRACT_STATUS[`${_}`]?.class}`] || styles.default}
        >
          {t(CONTRACT_STATUS?.[`${_}`]?.label || "-")}
        </div>
      )
    },
    {
      title: t("t_consignmentQty"),
      dataIndex: "qty",
      key: "qty",
      sorter: {
        multiple: 3
      },
      sortOrder: sortOrder('qty'),
      render: (_, record) => (
        record.status === "CANCELED" ?
        <div>{_ || "-"}</div> :
        <div
          // style={{ cursor: "pointer" }}
          // onClick={() => handleDetails(record)}
        >
          {_ || "-"}
        </div>
      )
    },
    {
      title: t("t_contractDate"),
      dataIndex: "contractDate",
      key: "contractDate",
      sorter: {
        multiple: 2
      },
      sortOrder: sortOrder('contractDate'),
      render: (_, record) => (
        record.status === "CANCELED" ?
        <div>{_ || "-"}</div> :
        <div
          style={{ cursor: "pointer" }}
          // onClick={() => handleDetails(record)}
        >
          {_ || "-"}
        </div>
      )
    },
    {
      title: t("t_remark"),
      dataIndex: "remark",
      key: "remark",
      sorter: {
        multiple: 1
      },
      sortOrder: sortOrder('remark'),
      render: (_, record) => (
        record.status === "CANCELED" ?
        <div
          style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
        >
          {record?.remark || "-"}
        </div>
        :
        <div
          style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}
          // onClick={() => handleDetails(record)}
        >
          {record?.remark || "-"}
        </div>
      ),
      // ellipsis: true
    },
    {
      title: t("t_function"),
      dataIndex: "operate",
      key: "operate",
      fixed: 'right',
      width: 140,
      render: (_,
        record
      ) =>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          {
            permissions.findIndex((item) => item === "contract:download") > -1 ? 
            // <PrinterOutlined style={{ color: variables.themeBlue, padding: "5px" }} onClick={() => onPrint(record)} /> :
            <DownloadOutlined style={{ color: variables.themeBlue, fontSize: 24 }} onClick={() => onPrint(record)} /> :
            <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
              {/* <PrinterOutlined style={{ color: "grey", padding: "5px" }}/> */}
              <DownloadOutlined style={{ color: "grey", fontSize: 24 }}/>
            </Tooltip>
          }
          {
            isEditable(record.status) && permissions.findIndex((item) => item === "consignor:itemEdit") > -1 ? 
            // <ShoppingOutlined style={{ color: variables.themeBlue, padding: "5px" }}
            <ShoppingOutlined style={{ color: variables.themeBlue, fontSize: 24 }}
              onClick={() => {
                // setShowConsignmentModal(true)
                // setModalType("edit")
                // setContractData(record)
                setContractType(record.type)
                navigate(`/main/contract/${record.id}`);
              }}
          /> :
            <Tooltip title={"沒有權限"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
              {/* <ShoppingOutlined style={{ color:"grey", padding: "5px" }}/> */}
              <ShoppingOutlined style={{ color:"grey", fontSize: 24 }}/>
            </Tooltip>
          }
          {
            isEditable(record.status) ?
            <Tooltip title={t("t_syncContract")} color={"#8c8c8c"} mouseEnterDelay={0.2}>
              <SyncOutlined style={{ color: variables.themeBlue, fontSize: 24 }} onClick={() => onSync(record)} />
            </Tooltip>
            :
            <Tooltip title={"沒有權限/已鎖定"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
              <SyncOutlined style={{ color: "grey", fontSize: 24 }}/>
            </Tooltip>
          }
          {
            isEditable(record.status) && permissions.findIndex((item) => item === "contract:edit") > -1 ? 
            // <EditOutlined style={{ color: variables.themeBlue, padding: "5px" }} onClick={() => onEdit(record)} /> :
            <EditOutlined style={{ color: variables.themeBlue, fontSize: 24 }} onClick={() => onEdit(record)} /> :
            <Tooltip title={"沒有權限/已鎖定"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
              <EditOutlined style={{ color: "grey", fontSize: 24 }}/>
            </Tooltip>
          }
          {
            isEditable(record.status) && permissions.findIndex((item) => item === "contract:delete") > -1 ? 
            // <DeleteOutlined style={{ color: variables.themeRed, padding: "5px" }} onClick={() => onDelete(record)} /> :
            <DeleteOutlined style={{ color: variables.themeRed, fontSize: 24 }} onClick={() => onDelete(record)} /> :
            <Tooltip title={"沒有權限/已鎖定"} color={"#8c8c8c"} mouseEnterDelay={0.2}>
              {/* <DeleteOutlined style={{ color: "grey", padding: "5px" }} />  */}
              <DeleteOutlined style={{ color: "grey", fontSize: 24 }} /> 
            </Tooltip>
          }
        </div>
    }
  ]

  const onEdit = (record) => {
    setShowEditModal(true);
    setButtonType('update');
    setModalData(record);
  }

  const onPrint = (record) => {
    setContractData(record)
    setShowPrintContractModal(true)
  }

  const onDelete = async (record) => {
    dispatch(
      showModal({
        key: "warning",
        header: t("t_delete"),
        msg: t("t_confirmDelete"),
        showCloseBtn: true,
        callback: async () => {
          await ConsignmentContractApi.delete(record.id).then((res) => {
            console.log(res);
            listByPage();
            if (res.success) {
              dispatch(
                showModal({
                  key: "success",
                  header: "Update successfully",
                  callback: () => {
                    dispatch(closeModal());
                  },
                })
              );
            } else {
              dispatch(
                showModal({
                  key: "error",
                  header: "This is an error message",
                  msg: res.msg,
                  callback: () => {
                    dispatch(closeModal());
                  },
                })
              );
            }
          })
        }
      })
    );
  }
  
  const onSync = async (record) => {
    ConsignmentContractApi.sync(record.id).then((res) => {
      if (res.success) {
        message.success(t("t_success"))
        dispatch(setContractParams({...contractParams }));
      } else {
        dispatch(
          showModal({
            key: "error",
            header: "This is an error message",
            msg: res.msg,
            callback: () => {
              dispatch(closeModal());
            },
          })
        );
      }
    })
  }

  return (
    <>
      {
        consignorId ? (
          <>
            {showConsignmentModal && (
              <ContractModal
                showConsignmentModal={showConsignmentModal}
                setShowConsignmentModal={setShowConsignmentModal}
                btn={btn}
                setBtn={setBtn}
                modalType={modalType}
                contract={contractData}
                setContract={setContractData}
                // consignorId={consignorId}
                // contractNum={contractNum}
                contractType={contractType}
                onReload={onReload}
                onSync={onSync}
                onPrint={onPrint}
              />
            )}
            {showPrintContractModal &&
              <PrintContractModal 
                showPrintContractModal={showPrintContractModal}
                setShowPrintContractModal={setShowPrintContractModal}
                contractData={contractData}
              />
            }
          </>
        ) : (
          <>
            <TitleBar hasSideBar hasShadow color={variables.themeWhite}>
              <TitleBarContractPaginationContent
                setShowConsignmentModal={setShowConsignmentModal}
                setBtn={setBtn}
                reload={onReload}
                setModalType={setModalType}
                setContractType={setContractType}
              />
            </TitleBar>
            <div className={styles.container}>
              <div className={styles.tableWrapper}>
                <TickTable
                  dataSource={dataSource}
                  columns={columns}
                  rowKey={(record) => record.id}
                  loading={tableLoading}
                  onChange={onChange}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: event => {
                        const isTargetClicked = event.target.outerHTML.startsWith('<svg') || event.target.outerHTML.startsWith('<path')
                        if (!isTargetClicked) {
                          if (isDrawerOpen && record.id === itemDetails.id) {
                            setOpen(false);
                          } else {
                            setOpen(true);
                            setItemDetails(record);
                            setSelectedItemList(record.items)
                          }
                        }
                      },
                    };
                  }}
                />
              </div>
            <TickDrawer title={`${t("t_contractInfo")} (${itemDetails.contractNum})`} open={open} setOpen={setOpen}>
              <TickContractInfo ref={childRef} selectedItemList={selectedItemList} isReadOnly={true} itemDetails={itemDetails} createType="contract"/>
            </TickDrawer>
            </div>
            {showEditModal &&
              <ContractEditModal
                showMasterModal={showEditModal}
                setShowMasterModal={setShowEditModal}
                width="500px"
                modalData={modalData}
                buttonType={buttonType}
                listByPage={listByPage}
            />}
            {showPrintContractModal &&
              <PrintContractModal 
                showPrintContractModal={showPrintContractModal}
                setShowPrintContractModal={setShowPrintContractModal}
                contractData={contractData}
              />
            }
            {showConsignmentModal && (
              <ContractModal
                showConsignmentModal={showConsignmentModal}
                setShowConsignmentModal={setShowConsignmentModal}
                btn={btn}
                setBtn={setBtn}
                modalType={modalType}
                contract={contractData}
                contractType={contractType}
                setContract={setContractData}
                onReload={onReload}
                onSync={onSync}
                onPrint={onPrint}
              />
            )}
          </>
        )
      }
    </>
  );
};

export default memo(Contract);
