import { useTranslation } from "react-i18next";
import { TickLocalModal } from "./TickLocalModal";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Upload, Modal, message, Image } from "antd";
import styles from "./consignmentModalV2.module.scss";
import { Form, Input, InputNumber, DatePicker, Row, Col, Select } from "antd";
import React, { useState, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCreateConsignmentItems, setUpdateConsignmentItems, setSaveSubmitData, clearCreateConsignmentItems, clearUpdateConsignmentItems, clearTempItemData, editTempItem, setConsignStateToInitialState, setContractParams } from "../redux/tickTableSlice";
import { defaultBranchNum, defaultStockLocation, disabledUploadImg, getItemImgSrc, hideStockLocationItemStatus, lang } from "../config/systemConfig";
import CommonSelection from "../components/commonSelection/CommonSelection";
import { formatFormItemToBody } from "../utils/formatFormItemToBody";
import { formatFormItem } from "../utils/formatFormItem";
import { formatter } from "../utils/formatDatetime";
import dayjs from "dayjs";
import ItemApi from '../service/itemApi/itemApi';
import { closeModal, showModal } from "../redux/tickModalSilce";
import { sizeInputMaxLength } from "../config/systemConfig";
import { handleHandlingFee } from "../utils/handleHandlingFee";
import { tickLog } from "../utils/logger";
import ConsignmentContractApi from "../service/consignmentContractApi/consignmentContractApi";
import { PrefixEnum } from "../utils/prefixEnum";

const TransactionTypeEnum = { consignment: 1, purchase: 2 };

const ConsignmentModalV2 = (props) => {
  const { 
    showConsignmentModal, 
    setShowConsignmentModal, 
    btn, itemIndex, 
    setTempItemsArray, 
    modalType, 
    consignorId, 
    contract = null, // for updating contract
    newContractNum = undefined, // for creating contract
    contractType,
    submitCallback, // for updating consignor's items or contract
    setRefresh,
    action
  } = props;
  const { TextArea } = Input;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const tempItems = JSON.parse(localStorage.getItem("TempItems"));
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);

  const watchPhoto = Form.useWatch("photo", form);

  const tickTableState = useSelector((state) => state.tickTable)
  const dispatch = useDispatch()

  // Gary
  const [loading, setLoading] = useState(false);
  const [transactionTypeId, setTransactionTypeId] = useState();

  const width = window.innerWidth;

  const isShowStockLocation = () => {
    return !hideStockLocationItemStatus.includes(form.getFieldValue("status"))
  }

  //* TEST UPLOAD START - FUNC *//

  useEffect(() => {
    if (watchPhoto) {
      setFileList([
        {
          uid: "-1",
          name: "photo.jpg",
          status: "done",
          url: watchPhoto,
          preview: watchPhoto,
        },
      ]);
    } else {
      setFileList([]);
    }
  }, [watchPhoto]);

  useEffect(() => {
    if (contractType) setTransactionTypeId(TransactionTypeEnum[contractType]);
    else {
      form.setFieldsValue({
        transactionTypeId: TransactionTypeEnum["consignment"]
      })
      setTransactionTypeId(TransactionTypeEnum["consignment"])
    }
  }, [])

  const getBase64Async = async (file) => {
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      tickLog('ImageUpload', 'You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      tickLog('ImageUpload', 'Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    // if (!file.url && !file.preview) {
    //   file.preview = await getBase64Async(file.originFileObj);
    // }
    // setPreviewImage(file.url || (file.preview));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url?.substring(file.url?.lastIndexOf('/') + 1));
  };

  const handleChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList) 

    const file = newFileList.length >= 1 ? newFileList[0] : null;
    if (!file) {
      form.setFieldsValue({ itemImage: null })
      setPreviewImage(undefined);
      setLoading(false);
      return;
    }
    if (file.status === "uploading") {
      setLoading(true);
      return;      
    } else if (file.status === "done") {
      getBase64(file.originFileObj, (url) => {
        // update form state
        form.setFieldsValue({ itemImage: url })
        setPreviewImage(url);
        setLoading(false);
      });
    } else if (file.status === "error") { // error
      dispatch(
        showModal({
          key: "error",
          header: "This is an error message",
          msg: "Upload error",
          callback: () => {
            dispatch(closeModal());
          },
        })
      );
    }
    setLoading(false);
  };

  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t("t_upload")}
      </div>
    </div>
  );

  //* TEST UPLOAD END*//

  const setItemNum = async (index) => {
    if (action === "contract") { 
      const contractNum = contract ? contract.contractNum : newContractNum;
      let itemNumPrefix = contractNum?.substring(2, contractNum?.length);
      if (contractType === "purchase") {
        itemNumPrefix = `${PrefixEnum.PURCHASE_CONTRACT_ITEM}${contractNum?.substring(2, contractNum?.length)}`
      }
      let max = 26; // A-Z index
      let suffixLetterAsciiCode = 64 + Math.floor(index/max); // 64 + (cycle count) for the first letter
      let alphabet = String.fromCharCode(65 + (index%max)); // After A-Z, returns back to A for the second letter
      let itemNum;
      if (suffixLetterAsciiCode === 64) {
        itemNum = itemNumPrefix + alphabet;
      } else {
        itemNum = itemNumPrefix + String.fromCharCode(suffixLetterAsciiCode) + alphabet
      }
      form.setFieldsValue({
        itemNum,
				transactionTypeId: TransactionTypeEnum[contractType]
      })
    } else {
      form.setFieldsValue({
        transactionTypeId: TransactionTypeEnum["consignment"]
      })
      setTransactionTypeId(TransactionTypeEnum["consignment"])
    }
  } 

  useEffect(() => {
    if (modalType === "temp") {
      // if (itemIndex === -1 || !showConsignmentModal) return
      if (btn === "add") {
        form.resetFields();
        setItemNum(tempItems.length);
      } else if (btn === "update") {
        let value = tempItems[itemIndex]
        console.log(value);
        setItemNum(itemIndex);
        form.setFieldsValue({
          transactionDate: dayjs(value?.transactionDate),
          brand: value.brand,
          itemNum: value.itemNum,
          itemNameTc: value.itemNameTc,
          amt: value.amt,
          handlingFee: value.handlingFee,
          onSalesPrice: value.onSalesPrice,
          qty: value.qty,
          category: value.category,
          ratingIds: value.ratingIds,
          model: value.model,
          year: value.year,
          width: value.width,
          height: value.height,
          length: value.length,
          originIds: value.originIds,
          colorIds: value.colorIds,
          cortexIds: value.cortexIds,
          materialIds: value.materialIds,
          mark: value.mark,
          accessoriesIds: value.accessoriesIds,
          itemNameInternal: value.itemNameInternal,
          colorInternal: value.colorInternal,
          remark: value.remark,
          status: value.status,
          itemImage: value.itemImage,
          stockLocation: value.stockLocation,
					directInCost: value.directInCost,
					directInDate: dayjs(value?.directInDate),
        })
      }

    } else if (modalType === "real") {
      console.log("itemIndex", itemIndex)
      if (itemIndex === -1 || !showConsignmentModal) return
      if (btn === "add") {
        form.resetFields();
        setItemNum(tickTableState?.consignment?.rowData?.length);
      } else if (btn === "update") {
        setFieldsValue(itemIndex)
      }
    }
  }, [showConsignmentModal]);

  async function setFieldsValue(id) {

    const resp = await ItemApi.getById(id)
    if (resp.success) {
      let value = resp.data
      form.setFieldsValue({
        transactionTypeId: value?.transactionTypeId,
        transactionDate: value?.transactionDate ? dayjs(value.transactionDate) : null,
        brand: value.deptKey,
        itemNum: value.itemNum,
        itemNameTc: value.itemNameTc,
        amt: value.amt,
        handlingFee: value.handlingFee || 0,
        handlingFeePercentage: value.handlingFeePercentage || 0,
				directInCost: value?.directInCost || 0,
				directInDate: value?.directInDate ? dayjs(value.directInDate) : null,
        onSalesPrice: value.onSalesPrice,
        qty: value.qty,
        category: value.subCatKey,
        ratingIds: value.ratingIds?.split(",").map(Number),
        model: value.model,
        year: value.year,
        width: value.width,
        height: value.height,
        length: value.length,
        originIds: value.originIds?.split(",").map(Number),
        colorIds: value.colorIds?.split(",").map(Number),
        cortexIds: value.cortexIds?.split(",").map(Number),
        materialIds: value.materialIds?.split(",").map(Number),
        mark: value.mark,
        accessoriesIds: value.accessoriesIds?.split(",").map(Number),
        itemNameInternal: value.itemNameInternal,
        colorInternal: value.colorIdsInternal?.split(",").map(Number),
        remark: value.remark,
        status: value.status,
        itemImage: value.itemImage,
        stockLocation: value.stockLocation
      })
      if (value.itemImage) {  
        const imgFormat = value.itemImage.split(';')[0].split('/')[1];
        setFileList([{
          uid: '1',
          name: `item.${imgFormat}`,
          status: 'done',
          url: value.itemImage
        }])
        setPreviewImage(value.itemImage)
      }
      if (value.transactionTypeId) {
        setTransactionTypeId(value.transactionTypeId)
      }
    }
  }

  const onCloseModal = () => {
    form.resetFields();
    setShowConsignmentModal(false);
    dispatch(clearCreateConsignmentItems())
    dispatch(clearUpdateConsignmentItems())
    dispatch(clearTempItemData())
  };

  const onContractRealSaveItem = async (data) => {
    let resp = await ConsignmentContractApi.saveItem(data);
    if (resp.success) {
      setShowConsignmentModal(false);
      submitCallback();
      form.resetFields()
      dispatch(setConsignStateToInitialState())
      dispatch(
        showModal({
          key: "success",
          header: btn == "add" ? "Add Item success" : "Update Item success",
          msg: resp.msg,
          callback: () => {
            dispatch(closeModal());
          },
        })
      );
    } else {
      dispatch(
        showModal({
          key: "error",
          header: "This is an error message",
          msg: resp.msg + ` (itemNum: ${data.itemNum})`,
          callback: () => {
            dispatch(closeModal());
          },
        })
      );
      return;
    }
  }


  const onHandleSubmit = async (e) => {

    const data = form.getFieldsValue()
    console.log(data);
    if (modalType === "temp") {
 
      let storedData = JSON.parse(localStorage.getItem("TempItems"));
      if (btn == "add") {
        storedData.push(data)
        setTempItemsArray(storedData)
        localStorage.setItem("TempItems", JSON.stringify(storedData));
        storedData.forEach(item => item.transactionDate = "") // for killing console error
        dispatch(editTempItem(storedData))

      } else if (btn == "update") {
        storedData[itemIndex] = data
        setTempItemsArray(storedData)
        localStorage.setItem("TempItems", JSON.stringify(storedData));
      }
      form.resetFields();
      setShowConsignmentModal(false);

    } else if (modalType === "real") {
      if (btn == "add") {

        data.itemNameEn = data.itemNameTc
        // data.transactionTypeId = 1
        // data.year = dayjs(data.year).format("YYYY")
        console.log(tickTableState.optionsOfBrand)
        data.deptType = tickTableState.optionsOfBrand.find(i => i.itemDeptKey === data.brand).itemDeptType
        data.deptKey = data.brand
        data.subCatKey = data.category
        data.catKey = tickTableState.optionsOfCategory.all.find(i => i.itemSubCatKey === data.category).itemCatKey
        data.amt = parseInt(data.amt)
        data.onSalesPrice = parseInt(data.onSalesPrice) || 0
        data.colorIds = data.colorIds?.join(",")
        data.cortexIds = data.cortexIds?.join(",")
        data.ratingIds = data.ratingIds?.join(",")
        data.materialIds = data.materialIds?.join(",")
        data.originIds = data.originIds?.toString()
        data.accessoriesIds = data.accessoriesIds?.join(",")
        data.colorIdsInternal = data.colorInternal?.join(",")
        
        delete data.colorInternal
        delete data.brand
        delete data.category

        if (action === "consignor") {
          data.consignorId = consignorId;
          let resp = await ItemApi.save(data);
          if (resp.success) {
            setShowConsignmentModal(false);
            submitCallback();
            form.resetFields()
            dispatch(setConsignStateToInitialState())
            dispatch(
              showModal({
                key: "success",
                header: btn == "add" ? "Add Item success" : "Update Item success",
                msg: resp.msg,
                callback: () => {
                  dispatch(closeModal());
                },
              })
            );
          } else {
            dispatch(
              showModal({
                key: "error",
                header: "This is an error message",
                msg: resp.msg + ` (itemNum: ${data.itemNum})`,
                callback: () => {
                  dispatch(closeModal());
                },
              })
            );
            return;
          }
        } else if (action === "contract") {
          data.consignorId = contract.consignorId;
          data.contractNum = contract.contractNum;

          onContractRealSaveItem(data);
          // const testSaveRes = await ConsignmentContractApi.testSaveItem(data);
          // if (!testSaveRes.success) {
          //   dispatch(
          //     showModal({
          //       key: "confirm",
          //       icon: "warning",
          //       header: t("t_warning"),
          //       msg: t("t_proceedWithNewItemNum", { usedItemNum: data.itemNum, newItemNum: testSaveRes.msg }),
          //       showCloseBtn: true,
          //       // showCloseBtn: true,
          //       callback: async () => {
          //         data.itemNum = testSaveRes.msg;
          //         onContractRealSaveItem(data)
          //       },
          //     })
          //   )
          // } else {
          //   onContractRealSaveItem(data)
          // }
        }

      } else if (btn == "update") {
        data.itemNameTc = data.itemNameTc || null
        data.qty = data.qty ? data.qty : 0
        data.onSalesPrice = data.onSalesPrice ? data.onSalesPrice : 0
        data.handlingFee = data.handlingFee ? data.handlingFee : 0
        data.colorIds = data.colorIds?.join(",")
        data.cortexIds = data.cortexIds?.join(",")
        data.ratingIds = data.ratingIds?.join(",")
        data.materialIds = data.materialIds?.join(",")
        data.accessoriesIds = data.accessoriesIds?.join(",")
        data.remark = data.remark ? data.remark : ""
        data.itemNameInternal = data.itemNameInternal ? data.itemNameInternal : ""
        data.deptKey = data.brand
        data.subCatKey = data.category
        data.colorIdsInternal = data.colorInternal?.join(",")
        data.originIds = data.originIds?.toString() || null
        data.model = data.model || null;

        data.year = data.year || "";
        data.mark = data.mark || "";
        
        delete data.brand
        delete data.category
        delete data.colorInternal
        const resp = await ItemApi.update(itemIndex, data)
        if (resp.success) {
          setShowConsignmentModal(false);
          dispatch(setConsignStateToInitialState())
          dispatch(
            showModal({
              key: "success",
              header: btn == "add" ? "Add Item success" : "Update Item success",
              msg: resp.msg,
              callback: () => {
                dispatch(closeModal());
              },
            })
          );
          setRefresh(true);
        } else {
          dispatch(
            showModal({
              key: "error",
              header: "This is an error message",
              msg: resp.msg,
              callback: () => {
                dispatch(closeModal());
              },
            })
          );
          return;
        }
      }
      if (contract) {
        ConsignmentContractApi.sync(contract.id).then((res) => {
          if (res.success) {
            // message.success(`${t("t_syncContract")}${t("t_success")}`)
            dispatch(setContractParams({ ...tickTableState.contractParams }))
          } else {
            dispatch(
              showModal({
                key: "error",
                header: "This is an error message",
                msg: res.msg,
                callback: () => {
                  dispatch(closeModal());
                },
              })
            );
          }
        })
      }
    }
  }

  const onFieldsChange = (changedValue, originValue) => {
    // if (changedValue.amt) {
    //   form.setFieldsValue({ handlingFee: handleHandlingFee(form.getFieldValue('amt')) })
    // }
    if (changedValue.amt || changedValue.handlingFeePercentage === null) {
      const amt = form.getFieldValue('amt')
      form.setFieldsValue({ handlingFee: handleHandlingFee(amt) })
      if (amt && amt >= 50000 ) {
        form.setFieldsValue({ handlingFeePercentage: 10 })
      } else if (amt && amt >= 2001 && amt <= 49999 ) {
        form.setFieldsValue({ handlingFeePercentage: 15 })
      } else {
        form.setFieldsValue({ handlingFeePercentage: undefined })
      }
    }
    if (changedValue.handlingFeePercentage) {
      let handlingFeeCal = Math.round( form.getFieldValue('amt') * ( form.getFieldValue('handlingFeePercentage') / 100 ) )
      form.setFieldsValue({ handlingFee: handlingFeeCal })
    }
  }

  const consignmentContractFormComponent = () => (
    <>
      <Form.Item
        label={t("t_handlingFeePercentage")}
        name="handlingFeePercentage"
        initialValue={0}
        tooltip={t("t_handlingFeeReminder")}
      >
        <InputNumber
          name="handlingFeePercent"
          min={0}
          max={100}
          // placeholder="0.00"
          addonAfter="%"
          type="number"
          controls={false}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        label={t("t_handlingFee")}
        name="handlingFee"
        initialValue={0}
        tooltip={t("t_handlingFeeReminder")}
      >
        <InputNumber
          name="handlingFee"
          min={0}
          placeholder="0.00"
          prefix="HK$"
          type="number"
          style={{ width: "100%" }}
          disabled
        />
      </Form.Item>
    </>
  )

  const purchaseContractFormComponent = () => (
    <>
      <Form.Item
        label={t("t_directInCost")}
        name="directInCost"
        initialValue={0}
      >
        <InputNumber
          name="directInCost"
          min={0}
          type="number"
          prefix={"$"}
          // max={100}
          // formatter={(value) => `${value}%`}
          // parser={(value) => value.replace("%", "")}
          controls={false}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        label={t("t_directInDate")}
        name="directInDate"
      >
        <DatePicker
          name="directInDate" 
          placeholder={"YYYY-MM-DD"}
          allowClear={true}
          style={{ width: "100%" }}
          onKeyDown={(e) => {
            if (e.code !== "Backspace" && e.target.value?.length == 4 || e.code !== "Backspace" && e.target.value?.length == 7) {
              e.target.value += "-"
            }
          }}
        />
      </Form.Item>
    </>
  )

  return (
    <TickLocalModal
      onSubmit={onHandleSubmit}
      showLocalModal={showConsignmentModal}
      onCancel={onCloseModal}
      title={btn == "add" ? t("t_addNewItem") : t("t_editItem")}
      form={form}
      setVisible={setShowConsignmentModal}
    >
      <Form
        layout={"vertical"}
        form={form}
        onValuesChange={onFieldsChange}
      >
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div style={{ display: "flex", flexDirection: "column", width: "75%" }}>
            <div className={styles.header}>{t("t_generalInformation")}</div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <div className={styles.formCol} style={{ width: "33.3%" }}>
                <Form.Item
                  label={t("t_transactionType")}
                  rules={[{ required: true }]}
                  name="transactionTypeId"
                >
                  <CommonSelection
                    disabled={true}
                    optionKey={`transactionTypeId`}
                  />
                </Form.Item>
                {/* <div style={{ margin: "0 0 32px 0" }}>
                  <div >{formatter(t("t_transactionType"))}</div>
                  <div style={{ margin: "10px 0" }}>{t("t_consignment")}</div>
                </div> */}
                {/* <Form.Item
                  label={t("t_consignor")}
                  rules={[{ required: true }]}
                  name="consignor"
                >
                  <CommonSelection
                    optionKey={`consignor`}
                  />
                </Form.Item> */}
                <Form.Item
                  label={t("t_productNum")}
                  name="itemNum"
                  rules={[{ required: true }]}
                  initialValue={""}
                >
                  <Input
                    name="itemNum"
                    placeholder={t("t_pleaseEnter")}
                    disabled={action === "contract"}
                  />
                </Form.Item>
								{ transactionTypeId === 1 && consignmentContractFormComponent() }
                { transactionTypeId === 2 && purchaseContractFormComponent() }
              </div>
              <div className={styles.formCol} style={{ width: "33.3%" }}>
                <Form.Item
                  label={t("t_date")}
                  name="transactionDate"
                  rules={[{ required: true }]}
                  initialValue={dayjs()}
                >
                  <DatePicker
                    name="transactionDate"
                    placeholder={t("t_pleaseSelect")}
                    allowClear={true}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  label={t("t_productName")}
                  name={`itemName${lang}`}
                  rules={[{ required: true }]}
                >
                  <Input
                    name={`itemName${lang}`}
                    placeholder={t("t_pleaseEnter")}
                  />
                </Form.Item>
                <Form.Item
                  label={t("t_onSalesPrice")}
                  name="onSalesPrice"
                >
                  <InputNumber
                    name="onSalesPrice"
                    prefix="HK$"
                    type="number"
                    placeholder="0.00"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  label={t("t_branchNum")}
                  name="branchNum"
                  initialValue={defaultBranchNum}
                >
                  <Input
                    name="branchNum"
                    placeholder={t("t_pleaseEnter")}
                  />
                </Form.Item>
              </div>
              <div className={styles.formCol} style={{ width: "33.3%" }}>
                <Form.Item
                  label={t("t_brand")}
                  name={`brand`}
                  rules={[{ required: true }]}
                >
                  <CommonSelection
                    optionKey={`brand`}
                  // size={{
                  //   width: "100%",
                  //   margin: "0.5rem 0"
                  // }}
                  />
                </Form.Item>
                <Row justify="space-between">
                  <Col span={11}>
                    <Form.Item
                      label={t("t_price")}
                      name="amt"
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        name="amt"
                        prefix="HK$"
                        placeholder="0.00"
                        // type="number"
                        min={0}
                        controls={false}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      label={t("t_stock")}
                      name="qty"
                      initialValue={1}
                    >
                      <InputNumber
                        name="qty"
                        type="number"
                        min={1}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  label={t("t_status")}
                  name="status"
                  rules={[{ required: true }]}
                  initialValue={"IN_STOCK"}
                >
                  <Select
                    placeholder={t("t_pleaseSelect")}
                    options={[
                      { value: "IN_STOCK", label: t("t_inStock") },
                      { value: "SOLD", label: t("t_sold") },
                      { value: "RESERVED", label: t("t_reserved") },
                      { value: "ONLINE_RESERVED", label: t("t_onlineReserved") },
                      { value: "ONLINE_PURCHASE_RESERVED", label: t("t_onlinePurchaseReserved") },
                    ]}
                  />
                </Form.Item>
                { isShowStockLocation() ? (
                  <Form.Item
                    label={t("t_stockLocation")}
                    name="stockLocation"
                    initialValue={defaultStockLocation}
                  >
                    <Input
                      name="stockLocation"
                      placeholder={t("t_pleaseEnter")}
                    />
                  </Form.Item>
                ) : null}
              </div>
            </div>

            <div className={styles.header}>{t("t_details")}</div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <div className={styles.formCol} style={{ width: "33.3%" }}>
                <Form.Item
                  label={t("t_category")}
                  name={`category`}
                  rules={[{ required: true }]}
                >
                  <CommonSelection
                    optionKey={`category`}
                  />
                </Form.Item>
                <Form.Item
                  label={t("t_year")}
                  name="year"
                // rules={[{ required: true }]}
                >
                  <Input
                    name="year"
                    placeholder={t("t_pleaseEnter")}
                  />
                  {/* <DatePicker.YearPicker picker="year" allowClear={true} /> */}
                </Form.Item>
                <Form.Item
                  label={t("t_color")}
                  name={`colorIds`}
                  rules={[{ required: true }]}
                >
                  <CommonSelection
                    optionKey={`colorIds`}
                    mode="multiple"
                  />
                </Form.Item>
                <Form.Item
                  label={t("t_cortex")}
                  name={`cortexIds`}
                >
                  <CommonSelection
                    optionKey={`cortexIds`}
                    mode="multiple"
                  />
                </Form.Item>
              </div>
              <div className={styles.formCol} style={{ width: "33.3%" }}>
                <Form.Item
                  label={t("t_rating")}
                  name={`ratingIds`}
                  rules={[{required: true}]}
                >
                  <CommonSelection
                    optionKey={`ratingIds`}
                    mode="multiple"
                  />
                </Form.Item>
                <Form.Item
                  label={`${t("t_size")} (H x L x W cm)`}
                >
                  <Form.Item name="height" noStyle>
                    <InputNumber
                      min={0}
                      maxLength={sizeInputMaxLength}
                      // style={{ width: "29.5%" }}
                      style={{ width: width > 1080 ? "29.5%" : "27.3%" }}
                      step="0.1"
                      placeholder="-"
                      type="number"
                    />
                  </Form.Item>
                  <span> x </span>
                  <Form.Item name="length" noStyle>
                    <InputNumber
                      min={0}
                      maxLength={sizeInputMaxLength}
                      // style={{ width: "29.5%" }}
                      style={{ width: width > 1080 ? "29.5%" : "27.3%" }}
                      step="0.1"
                      placeholder="-"
                      type="number"
                      />
                  </Form.Item>
                  <span> x </span>
                  <Form.Item name="width" noStyle>
                    <InputNumber
                      controls="false"
                      min={0}
                      maxLength={sizeInputMaxLength}
                      // style={{ width: "29.5%" }}
                      style={{ width: width > 1080 ? "29.5%" : "27.3%" }}
                      step="0.1"
                      placeholder="-"
                      type="number"
                    />
                  </Form.Item>
                </Form.Item>

                <Form.Item label={t("t_material")} name={`materialIds`}>
                  <CommonSelection
                    optionKey={`materialIds`}
                    mode="multiple"
                  />
                </Form.Item>
                <Form.Item label={t("t_mark")} name="mark">
                  <Input
                    name="mark"
                    placeholder={t("t_pleaseEnter")}
                    maxLength={50}
                  />
                </Form.Item>
              </div>
              <div className={styles.formCol} style={{ width: "33.3%" }}>
                <Form.Item label={t("t_model")} name={"model"}>
                  <Input
                    name="model"
                    placeholder={t("t_pleaseEnter")}
                  />
                </Form.Item>
                <Form.Item
                  label={t("t_countryOfOrigin")}
                  name={`originIds`}
                >
                  <CommonSelection
                    optionKey={`originIds`}
                  />
                </Form.Item>
                <Form.Item
                  label={t("t_accessories")}
                  name={`accessoriesIds`}
                  rules={[{ required: true }]}
                >
                  <CommonSelection
                    optionKey={`accessoriesIds`}
                    mode="multiple"
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", width: "25%" }}>
            <div className={styles.header}>{t("t_uploadPhoto")}</div>
            {/* <Form.Item
              name={"itemImage"}
              // style={{ width: "200px", height: "240px" }}
            >
              <>
                <Upload
                  className="avatar"
                  listType="picture-card"
                  headers={{
                    authorization: 'authorization-text',
                    'Access-Control-Allow-Origin': '*',
                    'X-Requested-With': null
                  }}
                  fileList={fileList}
                  accept="image/png, image/jpeg, image/jpg"
                  // showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                  onPreview={handlePreview}
                  disabled={disabledUploadImg}
                  customRequest={customRequest}
                  style={{ width: "100%" }}
                >
                  {fileList.length >= 1 ? null : uploadButton}
                  {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '96%' }} /> : uploadButton}
                </Upload>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </>
            </Form.Item> */}
            <Image src={getItemImgSrc(form.getFieldValue("itemNum") || null)} />

            <div className={styles.header}>{t("t_descriptionForInternal")}</div>
            <div className={styles.descriptionContainer}>
              <Form.Item label={t("t_productName")} name="itemNameInternal">
                <Input
                  name="itemNameInternal"
                  placeholder={t("t_pleaseEnter")}
                />
              </Form.Item>

              <Form.Item label={t("t_color")} name="colorInternal">
                <CommonSelection
                  optionKey="colorIds"
                  mode="multiple"
                />
              </Form.Item>

              <Form.Item label={t("t_remark")} name="remark">
                <TextArea
                  name="remark"
                  rows={4}
                  placeholder={t("t_pleaseEnter")}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </TickLocalModal>
  );
};

export default memo(ConsignmentModalV2)